import TaxCodeCalculatorInverse from '../components/TaxCodeCalculatorInverse';
import TaxCodeCalculatorInverseDescription from '../components/TaxCodeCalculatorInverseDescription';
import { Box } from '@mui/material';

const TaxCodeInversePage = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(to top, #206F29, #67CBA0)',
        minHeight: '100vh',
        color: '#fff',
        padding: 3,
      }}
    >
      <TaxCodeCalculatorInverse />
      <TaxCodeCalculatorInverseDescription />
    </Box>
  );
};

export default TaxCodeInversePage;
