import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/NavBar';
import TaxCodePage from './pages/TaxCodePage';
import TaxCodeInversePage from './pages/TaxCodeInversePage';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<TaxCodePage />} />
        <Route
          path='/codice-fiscale-inverso'
          element={<TaxCodeInversePage />}
        />
      </Routes>
    </Router>
  );
};

export default App;
