import React, { useState, useEffect } from 'react';
import ResultCard from './ResultCard.jsx';
import CustomAutocomplete from './CustomAutocomplete.jsx';
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import { capitalize } from '../utils/helper.js';
import { generateTaxCode } from '../utils/taxCalculator.js';
import municipalities from '../assets/data/municipalities.js';
import cities from '../assets/data/municipalitiesDataJs.js';

const TaxCodeCalculator = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthDate: { day: 1, month: 0, year: 1980 },
    gender: '',
    birthPlace: '',
    birthPlaceCode: '',
    birthCity: '',
    birthCityCode: '',
  });
  const [result, setResult] = useState('');
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    setCityOptions(cities);
  }, []);

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1900 + 1 },
    (_, i) => 1900 + i
  );

  const handleDateChange = (type, value) => {
    setFormData((prev) => ({
      ...prev,
      birthDate: { ...prev.birthDate, [type]: value },
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const capitalizedValue = ['firstName', 'lastName'].includes(name)
      ? capitalize(value)
      : value;

    setFormData({ ...formData, [name]: capitalizedValue });
  };

  const handleProvinceSelect = (event, value) => {
    if (value) {
      setFormData({
        ...formData,
        birthPlace: value.name,
        birthPlaceCode: value.code,
      });
    }
  };

  const fetchCities = async (query, page) => {
    const pageSize = 100;
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;

    const validCities = cityOptions.filter((city) => city && city.name);

    const filteredCities = validCities.filter((city) =>
      city.name.toLowerCase().includes(query.toLowerCase())
    );

    return filteredCities.slice(startIndex, endIndex);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const birthDate = new Date(
        Date.UTC(
          formData.birthDate.year,
          formData.birthDate.month,
          formData.birthDate.day
        )
      );
      const taxCode = generateTaxCode({
        name: formData.firstName,
        surname: formData.lastName,
        birthDate: birthDate,
        gender: formData.gender,
        place: formData.birthCityCode || formData.birthPlaceCode,
      });
      setResult(taxCode);
    } catch (error) {
      setResult('Errore nella generazione del Codice Fiscale.');
    }
  };

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      minHeight='700px'
      sx={{
        background: 'linear-gradient(to top, #206F29, #67CBA0)',
      }}
    >
      <Card
        sx={{
          maxWidth: 600,
          width: '100%',
          padding: 3,
          borderRadius: '16px',
        }}
      >
        <CardContent>
          <Typography
            variant='h4'
            component='h1'
            textAlign='center'
            gutterBottom
          >
            Calcola il Codice Fiscale
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label='Cognome'
              fullWidth
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              margin='normal'
              required
            />
            <TextField
              label='Nome'
              fullWidth
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              margin='normal'
              required
            />
            <Box
              display='flex'
              gap={2}
              flexDirection={{ xs: 'column', sm: 'row' }}
              marginBottom={2}
            >
              <Select
                fullWidth
                value={formData.birthDate.day}
                onChange={(e) =>
                  handleDateChange('day', Number(e.target.value))
                }
              >
                {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
              <Select
                fullWidth
                value={formData.birthDate.month}
                onChange={(e) =>
                  handleDateChange('month', Number(e.target.value))
                }
              >
                {[...Array(12)].map((_, index) => (
                  <MenuItem key={index} value={index}>
                    {new Date(0, index).toLocaleString('it-IT', {
                      month: 'long',
                    })}
                  </MenuItem>
                ))}
              </Select>
              <Select
                fullWidth
                value={formData.birthDate.year}
                onChange={(e) =>
                  handleDateChange('year', Number(e.target.value))
                }
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Select
              fullWidth
              value={formData.gender}
              onChange={handleChange}
              name='gender'
              required
              displayEmpty
            >
              <MenuItem value='' disabled>
                Sesso
              </MenuItem>
              <MenuItem value='M'>Maschio</MenuItem>
              <MenuItem value='F'>Femmina</MenuItem>
            </Select>
            <CustomAutocomplete
              optionsFetcher={fetchCities}
              label='Comune di nascita'
              onSelect={(value) => {
                setFormData({
                  ...formData,
                  birthCity: value.name,
                  birthCityCode: value.code,
                });
              }}
            />
            <Autocomplete
              options={municipalities}
              getOptionLabel={(option) => `${option.name}`}
              onChange={handleProvinceSelect}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Provincia di nascita'
                  margin='normal'
                />
              )}
            />
            <Button type='submit' variant='contained' fullWidth sx={{ mt: 2 }}>
              Calcola
            </Button>
          </form>
          {result && <ResultCard result={result} />}
        </CardContent>
      </Card>
    </Box>
  );
};

export default TaxCodeCalculator;
