import React, { useState } from "react";
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  InputAdornment,
} from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

const CustomAutocomplete = ({ optionsFetcher, label, onSelect }) => {
  const [query, setQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const fetchOptions = async (searchQuery, nextPage) => {
    setLoading(true);
    const newOptions = await optionsFetcher(searchQuery, nextPage);
    setFilteredOptions((prevOptions) =>
      nextPage === 0 ? newOptions : [...prevOptions, ...newOptions]
    );
    setLoading(false);
  };

  const handleInputChange = async (e) => {
    const input = e.target.value;
    setQuery(input);
    setPage(0);
    fetchOptions(input, 0);
    setDropdownVisible(true);
  };

  const handleScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && !loading) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchOptions(query, nextPage);
    }
  };

  const handleSelect = (option) => {
    setQuery(option.name);
    setDropdownVisible(false);
    onSelect(option);
  };

  const handleBlur = () => {
    setTimeout(() => setDropdownVisible(false), 200);
  };

  return (
    <Box position="relative" sx={{ paddingTop: "16px" }}>
      <TextField
        fullWidth
        label={label}
        value={query}
        onChange={handleInputChange}
        onFocus={() => setDropdownVisible(true)}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ marginLeft: 0, marginRight: "-6px" }}>
              <ArrowDropDown />
            </InputAdornment>
          ),
        }}
      />
      {isDropdownVisible && (
        <Paper
          elevation={3}
          sx={{
            position: "absolute",
            width: "100%",
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: 10,
          }}
          onScroll={handleScroll}
        >
          {filteredOptions.length > 0 ? (
            <List>
              {filteredOptions.map((option) => (
                <ListItem key={option.code} button onClick={() => handleSelect(option)}>
                  <ListItemText primary={option.name} />
                </ListItem>
              ))}
              {loading && (
                <ListItem>
                  <ListItemText primary="Loading more..." />
                </ListItem>
              )}
            </List>
          ) : (
            !loading && (
              <Box padding={2}>
                <Typography>No options found.</Typography>
              </Box>
            )
          )}
        </Paper>
      )}
    </Box>
  );
};

export default CustomAutocomplete;
