import { NavLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position='static'
      sx={{
        background: 'linear-gradient(to right, #206F29, #67CBA0 50%, #206F29)',
        color: '#fff',
      }}
    >
      <Toolbar>
        <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
          Calcolo codice fiscale online
        </Typography>
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2 }}>
          <NavLink
            to='/'
            style={({ isActive }) => ({
              textDecoration: 'none',
              color: isActive ? '#fff' : '#EBEBEB',
              fontWeight: isActive ? 'bold' : 'normal',
            })}
          >
            Calcolatore Codice Fiscale
          </NavLink>
          <NavLink
            to='/codice-fiscale-inverso'
            style={({ isActive }) => ({
              textDecoration: 'none',
              color: isActive ? '#fff' : '#EBEBEB',
              fontWeight: isActive ? 'bold' : 'normal',
            })}
          >
            Codice Fiscale Inverso
          </NavLink>
        </Box>
        <IconButton
          edge='start'
          color='inherit'
          sx={{ display: { sm: 'none' } }}
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ display: { sm: 'none' } }}
        >
          <MenuItem onClick={handleMenuClose}>
            <NavLink to='/' style={{ textDecoration: 'none', color: '#000' }}>
              Calcolatore Codice Fiscale
            </NavLink>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <NavLink
              to='/codice-fiscale-inverso'
              style={{ textDecoration: 'none', color: '#000' }}
            >
              Codice Fiscale Inverso
            </NavLink>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
