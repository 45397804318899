const TaxCodeCalculatorInverseDescription = () => {
  return (
    <div
      style={{
        marginTop: '20px',
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#fff',
        textAlign: 'center',
      }}
    >
      {/*
        Istruzioni per aggiungere il testo:
        - Sostituisci o aggiungi il testo all'interno dei tag <p> qui sotto.
        - Copia e incolla semplicemente il contenuto del testo desiderato.
        - Puoi aggiungere più tag <p> per ulteriori paragrafi.
      */}
      <p>
        Decodificare il Codice Fiscale è un processo che consente di ricavare
        informazioni preziose dalla combinazione alfanumerica unica assegnata a
        ciascun individuo in Italia.
      </p>
      <p>
        Questo strumento offre un modo semplice per analizzare il Codice Fiscale
        e ottenere dettagli su nome, cognome, data e luogo di nascita, e sesso.
      </p>
    </div>
  );
};

export default TaxCodeCalculatorInverseDescription;
