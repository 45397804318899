import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import { decodeTaxCode } from '../utils/taxCalculatorInverse';

const TaxCodeCalculatorInverse = () => {
  const [taxCode, setTaxCode] = useState('');
  const [decodedData, setDecodedData] = useState(null);
  const [error, setError] = useState('');
  const [userData, setUserData] = useState({ firstName: '', lastName: '' });

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
      localStorage.removeItem('userData');
    }
  }, []);

  const handleDecode = () => {
    try {
      setError('');
      const result = decodeTaxCode(taxCode, userData);
      setDecodedData({ ...result, ...userData });
    } catch (err) {
      setDecodedData(null);
      setError(err.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Decodifica il Codice Fiscale</title>
        <meta
          name='description'
          content='Decodifica il tuo Codice Fiscale italiano per ottenere informazioni personali.'
        />
        <meta
          name='keywords'
          content='Codice Fiscale, Decodificatore, Tax Code'
        />
        <meta name='author' content='Codice Fiscale Team' />
      </Helmet>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='500px'
        sx={{
          background: 'linear-gradient(to top, #206F29, #67CBA0)',
        }}
      >
        <Card
          sx={{
            maxWidth: 600,
            width: '100%',
            padding: 3,
            borderRadius: '16px',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            color: '#000',
          }}
        >
          <CardContent>
            <Typography
              variant='h4'
              component='h1'
              textAlign='center'
              gutterBottom
              sx={{
                fontSize: { xs: '1.5rem', sm: '2rem' },
              }}
            >
              Decodifica il Codice Fiscale
            </Typography>
            <TextField
              fullWidth
              label='Inserisci Codice Fiscale'
              value={taxCode}
              onChange={(e) => setTaxCode(e.target.value)}
              margin='normal'
              error={Boolean(error)}
              helperText={error || ''}
            />
            <Button variant='contained' fullWidth onClick={handleDecode}>
              Decodifica
            </Button>
            {decodedData && (
              <Box
                mt={4}
                sx={{
                  backgroundColor: '#d4f8e8',
                  padding: 2,
                  borderRadius: '8px',
                }}
              >
                <Typography variant='h6' textAlign='center' gutterBottom>
                  Risultato:
                </Typography>
                <Typography>
                  <strong>Cognome:</strong> {decodedData.lastName}
                </Typography>
                <Typography>
                  <strong>Nome:</strong> {decodedData.firstName}
                </Typography>
                <Typography>
                  <strong>Data di nascita:</strong> {decodedData.birthDate}
                </Typography>
                <Typography>
                  <strong>Sesso:</strong> {decodedData.gender}
                </Typography>
                <Typography>
                  <strong>Luogo di nascita:</strong> {decodedData.birthPlace}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default TaxCodeCalculatorInverse;
