import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const ResultCard = ({ result }) => {
  return (
    <Card
      sx={{
        marginTop: 2,
        padding: 2,
        backgroundColor: '#d4f8e8',
        border: '1px solid #2e4057',
        borderRadius: '8px',
        textAlign: 'center',
        '@media (max-width: 480px)': {
          padding: 1,
          borderRadius: '6px',
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            textAlign: 'center',
            marginBottom: 1,
          }}
        >
          <Typography
            variant='h5'
            component='h2'
            sx={{
              color: '#2e4057',
              fontSize: { xs: '1.2rem', sm: '1.5rem' },
            }}
          >
            Il tuo codice fiscale è:
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            '@media (max-width: 480px)': {
              textAlign: 'left',
            },
          }}
        >
          <Typography
            variant='body1'
            sx={{
              fontSize: { xs: '1rem', sm: '1.2rem' },
              fontWeight: 'bold',
              color: '#1e272e',
              wordWrap: 'break-word',
            }}
          >
            {result}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ResultCard;
