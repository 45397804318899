import { Box } from '@mui/material';
import TaxCodeCalculator from '../components/TaxCodeCalculator';
import TaxCodeCalculatorDescription from '../components/TaxCodeCalculatorDescription';

const TaxCodePage = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(to top, #206F29, #67CBA0)',
        minHeight: '100vh',
        color: '#fff',
        padding: 3,
      }}
    >
      <TaxCodeCalculator />
      <TaxCodeCalculatorDescription />
    </Box>
  );
};

export default TaxCodePage;
