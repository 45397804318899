import { monthsMap } from './taxCalculator';
import municipalities from '../assets/data/municipalities';

const generateNameOrSurnameCode = (name) => {
  const consonants = name.replace(/[AEIOUaeiou]/g, '').toUpperCase();
  const vowelsInName = name.replace(/[^AEIOUaeiou]/g, '').toUpperCase();

  if (consonants.length >= 4) {
    return consonants[0] + consonants[2] + consonants[3];
  }
  return (consonants + vowelsInName + 'XXX').slice(0, 3);
};

export const decodeTaxCode = (taxCode, userData) => {
  if (!taxCode || taxCode.length !== 16) {
    throw new Error('Codice Fiscale non valido.');
  }

  const surnameCode = taxCode.slice(0, 3);
  const nameCode = taxCode.slice(3, 6);
  const yearCode = taxCode.slice(6, 8);
  const monthCode = taxCode.slice(8, 9);
  const dayCode = taxCode.slice(9, 11);
  const placeCode = taxCode.slice(11, 15);

  const currentYear = new Date().getFullYear();
  const century = currentYear - (currentYear % 100);
  const year =
    parseInt(yearCode, 10) +
    (parseInt(yearCode, 10) > currentYear % 100 ? century - 100 : century);

  const month = Object.keys(monthsMap).find(
    (key) => monthsMap[key] === monthCode
  );

  const rawDay = parseInt(dayCode, 10);
  const gender = rawDay > 31 ? 'F' : 'M';
  const day = rawDay > 31 ? rawDay - 40 : rawDay;

  const birthPlaceEntry = municipalities.find(
    (item) => item.code === placeCode
  );
  const birthPlace = birthPlaceEntry ? birthPlaceEntry.name : 'Sconosciuto';

  const { name: userName, surname: userSurname } = userData;

  const isNameValid = generateNameOrSurnameCode(userName || '') === nameCode;
  const isSurnameValid =
    generateNameOrSurnameCode(userSurname || '') === surnameCode;

  return {
    surname: isSurnameValid ? userSurname : '*****',
    name: isNameValid ? userName : '*****',
    birthDate: new Date(year, parseInt(month), day).toLocaleDateString('it-IT'),
    gender: gender === 'M' ? 'Maschio' : 'Femmina',
    birthPlace: birthPlace,
  };
};
