const municipalitiesData = [
  {
    name: 'Abano Terme',
    code: 'A001',
  },
  {
    name: 'Abbadia Cerreto',
    code: 'A004',
  },
  {
    name: 'Abbadia Lariana',
    code: 'A005',
  },
  {
    name: 'Abbadia San Salvatore',
    code: 'A006',
  },
  {
    name: 'Abbasanta',
    code: 'A007',
  },
  {
    name: 'Abbateggio',
    code: 'A008',
  },
  {
    name: 'Abbiategrasso',
    code: 'A010',
  },
  {
    name: 'Abetone Cutigliano',
    code: 'M376',
  },
  {
    name: 'Abriola',
    code: 'A013',
  },
  {
    name: 'Acate',
    code: 'A014',
  },
  {
    name: 'Accadia',
    code: 'A015',
  },
  {
    name: 'Acceglio',
    code: 'A016',
  },
  {
    name: 'Accettura',
    code: 'A017',
  },
  {
    name: 'Acciano',
    code: 'A018',
  },
  {
    name: 'Accumoli',
    code: 'A019',
  },
  {
    name: 'Acerenza',
    code: 'A020',
  },
  {
    name: 'Acerno',
    code: 'A023',
  },
  {
    name: 'Acerra',
    code: 'A024',
  },
  {
    name: 'Aci Bonaccorsi',
    code: 'A025',
  },
  {
    name: 'Aci Castello',
    code: 'A026',
  },
  {
    name: 'Aci Catena',
    code: 'A027',
  },
  {
    name: "Aci Sant'Antonio",
    code: 'A029',
  },
  {
    name: 'Acireale',
    code: 'A028',
  },
  {
    name: 'Acquafondata',
    code: 'A032',
  },
  {
    name: 'Acquaformosa',
    code: 'A033',
  },
  {
    name: 'Acquafredda',
    code: 'A034',
  },
  {
    name: 'Acqualagna',
    code: 'A035',
  },
  {
    name: 'Acquanegra Cremonese',
    code: 'A039',
  },
  {
    name: 'Acquanegra sul Chiese',
    code: 'A038',
  },
  {
    name: 'Acquapendente',
    code: 'A040',
  },
  {
    name: 'Acquappesa',
    code: 'A041',
  },
  {
    name: 'Acquaro',
    code: 'A043',
  },
  {
    name: 'Acquasanta Terme',
    code: 'A044',
  },
  {
    name: 'Acquasparta',
    code: 'A045',
  },
  {
    name: 'Acquaviva Collecroce',
    code: 'A050',
  },
  {
    name: "Acquaviva d'Isernia",
    code: 'A051',
  },
  {
    name: 'Acquaviva delle Fonti',
    code: 'A048',
  },
  {
    name: 'Acquaviva Picena',
    code: 'A047',
  },
  {
    name: 'Acquaviva Platani',
    code: 'A049',
  },
  {
    name: 'Acquedolci',
    code: 'M211',
  },
  {
    name: 'Acqui Terme',
    code: 'A052',
  },
  {
    name: 'Acri',
    code: 'A053',
  },
  {
    name: 'Acuto',
    code: 'A054',
  },
  {
    name: 'Adelfia',
    code: 'A055',
  },
  {
    name: 'Adrano',
    code: 'A056',
  },
  {
    name: 'Adrara San Martino',
    code: 'A057',
  },
  {
    name: 'Adrara San Rocco',
    code: 'A058',
  },
  {
    name: 'Adria',
    code: 'A059',
  },
  {
    name: 'Adro',
    code: 'A060',
  },
  {
    name: 'Affi',
    code: 'A061',
  },
  {
    name: 'Affile',
    code: 'A062',
  },
  {
    name: 'Afragola',
    code: 'A064',
  },
  {
    name: 'Africo',
    code: 'A065',
  },
  {
    name: 'Agazzano',
    code: 'A067',
  },
  {
    name: 'Agerola',
    code: 'A068',
  },
  {
    name: 'Aggius',
    code: 'A069',
  },
  {
    name: 'Agira',
    code: 'A070',
  },
  {
    name: 'Agliana',
    code: 'A071',
  },
  {
    name: 'Agliano Terme',
    code: 'A072',
  },
  {
    name: 'Agliè',
    code: 'A074',
  },
  {
    name: 'Aglientu',
    code: 'H848',
  },
  {
    name: 'Agna',
    code: 'A075',
  },
  {
    name: 'Agnadello',
    code: 'A076',
  },
  {
    name: 'Agnana Calabra',
    code: 'A077',
  },
  {
    name: 'Agnone',
    code: 'A080',
  },
  {
    name: 'Agnosine',
    code: 'A082',
  },
  {
    name: 'Agordo',
    code: 'A083',
  },
  {
    name: 'Agosta',
    code: 'A084',
  },
  {
    name: 'Agra',
    code: 'A085',
  },
  {
    name: 'Agrate Brianza',
    code: 'A087',
  },
  {
    name: 'Agrate Conturbia',
    code: 'A088',
  },
  {
    name: 'Agrigento',
    code: 'A089',
  },
  {
    name: 'Agropoli',
    code: 'A091',
  },
  {
    name: 'Agugliano',
    code: 'A092',
  },
  {
    name: 'Agugliaro',
    code: 'A093',
  },
  {
    name: 'Aicurzio',
    code: 'A096',
  },
  {
    name: 'Aidomaggiore',
    code: 'A097',
  },
  {
    name: 'Aidone',
    code: 'A098',
  },
  {
    name: 'Aielli',
    code: 'A100',
  },
  {
    name: 'Aiello Calabro',
    code: 'A102',
  },
  {
    name: 'Aiello del Friuli',
    code: 'A103',
  },
  {
    name: 'Aiello del Sabato',
    code: 'A101',
  },
  {
    name: 'Aieta',
    code: 'A105',
  },
  {
    name: 'Ailano',
    code: 'A106',
  },
  {
    name: 'Ailoche',
    code: 'A107',
  },
  {
    name: 'Airasca',
    code: 'A109',
  },
  {
    name: 'Airola',
    code: 'A110',
  },
  {
    name: 'Airole',
    code: 'A111',
  },
  {
    name: 'Airuno',
    code: 'A112',
  },
  {
    name: 'Aisone',
    code: 'A113',
  },
  {
    name: 'Ala',
    code: 'A116',
  },
  {
    name: 'Alà dei Sardi',
    code: 'A115',
  },
  {
    name: 'Ala di Stura',
    code: 'A117',
  },
  {
    name: 'Alagna',
    code: 'A118',
  },
  {
    name: 'Alagna Valsesia',
    code: 'A119',
  },
  {
    name: 'Alanno',
    code: 'A120',
  },
  {
    name: 'Alano di Piave',
    code: 'A121',
  },
  {
    name: 'Alassio',
    code: 'A122',
  },
  {
    name: 'Alatri',
    code: 'A123',
  },
  {
    name: 'Alba',
    code: 'A124',
  },
  {
    name: 'Alba Adriatica',
    code: 'A125',
  },
  {
    name: 'Albagiara',
    code: 'A126',
  },
  {
    name: 'Albairate',
    code: 'A127',
  },
  {
    name: 'Albanella',
    code: 'A128',
  },
  {
    name: 'Albano di Lucania',
    code: 'A131',
  },
  {
    name: 'Albano Laziale',
    code: 'A132',
  },
  {
    name: "Albano Sant'Alessandro",
    code: 'A129',
  },
  {
    name: 'Albano Vercellese',
    code: 'A130',
  },
  {
    name: 'Albaredo Arnaboldi',
    code: 'A134',
  },
  {
    name: "Albaredo d'Adige",
    code: 'A137',
  },
  {
    name: 'Albaredo per San Marco',
    code: 'A135',
  },
  {
    name: 'Albareto',
    code: 'A138',
  },
  {
    name: 'Albaretto della Torre',
    code: 'A139',
  },
  {
    name: 'Albavilla',
    code: 'A143',
  },
  {
    name: 'Albenga',
    code: 'A145',
  },
  {
    name: 'Albera Ligure',
    code: 'A146',
  },
  {
    name: 'Alberobello',
    code: 'A149',
  },
  {
    name: 'Alberona',
    code: 'A150',
  },
  {
    name: 'Albese con Cassano',
    code: 'A153',
  },
  {
    name: 'Albettone',
    code: 'A154',
  },
  {
    name: 'Albi',
    code: 'A155',
  },
  {
    name: 'Albiano',
    code: 'A158',
  },
  {
    name: "Albiano d'Ivrea",
    code: 'A157',
  },
  {
    name: 'Albiate',
    code: 'A159',
  },
  {
    name: 'Albidona',
    code: 'A160',
  },
  {
    name: 'Albignasego',
    code: 'A161',
  },
  {
    name: 'Albinea',
    code: 'A162',
  },
  {
    name: 'Albino',
    code: 'A163',
  },
  {
    name: 'Albiolo',
    code: 'A164',
  },
  {
    name: 'Albisola Superiore',
    code: 'A166',
  },
  {
    name: 'Albissola Marina',
    code: 'A165',
  },
  {
    name: 'Albizzate',
    code: 'A167',
  },
  {
    name: 'Albonese',
    code: 'A171',
  },
  {
    name: 'Albosaggia',
    code: 'A172',
  },
  {
    name: 'Albugnano',
    code: 'A173',
  },
  {
    name: 'Albuzzano',
    code: 'A175',
  },
  {
    name: 'Alcamo',
    code: 'A176',
  },
  {
    name: 'Alcara li Fusi',
    code: 'A177',
  },
  {
    name: 'Aldeno',
    code: 'A178',
  },
  {
    name: 'Aldino',
    code: 'A179',
  },
  {
    name: 'Ales',
    code: 'A180',
  },
  {
    name: 'Alessandria',
    code: 'A182',
  },
  {
    name: 'Alessandria del Carretto',
    code: 'A183',
  },
  {
    name: 'Alessandria della Rocca',
    code: 'A181',
  },
  {
    name: 'Alessano',
    code: 'A184',
  },
  {
    name: 'Alezio',
    code: 'A185',
  },
  {
    name: 'Alfano',
    code: 'A186',
  },
  {
    name: 'Alfedena',
    code: 'A187',
  },
  {
    name: 'Alfianello',
    code: 'A188',
  },
  {
    name: 'Alfiano Natta',
    code: 'A189',
  },
  {
    name: 'Alfonsine',
    code: 'A191',
  },
  {
    name: 'Alghero',
    code: 'A192',
  },
  {
    name: 'Algua',
    code: 'A193',
  },
  {
    name: 'Alì',
    code: 'A194',
  },
  {
    name: 'Alì Terme',
    code: 'A201',
  },
  {
    name: 'Alia',
    code: 'A195',
  },
  {
    name: 'Aliano',
    code: 'A196',
  },
  {
    name: 'Alice Bel Colle',
    code: 'A197',
  },
  {
    name: 'Alice Castello',
    code: 'A198',
  },
  {
    name: 'Alife',
    code: 'A200',
  },
  {
    name: 'Alimena',
    code: 'A202',
  },
  {
    name: 'Aliminusa',
    code: 'A203',
  },
  {
    name: 'Allai',
    code: 'A204',
  },
  {
    name: 'Alleghe',
    code: 'A206',
  },
  {
    name: 'Allein',
    code: 'A205',
  },
  {
    name: 'Allerona',
    code: 'A207',
  },
  {
    name: 'Alliste',
    code: 'A208',
  },
  {
    name: 'Allumiere',
    code: 'A210',
  },
  {
    name: 'Alluvioni Piovera',
    code: 'M397',
  },
  {
    name: 'Almè',
    code: 'A214',
  },
  {
    name: 'Almenno San Bartolomeo',
    code: 'A216',
  },
  {
    name: 'Almenno San Salvatore',
    code: 'A217',
  },
  {
    name: 'Almese',
    code: 'A218',
  },
  {
    name: 'Alonte',
    code: 'A220',
  },
  {
    name: 'Alpago',
    code: 'M375',
  },
  {
    name: 'Alpette',
    code: 'A221',
  },
  {
    name: 'Alpignano',
    code: 'A222',
  },
  {
    name: 'Alseno',
    code: 'A223',
  },
  {
    name: 'Alserio',
    code: 'A224',
  },
  {
    name: 'Alta Val Tidone',
    code: 'M386',
  },
  {
    name: 'Alta Valle Intelvi',
    code: 'M383',
  },
  {
    name: 'Altamura',
    code: 'A225',
  },
  {
    name: 'Altare',
    code: 'A226',
  },
  {
    name: 'Altavalle',
    code: 'M349',
  },
  {
    name: 'Altavilla Irpina',
    code: 'A228',
  },
  {
    name: 'Altavilla Milicia',
    code: 'A229',
  },
  {
    name: 'Altavilla Monferrato',
    code: 'A227',
  },
  {
    name: 'Altavilla Silentina',
    code: 'A230',
  },
  {
    name: 'Altavilla Vicentina',
    code: 'A231',
  },
  {
    name: 'Altidona',
    code: 'A233',
  },
  {
    name: 'Altilia',
    code: 'A234',
  },
  {
    name: 'Altino',
    code: 'A235',
  },
  {
    name: 'Altissimo',
    code: 'A236',
  },
  {
    name: 'Altivole',
    code: 'A237',
  },
  {
    name: 'Alto',
    code: 'A238',
  },
  {
    name: 'Alto Reno Terme',
    code: 'M369',
  },
  {
    name: 'Alto Sermenza',
    code: 'M389',
  },
  {
    name: 'Altofonte',
    code: 'A239',
  },
  {
    name: 'Altomonte',
    code: 'A240',
  },
  {
    name: 'Altopascio',
    code: 'A241',
  },
  {
    name: 'Altopiano della Vigolana',
    code: 'M350',
  },
  {
    name: 'Alviano',
    code: 'A242',
  },
  {
    name: 'Alvignano',
    code: 'A243',
  },
  {
    name: 'Alvito',
    code: 'A244',
  },
  {
    name: 'Alzano Lombardo',
    code: 'A246',
  },
  {
    name: 'Alzano Scrivia',
    code: 'A245',
  },
  {
    name: 'Alzate Brianza',
    code: 'A249',
  },
  {
    name: 'Amalfi',
    code: 'A251',
  },
  {
    name: 'Amandola',
    code: 'A252',
  },
  {
    name: 'Amantea',
    code: 'A253',
  },
  {
    name: 'Amaro',
    code: 'A254',
  },
  {
    name: 'Amaroni',
    code: 'A255',
  },
  {
    name: 'Amaseno',
    code: 'A256',
  },
  {
    name: 'Amato',
    code: 'A257',
  },
  {
    name: 'Amatrice',
    code: 'A258',
  },
  {
    name: 'Ambivere',
    code: 'A259',
  },
  {
    name: 'Amblar-Don',
    code: 'M351',
  },
  {
    name: 'Ameglia',
    code: 'A261',
  },
  {
    name: 'Amelia',
    code: 'A262',
  },
  {
    name: 'Amendolara',
    code: 'A263',
  },
  {
    name: 'Ameno',
    code: 'A264',
  },
  {
    name: 'Amorosi',
    code: 'A265',
  },
  {
    name: 'Ampezzo',
    code: 'A267',
  },
  {
    name: 'Anacapri',
    code: 'A268',
  },
  {
    name: 'Anagni',
    code: 'A269',
  },
  {
    name: 'Ancarano',
    code: 'A270',
  },
  {
    name: 'Ancona',
    code: 'A271',
  },
  {
    name: 'Andali',
    code: 'A272',
  },
  {
    name: 'Andalo',
    code: 'A274',
  },
  {
    name: 'Andalo Valtellino',
    code: 'A273',
  },
  {
    name: 'Andezeno',
    code: 'A275',
  },
  {
    name: 'Andora',
    code: 'A278',
  },
  {
    name: 'Andorno Micca',
    code: 'A280',
  },
  {
    name: 'Andrano',
    code: 'A281',
  },
  {
    name: 'Andrate',
    code: 'A282',
  },
  {
    name: 'Andreis',
    code: 'A283',
  },
  {
    name: 'Andretta',
    code: 'A284',
  },
  {
    name: 'Andria',
    code: 'A285',
  },
  {
    name: 'Andriano',
    code: 'A286',
  },
  {
    name: 'Anela',
    code: 'A287',
  },
  {
    name: 'Anfo',
    code: 'A288',
  },
  {
    name: 'Angera',
    code: 'A290',
  },
  {
    name: 'Anghiari',
    code: 'A291',
  },
  {
    name: 'Angiari',
    code: 'A292',
  },
  {
    name: 'Angolo Terme',
    code: 'A293',
  },
  {
    name: 'Angri',
    code: 'A294',
  },
  {
    name: 'Angrogna',
    code: 'A295',
  },
  {
    name: 'Anguillara Sabazia',
    code: 'A297',
  },
  {
    name: 'Anguillara Veneta',
    code: 'A296',
  },
  {
    name: 'Annicco',
    code: 'A299',
  },
  {
    name: 'Annone di Brianza',
    code: 'A301',
  },
  {
    name: 'Annone Veneto',
    code: 'A302',
  },
  {
    name: 'Anoia',
    code: 'A303',
  },
  {
    name: 'Antegnate',
    code: 'A304',
  },
  {
    name: 'Anterivo',
    code: 'A306',
  },
  {
    name: 'Antey-Saint-André',
    code: 'A305',
  },
  {
    name: 'Anticoli Corrado',
    code: 'A309',
  },
  {
    name: 'Antignano',
    code: 'A312',
  },
  {
    name: 'Antillo',
    code: 'A313',
  },
  {
    name: 'Antonimina',
    code: 'A314',
  },
  {
    name: 'Antrodoco',
    code: 'A315',
  },
  {
    name: 'Antrona Schieranco',
    code: 'A317',
  },
  {
    name: 'Anversa degli Abruzzi',
    code: 'A318',
  },
  {
    name: 'Anzano del Parco',
    code: 'A319',
  },
  {
    name: 'Anzano di Puglia',
    code: 'A320',
  },
  {
    name: 'Anzi',
    code: 'A321',
  },
  {
    name: 'Anzio',
    code: 'A323',
  },
  {
    name: "Anzola d'Ossola",
    code: 'A325',
  },
  {
    name: "Anzola dell'Emilia",
    code: 'A324',
  },
  {
    name: 'Aosta',
    code: 'A326',
  },
  {
    name: 'Apecchio',
    code: 'A327',
  },
  {
    name: 'Apice',
    code: 'A328',
  },
  {
    name: 'Apiro',
    code: 'A329',
  },
  {
    name: 'Apollosa',
    code: 'A330',
  },
  {
    name: 'Appiano Gentile',
    code: 'A333',
  },
  {
    name: 'Appiano sulla strada del vino',
    code: 'A332',
  },
  {
    name: 'Appignano',
    code: 'A334',
  },
  {
    name: 'Appignano del Tronto',
    code: 'A335',
  },
  {
    name: 'Aprica',
    code: 'A337',
  },
  {
    name: 'Apricale',
    code: 'A338',
  },
  {
    name: 'Apricena',
    code: 'A339',
  },
  {
    name: 'Aprigliano',
    code: 'A340',
  },
  {
    name: 'Aprilia',
    code: 'A341',
  },
  {
    name: 'Aquara',
    code: 'A343',
  },
  {
    name: "Aquila d'Arroscia",
    code: 'A344',
  },
  {
    name: 'Aquileia',
    code: 'A346',
  },
  {
    name: 'Aquilonia',
    code: 'A347',
  },
  {
    name: 'Aquino',
    code: 'A348',
  },
  {
    name: 'Aradeo',
    code: 'A350',
  },
  {
    name: 'Aragona',
    code: 'A351',
  },
  {
    name: 'Aramengo',
    code: 'A352',
  },
  {
    name: 'Arba',
    code: 'A354',
  },
  {
    name: 'Arborea',
    code: 'A357',
  },
  {
    name: 'Arborio',
    code: 'A358',
  },
  {
    name: 'Arbus',
    code: 'A359',
  },
  {
    name: 'Arcade',
    code: 'A360',
  },
  {
    name: 'Arce',
    code: 'A363',
  },
  {
    name: 'Arcene',
    code: 'A365',
  },
  {
    name: 'Arcevia',
    code: 'A366',
  },
  {
    name: 'Archi',
    code: 'A367',
  },
  {
    name: 'Arcidosso',
    code: 'A369',
  },
  {
    name: 'Arcinazzo Romano',
    code: 'A370',
  },
  {
    name: 'Arcisate',
    code: 'A371',
  },
  {
    name: 'Arco',
    code: 'A372',
  },
  {
    name: 'Arcola',
    code: 'A373',
  },
  {
    name: 'Arcole',
    code: 'A374',
  },
  {
    name: 'Arconate',
    code: 'A375',
  },
  {
    name: 'Arcore',
    code: 'A376',
  },
  {
    name: 'Arcugnano',
    code: 'A377',
  },
  {
    name: 'Ardara',
    code: 'A379',
  },
  {
    name: 'Ardauli',
    code: 'A380',
  },
  {
    name: 'Ardea',
    code: 'M213',
  },
  {
    name: 'Ardenno',
    code: 'A382',
  },
  {
    name: 'Ardesio',
    code: 'A383',
  },
  {
    name: 'Ardore',
    code: 'A385',
  },
  {
    name: 'Arena',
    code: 'A386',
  },
  {
    name: 'Arena Po',
    code: 'A387',
  },
  {
    name: 'Arenzano',
    code: 'A388',
  },
  {
    name: 'Arese',
    code: 'A389',
  },
  {
    name: 'Arezzo',
    code: 'A390',
  },
  {
    name: 'Argegno',
    code: 'A391',
  },
  {
    name: 'Argelato',
    code: 'A392',
  },
  {
    name: 'Argenta',
    code: 'A393',
  },
  {
    name: 'Argentera',
    code: 'A394',
  },
  {
    name: 'Arguello',
    code: 'A396',
  },
  {
    name: 'Argusto',
    code: 'A397',
  },
  {
    name: 'Ari',
    code: 'A398',
  },
  {
    name: 'Ariano Irpino',
    code: 'A399',
  },
  {
    name: 'Ariano nel Polesine',
    code: 'A400',
  },
  {
    name: 'Ariccia',
    code: 'A401',
  },
  {
    name: 'Arielli',
    code: 'A402',
  },
  {
    name: 'Arienzo',
    code: 'A403',
  },
  {
    name: 'Arignano',
    code: 'A405',
  },
  {
    name: 'Aritzo',
    code: 'A407',
  },
  {
    name: 'Arizzano',
    code: 'A409',
  },
  {
    name: 'Arlena di Castro',
    code: 'A412',
  },
  {
    name: 'Arluno',
    code: 'A413',
  },
  {
    name: 'Armeno',
    code: 'A414',
  },
  {
    name: 'Armento',
    code: 'A415',
  },
  {
    name: 'Armo',
    code: 'A418',
  },
  {
    name: 'Armungia',
    code: 'A419',
  },
  {
    name: 'Arnad',
    code: 'A424',
  },
  {
    name: 'Arnara',
    code: 'A421',
  },
  {
    name: 'Arnasco',
    code: 'A422',
  },
  {
    name: 'Arnesano',
    code: 'A425',
  },
  {
    name: 'Arola',
    code: 'A427',
  },
  {
    name: 'Arona',
    code: 'A429',
  },
  {
    name: 'Arosio',
    code: 'A430',
  },
  {
    name: 'Arpaia',
    code: 'A431',
  },
  {
    name: 'Arpaise',
    code: 'A432',
  },
  {
    name: 'Arpino',
    code: 'A433',
  },
  {
    name: 'Arquà Petrarca',
    code: 'A434',
  },
  {
    name: 'Arquà Polesine',
    code: 'A435',
  },
  {
    name: 'Arquata del Tronto',
    code: 'A437',
  },
  {
    name: 'Arquata Scrivia',
    code: 'A436',
  },
  {
    name: 'Arre',
    code: 'A438',
  },
  {
    name: 'Arrone',
    code: 'A439',
  },
  {
    name: 'Arsago Seprio',
    code: 'A441',
  },
  {
    name: 'Arsiè',
    code: 'A443',
  },
  {
    name: 'Arsiero',
    code: 'A444',
  },
  {
    name: 'Arsita',
    code: 'A445',
  },
  {
    name: 'Arsoli',
    code: 'A446',
  },
  {
    name: 'Arta Terme',
    code: 'A447',
  },
  {
    name: 'Artegna',
    code: 'A448',
  },
  {
    name: 'Artena',
    code: 'A449',
  },
  {
    name: 'Artogne',
    code: 'A451',
  },
  {
    name: 'Arvier',
    code: 'A452',
  },
  {
    name: 'Arzachena',
    code: 'A453',
  },
  {
    name: "Arzago d'Adda",
    code: 'A440',
  },
  {
    name: 'Arzana',
    code: 'A454',
  },
  {
    name: 'Arzano',
    code: 'A455',
  },
  {
    name: 'Arzergrande',
    code: 'A458',
  },
  {
    name: 'Arzignano',
    code: 'A459',
  },
  {
    name: 'Ascea',
    code: 'A460',
  },
  {
    name: 'Asciano',
    code: 'A461',
  },
  {
    name: 'Ascoli Piceno',
    code: 'A462',
  },
  {
    name: 'Ascoli Satriano',
    code: 'A463',
  },
  {
    name: 'Ascrea',
    code: 'A464',
  },
  {
    name: 'Asiago',
    code: 'A465',
  },
  {
    name: 'Asigliano Veneto',
    code: 'A467',
  },
  {
    name: 'Asigliano Vercellese',
    code: 'A466',
  },
  {
    name: 'Asola',
    code: 'A470',
  },
  {
    name: 'Asolo',
    code: 'A471',
  },
  {
    name: 'Assago',
    code: 'A473',
  },
  {
    name: 'Assemini',
    code: 'A474',
  },
  {
    name: 'Assisi',
    code: 'A475',
  },
  {
    name: 'Asso',
    code: 'A476',
  },
  {
    name: 'Assolo',
    code: 'A477',
  },
  {
    name: 'Assoro',
    code: 'A478',
  },
  {
    name: 'Asti',
    code: 'A479',
  },
  {
    name: 'Asuni',
    code: 'A480',
  },
  {
    name: 'Ateleta',
    code: 'A481',
  },
  {
    name: 'Atella',
    code: 'A482',
  },
  {
    name: 'Atena Lucana',
    code: 'A484',
  },
  {
    name: 'Atessa',
    code: 'A485',
  },
  {
    name: 'Atina',
    code: 'A486',
  },
  {
    name: 'Atrani',
    code: 'A487',
  },
  {
    name: 'Atri',
    code: 'A488',
  },
  {
    name: 'Atripalda',
    code: 'A489',
  },
  {
    name: 'Attigliano',
    code: 'A490',
  },
  {
    name: 'Attimis',
    code: 'A491',
  },
  {
    name: 'Atzara',
    code: 'A492',
  },
  {
    name: 'Augusta',
    code: 'A494',
  },
  {
    name: 'Auletta',
    code: 'A495',
  },
  {
    name: 'Aulla',
    code: 'A496',
  },
  {
    name: 'Aurano',
    code: 'A497',
  },
  {
    name: 'Aurigo',
    code: 'A499',
  },
  {
    name: 'Auronzo di Cadore',
    code: 'A501',
  },
  {
    name: 'Ausonia',
    code: 'A502',
  },
  {
    name: 'Austis',
    code: 'A503',
  },
  {
    name: 'Avegno',
    code: 'A506',
  },
  {
    name: 'Avelengo',
    code: 'A507',
  },
  {
    name: 'Avella',
    code: 'A508',
  },
  {
    name: 'Avellino',
    code: 'A509',
  },
  {
    name: 'Averara',
    code: 'A511',
  },
  {
    name: 'Aversa',
    code: 'A512',
  },
  {
    name: 'Avetrana',
    code: 'A514',
  },
  {
    name: 'Avezzano',
    code: 'A515',
  },
  {
    name: 'Aviano',
    code: 'A516',
  },
  {
    name: 'Aviatico',
    code: 'A517',
  },
  {
    name: 'Avigliana',
    code: 'A518',
  },
  {
    name: 'Avigliano',
    code: 'A519',
  },
  {
    name: 'Avigliano Umbro',
    code: 'M258',
  },
  {
    name: 'Avio',
    code: 'A520',
  },
  {
    name: 'Avise',
    code: 'A521',
  },
  {
    name: 'Avola',
    code: 'A522',
  },
  {
    name: 'Avolasca',
    code: 'A523',
  },
  {
    name: 'Ayas',
    code: 'A094',
  },
  {
    name: 'Aymavilles',
    code: 'A108',
  },
  {
    name: 'Azeglio',
    code: 'A525',
  },
  {
    name: 'Azzanello',
    code: 'A526',
  },
  {
    name: "Azzano d'Asti",
    code: 'A527',
  },
  {
    name: 'Azzano Decimo',
    code: 'A530',
  },
  {
    name: 'Azzano Mella',
    code: 'A529',
  },
  {
    name: 'Azzano San Paolo',
    code: 'A528',
  },
  {
    name: 'Azzate',
    code: 'A531',
  },
  {
    name: 'Azzio',
    code: 'A532',
  },
  {
    name: 'Azzone',
    code: 'A533',
  },
  {
    name: 'Baceno',
    code: 'A534',
  },
  {
    name: 'Bacoli',
    code: 'A535',
  },
  {
    name: 'Badalucco',
    code: 'A536',
  },
  {
    name: 'Badesi',
    code: 'M214',
  },
  {
    name: 'Badia',
    code: 'A537',
  },
  {
    name: 'Badia Calavena',
    code: 'A540',
  },
  {
    name: 'Badia Pavese',
    code: 'A538',
  },
  {
    name: 'Badia Polesine',
    code: 'A539',
  },
  {
    name: 'Badia Tedalda',
    code: 'A541',
  },
  {
    name: 'Badolato',
    code: 'A542',
  },
  {
    name: 'Bagaladi',
    code: 'A544',
  },
  {
    name: 'Bagheria',
    code: 'A546',
  },
  {
    name: 'Bagnacavallo',
    code: 'A547',
  },
  {
    name: 'Bagnara Calabra',
    code: 'A552',
  },
  {
    name: 'Bagnara di Romagna',
    code: 'A551',
  },
  {
    name: 'Bagnaria',
    code: 'A550',
  },
  {
    name: 'Bagnaria Arsa',
    code: 'A553',
  },
  {
    name: 'Bagnasco',
    code: 'A555',
  },
  {
    name: 'Bagnatica',
    code: 'A557',
  },
  {
    name: 'Bagni di Lucca',
    code: 'A560',
  },
  {
    name: 'Bagno a Ripoli',
    code: 'A564',
  },
  {
    name: 'Bagno di Romagna',
    code: 'A565',
  },
  {
    name: 'Bagnoli del Trigno',
    code: 'A567',
  },
  {
    name: 'Bagnoli di Sopra',
    code: 'A568',
  },
  {
    name: 'Bagnoli Irpino',
    code: 'A566',
  },
  {
    name: 'Bagnolo Cremasco',
    code: 'A570',
  },
  {
    name: 'Bagnolo del Salento',
    code: 'A572',
  },
  {
    name: 'Bagnolo di Po',
    code: 'A574',
  },
  {
    name: 'Bagnolo in Piano',
    code: 'A573',
  },
  {
    name: 'Bagnolo Mella',
    code: 'A569',
  },
  {
    name: 'Bagnolo Piemonte',
    code: 'A571',
  },
  {
    name: 'Bagnolo San Vito',
    code: 'A575',
  },
  {
    name: 'Bagnone',
    code: 'A576',
  },
  {
    name: 'Bagnoregio',
    code: 'A577',
  },
  {
    name: 'Bagolino',
    code: 'A578',
  },
  {
    name: 'Baia e Latina',
    code: 'A579',
  },
  {
    name: 'Baiano',
    code: 'A580',
  },
  {
    name: 'Bairo',
    code: 'A584',
  },
  {
    name: 'Baiso',
    code: 'A586',
  },
  {
    name: 'Bajardo',
    code: 'A581',
  },
  {
    name: 'Balangero',
    code: 'A587',
  },
  {
    name: "Baldichieri d'Asti",
    code: 'A588',
  },
  {
    name: 'Baldissero Canavese',
    code: 'A590',
  },
  {
    name: "Baldissero d'Alba",
    code: 'A589',
  },
  {
    name: 'Baldissero Torinese',
    code: 'A591',
  },
  {
    name: 'Balestrate',
    code: 'A592',
  },
  {
    name: 'Balestrino',
    code: 'A593',
  },
  {
    name: 'Ballabio',
    code: 'A594',
  },
  {
    name: 'Ballao',
    code: 'A597',
  },
  {
    name: 'Balme',
    code: 'A599',
  },
  {
    name: 'Balmuccia',
    code: 'A600',
  },
  {
    name: 'Balocco',
    code: 'A601',
  },
  {
    name: 'Balsorano',
    code: 'A603',
  },
  {
    name: 'Balvano',
    code: 'A604',
  },
  {
    name: 'Balzola',
    code: 'A605',
  },
  {
    name: 'Banari',
    code: 'A606',
  },
  {
    name: 'Banchette',
    code: 'A607',
  },
  {
    name: 'Bannio Anzino',
    code: 'A610',
  },
  {
    name: 'Banzi',
    code: 'A612',
  },
  {
    name: 'Baone',
    code: 'A613',
  },
  {
    name: 'Baradili',
    code: 'A614',
  },
  {
    name: 'Baragiano',
    code: 'A615',
  },
  {
    name: 'Baranello',
    code: 'A616',
  },
  {
    name: "Barano d'Ischia",
    code: 'A617',
  },
  {
    name: 'Baranzate',
    code: 'A618',
  },
  {
    name: 'Barasso',
    code: 'A619',
  },
  {
    name: 'Baratili San Pietro',
    code: 'A621',
  },
  {
    name: 'Barbania',
    code: 'A625',
  },
  {
    name: 'Barbara',
    code: 'A626',
  },
  {
    name: 'Barbarano Mossano',
    code: 'M401',
  },
  {
    name: 'Barbarano Romano',
    code: 'A628',
  },
  {
    name: 'Barbaresco',
    code: 'A629',
  },
  {
    name: 'Barbariga',
    code: 'A630',
  },
  {
    name: 'Barbata',
    code: 'A631',
  },
  {
    name: 'Barberino di Mugello',
    code: 'A632',
  },
  {
    name: 'Barberino Tavarnelle',
    code: 'M408',
  },
  {
    name: 'Barbianello',
    code: 'A634',
  },
  {
    name: 'Barbiano',
    code: 'A635',
  },
  {
    name: 'Barbona',
    code: 'A637',
  },
  {
    name: 'Barcellona Pozzo di Gotto',
    code: 'A638',
  },
  {
    name: 'Barcis',
    code: 'A640',
  },
  {
    name: 'Bard',
    code: 'A643',
  },
  {
    name: 'Bardello',
    code: 'A645',
  },
  {
    name: 'Bardi',
    code: 'A646',
  },
  {
    name: 'Bardineto',
    code: 'A647',
  },
  {
    name: 'Bardolino',
    code: 'A650',
  },
  {
    name: 'Bardonecchia',
    code: 'A651',
  },
  {
    name: 'Bareggio',
    code: 'A652',
  },
  {
    name: 'Barengo',
    code: 'A653',
  },
  {
    name: 'Baressa',
    code: 'A655',
  },
  {
    name: 'Barete',
    code: 'A656',
  },
  {
    name: 'Barga',
    code: 'A657',
  },
  {
    name: 'Bargagli',
    code: 'A658',
  },
  {
    name: 'Barge',
    code: 'A660',
  },
  {
    name: 'Barghe',
    code: 'A661',
  },
  {
    name: 'Bari',
    code: 'A662',
  },
  {
    name: 'Bari Sardo',
    code: 'A663',
  },
  {
    name: 'Bariano',
    code: 'A664',
  },
  {
    name: 'Baricella',
    code: 'A665',
  },
  {
    name: 'Barile',
    code: 'A666',
  },
  {
    name: 'Barisciano',
    code: 'A667',
  },
  {
    name: 'Barlassina',
    code: 'A668',
  },
  {
    name: 'Barletta',
    code: 'A669',
  },
  {
    name: 'Barni',
    code: 'A670',
  },
  {
    name: 'Barolo',
    code: 'A671',
  },
  {
    name: 'Barone Canavese',
    code: 'A673',
  },
  {
    name: 'Baronissi',
    code: 'A674',
  },
  {
    name: 'Barrafranca',
    code: 'A676',
  },
  {
    name: 'Barrali',
    code: 'A677',
  },
  {
    name: 'Barrea',
    code: 'A678',
  },
  {
    name: 'Barumini',
    code: 'A681',
  },
  {
    name: 'Barzago',
    code: 'A683',
  },
  {
    name: 'Barzana',
    code: 'A684',
  },
  {
    name: 'Barzanò',
    code: 'A686',
  },
  {
    name: 'Barzio',
    code: 'A687',
  },
  {
    name: 'Basaluzzo',
    code: 'A689',
  },
  {
    name: 'Bascapè',
    code: 'A690',
  },
  {
    name: 'Baschi',
    code: 'A691',
  },
  {
    name: 'Basciano',
    code: 'A692',
  },
  {
    name: 'Baselga di Pinè',
    code: 'A694',
  },
  {
    name: 'Baselice',
    code: 'A696',
  },
  {
    name: 'Basiano',
    code: 'A697',
  },
  {
    name: 'Basicò',
    code: 'A698',
  },
  {
    name: 'Basiglio',
    code: 'A699',
  },
  {
    name: 'Basiliano',
    code: 'A700',
  },
  {
    name: 'Bassano Bresciano',
    code: 'A702',
  },
  {
    name: 'Bassano del Grappa',
    code: 'A703',
  },
  {
    name: 'Bassano in Teverina',
    code: 'A706',
  },
  {
    name: 'Bassano Romano',
    code: 'A704',
  },
  {
    name: 'Bassiano',
    code: 'A707',
  },
  {
    name: 'Bassignana',
    code: 'A708',
  },
  {
    name: 'Bastia Mondovì',
    code: 'A709',
  },
  {
    name: 'Bastia Umbra',
    code: 'A710',
  },
  {
    name: 'Bastida Pancarana',
    code: 'A712',
  },
  {
    name: 'Bastiglia',
    code: 'A713',
  },
  {
    name: 'Battaglia Terme',
    code: 'A714',
  },
  {
    name: 'Battifollo',
    code: 'A716',
  },
  {
    name: 'Battipaglia',
    code: 'A717',
  },
  {
    name: 'Battuda',
    code: 'A718',
  },
  {
    name: 'Baucina',
    code: 'A719',
  },
  {
    name: 'Bauladu',
    code: 'A721',
  },
  {
    name: 'Baunei',
    code: 'A722',
  },
  {
    name: 'Baveno',
    code: 'A725',
  },
  {
    name: 'Bedero Valcuvia',
    code: 'A728',
  },
  {
    name: 'Bedizzole',
    code: 'A729',
  },
  {
    name: 'Bedollo',
    code: 'A730',
  },
  {
    name: 'Bedonia',
    code: 'A731',
  },
  {
    name: 'Bedulita',
    code: 'A732',
  },
  {
    name: 'Bee',
    code: 'A733',
  },
  {
    name: 'Beinasco',
    code: 'A734',
  },
  {
    name: 'Beinette',
    code: 'A735',
  },
  {
    name: 'Belcastro',
    code: 'A736',
  },
  {
    name: 'Belfiore',
    code: 'A737',
  },
  {
    name: "Belforte all'Isauro",
    code: 'A740',
  },
  {
    name: 'Belforte del Chienti',
    code: 'A739',
  },
  {
    name: 'Belforte Monferrato',
    code: 'A738',
  },
  {
    name: 'Belgioioso',
    code: 'A741',
  },
  {
    name: 'Belgirate',
    code: 'A742',
  },
  {
    name: 'Bella',
    code: 'A743',
  },
  {
    name: 'Bellagio',
    code: 'M335',
  },
  {
    name: 'Bellano',
    code: 'A745',
  },
  {
    name: 'Bellante',
    code: 'A746',
  },
  {
    name: 'Bellaria-Igea Marina',
    code: 'A747',
  },
  {
    name: 'Bellegra',
    code: 'A749',
  },
  {
    name: 'Bellino',
    code: 'A750',
  },
  {
    name: 'Bellinzago Lombardo',
    code: 'A751',
  },
  {
    name: 'Bellinzago Novarese',
    code: 'A752',
  },
  {
    name: 'Bellizzi',
    code: 'M294',
  },
  {
    name: 'Bellona',
    code: 'A755',
  },
  {
    name: 'Bellosguardo',
    code: 'A756',
  },
  {
    name: 'Belluno',
    code: 'A757',
  },
  {
    name: 'Bellusco',
    code: 'A759',
  },
  {
    name: 'Belmonte Calabro',
    code: 'A762',
  },
  {
    name: 'Belmonte Castello',
    code: 'A763',
  },
  {
    name: 'Belmonte del Sannio',
    code: 'A761',
  },
  {
    name: 'Belmonte in Sabina',
    code: 'A765',
  },
  {
    name: 'Belmonte Mezzagno',
    code: 'A764',
  },
  {
    name: 'Belmonte Piceno',
    code: 'A760',
  },
  {
    name: 'Belpasso',
    code: 'A766',
  },
  {
    name: 'Belsito',
    code: 'A768',
  },
  {
    name: 'Belvedere di Spinello',
    code: 'A772',
  },
  {
    name: 'Belvedere Langhe',
    code: 'A774',
  },
  {
    name: 'Belvedere Marittimo',
    code: 'A773',
  },
  {
    name: 'Belvedere Ostrense',
    code: 'A769',
  },
  {
    name: 'Belveglio',
    code: 'A770',
  },
  {
    name: 'Belvì',
    code: 'A776',
  },
  {
    name: 'Bema',
    code: 'A777',
  },
  {
    name: 'Bene Lario',
    code: 'A778',
  },
  {
    name: 'Bene Vagienna',
    code: 'A779',
  },
  {
    name: 'Benestare',
    code: 'A780',
  },
  {
    name: 'Benetutti',
    code: 'A781',
  },
  {
    name: 'Benevello',
    code: 'A782',
  },
  {
    name: 'Benevento',
    code: 'A783',
  },
  {
    name: 'Benna',
    code: 'A784',
  },
  {
    name: 'Bentivoglio',
    code: 'A785',
  },
  {
    name: 'Berbenno',
    code: 'A786',
  },
  {
    name: 'Berbenno di Valtellina',
    code: 'A787',
  },
  {
    name: 'Berceto',
    code: 'A788',
  },
  {
    name: 'Berchidda',
    code: 'A789',
  },
  {
    name: 'Beregazzo con Figliaro',
    code: 'A791',
  },
  {
    name: 'Bereguardo',
    code: 'A792',
  },
  {
    name: 'Bergamasco',
    code: 'A793',
  },
  {
    name: 'Bergamo',
    code: 'A794',
  },
  {
    name: 'Bergantino',
    code: 'A795',
  },
  {
    name: 'Bergeggi',
    code: 'A796',
  },
  {
    name: 'Bergolo',
    code: 'A798',
  },
  {
    name: 'Berlingo',
    code: 'A799',
  },
  {
    name: 'Bernalda',
    code: 'A801',
  },
  {
    name: 'Bernareggio',
    code: 'A802',
  },
  {
    name: 'Bernate Ticino',
    code: 'A804',
  },
  {
    name: 'Bernezzo',
    code: 'A805',
  },
  {
    name: 'Bertinoro',
    code: 'A809',
  },
  {
    name: 'Bertiolo',
    code: 'A810',
  },
  {
    name: 'Bertonico',
    code: 'A811',
  },
  {
    name: 'Berzano di San Pietro',
    code: 'A812',
  },
  {
    name: 'Berzano di Tortona',
    code: 'A813',
  },
  {
    name: 'Berzo Demo',
    code: 'A816',
  },
  {
    name: 'Berzo Inferiore',
    code: 'A817',
  },
  {
    name: 'Berzo San Fermo',
    code: 'A815',
  },
  {
    name: 'Besana in Brianza',
    code: 'A818',
  },
  {
    name: 'Besano',
    code: 'A819',
  },
  {
    name: 'Besate',
    code: 'A820',
  },
  {
    name: 'Besenello',
    code: 'A821',
  },
  {
    name: 'Besenzone',
    code: 'A823',
  },
  {
    name: 'Besnate',
    code: 'A825',
  },
  {
    name: 'Besozzo',
    code: 'A826',
  },
  {
    name: 'Bessude',
    code: 'A827',
  },
  {
    name: 'Bettola',
    code: 'A831',
  },
  {
    name: 'Bettona',
    code: 'A832',
  },
  {
    name: 'Beura-Cardezza',
    code: 'A834',
  },
  {
    name: 'Bevagna',
    code: 'A835',
  },
  {
    name: 'Beverino',
    code: 'A836',
  },
  {
    name: 'Bevilacqua',
    code: 'A837',
  },
  {
    name: 'Biancavilla',
    code: 'A841',
  },
  {
    name: 'Bianchi',
    code: 'A842',
  },
  {
    name: 'Bianco',
    code: 'A843',
  },
  {
    name: 'Biandrate',
    code: 'A844',
  },
  {
    name: 'Biandronno',
    code: 'A845',
  },
  {
    name: 'Bianzano',
    code: 'A846',
  },
  {
    name: 'Bianzè',
    code: 'A847',
  },
  {
    name: 'Bianzone',
    code: 'A848',
  },
  {
    name: 'Biassono',
    code: 'A849',
  },
  {
    name: 'Bibbiano',
    code: 'A850',
  },
  {
    name: 'Bibbiena',
    code: 'A851',
  },
  {
    name: 'Bibbona',
    code: 'A852',
  },
  {
    name: 'Bibiana',
    code: 'A853',
  },
  {
    name: 'Biccari',
    code: 'A854',
  },
  {
    name: 'Bicinicco',
    code: 'A855',
  },
  {
    name: 'Bidonì',
    code: 'A856',
  },
  {
    name: 'Biella',
    code: 'A859',
  },
  {
    name: 'Bienno',
    code: 'A861',
  },
  {
    name: 'Bieno',
    code: 'A863',
  },
  {
    name: 'Bientina',
    code: 'A864',
  },
  {
    name: 'Binago',
    code: 'A870',
  },
  {
    name: 'Binasco',
    code: 'A872',
  },
  {
    name: 'Binetto',
    code: 'A874',
  },
  {
    name: 'Bioglio',
    code: 'A876',
  },
  {
    name: 'Bionaz',
    code: 'A877',
  },
  {
    name: 'Bione',
    code: 'A878',
  },
  {
    name: 'Birori',
    code: 'A880',
  },
  {
    name: 'Bisaccia',
    code: 'A881',
  },
  {
    name: 'Bisacquino',
    code: 'A882',
  },
  {
    name: 'Bisceglie',
    code: 'A883',
  },
  {
    name: 'Bisegna',
    code: 'A884',
  },
  {
    name: 'Bisenti',
    code: 'A885',
  },
  {
    name: 'Bisignano',
    code: 'A887',
  },
  {
    name: 'Bistagno',
    code: 'A889',
  },
  {
    name: 'Bisuschio',
    code: 'A891',
  },
  {
    name: 'Bitetto',
    code: 'A892',
  },
  {
    name: 'Bitonto',
    code: 'A893',
  },
  {
    name: 'Bitritto',
    code: 'A894',
  },
  {
    name: 'Bitti',
    code: 'A895',
  },
  {
    name: 'Bivona',
    code: 'A896',
  },
  {
    name: 'Bivongi',
    code: 'A897',
  },
  {
    name: 'Bizzarone',
    code: 'A898',
  },
  {
    name: 'Bleggio Superiore',
    code: 'A902',
  },
  {
    name: 'Blello',
    code: 'A903',
  },
  {
    name: 'Blera',
    code: 'A857',
  },
  {
    name: 'Blessagno',
    code: 'A904',
  },
  {
    name: 'Blevio',
    code: 'A905',
  },
  {
    name: 'Blufi',
    code: 'M268',
  },
  {
    name: 'Boara Pisani',
    code: 'A906',
  },
  {
    name: 'Bobbio',
    code: 'A909',
  },
  {
    name: 'Bobbio Pellice',
    code: 'A910',
  },
  {
    name: 'Boca',
    code: 'A911',
  },
  {
    name: 'Bocchigliero',
    code: 'A912',
  },
  {
    name: 'Boccioleto',
    code: 'A914',
  },
  {
    name: 'Bocenago',
    code: 'A916',
  },
  {
    name: 'Bodio Lomnago',
    code: 'A918',
  },
  {
    name: "Boffalora d'Adda",
    code: 'A919',
  },
  {
    name: 'Boffalora sopra Ticino',
    code: 'A920',
  },
  {
    name: 'Bogliasco',
    code: 'A922',
  },
  {
    name: 'Bognanco',
    code: 'A925',
  },
  {
    name: 'Bogogno',
    code: 'A929',
  },
  {
    name: 'Boissano',
    code: 'A931',
  },
  {
    name: 'Bojano',
    code: 'A930',
  },
  {
    name: 'Bolano',
    code: 'A932',
  },
  {
    name: 'Bolgare',
    code: 'A937',
  },
  {
    name: 'Bollate',
    code: 'A940',
  },
  {
    name: 'Bollengo',
    code: 'A941',
  },
  {
    name: 'Bologna',
    code: 'A944',
  },
  {
    name: 'Bolognano',
    code: 'A945',
  },
  {
    name: 'Bolognetta',
    code: 'A946',
  },
  {
    name: 'Bolognola',
    code: 'A947',
  },
  {
    name: 'Bolotana',
    code: 'A948',
  },
  {
    name: 'Bolsena',
    code: 'A949',
  },
  {
    name: 'Boltiere',
    code: 'A950',
  },
  {
    name: 'Bolzano',
    code: 'A952',
  },
  {
    name: 'Bolzano Novarese',
    code: 'A953',
  },
  {
    name: 'Bolzano Vicentino',
    code: 'A954',
  },
  {
    name: 'Bomarzo',
    code: 'A955',
  },
  {
    name: 'Bomba',
    code: 'A956',
  },
  {
    name: 'Bompensiere',
    code: 'A957',
  },
  {
    name: 'Bompietro',
    code: 'A958',
  },
  {
    name: 'Bomporto',
    code: 'A959',
  },
  {
    name: 'Bonarcado',
    code: 'A960',
  },
  {
    name: 'Bonassola',
    code: 'A961',
  },
  {
    name: 'Bonate Sopra',
    code: 'A963',
  },
  {
    name: 'Bonate Sotto',
    code: 'A962',
  },
  {
    name: 'Bonavigo',
    code: 'A964',
  },
  {
    name: 'Bondeno',
    code: 'A965',
  },
  {
    name: 'Bondone',
    code: 'A968',
  },
  {
    name: 'Bonea',
    code: 'A970',
  },
  {
    name: 'Bonefro',
    code: 'A971',
  },
  {
    name: 'Bonemerse',
    code: 'A972',
  },
  {
    name: 'Bonifati',
    code: 'A973',
  },
  {
    name: 'Bonito',
    code: 'A975',
  },
  {
    name: 'Bonnanaro',
    code: 'A976',
  },
  {
    name: 'Bono',
    code: 'A977',
  },
  {
    name: 'Bonorva',
    code: 'A978',
  },
  {
    name: 'Bonvicino',
    code: 'A979',
  },
  {
    name: 'Borbona',
    code: 'A981',
  },
  {
    name: 'Borca di Cadore',
    code: 'A982',
  },
  {
    name: 'Bordano',
    code: 'A983',
  },
  {
    name: 'Bordighera',
    code: 'A984',
  },
  {
    name: 'Bordolano',
    code: 'A986',
  },
  {
    name: 'Bore',
    code: 'A987',
  },
  {
    name: 'Boretto',
    code: 'A988',
  },
  {
    name: 'Borgarello',
    code: 'A989',
  },
  {
    name: 'Borgaro Torinese',
    code: 'A990',
  },
  {
    name: 'Borgetto',
    code: 'A991',
  },
  {
    name: "Borghetto d'Arroscia",
    code: 'A993',
  },
  {
    name: 'Borghetto di Borbera',
    code: 'A998',
  },
  {
    name: 'Borghetto di Vara',
    code: 'A992',
  },
  {
    name: 'Borghetto Lodigiano',
    code: 'A995',
  },
  {
    name: 'Borghetto Santo Spirito',
    code: 'A999',
  },
  {
    name: 'Borghi',
    code: 'B001',
  },
  {
    name: 'Borgia',
    code: 'B002',
  },
  {
    name: 'Borgiallo',
    code: 'B003',
  },
  {
    name: 'Borgio Verezzi',
    code: 'B005',
  },
  {
    name: 'Borgo a Mozzano',
    code: 'B007',
  },
  {
    name: 'Borgo Chiese',
    code: 'M352',
  },
  {
    name: "Borgo d'Ale",
    code: 'B009',
  },
  {
    name: "Borgo d'Anaunia",
    code: 'M429',
  },
  {
    name: 'Borgo di Terzo',
    code: 'B010',
  },
  {
    name: 'Borgo Lares',
    code: 'M353',
  },
  {
    name: 'Borgo Mantovano',
    code: 'M396',
  },
  {
    name: 'Borgo Pace',
    code: 'B026',
  },
  {
    name: 'Borgo Priolo',
    code: 'B028',
  },
  {
    name: 'Borgo San Dalmazzo',
    code: 'B033',
  },
  {
    name: 'Borgo San Giacomo',
    code: 'B035',
  },
  {
    name: 'Borgo San Giovanni',
    code: 'B017',
  },
  {
    name: 'Borgo San Lorenzo',
    code: 'B036',
  },
  {
    name: 'Borgo San Martino',
    code: 'B037',
  },
  {
    name: 'Borgo San Siro',
    code: 'B038',
  },
  {
    name: 'Borgo Ticino',
    code: 'B043',
  },
  {
    name: 'Borgo Tossignano',
    code: 'B044',
  },
  {
    name: 'Borgo Val di Taro',
    code: 'B042',
  },
  {
    name: 'Borgo Valbelluna',
    code: 'M421',
  },
  {
    name: 'Borgo Valsugana',
    code: 'B006',
  },
  {
    name: 'Borgo Velino',
    code: 'A996',
  },
  {
    name: 'Borgo Veneto',
    code: 'M402',
  },
  {
    name: 'Borgo Vercelli',
    code: 'B046',
  },
  {
    name: 'Borgo Virgilio',
    code: 'M340',
  },
  {
    name: 'Borgocarbonara',
    code: 'M406',
  },
  {
    name: "Borgofranco d'Ivrea",
    code: 'B015',
  },
  {
    name: 'Borgolavezzaro',
    code: 'B016',
  },
  {
    name: 'Borgomale',
    code: 'B018',
  },
  {
    name: 'Borgomanero',
    code: 'B019',
  },
  {
    name: 'Borgomaro',
    code: 'B020',
  },
  {
    name: 'Borgomasino',
    code: 'B021',
  },
  {
    name: 'Borgomezzavalle',
    code: 'M370',
  },
  {
    name: 'Borgone Susa',
    code: 'B024',
  },
  {
    name: 'Borgonovo Val Tidone',
    code: 'B025',
  },
  {
    name: 'Borgoratto Alessandrino',
    code: 'B029',
  },
  {
    name: 'Borgoratto Mormorolo',
    code: 'B030',
  },
  {
    name: 'Borgoricco',
    code: 'B031',
  },
  {
    name: 'Borgorose',
    code: 'B008',
  },
  {
    name: 'Borgosatollo',
    code: 'B040',
  },
  {
    name: 'Borgosesia',
    code: 'B041',
  },
  {
    name: 'Bormida',
    code: 'B048',
  },
  {
    name: 'Bormio',
    code: 'B049',
  },
  {
    name: 'Bornasco',
    code: 'B051',
  },
  {
    name: 'Borno',
    code: 'B054',
  },
  {
    name: 'Boroneddu',
    code: 'B055',
  },
  {
    name: 'Borore',
    code: 'B056',
  },
  {
    name: 'Borrello',
    code: 'B057',
  },
  {
    name: 'Borriana',
    code: 'B058',
  },
  {
    name: 'Borso del Grappa',
    code: 'B061',
  },
  {
    name: 'Bortigali',
    code: 'B062',
  },
  {
    name: 'Bortigiadas',
    code: 'B063',
  },
  {
    name: 'Borutta',
    code: 'B064',
  },
  {
    name: 'Borzonasca',
    code: 'B067',
  },
  {
    name: 'Bosa',
    code: 'B068',
  },
  {
    name: 'Bosaro',
    code: 'B069',
  },
  {
    name: "Boschi Sant'Anna",
    code: 'B070',
  },
  {
    name: 'Bosco Chiesanuova',
    code: 'B073',
  },
  {
    name: 'Bosco Marengo',
    code: 'B071',
  },
  {
    name: 'Bosconero',
    code: 'B075',
  },
  {
    name: 'Boscoreale',
    code: 'B076',
  },
  {
    name: 'Boscotrecase',
    code: 'B077',
  },
  {
    name: 'Bosia',
    code: 'B079',
  },
  {
    name: 'Bosio',
    code: 'B080',
  },
  {
    name: 'Bosisio Parini',
    code: 'B081',
  },
  {
    name: 'Bosnasco',
    code: 'B082',
  },
  {
    name: 'Bossico',
    code: 'B083',
  },
  {
    name: 'Bossolasco',
    code: 'B084',
  },
  {
    name: 'Botricello',
    code: 'B085',
  },
  {
    name: 'Botrugno',
    code: 'B086',
  },
  {
    name: 'Bottanuco',
    code: 'B088',
  },
  {
    name: 'Botticino',
    code: 'B091',
  },
  {
    name: 'Bottidda',
    code: 'B094',
  },
  {
    name: 'Bova',
    code: 'B097',
  },
  {
    name: 'Bova Marina',
    code: 'B099',
  },
  {
    name: 'Bovalino',
    code: 'B098',
  },
  {
    name: 'Bovegno',
    code: 'B100',
  },
  {
    name: 'Boves',
    code: 'B101',
  },
  {
    name: 'Bovezzo',
    code: 'B102',
  },
  {
    name: 'Boville Ernica',
    code: 'A720',
  },
  {
    name: 'Bovino',
    code: 'B104',
  },
  {
    name: 'Bovisio-Masciago',
    code: 'B105',
  },
  {
    name: 'Bovolenta',
    code: 'B106',
  },
  {
    name: 'Bovolone',
    code: 'B107',
  },
  {
    name: 'Bozzole',
    code: 'B109',
  },
  {
    name: 'Bozzolo',
    code: 'B110',
  },
  {
    name: 'Bra',
    code: 'B111',
  },
  {
    name: 'Bracca',
    code: 'B112',
  },
  {
    name: 'Bracciano',
    code: 'B114',
  },
  {
    name: 'Bracigliano',
    code: 'B115',
  },
  {
    name: 'Braies',
    code: 'B116',
  },
  {
    name: 'Brallo di Pregola',
    code: 'B117',
  },
  {
    name: 'Brancaleone',
    code: 'B118',
  },
  {
    name: 'Brandico',
    code: 'B120',
  },
  {
    name: 'Brandizzo',
    code: 'B121',
  },
  {
    name: 'Branzi',
    code: 'B123',
  },
  {
    name: 'Braone',
    code: 'B124',
  },
  {
    name: 'Brebbia',
    code: 'B126',
  },
  {
    name: 'Breda di Piave',
    code: 'B128',
  },
  {
    name: 'Bregano',
    code: 'B131',
  },
  {
    name: 'Breganze',
    code: 'B132',
  },
  {
    name: 'Bregnano',
    code: 'B134',
  },
  {
    name: 'Brembate',
    code: 'B137',
  },
  {
    name: 'Brembate di Sopra',
    code: 'B138',
  },
  {
    name: 'Brembio',
    code: 'B141',
  },
  {
    name: 'Breme',
    code: 'B142',
  },
  {
    name: 'Brendola',
    code: 'B143',
  },
  {
    name: 'Brenna',
    code: 'B144',
  },
  {
    name: 'Brennero',
    code: 'B145',
  },
  {
    name: 'Breno',
    code: 'B149',
  },
  {
    name: 'Brenta',
    code: 'B150',
  },
  {
    name: 'Brentino Belluno',
    code: 'B152',
  },
  {
    name: 'Brentonico',
    code: 'B153',
  },
  {
    name: 'Brenzone sul Garda',
    code: 'B154',
  },
  {
    name: 'Brescello',
    code: 'B156',
  },
  {
    name: 'Brescia',
    code: 'B157',
  },
  {
    name: 'Bresimo',
    code: 'B158',
  },
  {
    name: 'Bressana Bottarone',
    code: 'B159',
  },
  {
    name: 'Bressanone',
    code: 'B160',
  },
  {
    name: 'Bressanvido',
    code: 'B161',
  },
  {
    name: 'Bresso',
    code: 'B162',
  },
  {
    name: 'Brezzo di Bedero',
    code: 'B166',
  },
  {
    name: 'Briaglia',
    code: 'B167',
  },
  {
    name: 'Briatico',
    code: 'B169',
  },
  {
    name: 'Bricherasio',
    code: 'B171',
  },
  {
    name: 'Brienno',
    code: 'B172',
  },
  {
    name: 'Brienza',
    code: 'B173',
  },
  {
    name: 'Briga Alta',
    code: 'B175',
  },
  {
    name: 'Briga Novarese',
    code: 'B176',
  },
  {
    name: "Brignano Gera d'Adda",
    code: 'B178',
  },
  {
    name: 'Brignano-Frascata',
    code: 'B179',
  },
  {
    name: 'Brindisi',
    code: 'B180',
  },
  {
    name: 'Brindisi Montagna',
    code: 'B181',
  },
  {
    name: 'Brinzio',
    code: 'B182',
  },
  {
    name: 'Briona',
    code: 'B183',
  },
  {
    name: 'Brione',
    code: 'B184',
  },
  {
    name: 'Briosco',
    code: 'B187',
  },
  {
    name: 'Brisighella',
    code: 'B188',
  },
  {
    name: 'Brissago-Valtravaglia',
    code: 'B191',
  },
  {
    name: 'Brissogne',
    code: 'B192',
  },
  {
    name: 'Brittoli',
    code: 'B193',
  },
  {
    name: 'Brivio',
    code: 'B194',
  },
  {
    name: 'Broccostella',
    code: 'B195',
  },
  {
    name: 'Brogliano',
    code: 'B196',
  },
  {
    name: 'Brognaturo',
    code: 'B197',
  },
  {
    name: 'Brolo',
    code: 'B198',
  },
  {
    name: 'Brondello',
    code: 'B200',
  },
  {
    name: 'Broni',
    code: 'B201',
  },
  {
    name: 'Bronte',
    code: 'B202',
  },
  {
    name: 'Bronzolo',
    code: 'B203',
  },
  {
    name: 'Brossasco',
    code: 'B204',
  },
  {
    name: 'Brosso',
    code: 'B205',
  },
  {
    name: 'Brovello-Carpugnino',
    code: 'B207',
  },
  {
    name: 'Brozolo',
    code: 'B209',
  },
  {
    name: 'Brugherio',
    code: 'B212',
  },
  {
    name: 'Brugine',
    code: 'B213',
  },
  {
    name: 'Brugnato',
    code: 'B214',
  },
  {
    name: 'Brugnera',
    code: 'B215',
  },
  {
    name: 'Bruino',
    code: 'B216',
  },
  {
    name: 'Brumano',
    code: 'B217',
  },
  {
    name: 'Brunate',
    code: 'B218',
  },
  {
    name: 'Brunello',
    code: 'B219',
  },
  {
    name: 'Brunico',
    code: 'B220',
  },
  {
    name: 'Bruno',
    code: 'B221',
  },
  {
    name: 'Brusaporto',
    code: 'B223',
  },
  {
    name: 'Brusasco',
    code: 'B225',
  },
  {
    name: 'Brusciano',
    code: 'B227',
  },
  {
    name: 'Brusimpiano',
    code: 'B228',
  },
  {
    name: 'Brusnengo',
    code: 'B229',
  },
  {
    name: 'Brusson',
    code: 'B230',
  },
  {
    name: 'Bruzolo',
    code: 'B232',
  },
  {
    name: 'Bruzzano Zeffirio',
    code: 'B234',
  },
  {
    name: 'Bubbiano',
    code: 'B235',
  },
  {
    name: 'Bubbio',
    code: 'B236',
  },
  {
    name: 'Buccheri',
    code: 'B237',
  },
  {
    name: 'Bucchianico',
    code: 'B238',
  },
  {
    name: 'Bucciano',
    code: 'B239',
  },
  {
    name: 'Buccinasco',
    code: 'B240',
  },
  {
    name: 'Buccino',
    code: 'B242',
  },
  {
    name: 'Bucine',
    code: 'B243',
  },
  {
    name: 'Buddusò',
    code: 'B246',
  },
  {
    name: 'Budoia',
    code: 'B247',
  },
  {
    name: 'Budoni',
    code: 'B248',
  },
  {
    name: 'Budrio',
    code: 'B249',
  },
  {
    name: 'Buggerru',
    code: 'B250',
  },
  {
    name: 'Buggiano',
    code: 'B251',
  },
  {
    name: 'Buglio in Monte',
    code: 'B255',
  },
  {
    name: 'Bugnara',
    code: 'B256',
  },
  {
    name: 'Buguggiate',
    code: 'B258',
  },
  {
    name: 'Buja',
    code: 'B259',
  },
  {
    name: 'Bulciago',
    code: 'B261',
  },
  {
    name: 'Bulgarograsso',
    code: 'B262',
  },
  {
    name: 'Bultei',
    code: 'B264',
  },
  {
    name: 'Bulzi',
    code: 'B265',
  },
  {
    name: 'Buonabitacolo',
    code: 'B266',
  },
  {
    name: 'Buonalbergo',
    code: 'B267',
  },
  {
    name: 'Buonconvento',
    code: 'B269',
  },
  {
    name: 'Buonvicino',
    code: 'B270',
  },
  {
    name: 'Burago di Molgora',
    code: 'B272',
  },
  {
    name: 'Burcei',
    code: 'B274',
  },
  {
    name: 'Burgio',
    code: 'B275',
  },
  {
    name: 'Burgos',
    code: 'B276',
  },
  {
    name: 'Buriasco',
    code: 'B278',
  },
  {
    name: 'Burolo',
    code: 'B279',
  },
  {
    name: 'Buronzo',
    code: 'B280',
  },
  {
    name: 'Busachi',
    code: 'B281',
  },
  {
    name: 'Busalla',
    code: 'B282',
  },
  {
    name: 'Busano',
    code: 'B284',
  },
  {
    name: 'Busca',
    code: 'B285',
  },
  {
    name: 'Buscate',
    code: 'B286',
  },
  {
    name: 'Buscemi',
    code: 'B287',
  },
  {
    name: 'Buseto Palizzolo',
    code: 'B288',
  },
  {
    name: 'Busnago',
    code: 'B289',
  },
  {
    name: 'Bussero',
    code: 'B292',
  },
  {
    name: 'Busseto',
    code: 'B293',
  },
  {
    name: 'Bussi sul Tirino',
    code: 'B294',
  },
  {
    name: 'Busso',
    code: 'B295',
  },
  {
    name: 'Bussolengo',
    code: 'B296',
  },
  {
    name: 'Bussoleno',
    code: 'B297',
  },
  {
    name: 'Busto Arsizio',
    code: 'B300',
  },
  {
    name: 'Busto Garolfo',
    code: 'B301',
  },
  {
    name: 'Butera',
    code: 'B302',
  },
  {
    name: 'Buti',
    code: 'B303',
  },
  {
    name: 'Buttapietra',
    code: 'B304',
  },
  {
    name: 'Buttigliera Alta',
    code: 'B305',
  },
  {
    name: "Buttigliera d'Asti",
    code: 'B306',
  },
  {
    name: 'Buttrio',
    code: 'B309',
  },
  {
    name: 'Cabella Ligure',
    code: 'B311',
  },
  {
    name: 'Cabiate',
    code: 'B313',
  },
  {
    name: 'Cabras',
    code: 'B314',
  },
  {
    name: 'Caccamo',
    code: 'B315',
  },
  {
    name: 'Caccuri',
    code: 'B319',
  },
  {
    name: 'Cadegliano-Viconago',
    code: 'B326',
  },
  {
    name: 'Cadelbosco di Sopra',
    code: 'B328',
  },
  {
    name: 'Cadeo',
    code: 'B332',
  },
  {
    name: 'Caderzone Terme',
    code: 'B335',
  },
  {
    name: 'Cadoneghe',
    code: 'B345',
  },
  {
    name: 'Cadorago',
    code: 'B346',
  },
  {
    name: 'Cadrezzate con Osmate',
    code: 'M425',
  },
  {
    name: 'Caerano di San Marco',
    code: 'B349',
  },
  {
    name: 'Cafasse',
    code: 'B350',
  },
  {
    name: 'Caggiano',
    code: 'B351',
  },
  {
    name: 'Cagli',
    code: 'B352',
  },
  {
    name: 'Cagliari',
    code: 'B354',
  },
  {
    name: 'Caglio',
    code: 'B355',
  },
  {
    name: 'Cagnano Amiterno',
    code: 'B358',
  },
  {
    name: 'Cagnano Varano',
    code: 'B357',
  },
  {
    name: 'Caianello',
    code: 'B361',
  },
  {
    name: 'Caiazzo',
    code: 'B362',
  },
  {
    name: 'Caines',
    code: 'B364',
  },
  {
    name: 'Caino',
    code: 'B365',
  },
  {
    name: 'Caiolo',
    code: 'B366',
  },
  {
    name: 'Cairano',
    code: 'B367',
  },
  {
    name: 'Cairate',
    code: 'B368',
  },
  {
    name: 'Cairo Montenotte',
    code: 'B369',
  },
  {
    name: 'Caivano',
    code: 'B371',
  },
  {
    name: 'Calabritto',
    code: 'B374',
  },
  {
    name: 'Calalzo di Cadore',
    code: 'B375',
  },
  {
    name: 'Calamandrana',
    code: 'B376',
  },
  {
    name: 'Calamonaci',
    code: 'B377',
  },
  {
    name: 'Calangianus',
    code: 'B378',
  },
  {
    name: 'Calanna',
    code: 'B379',
  },
  {
    name: 'Calasca-Castiglione',
    code: 'B380',
  },
  {
    name: 'Calascibetta',
    code: 'B381',
  },
  {
    name: 'Calascio',
    code: 'B382',
  },
  {
    name: 'Calasetta',
    code: 'B383',
  },
  {
    name: 'Calatabiano',
    code: 'B384',
  },
  {
    name: 'Calatafimi-Segesta',
    code: 'B385',
  },
  {
    name: 'Calcata',
    code: 'B388',
  },
  {
    name: 'Calceranica al Lago',
    code: 'B389',
  },
  {
    name: 'Calci',
    code: 'B390',
  },
  {
    name: 'Calciano',
    code: 'B391',
  },
  {
    name: 'Calcinaia',
    code: 'B392',
  },
  {
    name: 'Calcinate',
    code: 'B393',
  },
  {
    name: 'Calcinato',
    code: 'B394',
  },
  {
    name: 'Calcio',
    code: 'B395',
  },
  {
    name: 'Calco',
    code: 'B396',
  },
  {
    name: 'Caldaro sulla strada del vino',
    code: 'B397',
  },
  {
    name: 'Caldarola',
    code: 'B398',
  },
  {
    name: 'Calderara di Reno',
    code: 'B399',
  },
  {
    name: 'Caldes',
    code: 'B400',
  },
  {
    name: 'Caldiero',
    code: 'B402',
  },
  {
    name: 'Caldogno',
    code: 'B403',
  },
  {
    name: 'Caldonazzo',
    code: 'B404',
  },
  {
    name: 'Calendasco',
    code: 'B405',
  },
  {
    name: 'Calenzano',
    code: 'B406',
  },
  {
    name: 'Calestano',
    code: 'B408',
  },
  {
    name: 'Calice al Cornoviglio',
    code: 'B410',
  },
  {
    name: 'Calice Ligure',
    code: 'B409',
  },
  {
    name: 'Calimera',
    code: 'B413',
  },
  {
    name: 'Calitri',
    code: 'B415',
  },
  {
    name: 'Calizzano',
    code: 'B416',
  },
  {
    name: 'Callabiana',
    code: 'B417',
  },
  {
    name: 'Calliano',
    code: 'B418',
  },
  {
    name: 'Calliano',
    code: 'B419',
  },
  {
    name: 'Calolziocorte',
    code: 'B423',
  },
  {
    name: 'Calopezzati',
    code: 'B424',
  },
  {
    name: 'Calosso',
    code: 'B425',
  },
  {
    name: 'Caloveto',
    code: 'B426',
  },
  {
    name: 'Caltabellotta',
    code: 'B427',
  },
  {
    name: 'Caltagirone',
    code: 'B428',
  },
  {
    name: 'Caltanissetta',
    code: 'B429',
  },
  {
    name: 'Caltavuturo',
    code: 'B430',
  },
  {
    name: 'Caltignaga',
    code: 'B431',
  },
  {
    name: 'Calto',
    code: 'B432',
  },
  {
    name: 'Caltrano',
    code: 'B433',
  },
  {
    name: "Calusco d'Adda",
    code: 'B434',
  },
  {
    name: 'Caluso',
    code: 'B435',
  },
  {
    name: 'Calvagese della Riviera',
    code: 'B436',
  },
  {
    name: 'Calvanico',
    code: 'B437',
  },
  {
    name: 'Calvatone',
    code: 'B439',
  },
  {
    name: 'Calvello',
    code: 'B440',
  },
  {
    name: 'Calvene',
    code: 'B441',
  },
  {
    name: 'Calvenzano',
    code: 'B442',
  },
  {
    name: 'Calvera',
    code: 'B443',
  },
  {
    name: 'Calvi',
    code: 'B444',
  },
  {
    name: "Calvi dell'Umbria",
    code: 'B446',
  },
  {
    name: 'Calvi Risorta',
    code: 'B445',
  },
  {
    name: 'Calvignano',
    code: 'B447',
  },
  {
    name: 'Calvignasco',
    code: 'B448',
  },
  {
    name: 'Calvisano',
    code: 'B450',
  },
  {
    name: 'Calvizzano',
    code: 'B452',
  },
  {
    name: 'Camagna Monferrato',
    code: 'B453',
  },
  {
    name: 'Camaiore',
    code: 'B455',
  },
  {
    name: 'Camandona',
    code: 'B457',
  },
  {
    name: 'Camastra',
    code: 'B460',
  },
  {
    name: 'Cambiago',
    code: 'B461',
  },
  {
    name: 'Cambiano',
    code: 'B462',
  },
  {
    name: 'Cambiasca',
    code: 'B463',
  },
  {
    name: 'Camburzano',
    code: 'B465',
  },
  {
    name: 'Camerana',
    code: 'B467',
  },
  {
    name: 'Camerano',
    code: 'B468',
  },
  {
    name: 'Camerano Casasco',
    code: 'B469',
  },
  {
    name: 'Camerata Cornello',
    code: 'B471',
  },
  {
    name: 'Camerata Nuova',
    code: 'B472',
  },
  {
    name: 'Camerata Picena',
    code: 'B470',
  },
  {
    name: 'Cameri',
    code: 'B473',
  },
  {
    name: 'Camerino',
    code: 'B474',
  },
  {
    name: 'Camerota',
    code: 'B476',
  },
  {
    name: 'Camigliano',
    code: 'B477',
  },
  {
    name: 'Camini',
    code: 'B481',
  },
  {
    name: 'Camino',
    code: 'B482',
  },
  {
    name: 'Camino al Tagliamento',
    code: 'B483',
  },
  {
    name: 'Camisano',
    code: 'B484',
  },
  {
    name: 'Camisano Vicentino',
    code: 'B485',
  },
  {
    name: 'Cammarata',
    code: 'B486',
  },
  {
    name: 'Camogli',
    code: 'B490',
  },
  {
    name: 'Campagna',
    code: 'B492',
  },
  {
    name: 'Campagna Lupia',
    code: 'B493',
  },
  {
    name: 'Campagnano di Roma',
    code: 'B496',
  },
  {
    name: 'Campagnatico',
    code: 'B497',
  },
  {
    name: 'Campagnola Cremasca',
    code: 'B498',
  },
  {
    name: 'Campagnola Emilia',
    code: 'B499',
  },
  {
    name: 'Campana',
    code: 'B500',
  },
  {
    name: 'Camparada',
    code: 'B501',
  },
  {
    name: 'Campegine',
    code: 'B502',
  },
  {
    name: 'Campello sul Clitunno',
    code: 'B504',
  },
  {
    name: 'Campertogno',
    code: 'B505',
  },
  {
    name: 'Campi Bisenzio',
    code: 'B507',
  },
  {
    name: 'Campi Salentina',
    code: 'B506',
  },
  {
    name: 'Campiglia Cervo',
    code: 'M373',
  },
  {
    name: 'Campiglia dei Berici',
    code: 'B511',
  },
  {
    name: 'Campiglia Marittima',
    code: 'B509',
  },
  {
    name: 'Campiglione Fenile',
    code: 'B512',
  },
  {
    name: "Campione d'Italia",
    code: 'B513',
  },
  {
    name: 'Campitello di Fassa',
    code: 'B514',
  },
  {
    name: 'Campli',
    code: 'B515',
  },
  {
    name: 'Campo Calabro',
    code: 'B516',
  },
  {
    name: 'Campo di Giove',
    code: 'B526',
  },
  {
    name: 'Campo di Trens',
    code: 'B529',
  },
  {
    name: 'Campo Ligure',
    code: 'B538',
  },
  {
    name: "Campo nell'Elba",
    code: 'B553',
  },
  {
    name: 'Campo San Martino',
    code: 'B564',
  },
  {
    name: 'Campo Tures',
    code: 'B570',
  },
  {
    name: 'Campobasso',
    code: 'B519',
  },
  {
    name: 'Campobello di Licata',
    code: 'B520',
  },
  {
    name: 'Campobello di Mazara',
    code: 'B521',
  },
  {
    name: 'Campochiaro',
    code: 'B522',
  },
  {
    name: 'Campodarsego',
    code: 'B524',
  },
  {
    name: 'Campodenno',
    code: 'B525',
  },
  {
    name: 'Campodimele',
    code: 'B527',
  },
  {
    name: 'Campodipietra',
    code: 'B528',
  },
  {
    name: 'Campodolcino',
    code: 'B530',
  },
  {
    name: 'Campodoro',
    code: 'B531',
  },
  {
    name: 'Campofelice di Fitalia',
    code: 'B533',
  },
  {
    name: 'Campofelice di Roccella',
    code: 'B532',
  },
  {
    name: 'Campofilone',
    code: 'B534',
  },
  {
    name: 'Campofiorito',
    code: 'B535',
  },
  {
    name: 'Campoformido',
    code: 'B536',
  },
  {
    name: 'Campofranco',
    code: 'B537',
  },
  {
    name: 'Campogalliano',
    code: 'B539',
  },
  {
    name: 'Campolattaro',
    code: 'B541',
  },
  {
    name: 'Campoli Appennino',
    code: 'B543',
  },
  {
    name: 'Campoli del Monte Taburno',
    code: 'B542',
  },
  {
    name: 'Campolieto',
    code: 'B544',
  },
  {
    name: 'Campolongo Maggiore',
    code: 'B546',
  },
  {
    name: 'Campolongo Tapogliano',
    code: 'M311',
  },
  {
    name: 'Campomaggiore',
    code: 'B549',
  },
  {
    name: 'Campomarino',
    code: 'B550',
  },
  {
    name: 'Campomorone',
    code: 'B551',
  },
  {
    name: 'Camponogara',
    code: 'B554',
  },
  {
    name: 'Campora',
    code: 'B555',
  },
  {
    name: 'Camporeale',
    code: 'B556',
  },
  {
    name: 'Camporgiano',
    code: 'B557',
  },
  {
    name: 'Camporosso',
    code: 'B559',
  },
  {
    name: 'Camporotondo di Fiastrone',
    code: 'B562',
  },
  {
    name: 'Camporotondo Etneo',
    code: 'B561',
  },
  {
    name: 'Camposampiero',
    code: 'B563',
  },
  {
    name: 'Camposano',
    code: 'B565',
  },
  {
    name: 'Camposanto',
    code: 'B566',
  },
  {
    name: 'Campospinoso',
    code: 'B567',
  },
  {
    name: 'Campotosto',
    code: 'B569',
  },
  {
    name: 'Camugnano',
    code: 'B572',
  },
  {
    name: 'Canal San Bovo',
    code: 'B577',
  },
  {
    name: 'Canale',
    code: 'B573',
  },
  {
    name: "Canale d'Agordo",
    code: 'B574',
  },
  {
    name: 'Canale Monterano',
    code: 'B576',
  },
  {
    name: 'Canaro',
    code: 'B578',
  },
  {
    name: 'Canazei',
    code: 'B579',
  },
  {
    name: 'Cancellara',
    code: 'B580',
  },
  {
    name: 'Cancello ed Arnone',
    code: 'B581',
  },
  {
    name: 'Canda',
    code: 'B582',
  },
  {
    name: 'Candela',
    code: 'B584',
  },
  {
    name: 'Candelo',
    code: 'B586',
  },
  {
    name: 'Candia Canavese',
    code: 'B588',
  },
  {
    name: 'Candia Lomellina',
    code: 'B587',
  },
  {
    name: 'Candiana',
    code: 'B589',
  },
  {
    name: 'Candida',
    code: 'B590',
  },
  {
    name: 'Candidoni',
    code: 'B591',
  },
  {
    name: 'Candiolo',
    code: 'B592',
  },
  {
    name: 'Canegrate',
    code: 'B593',
  },
  {
    name: 'Canelli',
    code: 'B594',
  },
  {
    name: 'Canepina',
    code: 'B597',
  },
  {
    name: 'Caneva',
    code: 'B598',
  },
  {
    name: 'Canicattì',
    code: 'B602',
  },
  {
    name: 'Canicattini Bagni',
    code: 'B603',
  },
  {
    name: 'Canino',
    code: 'B604',
  },
  {
    name: 'Canischio',
    code: 'B605',
  },
  {
    name: 'Canistro',
    code: 'B606',
  },
  {
    name: 'Canna',
    code: 'B607',
  },
  {
    name: 'Cannalonga',
    code: 'B608',
  },
  {
    name: 'Cannara',
    code: 'B609',
  },
  {
    name: 'Cannero Riviera',
    code: 'B610',
  },
  {
    name: 'Canneto Pavese',
    code: 'B613',
  },
  {
    name: "Canneto sull'Oglio",
    code: 'B612',
  },
  {
    name: 'Cannobio',
    code: 'B615',
  },
  {
    name: 'Cannole',
    code: 'B616',
  },
  {
    name: 'Canolo',
    code: 'B617',
  },
  {
    name: "Canonica d'Adda",
    code: 'B618',
  },
  {
    name: 'Canosa di Puglia',
    code: 'B619',
  },
  {
    name: 'Canosa Sannita',
    code: 'B620',
  },
  {
    name: 'Canosio',
    code: 'B621',
  },
  {
    name: 'Canossa',
    code: 'C669',
  },
  {
    name: 'Cansano',
    code: 'B624',
  },
  {
    name: 'Cantagallo',
    code: 'B626',
  },
  {
    name: 'Cantalice',
    code: 'B627',
  },
  {
    name: 'Cantalupa',
    code: 'B628',
  },
  {
    name: 'Cantalupo in Sabina',
    code: 'B631',
  },
  {
    name: 'Cantalupo Ligure',
    code: 'B629',
  },
  {
    name: 'Cantalupo nel Sannio',
    code: 'B630',
  },
  {
    name: 'Cantarana',
    code: 'B633',
  },
  {
    name: 'Cantello',
    code: 'B634',
  },
  {
    name: 'Canterano',
    code: 'B635',
  },
  {
    name: 'Cantiano',
    code: 'B636',
  },
  {
    name: 'Cantoira',
    code: 'B637',
  },
  {
    name: 'Cantù',
    code: 'B639',
  },
  {
    name: 'Canzano',
    code: 'B640',
  },
  {
    name: 'Canzo',
    code: 'B641',
  },
  {
    name: 'Caorle',
    code: 'B642',
  },
  {
    name: 'Caorso',
    code: 'B643',
  },
  {
    name: 'Capaccio Paestum',
    code: 'B644',
  },
  {
    name: 'Capaci',
    code: 'B645',
  },
  {
    name: 'Capalbio',
    code: 'B646',
  },
  {
    name: 'Capannoli',
    code: 'B647',
  },
  {
    name: 'Capannori',
    code: 'B648',
  },
  {
    name: 'Capena',
    code: 'B649',
  },
  {
    name: 'Capergnanica',
    code: 'B650',
  },
  {
    name: 'Capestrano',
    code: 'B651',
  },
  {
    name: 'Capiago Intimiano',
    code: 'B653',
  },
  {
    name: 'Capistrano',
    code: 'B655',
  },
  {
    name: 'Capistrello',
    code: 'B656',
  },
  {
    name: 'Capitignano',
    code: 'B658',
  },
  {
    name: 'Capizzi',
    code: 'B660',
  },
  {
    name: 'Capizzone',
    code: 'B661',
  },
  {
    name: "Capo d'Orlando",
    code: 'B666',
  },
  {
    name: 'Capo di Ponte',
    code: 'B664',
  },
  {
    name: 'Capodimonte',
    code: 'B663',
  },
  {
    name: 'Capodrise',
    code: 'B667',
  },
  {
    name: 'Capoliveri',
    code: 'B669',
  },
  {
    name: 'Capolona',
    code: 'B670',
  },
  {
    name: 'Caponago',
    code: 'B671',
  },
  {
    name: 'Caporciano',
    code: 'B672',
  },
  {
    name: 'Caposele',
    code: 'B674',
  },
  {
    name: 'Capoterra',
    code: 'B675',
  },
  {
    name: 'Capovalle',
    code: 'B676',
  },
  {
    name: 'Cappadocia',
    code: 'B677',
  },
  {
    name: 'Cappella Cantone',
    code: 'B679',
  },
  {
    name: "Cappella de' Picenardi",
    code: 'B680',
  },
  {
    name: 'Cappella Maggiore',
    code: 'B678',
  },
  {
    name: 'Cappelle sul Tavo',
    code: 'B681',
  },
  {
    name: 'Capracotta',
    code: 'B682',
  },
  {
    name: 'Capraia e Limite',
    code: 'B684',
  },
  {
    name: 'Capraia Isola',
    code: 'B685',
  },
  {
    name: 'Capralba',
    code: 'B686',
  },
  {
    name: 'Capranica',
    code: 'B688',
  },
  {
    name: 'Capranica Prenestina',
    code: 'B687',
  },
  {
    name: 'Caprarica di Lecce',
    code: 'B690',
  },
  {
    name: 'Caprarola',
    code: 'B691',
  },
  {
    name: 'Caprauna',
    code: 'B692',
  },
  {
    name: 'Caprese Michelangelo',
    code: 'B693',
  },
  {
    name: 'Caprezzo',
    code: 'B694',
  },
  {
    name: 'Capri',
    code: 'B696',
  },
  {
    name: 'Capri Leone',
    code: 'B695',
  },
  {
    name: 'Capriana',
    code: 'B697',
  },
  {
    name: 'Capriano del Colle',
    code: 'B698',
  },
  {
    name: "Capriata d'Orba",
    code: 'B701',
  },
  {
    name: 'Capriate San Gervasio',
    code: 'B703',
  },
  {
    name: 'Capriati a Volturno',
    code: 'B704',
  },
  {
    name: 'Caprie',
    code: 'B705',
  },
  {
    name: 'Capriglia Irpina',
    code: 'B706',
  },
  {
    name: 'Capriglio',
    code: 'B707',
  },
  {
    name: 'Caprile',
    code: 'B708',
  },
  {
    name: 'Caprino Bergamasco',
    code: 'B710',
  },
  {
    name: 'Caprino Veronese',
    code: 'B709',
  },
  {
    name: 'Capriolo',
    code: 'B711',
  },
  {
    name: 'Capriva del Friuli',
    code: 'B712',
  },
  {
    name: 'Capua',
    code: 'B715',
  },
  {
    name: 'Capurso',
    code: 'B716',
  },
  {
    name: 'Caraffa del Bianco',
    code: 'B718',
  },
  {
    name: 'Caraffa di Catanzaro',
    code: 'B717',
  },
  {
    name: 'Caraglio',
    code: 'B719',
  },
  {
    name: 'Caramagna Piemonte',
    code: 'B720',
  },
  {
    name: 'Caramanico Terme',
    code: 'B722',
  },
  {
    name: 'Carapelle',
    code: 'B724',
  },
  {
    name: 'Carapelle Calvisio',
    code: 'B725',
  },
  {
    name: 'Carasco',
    code: 'B726',
  },
  {
    name: 'Carassai',
    code: 'B727',
  },
  {
    name: 'Carate Brianza',
    code: 'B729',
  },
  {
    name: 'Carate Urio',
    code: 'B730',
  },
  {
    name: 'Caravaggio',
    code: 'B731',
  },
  {
    name: 'Caravate',
    code: 'B732',
  },
  {
    name: 'Caravino',
    code: 'B733',
  },
  {
    name: 'Caravonica',
    code: 'B734',
  },
  {
    name: 'Carbognano',
    code: 'B735',
  },
  {
    name: 'Carbonara al Ticino',
    code: 'B741',
  },
  {
    name: 'Carbonara di Nola',
    code: 'B740',
  },
  {
    name: 'Carbonara Scrivia',
    code: 'B736',
  },
  {
    name: 'Carbonate',
    code: 'B742',
  },
  {
    name: 'Carbone',
    code: 'B743',
  },
  {
    name: 'Carbonera',
    code: 'B744',
  },
  {
    name: 'Carbonia',
    code: 'B745',
  },
  {
    name: 'Carcare',
    code: 'B748',
  },
  {
    name: 'Carceri',
    code: 'B749',
  },
  {
    name: 'Carcoforo',
    code: 'B752',
  },
  {
    name: 'Cardano al Campo',
    code: 'B754',
  },
  {
    name: 'Cardè',
    code: 'B755',
  },
  {
    name: 'Cardedu',
    code: 'M285',
  },
  {
    name: 'Cardeto',
    code: 'B756',
  },
  {
    name: 'Cardinale',
    code: 'B758',
  },
  {
    name: 'Cardito',
    code: 'B759',
  },
  {
    name: 'Careggine',
    code: 'B760',
  },
  {
    name: 'Carema',
    code: 'B762',
  },
  {
    name: 'Carenno',
    code: 'B763',
  },
  {
    name: 'Carentino',
    code: 'B765',
  },
  {
    name: 'Careri',
    code: 'B766',
  },
  {
    name: 'Caresana',
    code: 'B767',
  },
  {
    name: 'Caresanablot',
    code: 'B768',
  },
  {
    name: 'Carezzano',
    code: 'B769',
  },
  {
    name: 'Carfizzi',
    code: 'B771',
  },
  {
    name: 'Cargeghe',
    code: 'B772',
  },
  {
    name: 'Cariati',
    code: 'B774',
  },
  {
    name: 'Carife',
    code: 'B776',
  },
  {
    name: 'Carignano',
    code: 'B777',
  },
  {
    name: 'Carimate',
    code: 'B778',
  },
  {
    name: 'Carinaro',
    code: 'B779',
  },
  {
    name: 'Carini',
    code: 'B780',
  },
  {
    name: 'Carinola',
    code: 'B781',
  },
  {
    name: 'Carisio',
    code: 'B782',
  },
  {
    name: 'Carisolo',
    code: 'B783',
  },
  {
    name: 'Carlantino',
    code: 'B784',
  },
  {
    name: 'Carlazzo',
    code: 'B785',
  },
  {
    name: 'Carlentini',
    code: 'B787',
  },
  {
    name: 'Carlino',
    code: 'B788',
  },
  {
    name: 'Carloforte',
    code: 'B789',
  },
  {
    name: 'Carlopoli',
    code: 'B790',
  },
  {
    name: 'Carmagnola',
    code: 'B791',
  },
  {
    name: 'Carmiano',
    code: 'B792',
  },
  {
    name: 'Carmignano',
    code: 'B794',
  },
  {
    name: 'Carmignano di Brenta',
    code: 'B795',
  },
  {
    name: 'Carnago',
    code: 'B796',
  },
  {
    name: 'Carnate',
    code: 'B798',
  },
  {
    name: 'Carobbio degli Angeli',
    code: 'B801',
  },
  {
    name: 'Carolei',
    code: 'B802',
  },
  {
    name: 'Carona',
    code: 'B803',
  },
  {
    name: 'Caronia',
    code: 'B804',
  },
  {
    name: 'Caronno Pertusella',
    code: 'B805',
  },
  {
    name: 'Caronno Varesino',
    code: 'B807',
  },
  {
    name: 'Carosino',
    code: 'B808',
  },
  {
    name: 'Carovigno',
    code: 'B809',
  },
  {
    name: 'Carovilli',
    code: 'B810',
  },
  {
    name: 'Carpaneto Piacentino',
    code: 'B812',
  },
  {
    name: 'Carpanzano',
    code: 'B813',
  },
  {
    name: 'Carpegna',
    code: 'B816',
  },
  {
    name: 'Carpenedolo',
    code: 'B817',
  },
  {
    name: 'Carpeneto',
    code: 'B818',
  },
  {
    name: 'Carpi',
    code: 'B819',
  },
  {
    name: 'Carpiano',
    code: 'B820',
  },
  {
    name: 'Carpignano Salentino',
    code: 'B822',
  },
  {
    name: 'Carpignano Sesia',
    code: 'B823',
  },
  {
    name: 'Carpineti',
    code: 'B825',
  },
  {
    name: 'Carpineto della Nora',
    code: 'B827',
  },
  {
    name: 'Carpineto Romano',
    code: 'B828',
  },
  {
    name: 'Carpineto Sinello',
    code: 'B826',
  },
  {
    name: 'Carpino',
    code: 'B829',
  },
  {
    name: 'Carpinone',
    code: 'B830',
  },
  {
    name: 'Carrara',
    code: 'B832',
  },
  {
    name: 'Carrè',
    code: 'B835',
  },
  {
    name: 'Carrega Ligure',
    code: 'B836',
  },
  {
    name: 'Carro',
    code: 'B838',
  },
  {
    name: 'Carrodano',
    code: 'B839',
  },
  {
    name: 'Carrosio',
    code: 'B840',
  },
  {
    name: 'Carrù',
    code: 'B841',
  },
  {
    name: 'Carsoli',
    code: 'B842',
  },
  {
    name: 'Cartigliano',
    code: 'B844',
  },
  {
    name: 'Cartignano',
    code: 'B845',
  },
  {
    name: 'Cartoceto',
    code: 'B846',
  },
  {
    name: 'Cartosio',
    code: 'B847',
  },
  {
    name: 'Cartura',
    code: 'B848',
  },
  {
    name: 'Carugate',
    code: 'B850',
  },
  {
    name: 'Carugo',
    code: 'B851',
  },
  {
    name: 'Carunchio',
    code: 'B853',
  },
  {
    name: 'Carvico',
    code: 'B854',
  },
  {
    name: 'Carzano',
    code: 'B856',
  },
  {
    name: 'Casabona',
    code: 'B857',
  },
  {
    name: 'Casacalenda',
    code: 'B858',
  },
  {
    name: 'Casacanditella',
    code: 'B859',
  },
  {
    name: 'Casagiove',
    code: 'B860',
  },
  {
    name: 'Casal Cermelli',
    code: 'B870',
  },
  {
    name: 'Casal di Principe',
    code: 'B872',
  },
  {
    name: 'Casal Velino',
    code: 'B895',
  },
  {
    name: 'Casalanguida',
    code: 'B861',
  },
  {
    name: 'Casalattico',
    code: 'B862',
  },
  {
    name: 'Casalbeltrame',
    code: 'B864',
  },
  {
    name: 'Casalbordino',
    code: 'B865',
  },
  {
    name: 'Casalbore',
    code: 'B866',
  },
  {
    name: 'Casalborgone',
    code: 'B867',
  },
  {
    name: 'Casalbuono',
    code: 'B868',
  },
  {
    name: 'Casalbuttano ed Uniti',
    code: 'B869',
  },
  {
    name: 'Casalciprano',
    code: 'B871',
  },
  {
    name: 'Casalduni',
    code: 'B873',
  },
  {
    name: 'Casale Corte Cerro',
    code: 'B876',
  },
  {
    name: 'Casale Cremasco-Vidolasco',
    code: 'B881',
  },
  {
    name: 'Casale di Scodosia',
    code: 'B877',
  },
  {
    name: 'Casale Litta',
    code: 'B875',
  },
  {
    name: 'Casale Marittimo',
    code: 'B878',
  },
  {
    name: 'Casale Monferrato',
    code: 'B885',
  },
  {
    name: 'Casale sul Sile',
    code: 'B879',
  },
  {
    name: 'Casalecchio di Reno',
    code: 'B880',
  },
  {
    name: 'Casaleggio Boiro',
    code: 'B882',
  },
  {
    name: 'Casaleggio Novara',
    code: 'B883',
  },
  {
    name: 'Casaleone',
    code: 'B886',
  },
  {
    name: 'Casaletto Ceredano',
    code: 'B889',
  },
  {
    name: 'Casaletto di Sopra',
    code: 'B890',
  },
  {
    name: 'Casaletto Lodigiano',
    code: 'B887',
  },
  {
    name: 'Casaletto Spartano',
    code: 'B888',
  },
  {
    name: 'Casaletto Vaprio',
    code: 'B891',
  },
  {
    name: 'Casalfiumanese',
    code: 'B892',
  },
  {
    name: 'Casalgrande',
    code: 'B893',
  },
  {
    name: 'Casalgrasso',
    code: 'B894',
  },
  {
    name: 'Casali del Manco',
    code: 'M385',
  },
  {
    name: 'Casalincontrada',
    code: 'B896',
  },
  {
    name: 'Casalino',
    code: 'B897',
  },
  {
    name: 'Casalmaggiore',
    code: 'B898',
  },
  {
    name: 'Casalmaiocco',
    code: 'B899',
  },
  {
    name: 'Casalmorano',
    code: 'B900',
  },
  {
    name: 'Casalmoro',
    code: 'B901',
  },
  {
    name: 'Casalnoceto',
    code: 'B902',
  },
  {
    name: 'Casalnuovo di Napoli',
    code: 'B905',
  },
  {
    name: 'Casalnuovo Monterotaro',
    code: 'B904',
  },
  {
    name: 'Casaloldo',
    code: 'B907',
  },
  {
    name: 'Casalpusterlengo',
    code: 'B910',
  },
  {
    name: 'Casalromano',
    code: 'B911',
  },
  {
    name: 'Casalserugo',
    code: 'B912',
  },
  {
    name: 'Casaluce',
    code: 'B916',
  },
  {
    name: 'Casalvecchio di Puglia',
    code: 'B917',
  },
  {
    name: 'Casalvecchio Siculo',
    code: 'B918',
  },
  {
    name: 'Casalvieri',
    code: 'B919',
  },
  {
    name: 'Casalvolone',
    code: 'B920',
  },
  {
    name: 'Casalzuigno',
    code: 'B921',
  },
  {
    name: 'Casamarciano',
    code: 'B922',
  },
  {
    name: 'Casamassima',
    code: 'B923',
  },
  {
    name: 'Casamicciola Terme',
    code: 'B924',
  },
  {
    name: 'Casandrino',
    code: 'B925',
  },
  {
    name: 'Casanova Elvo',
    code: 'B928',
  },
  {
    name: 'Casanova Lerrone',
    code: 'B927',
  },
  {
    name: 'Casanova Lonati',
    code: 'B929',
  },
  {
    name: 'Casape',
    code: 'B932',
  },
  {
    name: 'Casapesenna',
    code: 'M260',
  },
  {
    name: 'Casapinta',
    code: 'B933',
  },
  {
    name: 'Casaprota',
    code: 'B934',
  },
  {
    name: 'Casapulla',
    code: 'B935',
  },
  {
    name: 'Casarano',
    code: 'B936',
  },
  {
    name: 'Casargo',
    code: 'B937',
  },
  {
    name: 'Casarile',
    code: 'B938',
  },
  {
    name: 'Casarsa della Delizia',
    code: 'B940',
  },
  {
    name: 'Casarza Ligure',
    code: 'B939',
  },
  {
    name: 'Casasco',
    code: 'B941',
  },
  {
    name: 'Casatenovo',
    code: 'B943',
  },
  {
    name: 'Casatisma',
    code: 'B945',
  },
  {
    name: 'Casavatore',
    code: 'B946',
  },
  {
    name: 'Casazza',
    code: 'B947',
  },
  {
    name: 'Cascia',
    code: 'B948',
  },
  {
    name: 'Casciago',
    code: 'B949',
  },
  {
    name: 'Casciana Terme Lari',
    code: 'M327',
  },
  {
    name: 'Cascina',
    code: 'B950',
  },
  {
    name: "Cascinette d'Ivrea",
    code: 'B953',
  },
  {
    name: 'Casei Gerola',
    code: 'B954',
  },
  {
    name: 'Caselette',
    code: 'B955',
  },
  {
    name: 'Casella',
    code: 'B956',
  },
  {
    name: 'Caselle in Pittari',
    code: 'B959',
  },
  {
    name: 'Caselle Landi',
    code: 'B961',
  },
  {
    name: 'Caselle Lurani',
    code: 'B958',
  },
  {
    name: 'Caselle Torinese',
    code: 'B960',
  },
  {
    name: 'Caserta',
    code: 'B963',
  },
  {
    name: 'Casier',
    code: 'B965',
  },
  {
    name: 'Casignana',
    code: 'B966',
  },
  {
    name: 'Casina',
    code: 'B967',
  },
  {
    name: "Casirate d'Adda",
    code: 'B971',
  },
  {
    name: "Caslino d'Erba",
    code: 'B974',
  },
  {
    name: 'Casnate con Bernate',
    code: 'B977',
  },
  {
    name: 'Casnigo',
    code: 'B978',
  },
  {
    name: 'Casola di Napoli',
    code: 'B980',
  },
  {
    name: 'Casola in Lunigiana',
    code: 'B979',
  },
  {
    name: 'Casola Valsenio',
    code: 'B982',
  },
  {
    name: "Casole d'Elsa",
    code: 'B984',
  },
  {
    name: 'Casoli',
    code: 'B985',
  },
  {
    name: 'Casorate Primo',
    code: 'B988',
  },
  {
    name: 'Casorate Sempione',
    code: 'B987',
  },
  {
    name: 'Casorezzo',
    code: 'B989',
  },
  {
    name: 'Casoria',
    code: 'B990',
  },
  {
    name: 'Casorzo',
    code: 'B991',
  },
  {
    name: 'Casperia',
    code: 'A472',
  },
  {
    name: 'Caspoggio',
    code: 'B993',
  },
  {
    name: 'Cassacco',
    code: 'B994',
  },
  {
    name: 'Cassago Brianza',
    code: 'B996',
  },
  {
    name: "Cassano all'Ionio",
    code: 'C002',
  },
  {
    name: "Cassano d'Adda",
    code: 'C003',
  },
  {
    name: 'Cassano delle Murge',
    code: 'B998',
  },
  {
    name: 'Cassano Irpino',
    code: 'B997',
  },
  {
    name: 'Cassano Magnago',
    code: 'C004',
  },
  {
    name: 'Cassano Spinola',
    code: 'M388',
  },
  {
    name: 'Cassano Valcuvia',
    code: 'B999',
  },
  {
    name: 'Cassaro',
    code: 'C006',
  },
  {
    name: 'Cassiglio',
    code: 'C007',
  },
  {
    name: "Cassina de' Pecchi",
    code: 'C014',
  },
  {
    name: 'Cassina Rizzardi',
    code: 'C020',
  },
  {
    name: 'Cassina Valsassina',
    code: 'C024',
  },
  {
    name: 'Cassinasco',
    code: 'C022',
  },
  {
    name: 'Cassine',
    code: 'C027',
  },
  {
    name: 'Cassinelle',
    code: 'C030',
  },
  {
    name: 'Cassinetta di Lugagnano',
    code: 'C033',
  },
  {
    name: 'Cassino',
    code: 'C034',
  },
  {
    name: 'Cassola',
    code: 'C037',
  },
  {
    name: 'Cassolnovo',
    code: 'C038',
  },
  {
    name: 'Castagnaro',
    code: 'C041',
  },
  {
    name: 'Castagneto Carducci',
    code: 'C044',
  },
  {
    name: 'Castagneto Po',
    code: 'C045',
  },
  {
    name: 'Castagnito',
    code: 'C046',
  },
  {
    name: 'Castagnole delle Lanze',
    code: 'C049',
  },
  {
    name: 'Castagnole Monferrato',
    code: 'C047',
  },
  {
    name: 'Castagnole Piemonte',
    code: 'C048',
  },
  {
    name: 'Castana',
    code: 'C050',
  },
  {
    name: 'Castano Primo',
    code: 'C052',
  },
  {
    name: 'Casteggio',
    code: 'C053',
  },
  {
    name: 'Castegnato',
    code: 'C055',
  },
  {
    name: 'Castegnero',
    code: 'C056',
  },
  {
    name: 'Castel Baronia',
    code: 'C058',
  },
  {
    name: 'Castel Boglione',
    code: 'C064',
  },
  {
    name: 'Castel Bolognese',
    code: 'C065',
  },
  {
    name: 'Castel Campagnano',
    code: 'B494',
  },
  {
    name: 'Castel Castagna',
    code: 'C040',
  },
  {
    name: 'Castel Condino',
    code: 'C183',
  },
  {
    name: "Castel d'Aiano",
    code: 'C075',
  },
  {
    name: "Castel d'Ario",
    code: 'C076',
  },
  {
    name: "Castel d'Azzano",
    code: 'C078',
  },
  {
    name: 'Castel del Giudice',
    code: 'C082',
  },
  {
    name: 'Castel del Monte',
    code: 'C083',
  },
  {
    name: 'Castel del Piano',
    code: 'C085',
  },
  {
    name: 'Castel del Rio',
    code: 'C086',
  },
  {
    name: 'Castel di Casio',
    code: 'B969',
  },
  {
    name: 'Castel di Ieri',
    code: 'C090',
  },
  {
    name: 'Castel di Iudica',
    code: 'C091',
  },
  {
    name: 'Castel di Lama',
    code: 'C093',
  },
  {
    name: 'Castel di Lucio',
    code: 'C094',
  },
  {
    name: 'Castel di Sangro',
    code: 'C096',
  },
  {
    name: 'Castel di Sasso',
    code: 'C097',
  },
  {
    name: 'Castel di Tora',
    code: 'C098',
  },
  {
    name: 'Castel Focognano',
    code: 'C102',
  },
  {
    name: 'Castel Frentano',
    code: 'C114',
  },
  {
    name: 'Castel Gabbiano',
    code: 'C115',
  },
  {
    name: 'Castel Gandolfo',
    code: 'C116',
  },
  {
    name: 'Castel Giorgio',
    code: 'C117',
  },
  {
    name: 'Castel Goffredo',
    code: 'C118',
  },
  {
    name: 'Castel Guelfo di Bologna',
    code: 'C121',
  },
  {
    name: 'Castel Ivano',
    code: 'M354',
  },
  {
    name: 'Castel Madama',
    code: 'C203',
  },
  {
    name: 'Castel Maggiore',
    code: 'C204',
  },
  {
    name: 'Castel Mella',
    code: 'C208',
  },
  {
    name: 'Castel Morrone',
    code: 'C211',
  },
  {
    name: 'Castel Ritaldi',
    code: 'C252',
  },
  {
    name: 'Castel Rocchero',
    code: 'C253',
  },
  {
    name: 'Castel Rozzone',
    code: 'C255',
  },
  {
    name: 'Castel San Giorgio',
    code: 'C259',
  },
  {
    name: 'Castel San Giovanni',
    code: 'C261',
  },
  {
    name: 'Castel San Lorenzo',
    code: 'C262',
  },
  {
    name: 'Castel San Niccolò',
    code: 'C263',
  },
  {
    name: 'Castel San Pietro Romano',
    code: 'C266',
  },
  {
    name: 'Castel San Pietro Terme',
    code: 'C265',
  },
  {
    name: 'Castel San Vincenzo',
    code: 'C270',
  },
  {
    name: "Castel Sant'Angelo",
    code: 'C268',
  },
  {
    name: "Castel Sant'Elia",
    code: 'C269',
  },
  {
    name: 'Castel Viscardo',
    code: 'C289',
  },
  {
    name: 'Castel Vittorio',
    code: 'C110',
  },
  {
    name: 'Castel Volturno',
    code: 'C291',
  },
  {
    name: 'Castelbaldo',
    code: 'C057',
  },
  {
    name: 'Castelbelforte',
    code: 'C059',
  },
  {
    name: 'Castelbellino',
    code: 'C060',
  },
  {
    name: 'Castelbello-Ciardes',
    code: 'C062',
  },
  {
    name: 'Castelbianco',
    code: 'C063',
  },
  {
    name: 'Castelbottaccio',
    code: 'C066',
  },
  {
    name: 'Castelbuono',
    code: 'C067',
  },
  {
    name: 'Castelcivita',
    code: 'C069',
  },
  {
    name: 'Castelcovati',
    code: 'C072',
  },
  {
    name: 'Castelcucco',
    code: 'C073',
  },
  {
    name: 'Casteldaccia',
    code: 'C074',
  },
  {
    name: 'Casteldelci',
    code: 'C080',
  },
  {
    name: 'Casteldelfino',
    code: 'C081',
  },
  {
    name: 'Casteldidone',
    code: 'C089',
  },
  {
    name: 'Castelfidardo',
    code: 'C100',
  },
  {
    name: 'Castelfiorentino',
    code: 'C101',
  },
  {
    name: 'Castelforte',
    code: 'C104',
  },
  {
    name: 'Castelfranci',
    code: 'C105',
  },
  {
    name: 'Castelfranco di Sotto',
    code: 'C113',
  },
  {
    name: 'Castelfranco Emilia',
    code: 'C107',
  },
  {
    name: 'Castelfranco in Miscano',
    code: 'C106',
  },
  {
    name: 'Castelfranco Piandiscò',
    code: 'M322',
  },
  {
    name: 'Castelfranco Veneto',
    code: 'C111',
  },
  {
    name: 'Castelgerundo',
    code: 'M393',
  },
  {
    name: 'Castelgomberto',
    code: 'C119',
  },
  {
    name: 'Castelgrande',
    code: 'C120',
  },
  {
    name: 'Castelguglielmo',
    code: 'C122',
  },
  {
    name: 'Castelguidone',
    code: 'C123',
  },
  {
    name: "Castell'Alfero",
    code: 'C127',
  },
  {
    name: "Castell'Arquato",
    code: 'C145',
  },
  {
    name: "Castell'Azzara",
    code: 'C147',
  },
  {
    name: "Castell'Umberto",
    code: 'C051',
  },
  {
    name: 'Castellabate',
    code: 'C125',
  },
  {
    name: 'Castellafiume',
    code: 'C126',
  },
  {
    name: 'Castellalto',
    code: 'C128',
  },
  {
    name: 'Castellammare del Golfo',
    code: 'C130',
  },
  {
    name: 'Castellammare di Stabia',
    code: 'C129',
  },
  {
    name: 'Castellamonte',
    code: 'C133',
  },
  {
    name: 'Castellana Grotte',
    code: 'C134',
  },
  {
    name: 'Castellana Sicula',
    code: 'C135',
  },
  {
    name: 'Castellaneta',
    code: 'C136',
  },
  {
    name: 'Castellania Coppi',
    code: 'C137',
  },
  {
    name: 'Castellanza',
    code: 'C139',
  },
  {
    name: 'Castellar Guidobono',
    code: 'C142',
  },
  {
    name: 'Castellarano',
    code: 'C141',
  },
  {
    name: 'Castellaro',
    code: 'C143',
  },
  {
    name: 'Castellazzo Bormida',
    code: 'C148',
  },
  {
    name: 'Castellazzo Novarese',
    code: 'C149',
  },
  {
    name: 'Castelleone',
    code: 'C153',
  },
  {
    name: 'Castelleone di Suasa',
    code: 'C152',
  },
  {
    name: 'Castellero',
    code: 'C154',
  },
  {
    name: 'Castelletto Cervo',
    code: 'C155',
  },
  {
    name: "Castelletto d'Erro",
    code: 'C156',
  },
  {
    name: "Castelletto d'Orba",
    code: 'C158',
  },
  {
    name: 'Castelletto di Branduzzo',
    code: 'C157',
  },
  {
    name: 'Castelletto Merli',
    code: 'C160',
  },
  {
    name: 'Castelletto Molina',
    code: 'C161',
  },
  {
    name: 'Castelletto Monferrato',
    code: 'C162',
  },
  {
    name: 'Castelletto sopra Ticino',
    code: 'C166',
  },
  {
    name: 'Castelletto Stura',
    code: 'C165',
  },
  {
    name: 'Castelletto Uzzone',
    code: 'C167',
  },
  {
    name: 'Castelli',
    code: 'C169',
  },
  {
    name: 'Castelli Calepio',
    code: 'C079',
  },
  {
    name: 'Castellina in Chianti',
    code: 'C172',
  },
  {
    name: 'Castellina Marittima',
    code: 'C174',
  },
  {
    name: "Castellinaldo d'Alba",
    code: 'C173',
  },
  {
    name: 'Castellino del Biferno',
    code: 'C175',
  },
  {
    name: 'Castellino Tanaro',
    code: 'C176',
  },
  {
    name: 'Castelliri',
    code: 'C177',
  },
  {
    name: 'Castello Cabiaglio',
    code: 'B312',
  },
  {
    name: "Castello d'Agogna",
    code: 'C184',
  },
  {
    name: "Castello d'Argile",
    code: 'C185',
  },
  {
    name: 'Castello del Matese',
    code: 'C178',
  },
  {
    name: "Castello dell'Acqua",
    code: 'C186',
  },
  {
    name: 'Castello di Annone',
    code: 'A300',
  },
  {
    name: 'Castello di Brianza',
    code: 'C187',
  },
  {
    name: 'Castello di Cisterna',
    code: 'C188',
  },
  {
    name: 'Castello di Godego',
    code: 'C190',
  },
  {
    name: 'Castello Tesino',
    code: 'C194',
  },
  {
    name: 'Castello-Molina di Fiemme',
    code: 'C189',
  },
  {
    name: 'Castellucchio',
    code: 'C195',
  },
  {
    name: 'Castelluccio dei Sauri',
    code: 'C198',
  },
  {
    name: 'Castelluccio Inferiore',
    code: 'C199',
  },
  {
    name: 'Castelluccio Superiore',
    code: 'C201',
  },
  {
    name: 'Castelluccio Valmaggiore',
    code: 'C202',
  },
  {
    name: 'Castelmagno',
    code: 'C205',
  },
  {
    name: 'Castelmarte',
    code: 'C206',
  },
  {
    name: 'Castelmassa',
    code: 'C207',
  },
  {
    name: 'Castelmauro',
    code: 'C197',
  },
  {
    name: 'Castelmezzano',
    code: 'C209',
  },
  {
    name: 'Castelmola',
    code: 'C210',
  },
  {
    name: 'Castelnovetto',
    code: 'C213',
  },
  {
    name: 'Castelnovo Bariano',
    code: 'C215',
  },
  {
    name: 'Castelnovo del Friuli',
    code: 'C217',
  },
  {
    name: 'Castelnovo di Sotto',
    code: 'C218',
  },
  {
    name: "Castelnovo ne' Monti",
    code: 'C219',
  },
  {
    name: 'Castelnuovo',
    code: 'C216',
  },
  {
    name: 'Castelnuovo Belbo',
    code: 'C226',
  },
  {
    name: 'Castelnuovo Berardenga',
    code: 'C227',
  },
  {
    name: "Castelnuovo Bocca d'Adda",
    code: 'C228',
  },
  {
    name: 'Castelnuovo Bormida',
    code: 'C229',
  },
  {
    name: 'Castelnuovo Bozzente',
    code: 'C220',
  },
  {
    name: 'Castelnuovo Calcea',
    code: 'C230',
  },
  {
    name: 'Castelnuovo Cilento',
    code: 'C231',
  },
  {
    name: 'Castelnuovo del Garda',
    code: 'C225',
  },
  {
    name: 'Castelnuovo della Daunia',
    code: 'C222',
  },
  {
    name: 'Castelnuovo di Ceva',
    code: 'C214',
  },
  {
    name: 'Castelnuovo di Conza',
    code: 'C235',
  },
  {
    name: 'Castelnuovo di Farfa',
    code: 'C224',
  },
  {
    name: 'Castelnuovo di Garfagnana',
    code: 'C236',
  },
  {
    name: 'Castelnuovo di Porto',
    code: 'C237',
  },
  {
    name: 'Castelnuovo di Val di Cecina',
    code: 'C244',
  },
  {
    name: 'Castelnuovo Don Bosco',
    code: 'C232',
  },
  {
    name: 'Castelnuovo Magra',
    code: 'C240',
  },
  {
    name: 'Castelnuovo Nigra',
    code: 'C241',
  },
  {
    name: 'Castelnuovo Parano',
    code: 'C223',
  },
  {
    name: 'Castelnuovo Rangone',
    code: 'C242',
  },
  {
    name: 'Castelnuovo Scrivia',
    code: 'C243',
  },
  {
    name: 'Castelpagano',
    code: 'C245',
  },
  {
    name: 'Castelpetroso',
    code: 'C246',
  },
  {
    name: 'Castelpizzuto',
    code: 'C247',
  },
  {
    name: 'Castelplanio',
    code: 'C248',
  },
  {
    name: 'Castelpoto',
    code: 'C250',
  },
  {
    name: 'Castelraimondo',
    code: 'C251',
  },
  {
    name: 'Castelrotto',
    code: 'C254',
  },
  {
    name: 'Castelsantangelo sul Nera',
    code: 'C267',
  },
  {
    name: 'Castelsaraceno',
    code: 'C271',
  },
  {
    name: 'Castelsardo',
    code: 'C272',
  },
  {
    name: 'Castelseprio',
    code: 'C273',
  },
  {
    name: 'Castelsilano',
    code: 'B968',
  },
  {
    name: 'Castelspina',
    code: 'C274',
  },
  {
    name: 'Casteltermini',
    code: 'C275',
  },
  {
    name: 'Castelveccana',
    code: 'C181',
  },
  {
    name: 'Castelvecchio Calvisio',
    code: 'C278',
  },
  {
    name: 'Castelvecchio di Rocca Barbena',
    code: 'C276',
  },
  {
    name: 'Castelvecchio Subequo',
    code: 'C279',
  },
  {
    name: 'Castelvenere',
    code: 'C280',
  },
  {
    name: 'Castelverde',
    code: 'B129',
  },
  {
    name: 'Castelverrino',
    code: 'C200',
  },
  {
    name: 'Castelvetere in Val Fortore',
    code: 'C284',
  },
  {
    name: 'Castelvetere sul Calore',
    code: 'C283',
  },
  {
    name: 'Castelvetrano',
    code: 'C286',
  },
  {
    name: 'Castelvetro di Modena',
    code: 'C287',
  },
  {
    name: 'Castelvetro Piacentino',
    code: 'C288',
  },
  {
    name: 'Castelvisconti',
    code: 'C290',
  },
  {
    name: 'Castenaso',
    code: 'C292',
  },
  {
    name: 'Castenedolo',
    code: 'C293',
  },
  {
    name: 'Castiadas',
    code: 'M288',
  },
  {
    name: 'Castiglion Fibocchi',
    code: 'C318',
  },
  {
    name: 'Castiglion Fiorentino',
    code: 'C319',
  },
  {
    name: 'Castiglione a Casauria',
    code: 'C308',
  },
  {
    name: 'Castiglione Chiavarese',
    code: 'C302',
  },
  {
    name: 'Castiglione Cosentino',
    code: 'C301',
  },
  {
    name: "Castiglione d'Adda",
    code: 'C304',
  },
  {
    name: "Castiglione d'Orcia",
    code: 'C313',
  },
  {
    name: 'Castiglione dei Pepoli',
    code: 'C296',
  },
  {
    name: 'Castiglione del Genovesi',
    code: 'C306',
  },
  {
    name: 'Castiglione del Lago',
    code: 'C309',
  },
  {
    name: 'Castiglione della Pescaia',
    code: 'C310',
  },
  {
    name: 'Castiglione delle Stiviere',
    code: 'C312',
  },
  {
    name: 'Castiglione di Garfagnana',
    code: 'C303',
  },
  {
    name: 'Castiglione di Sicilia',
    code: 'C297',
  },
  {
    name: 'Castiglione Falletto',
    code: 'C314',
  },
  {
    name: 'Castiglione in Teverina',
    code: 'C315',
  },
  {
    name: 'Castiglione Messer Marino',
    code: 'C298',
  },
  {
    name: 'Castiglione Messer Raimondo',
    code: 'C316',
  },
  {
    name: 'Castiglione Olona',
    code: 'C300',
  },
  {
    name: 'Castiglione Tinella',
    code: 'C317',
  },
  {
    name: 'Castiglione Torinese',
    code: 'C307',
  },
  {
    name: 'Castignano',
    code: 'C321',
  },
  {
    name: 'Castilenti',
    code: 'C322',
  },
  {
    name: 'Castino',
    code: 'C323',
  },
  {
    name: 'Castione Andevenno',
    code: 'C325',
  },
  {
    name: 'Castione della Presolana',
    code: 'C324',
  },
  {
    name: 'Castions di Strada',
    code: 'C327',
  },
  {
    name: 'Castiraga Vidardo',
    code: 'C329',
  },
  {
    name: 'Casto',
    code: 'C330',
  },
  {
    name: 'Castorano',
    code: 'C331',
  },
  {
    name: 'Castrezzato',
    code: 'C332',
  },
  {
    name: 'Castri di Lecce',
    code: 'C334',
  },
  {
    name: "Castrignano de' Greci",
    code: 'C335',
  },
  {
    name: 'Castrignano del Capo',
    code: 'C336',
  },
  {
    name: 'Castro',
    code: 'C337',
  },
  {
    name: 'Castro',
    code: 'M261',
  },
  {
    name: 'Castro dei Volsci',
    code: 'C338',
  },
  {
    name: 'Castrocaro Terme e Terra del Sole',
    code: 'C339',
  },
  {
    name: 'Castrocielo',
    code: 'C340',
  },
  {
    name: 'Castrofilippo',
    code: 'C341',
  },
  {
    name: 'Castrolibero',
    code: 'C108',
  },
  {
    name: 'Castronno',
    code: 'C343',
  },
  {
    name: 'Castronovo di Sicilia',
    code: 'C344',
  },
  {
    name: "Castronuovo di Sant'Andrea",
    code: 'C345',
  },
  {
    name: 'Castropignano',
    code: 'C346',
  },
  {
    name: 'Castroreale',
    code: 'C347',
  },
  {
    name: 'Castroregio',
    code: 'C348',
  },
  {
    name: 'Castrovillari',
    code: 'C349',
  },
  {
    name: 'Catania',
    code: 'C351',
  },
  {
    name: 'Catanzaro',
    code: 'C352',
  },
  {
    name: 'Catenanuova',
    code: 'C353',
  },
  {
    name: 'Catignano',
    code: 'C354',
  },
  {
    name: 'Cattolica',
    code: 'C357',
  },
  {
    name: 'Cattolica Eraclea',
    code: 'C356',
  },
  {
    name: 'Caulonia',
    code: 'C285',
  },
  {
    name: 'Cautano',
    code: 'C359',
  },
  {
    name: "Cava de' Tirreni",
    code: 'C361',
  },
  {
    name: 'Cava Manara',
    code: 'C360',
  },
  {
    name: 'Cavaglià',
    code: 'C363',
  },
  {
    name: 'Cavaglietto',
    code: 'C364',
  },
  {
    name: "Cavaglio d'Agogna",
    code: 'C365',
  },
  {
    name: 'Cavagnolo',
    code: 'C369',
  },
  {
    name: 'Cavaion Veronese',
    code: 'C370',
  },
  {
    name: 'Cavalese',
    code: 'C372',
  },
  {
    name: 'Cavallerleone',
    code: 'C375',
  },
  {
    name: 'Cavallermaggiore',
    code: 'C376',
  },
  {
    name: 'Cavallino',
    code: 'C377',
  },
  {
    name: 'Cavallino-Treporti',
    code: 'M308',
  },
  {
    name: 'Cavallirio',
    code: 'C378',
  },
  {
    name: 'Cavareno',
    code: 'C380',
  },
  {
    name: 'Cavargna',
    code: 'C381',
  },
  {
    name: 'Cavaria con Premezzo',
    code: 'C382',
  },
  {
    name: 'Cavarzere',
    code: 'C383',
  },
  {
    name: 'Cavaso del Tomba',
    code: 'C384',
  },
  {
    name: 'Cavasso Nuovo',
    code: 'C385',
  },
  {
    name: 'Cavatore',
    code: 'C387',
  },
  {
    name: 'Cavazzo Carnico',
    code: 'C389',
  },
  {
    name: 'Cave',
    code: 'C390',
  },
  {
    name: 'Cavedago',
    code: 'C392',
  },
  {
    name: 'Cavedine',
    code: 'C393',
  },
  {
    name: "Cavenago d'Adda",
    code: 'C394',
  },
  {
    name: 'Cavenago di Brianza',
    code: 'C395',
  },
  {
    name: 'Cavernago',
    code: 'C396',
  },
  {
    name: 'Cavezzo',
    code: 'C398',
  },
  {
    name: 'Cavizzana',
    code: 'C400',
  },
  {
    name: 'Cavour',
    code: 'C404',
  },
  {
    name: 'Cavriago',
    code: 'C405',
  },
  {
    name: 'Cavriana',
    code: 'C406',
  },
  {
    name: 'Cavriglia',
    code: 'C407',
  },
  {
    name: 'Cazzago Brabbia',
    code: 'C409',
  },
  {
    name: 'Cazzago San Martino',
    code: 'C408',
  },
  {
    name: 'Cazzano di Tramigna',
    code: 'C412',
  },
  {
    name: "Cazzano Sant'Andrea",
    code: 'C410',
  },
  {
    name: 'Ceccano',
    code: 'C413',
  },
  {
    name: 'Cecima',
    code: 'C414',
  },
  {
    name: 'Cecina',
    code: 'C415',
  },
  {
    name: 'Cedegolo',
    code: 'C417',
  },
  {
    name: 'Cedrasco',
    code: 'C418',
  },
  {
    name: 'Cefalà Diana',
    code: 'C420',
  },
  {
    name: 'Cefalù',
    code: 'C421',
  },
  {
    name: 'Ceggia',
    code: 'C422',
  },
  {
    name: 'Ceglie Messapica',
    code: 'C424',
  },
  {
    name: 'Celano',
    code: 'C426',
  },
  {
    name: 'Celenza sul Trigno',
    code: 'C428',
  },
  {
    name: 'Celenza Valfortore',
    code: 'C429',
  },
  {
    name: 'Celico',
    code: 'C430',
  },
  {
    name: 'Cella Dati',
    code: 'C435',
  },
  {
    name: 'Cella Monte',
    code: 'C432',
  },
  {
    name: 'Cellamare',
    code: 'C436',
  },
  {
    name: 'Cellara',
    code: 'C437',
  },
  {
    name: 'Cellarengo',
    code: 'C438',
  },
  {
    name: 'Cellatica',
    code: 'C439',
  },
  {
    name: 'Celle di Bulgheria',
    code: 'C444',
  },
  {
    name: 'Celle di Macra',
    code: 'C441',
  },
  {
    name: 'Celle di San Vito',
    code: 'C442',
  },
  {
    name: 'Celle Enomondo',
    code: 'C440',
  },
  {
    name: 'Celle Ligure',
    code: 'C443',
  },
  {
    name: 'Celleno',
    code: 'C446',
  },
  {
    name: 'Cellere',
    code: 'C447',
  },
  {
    name: 'Cellino Attanasio',
    code: 'C449',
  },
  {
    name: 'Cellino San Marco',
    code: 'C448',
  },
  {
    name: 'Cellio con Breia',
    code: 'M398',
  },
  {
    name: 'Cellole',
    code: 'M262',
  },
  {
    name: 'Cembra Lisignago',
    code: 'M355',
  },
  {
    name: 'Cenadi',
    code: 'C453',
  },
  {
    name: 'Cenate Sopra',
    code: 'C456',
  },
  {
    name: 'Cenate Sotto',
    code: 'C457',
  },
  {
    name: 'Cencenighe Agordino',
    code: 'C458',
  },
  {
    name: 'Cene',
    code: 'C459',
  },
  {
    name: 'Ceneselli',
    code: 'C461',
  },
  {
    name: 'Cengio',
    code: 'C463',
  },
  {
    name: 'Centallo',
    code: 'C466',
  },
  {
    name: 'Cento',
    code: 'C469',
  },
  {
    name: 'Centola',
    code: 'C470',
  },
  {
    name: 'Centrache',
    code: 'C472',
  },
  {
    name: 'Centro Valle Intelvi',
    code: 'M394',
  },
  {
    name: 'Centuripe',
    code: 'C471',
  },
  {
    name: 'Cepagatti',
    code: 'C474',
  },
  {
    name: 'Ceppaloni',
    code: 'C476',
  },
  {
    name: 'Ceppo Morelli',
    code: 'C478',
  },
  {
    name: 'Ceprano',
    code: 'C479',
  },
  {
    name: 'Cerami',
    code: 'C480',
  },
  {
    name: 'Ceranesi',
    code: 'C481',
  },
  {
    name: 'Cerano',
    code: 'C483',
  },
  {
    name: "Cerano d'Intelvi",
    code: 'C482',
  },
  {
    name: 'Ceranova',
    code: 'C484',
  },
  {
    name: 'Ceraso',
    code: 'C485',
  },
  {
    name: 'Cercemaggiore',
    code: 'C486',
  },
  {
    name: 'Cercenasco',
    code: 'C487',
  },
  {
    name: 'Cercepiccola',
    code: 'C488',
  },
  {
    name: 'Cerchiara di Calabria',
    code: 'C489',
  },
  {
    name: 'Cerchio',
    code: 'C492',
  },
  {
    name: 'Cercino',
    code: 'C493',
  },
  {
    name: 'Cercivento',
    code: 'C494',
  },
  {
    name: 'Cercola',
    code: 'C495',
  },
  {
    name: 'Cerda',
    code: 'C496',
  },
  {
    name: 'Cerea',
    code: 'C498',
  },
  {
    name: 'Ceregnano',
    code: 'C500',
  },
  {
    name: 'Cerenzia',
    code: 'C501',
  },
  {
    name: 'Ceres',
    code: 'C497',
  },
  {
    name: 'Ceresara',
    code: 'C502',
  },
  {
    name: 'Cereseto',
    code: 'C503',
  },
  {
    name: 'Ceresole Alba',
    code: 'C504',
  },
  {
    name: 'Ceresole Reale',
    code: 'C505',
  },
  {
    name: 'Cerete',
    code: 'C506',
  },
  {
    name: 'Ceretto Lomellina',
    code: 'C508',
  },
  {
    name: 'Cergnago',
    code: 'C509',
  },
  {
    name: 'Ceriale',
    code: 'C510',
  },
  {
    name: 'Ceriana',
    code: 'C511',
  },
  {
    name: 'Ceriano Laghetto',
    code: 'C512',
  },
  {
    name: 'Cerignale',
    code: 'C513',
  },
  {
    name: 'Cerignola',
    code: 'C514',
  },
  {
    name: 'Cerisano',
    code: 'C515',
  },
  {
    name: 'Cermenate',
    code: 'C516',
  },
  {
    name: 'Cermes',
    code: 'A022',
  },
  {
    name: 'Cermignano',
    code: 'C517',
  },
  {
    name: 'Cernobbio',
    code: 'C520',
  },
  {
    name: 'Cernusco Lombardone',
    code: 'C521',
  },
  {
    name: 'Cernusco sul Naviglio',
    code: 'C523',
  },
  {
    name: "Cerreto d'Asti",
    code: 'C528',
  },
  {
    name: "Cerreto d'Esi",
    code: 'C524',
  },
  {
    name: 'Cerreto di Spoleto',
    code: 'C527',
  },
  {
    name: 'Cerreto Grue',
    code: 'C507',
  },
  {
    name: 'Cerreto Guidi',
    code: 'C529',
  },
  {
    name: 'Cerreto Laziale',
    code: 'C518',
  },
  {
    name: 'Cerreto Sannita',
    code: 'C525',
  },
  {
    name: 'Cerretto Langhe',
    code: 'C530',
  },
  {
    name: 'Cerrina Monferrato',
    code: 'C531',
  },
  {
    name: 'Cerrione',
    code: 'C532',
  },
  {
    name: 'Cerro al Lambro',
    code: 'C536',
  },
  {
    name: 'Cerro al Volturno',
    code: 'C534',
  },
  {
    name: 'Cerro Maggiore',
    code: 'C537',
  },
  {
    name: 'Cerro Tanaro',
    code: 'C533',
  },
  {
    name: 'Cerro Veronese',
    code: 'C538',
  },
  {
    name: 'Cersosimo',
    code: 'C539',
  },
  {
    name: 'Certaldo',
    code: 'C540',
  },
  {
    name: 'Certosa di Pavia',
    code: 'C541',
  },
  {
    name: 'Cerva',
    code: 'C542',
  },
  {
    name: 'Cervara di Roma',
    code: 'C543',
  },
  {
    name: 'Cervarese Santa Croce',
    code: 'C544',
  },
  {
    name: 'Cervaro',
    code: 'C545',
  },
  {
    name: 'Cervasca',
    code: 'C547',
  },
  {
    name: 'Cervatto',
    code: 'C548',
  },
  {
    name: 'Cerveno',
    code: 'C549',
  },
  {
    name: 'Cervere',
    code: 'C550',
  },
  {
    name: 'Cervesina',
    code: 'C551',
  },
  {
    name: 'Cerveteri',
    code: 'C552',
  },
  {
    name: 'Cervia',
    code: 'C553',
  },
  {
    name: 'Cervicati',
    code: 'C554',
  },
  {
    name: "Cervignano d'Adda",
    code: 'C555',
  },
  {
    name: 'Cervignano del Friuli',
    code: 'C556',
  },
  {
    name: 'Cervinara',
    code: 'C557',
  },
  {
    name: 'Cervino',
    code: 'C558',
  },
  {
    name: 'Cervo',
    code: 'C559',
  },
  {
    name: 'Cerzeto',
    code: 'C560',
  },
  {
    name: 'Cesa',
    code: 'C561',
  },
  {
    name: 'Cesana Brianza',
    code: 'C563',
  },
  {
    name: 'Cesana Torinese',
    code: 'C564',
  },
  {
    name: 'Cesano Boscone',
    code: 'C565',
  },
  {
    name: 'Cesano Maderno',
    code: 'C566',
  },
  {
    name: 'Cesara',
    code: 'C567',
  },
  {
    name: 'Cesarò',
    code: 'C568',
  },
  {
    name: 'Cesate',
    code: 'C569',
  },
  {
    name: 'Cesena',
    code: 'C573',
  },
  {
    name: 'Cesenatico',
    code: 'C574',
  },
  {
    name: 'Cesinali',
    code: 'C576',
  },
  {
    name: 'Cesio',
    code: 'C578',
  },
  {
    name: 'Cesiomaggiore',
    code: 'C577',
  },
  {
    name: 'Cessalto',
    code: 'C580',
  },
  {
    name: 'Cessaniti',
    code: 'C581',
  },
  {
    name: 'Cessapalombo',
    code: 'C582',
  },
  {
    name: 'Cessole',
    code: 'C583',
  },
  {
    name: 'Cetara',
    code: 'C584',
  },
  {
    name: 'Ceto',
    code: 'C585',
  },
  {
    name: 'Cetona',
    code: 'C587',
  },
  {
    name: 'Cetraro',
    code: 'C588',
  },
  {
    name: 'Ceva',
    code: 'C589',
  },
  {
    name: 'Cevo',
    code: 'C591',
  },
  {
    name: 'Challand-Saint-Anselme',
    code: 'C593',
  },
  {
    name: 'Challand-Saint-Victor',
    code: 'C594',
  },
  {
    name: 'Chambave',
    code: 'C595',
  },
  {
    name: 'Chamois',
    code: 'B491',
  },
  {
    name: 'Champdepraz',
    code: 'C596',
  },
  {
    name: 'Champorcher',
    code: 'B540',
  },
  {
    name: 'Charvensod',
    code: 'C598',
  },
  {
    name: 'Châtillon',
    code: 'C294',
  },
  {
    name: 'Cherasco',
    code: 'C599',
  },
  {
    name: 'Cheremule',
    code: 'C600',
  },
  {
    name: 'Chialamberto',
    code: 'C604',
  },
  {
    name: 'Chiampo',
    code: 'C605',
  },
  {
    name: 'Chianche',
    code: 'C606',
  },
  {
    name: 'Chianciano Terme',
    code: 'C608',
  },
  {
    name: 'Chianni',
    code: 'C609',
  },
  {
    name: 'Chianocco',
    code: 'C610',
  },
  {
    name: 'Chiaramonte Gulfi',
    code: 'C612',
  },
  {
    name: 'Chiaramonti',
    code: 'C613',
  },
  {
    name: 'Chiarano',
    code: 'C614',
  },
  {
    name: 'Chiaravalle',
    code: 'C615',
  },
  {
    name: 'Chiaravalle Centrale',
    code: 'C616',
  },
  {
    name: 'Chiari',
    code: 'C618',
  },
  {
    name: 'Chiaromonte',
    code: 'C619',
  },
  {
    name: 'Chiauci',
    code: 'C620',
  },
  {
    name: 'Chiavari',
    code: 'C621',
  },
  {
    name: 'Chiavenna',
    code: 'C623',
  },
  {
    name: 'Chiaverano',
    code: 'C624',
  },
  {
    name: 'Chienes',
    code: 'C625',
  },
  {
    name: 'Chieri',
    code: 'C627',
  },
  {
    name: "Chies d'Alpago",
    code: 'C630',
  },
  {
    name: 'Chiesa in Valmalenco',
    code: 'C628',
  },
  {
    name: 'Chiesanuova',
    code: 'C629',
  },
  {
    name: 'Chiesina Uzzanese',
    code: 'C631',
  },
  {
    name: 'Chieti',
    code: 'C632',
  },
  {
    name: 'Chieuti',
    code: 'C633',
  },
  {
    name: 'Chieve',
    code: 'C634',
  },
  {
    name: "Chignolo d'Isola",
    code: 'C635',
  },
  {
    name: 'Chignolo Po',
    code: 'C637',
  },
  {
    name: 'Chioggia',
    code: 'C638',
  },
  {
    name: 'Chiomonte',
    code: 'C639',
  },
  {
    name: 'Chions',
    code: 'C640',
  },
  {
    name: 'Chiopris-Viscone',
    code: 'C641',
  },
  {
    name: 'Chitignano',
    code: 'C648',
  },
  {
    name: 'Chiuduno',
    code: 'C649',
  },
  {
    name: 'Chiuppano',
    code: 'C650',
  },
  {
    name: 'Chiuro',
    code: 'C651',
  },
  {
    name: 'Chiusa',
    code: 'C652',
  },
  {
    name: 'Chiusa di Pesio',
    code: 'C653',
  },
  {
    name: 'Chiusa di San Michele',
    code: 'C655',
  },
  {
    name: 'Chiusa Sclafani',
    code: 'C654',
  },
  {
    name: 'Chiusaforte',
    code: 'C656',
  },
  {
    name: 'Chiusanico',
    code: 'C657',
  },
  {
    name: "Chiusano d'Asti",
    code: 'C658',
  },
  {
    name: 'Chiusano di San Domenico',
    code: 'C659',
  },
  {
    name: 'Chiusavecchia',
    code: 'C660',
  },
  {
    name: 'Chiusdino',
    code: 'C661',
  },
  {
    name: 'Chiusi',
    code: 'C662',
  },
  {
    name: 'Chiusi della Verna',
    code: 'C663',
  },
  {
    name: 'Chivasso',
    code: 'C665',
  },
  {
    name: 'Ciampino',
    code: 'M272',
  },
  {
    name: 'Cianciana',
    code: 'C668',
  },
  {
    name: 'Cibiana di Cadore',
    code: 'C672',
  },
  {
    name: 'Cicagna',
    code: 'C673',
  },
  {
    name: 'Cicala',
    code: 'C674',
  },
  {
    name: 'Cicciano',
    code: 'C675',
  },
  {
    name: 'Cicerale',
    code: 'C676',
  },
  {
    name: 'Ciciliano',
    code: 'C677',
  },
  {
    name: 'Cicognolo',
    code: 'C678',
  },
  {
    name: 'Ciconio',
    code: 'C679',
  },
  {
    name: 'Cigliano',
    code: 'C680',
  },
  {
    name: 'Cigliè',
    code: 'C681',
  },
  {
    name: 'Cigognola',
    code: 'C684',
  },
  {
    name: 'Cigole',
    code: 'C685',
  },
  {
    name: 'Cilavegna',
    code: 'C686',
  },
  {
    name: 'Cimadolmo',
    code: 'C689',
  },
  {
    name: 'Cimbergo',
    code: 'C691',
  },
  {
    name: 'Ciminà',
    code: 'C695',
  },
  {
    name: 'Ciminna',
    code: 'C696',
  },
  {
    name: 'Cimitile',
    code: 'C697',
  },
  {
    name: 'Cimolais',
    code: 'C699',
  },
  {
    name: 'Cimone',
    code: 'C700',
  },
  {
    name: 'Cinaglio',
    code: 'C701',
  },
  {
    name: 'Cineto Romano',
    code: 'C702',
  },
  {
    name: "Cingia de' Botti",
    code: 'C703',
  },
  {
    name: 'Cingoli',
    code: 'C704',
  },
  {
    name: 'Cinigiano',
    code: 'C705',
  },
  {
    name: 'Cinisello Balsamo',
    code: 'C707',
  },
  {
    name: 'Cinisi',
    code: 'C708',
  },
  {
    name: 'Cino',
    code: 'C709',
  },
  {
    name: 'Cinquefrondi',
    code: 'C710',
  },
  {
    name: 'Cintano',
    code: 'C711',
  },
  {
    name: 'Cinte Tesino',
    code: 'C712',
  },
  {
    name: 'Cinto Caomaggiore',
    code: 'C714',
  },
  {
    name: 'Cinto Euganeo',
    code: 'C713',
  },
  {
    name: 'Cinzano',
    code: 'C715',
  },
  {
    name: 'Ciorlano',
    code: 'C716',
  },
  {
    name: 'Cipressa',
    code: 'C718',
  },
  {
    name: 'Circello',
    code: 'C719',
  },
  {
    name: 'Ciriè',
    code: 'C722',
  },
  {
    name: 'Cirigliano',
    code: 'C723',
  },
  {
    name: 'Cirimido',
    code: 'C724',
  },
  {
    name: 'Cirò',
    code: 'C725',
  },
  {
    name: 'Cirò Marina',
    code: 'C726',
  },
  {
    name: 'Cis',
    code: 'C727',
  },
  {
    name: 'Cisano Bergamasco',
    code: 'C728',
  },
  {
    name: 'Cisano sul Neva',
    code: 'C729',
  },
  {
    name: 'Ciserano',
    code: 'C730',
  },
  {
    name: 'Cislago',
    code: 'C732',
  },
  {
    name: 'Cisliano',
    code: 'C733',
  },
  {
    name: 'Cison di Valmarino',
    code: 'C735',
  },
  {
    name: 'Cissone',
    code: 'C738',
  },
  {
    name: "Cisterna d'Asti",
    code: 'C739',
  },
  {
    name: 'Cisterna di Latina',
    code: 'C740',
  },
  {
    name: 'Cisternino',
    code: 'C741',
  },
  {
    name: 'Citerna',
    code: 'C742',
  },
  {
    name: 'Città della Pieve',
    code: 'C744',
  },
  {
    name: 'Città di Castello',
    code: 'C745',
  },
  {
    name: "Città Sant'Angelo",
    code: 'C750',
  },
  {
    name: 'Cittadella',
    code: 'C743',
  },
  {
    name: 'Cittaducale',
    code: 'C746',
  },
  {
    name: 'Cittanova',
    code: 'C747',
  },
  {
    name: 'Cittareale',
    code: 'C749',
  },
  {
    name: 'Cittiglio',
    code: 'C751',
  },
  {
    name: 'Civate',
    code: 'C752',
  },
  {
    name: 'Civezza',
    code: 'C755',
  },
  {
    name: 'Civezzano',
    code: 'C756',
  },
  {
    name: 'Civiasco',
    code: 'C757',
  },
  {
    name: 'Cividale del Friuli',
    code: 'C758',
  },
  {
    name: 'Cividate al Piano',
    code: 'C759',
  },
  {
    name: 'Cividate Camuno',
    code: 'C760',
  },
  {
    name: 'Civita',
    code: 'C763',
  },
  {
    name: 'Civita Castellana',
    code: 'C765',
  },
  {
    name: "Civita d'Antino",
    code: 'C766',
  },
  {
    name: 'Civitacampomarano',
    code: 'C764',
  },
  {
    name: 'Civitaluparella',
    code: 'C768',
  },
  {
    name: 'Civitanova del Sannio',
    code: 'C769',
  },
  {
    name: 'Civitanova Marche',
    code: 'C770',
  },
  {
    name: 'Civitaquana',
    code: 'C771',
  },
  {
    name: 'Civitavecchia',
    code: 'C773',
  },
  {
    name: 'Civitella Alfedena',
    code: 'C778',
  },
  {
    name: 'Civitella Casanova',
    code: 'C779',
  },
  {
    name: "Civitella d'Agliano",
    code: 'C780',
  },
  {
    name: 'Civitella del Tronto',
    code: 'C781',
  },
  {
    name: 'Civitella di Romagna',
    code: 'C777',
  },
  {
    name: 'Civitella in Val di Chiana',
    code: 'C774',
  },
  {
    name: 'Civitella Messer Raimondo',
    code: 'C776',
  },
  {
    name: 'Civitella Paganico',
    code: 'C782',
  },
  {
    name: 'Civitella Roveto',
    code: 'C783',
  },
  {
    name: 'Civitella San Paolo',
    code: 'C784',
  },
  {
    name: 'Civo',
    code: 'C785',
  },
  {
    name: 'Claino con Osteno',
    code: 'C787',
  },
  {
    name: 'Claut',
    code: 'C790',
  },
  {
    name: 'Clauzetto',
    code: 'C791',
  },
  {
    name: 'Clavesana',
    code: 'C792',
  },
  {
    name: 'Claviere',
    code: 'C793',
  },
  {
    name: 'Cles',
    code: 'C794',
  },
  {
    name: 'Cleto',
    code: 'C795',
  },
  {
    name: 'Clivio',
    code: 'C796',
  },
  {
    name: 'Clusone',
    code: 'C800',
  },
  {
    name: 'Coassolo Torinese',
    code: 'C801',
  },
  {
    name: 'Coazze',
    code: 'C803',
  },
  {
    name: 'Coazzolo',
    code: 'C804',
  },
  {
    name: 'Coccaglio',
    code: 'C806',
  },
  {
    name: 'Cocconato',
    code: 'C807',
  },
  {
    name: 'Cocquio-Trevisago',
    code: 'C810',
  },
  {
    name: 'Cocullo',
    code: 'C811',
  },
  {
    name: 'Codevigo',
    code: 'C812',
  },
  {
    name: 'Codevilla',
    code: 'C813',
  },
  {
    name: 'Codigoro',
    code: 'C814',
  },
  {
    name: 'Codognè',
    code: 'C815',
  },
  {
    name: 'Codogno',
    code: 'C816',
  },
  {
    name: 'Codroipo',
    code: 'C817',
  },
  {
    name: 'Codrongianos',
    code: 'C818',
  },
  {
    name: 'Coggiola',
    code: 'C819',
  },
  {
    name: 'Cogliate',
    code: 'C820',
  },
  {
    name: 'Cogne',
    code: 'C821',
  },
  {
    name: 'Cogoleto',
    code: 'C823',
  },
  {
    name: 'Cogollo del Cengio',
    code: 'C824',
  },
  {
    name: 'Cogorno',
    code: 'C826',
  },
  {
    name: 'Colazza',
    code: 'C829',
  },
  {
    name: 'Colceresa',
    code: 'M426',
  },
  {
    name: 'Colere',
    code: 'C835',
  },
  {
    name: 'Colfelice',
    code: 'C836',
  },
  {
    name: 'Coli',
    code: 'C838',
  },
  {
    name: 'Colico',
    code: 'C839',
  },
  {
    name: 'Collalto Sabino',
    code: 'C841',
  },
  {
    name: 'Collarmele',
    code: 'C844',
  },
  {
    name: 'Collazzone',
    code: 'C845',
  },
  {
    name: 'Colle Brianza',
    code: 'C851',
  },
  {
    name: "Colle d'Anchise",
    code: 'C854',
  },
  {
    name: 'Colle di Tora',
    code: 'C857',
  },
  {
    name: "Colle di Val d'Elsa",
    code: 'C847',
  },
  {
    name: 'Colle San Magno',
    code: 'C870',
  },
  {
    name: 'Colle Sannita',
    code: 'C846',
  },
  {
    name: 'Colle Santa Lucia',
    code: 'C872',
  },
  {
    name: 'Colle Umberto',
    code: 'C848',
  },
  {
    name: 'Collebeato',
    code: 'C850',
  },
  {
    name: 'Collecchio',
    code: 'C852',
  },
  {
    name: 'Collecorvino',
    code: 'C853',
  },
  {
    name: 'Colledara',
    code: 'C311',
  },
  {
    name: 'Colledimacine',
    code: 'C855',
  },
  {
    name: 'Colledimezzo',
    code: 'C856',
  },
  {
    name: 'Colleferro',
    code: 'C858',
  },
  {
    name: 'Collegiove',
    code: 'C859',
  },
  {
    name: 'Collegno',
    code: 'C860',
  },
  {
    name: 'Collelongo',
    code: 'C862',
  },
  {
    name: 'Collepardo',
    code: 'C864',
  },
  {
    name: 'Collepasso',
    code: 'C865',
  },
  {
    name: 'Collepietro',
    code: 'C866',
  },
  {
    name: 'Colleretto Castelnuovo',
    code: 'C867',
  },
  {
    name: 'Colleretto Giacosa',
    code: 'C868',
  },
  {
    name: 'Collesalvetti',
    code: 'C869',
  },
  {
    name: 'Collesano',
    code: 'C871',
  },
  {
    name: 'Colletorto',
    code: 'C875',
  },
  {
    name: 'Collevecchio',
    code: 'C876',
  },
  {
    name: 'Colli a Volturno',
    code: 'C878',
  },
  {
    name: 'Colli al Metauro',
    code: 'M380',
  },
  {
    name: 'Colli del Tronto',
    code: 'C877',
  },
  {
    name: 'Colli sul Velino',
    code: 'C880',
  },
  {
    name: 'Colli Verdi',
    code: 'M419',
  },
  {
    name: 'Colliano',
    code: 'C879',
  },
  {
    name: 'Collinas',
    code: 'C882',
  },
  {
    name: 'Collio',
    code: 'C883',
  },
  {
    name: 'Collobiano',
    code: 'C884',
  },
  {
    name: 'Colloredo di Monte Albano',
    code: 'C885',
  },
  {
    name: 'Colmurano',
    code: 'C886',
  },
  {
    name: 'Colobraro',
    code: 'C888',
  },
  {
    name: 'Cologna Veneta',
    code: 'C890',
  },
  {
    name: 'Cologne',
    code: 'C893',
  },
  {
    name: 'Cologno al Serio',
    code: 'C894',
  },
  {
    name: 'Cologno Monzese',
    code: 'C895',
  },
  {
    name: 'Colognola ai Colli',
    code: 'C897',
  },
  {
    name: 'Colonna',
    code: 'C900',
  },
  {
    name: 'Colonnella',
    code: 'C901',
  },
  {
    name: 'Colonno',
    code: 'C902',
  },
  {
    name: 'Colorina',
    code: 'C903',
  },
  {
    name: 'Colorno',
    code: 'C904',
  },
  {
    name: 'Colosimi',
    code: 'C905',
  },
  {
    name: 'Colturano',
    code: 'C908',
  },
  {
    name: 'Colverde',
    code: 'M336',
  },
  {
    name: 'Colzate',
    code: 'C910',
  },
  {
    name: 'Comabbio',
    code: 'C911',
  },
  {
    name: 'Comacchio',
    code: 'C912',
  },
  {
    name: 'Comano',
    code: 'C914',
  },
  {
    name: 'Comano Terme',
    code: 'M314',
  },
  {
    name: 'Comazzo',
    code: 'C917',
  },
  {
    name: 'Comeglians',
    code: 'C918',
  },
  {
    name: 'Comelico Superiore',
    code: 'C920',
  },
  {
    name: 'Comerio',
    code: 'C922',
  },
  {
    name: 'Comezzano-Cizzago',
    code: 'C925',
  },
  {
    name: 'Comignago',
    code: 'C926',
  },
  {
    name: 'Comiso',
    code: 'C927',
  },
  {
    name: 'Comitini',
    code: 'C928',
  },
  {
    name: 'Comiziano',
    code: 'C929',
  },
  {
    name: 'Commessaggio',
    code: 'C930',
  },
  {
    name: 'Commezzadura',
    code: 'C931',
  },
  {
    name: 'Como',
    code: 'C933',
  },
  {
    name: 'Compiano',
    code: 'C934',
  },
  {
    name: 'Comun Nuovo',
    code: 'C937',
  },
  {
    name: 'Comunanza',
    code: 'C935',
  },
  {
    name: 'Cona',
    code: 'C938',
  },
  {
    name: 'Conca Casale',
    code: 'C941',
  },
  {
    name: 'Conca dei Marini',
    code: 'C940',
  },
  {
    name: 'Conca della Campania',
    code: 'C939',
  },
  {
    name: 'Concamarise',
    code: 'C943',
  },
  {
    name: 'Concerviano',
    code: 'C946',
  },
  {
    name: 'Concesio',
    code: 'C948',
  },
  {
    name: 'Concordia Sagittaria',
    code: 'C950',
  },
  {
    name: 'Concordia sulla Secchia',
    code: 'C951',
  },
  {
    name: 'Concorezzo',
    code: 'C952',
  },
  {
    name: 'Condofuri',
    code: 'C954',
  },
  {
    name: 'Condove',
    code: 'C955',
  },
  {
    name: 'Condrò',
    code: 'C956',
  },
  {
    name: 'Conegliano',
    code: 'C957',
  },
  {
    name: 'Confienza',
    code: 'C958',
  },
  {
    name: 'Configni',
    code: 'C959',
  },
  {
    name: 'Conflenti',
    code: 'C960',
  },
  {
    name: 'Coniolo',
    code: 'C962',
  },
  {
    name: 'Conselice',
    code: 'C963',
  },
  {
    name: 'Conselve',
    code: 'C964',
  },
  {
    name: 'Contà',
    code: 'M356',
  },
  {
    name: 'Contessa Entellina',
    code: 'C968',
  },
  {
    name: 'Contigliano',
    code: 'C969',
  },
  {
    name: 'Contrada',
    code: 'C971',
  },
  {
    name: 'Controguerra',
    code: 'C972',
  },
  {
    name: 'Controne',
    code: 'C973',
  },
  {
    name: 'Contursi Terme',
    code: 'C974',
  },
  {
    name: 'Conversano',
    code: 'C975',
  },
  {
    name: 'Conza della Campania',
    code: 'C976',
  },
  {
    name: 'Conzano',
    code: 'C977',
  },
  {
    name: 'Copertino',
    code: 'C978',
  },
  {
    name: 'Copiano',
    code: 'C979',
  },
  {
    name: 'Copparo',
    code: 'C980',
  },
  {
    name: 'Corana',
    code: 'C982',
  },
  {
    name: 'Corato',
    code: 'C983',
  },
  {
    name: 'Corbara',
    code: 'C984',
  },
  {
    name: 'Corbetta',
    code: 'C986',
  },
  {
    name: 'Corbola',
    code: 'C987',
  },
  {
    name: 'Corchiano',
    code: 'C988',
  },
  {
    name: 'Corciano',
    code: 'C990',
  },
  {
    name: 'Cordenons',
    code: 'C991',
  },
  {
    name: 'Cordignano',
    code: 'C992',
  },
  {
    name: 'Cordovado',
    code: 'C993',
  },
  {
    name: 'Coreglia Antelminelli',
    code: 'C996',
  },
  {
    name: 'Coreglia Ligure',
    code: 'C995',
  },
  {
    name: 'Coreno Ausonio',
    code: 'C998',
  },
  {
    name: 'Corfinio',
    code: 'C999',
  },
  {
    name: 'Cori',
    code: 'D003',
  },
  {
    name: 'Coriano',
    code: 'D004',
  },
  {
    name: "Corigliano d'Otranto",
    code: 'D006',
  },
  {
    name: 'Corigliano-Rossano',
    code: 'M403',
  },
  {
    name: 'Corinaldo',
    code: 'D007',
  },
  {
    name: 'Corio',
    code: 'D008',
  },
  {
    name: 'Corleone',
    code: 'D009',
  },
  {
    name: 'Corleto Monforte',
    code: 'D011',
  },
  {
    name: 'Corleto Perticara',
    code: 'D010',
  },
  {
    name: 'Cormano',
    code: 'D013',
  },
  {
    name: 'Cormons',
    code: 'D014',
  },
  {
    name: 'Corna Imagna',
    code: 'D015',
  },
  {
    name: 'Cornalba',
    code: 'D016',
  },
  {
    name: 'Cornale e Bastida',
    code: 'M338',
  },
  {
    name: 'Cornaredo',
    code: 'D018',
  },
  {
    name: "Cornate d'Adda",
    code: 'D019',
  },
  {
    name: "Cornedo all'Isarco",
    code: 'B799',
  },
  {
    name: 'Cornedo Vicentino',
    code: 'D020',
  },
  {
    name: 'Cornegliano Laudense',
    code: 'D021',
  },
  {
    name: "Corneliano d'Alba",
    code: 'D022',
  },
  {
    name: 'Corniglio',
    code: 'D026',
  },
  {
    name: 'Corno di Rosazzo',
    code: 'D027',
  },
  {
    name: 'Corno Giovine',
    code: 'D028',
  },
  {
    name: 'Cornovecchio',
    code: 'D029',
  },
  {
    name: 'Cornuda',
    code: 'D030',
  },
  {
    name: 'Correggio',
    code: 'D037',
  },
  {
    name: 'Correzzana',
    code: 'D038',
  },
  {
    name: 'Correzzola',
    code: 'D040',
  },
  {
    name: 'Corrido',
    code: 'D041',
  },
  {
    name: 'Corridonia',
    code: 'D042',
  },
  {
    name: 'Corropoli',
    code: 'D043',
  },
  {
    name: 'Corsano',
    code: 'D044',
  },
  {
    name: 'Corsico',
    code: 'D045',
  },
  {
    name: 'Corsione',
    code: 'D046',
  },
  {
    name: 'Cortaccia sulla strada del vino',
    code: 'D048',
  },
  {
    name: 'Cortale',
    code: 'D049',
  },
  {
    name: 'Cortandone',
    code: 'D050',
  },
  {
    name: 'Cortanze',
    code: 'D051',
  },
  {
    name: 'Cortazzone',
    code: 'D052',
  },
  {
    name: 'Corte Brugnatella',
    code: 'D054',
  },
  {
    name: "Corte de' Cortesi con Cignone",
    code: 'D056',
  },
  {
    name: "Corte de' Frati",
    code: 'D057',
  },
  {
    name: 'Corte Franca',
    code: 'D058',
  },
  {
    name: 'Corte Palasio',
    code: 'D068',
  },
  {
    name: 'Cortemaggiore',
    code: 'D061',
  },
  {
    name: 'Cortemilia',
    code: 'D062',
  },
  {
    name: 'Corteno Golgi',
    code: 'D064',
  },
  {
    name: 'Cortenova',
    code: 'D065',
  },
  {
    name: 'Cortenuova',
    code: 'D066',
  },
  {
    name: 'Corteolona e Genzone',
    code: 'M372',
  },
  {
    name: 'Cortiglione',
    code: 'D072',
  },
  {
    name: "Cortina d'Ampezzo",
    code: 'A266',
  },
  {
    name: 'Cortina sulla strada del vino',
    code: 'D075',
  },
  {
    name: 'Cortino',
    code: 'D076',
  },
  {
    name: 'Cortona',
    code: 'D077',
  },
  {
    name: 'Corvara',
    code: 'D078',
  },
  {
    name: 'Corvara in Badia',
    code: 'D079',
  },
  {
    name: 'Corvino San Quirico',
    code: 'D081',
  },
  {
    name: 'Corzano',
    code: 'D082',
  },
  {
    name: 'Coseano',
    code: 'D085',
  },
  {
    name: 'Cosenza',
    code: 'D086',
  },
  {
    name: "Cosio d'Arroscia",
    code: 'D087',
  },
  {
    name: 'Cosio Valtellino',
    code: 'D088',
  },
  {
    name: 'Cosoleto',
    code: 'D089',
  },
  {
    name: 'Cossano Belbo',
    code: 'D093',
  },
  {
    name: 'Cossano Canavese',
    code: 'D092',
  },
  {
    name: 'Cossato',
    code: 'D094',
  },
  {
    name: 'Cosseria',
    code: 'D095',
  },
  {
    name: 'Cossignano',
    code: 'D096',
  },
  {
    name: 'Cossogno',
    code: 'D099',
  },
  {
    name: 'Cossoine',
    code: 'D100',
  },
  {
    name: 'Cossombrato',
    code: 'D101',
  },
  {
    name: "Costa de' Nobili",
    code: 'D109',
  },
  {
    name: 'Costa di Mezzate',
    code: 'D110',
  },
  {
    name: 'Costa di Rovigo',
    code: 'D105',
  },
  {
    name: 'Costa Masnaga',
    code: 'D112',
  },
  {
    name: 'Costa Serina',
    code: 'D111',
  },
  {
    name: 'Costa Valle Imagna',
    code: 'D103',
  },
  {
    name: 'Costa Vescovato',
    code: 'D102',
  },
  {
    name: 'Costa Volpino',
    code: 'D117',
  },
  {
    name: 'Costabissara',
    code: 'D107',
  },
  {
    name: 'Costacciaro',
    code: 'D108',
  },
  {
    name: 'Costanzana',
    code: 'D113',
  },
  {
    name: 'Costarainera',
    code: 'D114',
  },
  {
    name: 'Costermano sul Garda',
    code: 'D118',
  },
  {
    name: "Costigliole d'Asti",
    code: 'D119',
  },
  {
    name: 'Costigliole Saluzzo',
    code: 'D120',
  },
  {
    name: 'Cotignola',
    code: 'D121',
  },
  {
    name: 'Cotronei',
    code: 'D123',
  },
  {
    name: 'Cottanello',
    code: 'D124',
  },
  {
    name: 'Courmayeur',
    code: 'D012',
  },
  {
    name: 'Covo',
    code: 'D126',
  },
  {
    name: 'Cozzo',
    code: 'D127',
  },
  {
    name: 'Craco',
    code: 'D128',
  },
  {
    name: 'Crandola Valsassina',
    code: 'D131',
  },
  {
    name: 'Cravagliana',
    code: 'D132',
  },
  {
    name: 'Cravanzana',
    code: 'D133',
  },
  {
    name: 'Craveggia',
    code: 'D134',
  },
  {
    name: 'Creazzo',
    code: 'D136',
  },
  {
    name: 'Crecchio',
    code: 'D137',
  },
  {
    name: 'Credaro',
    code: 'D139',
  },
  {
    name: 'Credera Rubbiano',
    code: 'D141',
  },
  {
    name: 'Crema',
    code: 'D142',
  },
  {
    name: 'Cremella',
    code: 'D143',
  },
  {
    name: 'Cremenaga',
    code: 'D144',
  },
  {
    name: 'Cremeno',
    code: 'D145',
  },
  {
    name: 'Cremia',
    code: 'D147',
  },
  {
    name: 'Cremolino',
    code: 'D149',
  },
  {
    name: 'Cremona',
    code: 'D150',
  },
  {
    name: 'Cremosano',
    code: 'D151',
  },
  {
    name: 'Crescentino',
    code: 'D154',
  },
  {
    name: 'Crespadoro',
    code: 'D156',
  },
  {
    name: 'Crespiatica',
    code: 'D159',
  },
  {
    name: 'Crespina Lorenzana',
    code: 'M328',
  },
  {
    name: 'Crespino',
    code: 'D161',
  },
  {
    name: 'Cressa',
    code: 'D162',
  },
  {
    name: 'Crevacuore',
    code: 'D165',
  },
  {
    name: 'Crevalcore',
    code: 'D166',
  },
  {
    name: 'Crevoladossola',
    code: 'D168',
  },
  {
    name: 'Crispano',
    code: 'D170',
  },
  {
    name: 'Crispiano',
    code: 'D171',
  },
  {
    name: 'Crissolo',
    code: 'D172',
  },
  {
    name: 'Crocefieschi',
    code: 'D175',
  },
  {
    name: 'Crocetta del Montello',
    code: 'C670',
  },
  {
    name: 'Crodo',
    code: 'D177',
  },
  {
    name: 'Crognaleto',
    code: 'D179',
  },
  {
    name: 'Cropalati',
    code: 'D180',
  },
  {
    name: 'Cropani',
    code: 'D181',
  },
  {
    name: 'Crosia',
    code: 'D184',
  },
  {
    name: 'Crosio della Valle',
    code: 'D185',
  },
  {
    name: 'Crotone',
    code: 'D122',
  },
  {
    name: "Crotta d'Adda",
    code: 'D186',
  },
  {
    name: 'Crova',
    code: 'D187',
  },
  {
    name: 'Croviana',
    code: 'D188',
  },
  {
    name: 'Crucoli',
    code: 'D189',
  },
  {
    name: 'Cuasso al Monte',
    code: 'D192',
  },
  {
    name: 'Cuccaro Vetere',
    code: 'D195',
  },
  {
    name: 'Cucciago',
    code: 'D196',
  },
  {
    name: 'Cuceglio',
    code: 'D197',
  },
  {
    name: 'Cuggiono',
    code: 'D198',
  },
  {
    name: 'Cugliate-Fabiasco',
    code: 'D199',
  },
  {
    name: 'Cuglieri',
    code: 'D200',
  },
  {
    name: 'Cugnoli',
    code: 'D201',
  },
  {
    name: 'Cumiana',
    code: 'D202',
  },
  {
    name: 'Cumignano sul Naviglio',
    code: 'D203',
  },
  {
    name: 'Cunardo',
    code: 'D204',
  },
  {
    name: 'Cuneo',
    code: 'D205',
  },
  {
    name: 'Cunico',
    code: 'D207',
  },
  {
    name: 'Cuorgnè',
    code: 'D208',
  },
  {
    name: 'Cupello',
    code: 'D209',
  },
  {
    name: 'Cupra Marittima',
    code: 'D210',
  },
  {
    name: 'Cupramontana',
    code: 'D211',
  },
  {
    name: 'Cura Carpignano',
    code: 'B824',
  },
  {
    name: 'Curcuris',
    code: 'D214',
  },
  {
    name: 'Cureggio',
    code: 'D216',
  },
  {
    name: 'Curiglia con Monteviasco',
    code: 'D217',
  },
  {
    name: 'Curinga',
    code: 'D218',
  },
  {
    name: 'Curino',
    code: 'D219',
  },
  {
    name: 'Curno',
    code: 'D221',
  },
  {
    name: 'Curon Venosta',
    code: 'D222',
  },
  {
    name: 'Cursi',
    code: 'D223',
  },
  {
    name: 'Curtarolo',
    code: 'D226',
  },
  {
    name: 'Curtatone',
    code: 'D227',
  },
  {
    name: 'Curti',
    code: 'D228',
  },
  {
    name: 'Cusago',
    code: 'D229',
  },
  {
    name: 'Cusano Milanino',
    code: 'D231',
  },
  {
    name: 'Cusano Mutri',
    code: 'D230',
  },
  {
    name: 'Cusino',
    code: 'D232',
  },
  {
    name: 'Cusio',
    code: 'D233',
  },
  {
    name: 'Custonaci',
    code: 'D234',
  },
  {
    name: 'Cutro',
    code: 'D236',
  },
  {
    name: 'Cutrofiano',
    code: 'D237',
  },
  {
    name: 'Cuveglio',
    code: 'D238',
  },
  {
    name: 'Cuvio',
    code: 'D239',
  },
  {
    name: 'Dairago',
    code: 'D244',
  },
  {
    name: 'Dalmine',
    code: 'D245',
  },
  {
    name: 'Dambel',
    code: 'D246',
  },
  {
    name: 'Danta di Cadore',
    code: 'D247',
  },
  {
    name: 'Darfo Boario Terme',
    code: 'D251',
  },
  {
    name: 'Dasà',
    code: 'D253',
  },
  {
    name: 'Davagna',
    code: 'D255',
  },
  {
    name: 'Daverio',
    code: 'D256',
  },
  {
    name: 'Davoli',
    code: 'D257',
  },
  {
    name: 'Dazio',
    code: 'D258',
  },
  {
    name: 'Decimomannu',
    code: 'D259',
  },
  {
    name: 'Decimoputzu',
    code: 'D260',
  },
  {
    name: 'Decollatura',
    code: 'D261',
  },
  {
    name: 'Dego',
    code: 'D264',
  },
  {
    name: 'Deiva Marina',
    code: 'D265',
  },
  {
    name: 'Delebio',
    code: 'D266',
  },
  {
    name: 'Delia',
    code: 'D267',
  },
  {
    name: 'Delianuova',
    code: 'D268',
  },
  {
    name: 'Deliceto',
    code: 'D269',
  },
  {
    name: 'Dello',
    code: 'D270',
  },
  {
    name: 'Demonte',
    code: 'D271',
  },
  {
    name: 'Denice',
    code: 'D272',
  },
  {
    name: 'Denno',
    code: 'D273',
  },
  {
    name: 'Dernice',
    code: 'D277',
  },
  {
    name: 'Derovere',
    code: 'D278',
  },
  {
    name: 'Deruta',
    code: 'D279',
  },
  {
    name: 'Dervio',
    code: 'D280',
  },
  {
    name: 'Desana',
    code: 'D281',
  },
  {
    name: 'Desenzano del Garda',
    code: 'D284',
  },
  {
    name: 'Desio',
    code: 'D286',
  },
  {
    name: 'Desulo',
    code: 'D287',
  },
  {
    name: 'Diamante',
    code: 'D289',
  },
  {
    name: 'Diano Arentino',
    code: 'D293',
  },
  {
    name: 'Diano Castello',
    code: 'D296',
  },
  {
    name: "Diano d'Alba",
    code: 'D291',
  },
  {
    name: 'Diano Marina',
    code: 'D297',
  },
  {
    name: 'Diano San Pietro',
    code: 'D298',
  },
  {
    name: 'Dicomano',
    code: 'D299',
  },
  {
    name: 'Dignano',
    code: 'D300',
  },
  {
    name: 'Dimaro Folgarida',
    code: 'M366',
  },
  {
    name: 'Dinami',
    code: 'D303',
  },
  {
    name: 'Dipignano',
    code: 'D304',
  },
  {
    name: 'Diso',
    code: 'D305',
  },
  {
    name: 'Divignano',
    code: 'D309',
  },
  {
    name: 'Dizzasco',
    code: 'D310',
  },
  {
    name: 'Dobbiaco',
    code: 'D311',
  },
  {
    name: 'Doberdò del Lago',
    code: 'D312',
  },
  {
    name: 'Dogliani',
    code: 'D314',
  },
  {
    name: 'Dogliola',
    code: 'D315',
  },
  {
    name: 'Dogna',
    code: 'D316',
  },
  {
    name: 'Dolcè',
    code: 'D317',
  },
  {
    name: 'Dolceacqua',
    code: 'D318',
  },
  {
    name: 'Dolcedo',
    code: 'D319',
  },
  {
    name: 'Dolegna del Collio',
    code: 'D321',
  },
  {
    name: 'Dolianova',
    code: 'D323',
  },
  {
    name: 'Dolo',
    code: 'D325',
  },
  {
    name: 'Dolzago',
    code: 'D327',
  },
  {
    name: 'Domanico',
    code: 'D328',
  },
  {
    name: 'Domaso',
    code: 'D329',
  },
  {
    name: 'Domegge di Cadore',
    code: 'D330',
  },
  {
    name: 'Domicella',
    code: 'D331',
  },
  {
    name: 'Domodossola',
    code: 'D332',
  },
  {
    name: 'Domus de Maria',
    code: 'D333',
  },
  {
    name: 'Domusnovas',
    code: 'D334',
  },
  {
    name: 'Donato',
    code: 'D339',
  },
  {
    name: 'Dongo',
    code: 'D341',
  },
  {
    name: 'Donnas',
    code: 'D338',
  },
  {
    name: 'Donori',
    code: 'D344',
  },
  {
    name: 'Dorgali',
    code: 'D345',
  },
  {
    name: 'Dorio',
    code: 'D346',
  },
  {
    name: 'Dormelletto',
    code: 'D347',
  },
  {
    name: 'Dorno',
    code: 'D348',
  },
  {
    name: 'Dorzano',
    code: 'D350',
  },
  {
    name: 'Dosolo',
    code: 'D351',
  },
  {
    name: 'Dossena',
    code: 'D352',
  },
  {
    name: 'Dosso del Liro',
    code: 'D355',
  },
  {
    name: 'Doues',
    code: 'D356',
  },
  {
    name: 'Dovadola',
    code: 'D357',
  },
  {
    name: 'Dovera',
    code: 'D358',
  },
  {
    name: 'Dozza',
    code: 'D360',
  },
  {
    name: 'Dragoni',
    code: 'D361',
  },
  {
    name: 'Drapia',
    code: 'D364',
  },
  {
    name: 'Drena',
    code: 'D365',
  },
  {
    name: 'Drenchia',
    code: 'D366',
  },
  {
    name: 'Dresano',
    code: 'D367',
  },
  {
    name: 'Dro',
    code: 'D371',
  },
  {
    name: 'Dronero',
    code: 'D372',
  },
  {
    name: 'Druento',
    code: 'D373',
  },
  {
    name: 'Druogno',
    code: 'D374',
  },
  {
    name: 'Dualchi',
    code: 'D376',
  },
  {
    name: 'Dubino',
    code: 'D377',
  },
  {
    name: 'Due Carrare',
    code: 'M300',
  },
  {
    name: 'Dueville',
    code: 'D379',
  },
  {
    name: 'Dugenta',
    code: 'D380',
  },
  {
    name: 'Duino Aurisina',
    code: 'D383',
  },
  {
    name: 'Dumenza',
    code: 'D384',
  },
  {
    name: 'Duno',
    code: 'D385',
  },
  {
    name: 'Durazzano',
    code: 'D386',
  },
  {
    name: 'Duronia',
    code: 'C772',
  },
  {
    name: 'Dusino San Michele',
    code: 'D388',
  },
  {
    name: 'Eboli',
    code: 'D390',
  },
  {
    name: 'Edolo',
    code: 'D391',
  },
  {
    name: 'Egna',
    code: 'D392',
  },
  {
    name: 'Elice',
    code: 'D394',
  },
  {
    name: 'Elini',
    code: 'D395',
  },
  {
    name: 'Ello',
    code: 'D398',
  },
  {
    name: 'Elmas',
    code: 'D399',
  },
  {
    name: 'Elva',
    code: 'D401',
  },
  {
    name: 'Emarèse',
    code: 'D402',
  },
  {
    name: 'Empoli',
    code: 'D403',
  },
  {
    name: 'Endine Gaiano',
    code: 'D406',
  },
  {
    name: 'Enego',
    code: 'D407',
  },
  {
    name: 'Enemonzo',
    code: 'D408',
  },
  {
    name: 'Enna',
    code: 'C342',
  },
  {
    name: 'Entracque',
    code: 'D410',
  },
  {
    name: 'Entratico',
    code: 'D411',
  },
  {
    name: 'Envie',
    code: 'D412',
  },
  {
    name: 'Episcopia',
    code: 'D414',
  },
  {
    name: 'Eraclea',
    code: 'D415',
  },
  {
    name: 'Erba',
    code: 'D416',
  },
  {
    name: 'Erbè',
    code: 'D419',
  },
  {
    name: 'Erbezzo',
    code: 'D420',
  },
  {
    name: 'Erbusco',
    code: 'D421',
  },
  {
    name: 'Erchie',
    code: 'D422',
  },
  {
    name: 'Ercolano',
    code: 'H243',
  },
  {
    name: 'Erice',
    code: 'D423',
  },
  {
    name: 'Erli',
    code: 'D424',
  },
  {
    name: 'Erto e Casso',
    code: 'D426',
  },
  {
    name: 'Erula',
    code: 'M292',
  },
  {
    name: 'Erve',
    code: 'D428',
  },
  {
    name: 'Esanatoglia',
    code: 'D429',
  },
  {
    name: 'Escalaplano',
    code: 'D430',
  },
  {
    name: 'Escolca',
    code: 'D431',
  },
  {
    name: 'Esine',
    code: 'D434',
  },
  {
    name: 'Esino Lario',
    code: 'D436',
  },
  {
    name: 'Esperia',
    code: 'D440',
  },
  {
    name: 'Esporlatu',
    code: 'D441',
  },
  {
    name: 'Este',
    code: 'D442',
  },
  {
    name: 'Esterzili',
    code: 'D443',
  },
  {
    name: 'Etroubles',
    code: 'D444',
  },
  {
    name: 'Eupilio',
    code: 'D445',
  },
  {
    name: 'Exilles',
    code: 'D433',
  },
  {
    name: 'Fabbrica Curone',
    code: 'D447',
  },
  {
    name: 'Fabbriche di Vergemoli',
    code: 'M319',
  },
  {
    name: 'Fabbrico',
    code: 'D450',
  },
  {
    name: 'Fabriano',
    code: 'D451',
  },
  {
    name: 'Fabrica di Roma',
    code: 'D452',
  },
  {
    name: 'Fabrizia',
    code: 'D453',
  },
  {
    name: 'Fabro',
    code: 'D454',
  },
  {
    name: 'Faedis',
    code: 'D455',
  },
  {
    name: 'Faedo Valtellino',
    code: 'D456',
  },
  {
    name: 'Faenza',
    code: 'D458',
  },
  {
    name: 'Faeto',
    code: 'D459',
  },
  {
    name: 'Fagagna',
    code: 'D461',
  },
  {
    name: 'Faggeto Lario',
    code: 'D462',
  },
  {
    name: 'Faggiano',
    code: 'D463',
  },
  {
    name: 'Fagnano Alto',
    code: 'D465',
  },
  {
    name: 'Fagnano Castello',
    code: 'D464',
  },
  {
    name: 'Fagnano Olona',
    code: 'D467',
  },
  {
    name: 'Fai della Paganella',
    code: 'D468',
  },
  {
    name: 'Faicchio',
    code: 'D469',
  },
  {
    name: 'Falcade',
    code: 'D470',
  },
  {
    name: 'Falciano del Massico',
    code: 'D471',
  },
  {
    name: 'Falconara Albanese',
    code: 'D473',
  },
  {
    name: 'Falconara Marittima',
    code: 'D472',
  },
  {
    name: 'Falcone',
    code: 'D474',
  },
  {
    name: 'Faleria',
    code: 'D475',
  },
  {
    name: 'Falerna',
    code: 'D476',
  },
  {
    name: 'Falerone',
    code: 'D477',
  },
  {
    name: 'Fallo',
    code: 'D480',
  },
  {
    name: 'Faloppio',
    code: 'D482',
  },
  {
    name: 'Falvaterra',
    code: 'D483',
  },
  {
    name: 'Falzes',
    code: 'D484',
  },
  {
    name: 'Fanano',
    code: 'D486',
  },
  {
    name: 'Fanna',
    code: 'D487',
  },
  {
    name: 'Fano',
    code: 'D488',
  },
  {
    name: 'Fano Adriano',
    code: 'D489',
  },
  {
    name: 'Fara Filiorum Petri',
    code: 'D494',
  },
  {
    name: "Fara Gera d'Adda",
    code: 'D490',
  },
  {
    name: 'Fara in Sabina',
    code: 'D493',
  },
  {
    name: 'Fara Novarese',
    code: 'D492',
  },
  {
    name: 'Fara Olivana con Sola',
    code: 'D491',
  },
  {
    name: 'Fara San Martino',
    code: 'D495',
  },
  {
    name: 'Fara Vicentino',
    code: 'D496',
  },
  {
    name: 'Fardella',
    code: 'D497',
  },
  {
    name: 'Farigliano',
    code: 'D499',
  },
  {
    name: 'Farindola',
    code: 'D501',
  },
  {
    name: 'Farini',
    code: 'D502',
  },
  {
    name: 'Farnese',
    code: 'D503',
  },
  {
    name: "Farra d'Isonzo",
    code: 'D504',
  },
  {
    name: 'Farra di Soligo',
    code: 'D505',
  },
  {
    name: 'Fasano',
    code: 'D508',
  },
  {
    name: 'Fascia',
    code: 'D509',
  },
  {
    name: 'Fauglia',
    code: 'D510',
  },
  {
    name: 'Faule',
    code: 'D511',
  },
  {
    name: 'Favale di Malvaro',
    code: 'D512',
  },
  {
    name: 'Favara',
    code: 'D514',
  },
  {
    name: 'Favignana',
    code: 'D518',
  },
  {
    name: 'Favria',
    code: 'D520',
  },
  {
    name: 'Feisoglio',
    code: 'D523',
  },
  {
    name: 'Feletto',
    code: 'D524',
  },
  {
    name: 'Felino',
    code: 'D526',
  },
  {
    name: 'Felitto',
    code: 'D527',
  },
  {
    name: 'Felizzano',
    code: 'D528',
  },
  {
    name: 'Feltre',
    code: 'D530',
  },
  {
    name: 'Fenegrò',
    code: 'D531',
  },
  {
    name: 'Fenestrelle',
    code: 'D532',
  },
  {
    name: 'Fénis',
    code: 'D537',
  },
  {
    name: 'Ferentillo',
    code: 'D538',
  },
  {
    name: 'Ferentino',
    code: 'D539',
  },
  {
    name: 'Ferla',
    code: 'D540',
  },
  {
    name: 'Fermignano',
    code: 'D541',
  },
  {
    name: 'Fermo',
    code: 'D542',
  },
  {
    name: 'Ferno',
    code: 'D543',
  },
  {
    name: 'Feroleto Antico',
    code: 'D544',
  },
  {
    name: 'Feroleto della Chiesa',
    code: 'D545',
  },
  {
    name: 'Ferrandina',
    code: 'D547',
  },
  {
    name: 'Ferrara',
    code: 'D548',
  },
  {
    name: 'Ferrara di Monte Baldo',
    code: 'D549',
  },
  {
    name: 'Ferrazzano',
    code: 'D550',
  },
  {
    name: 'Ferrera di Varese',
    code: 'D551',
  },
  {
    name: 'Ferrera Erbognone',
    code: 'D552',
  },
  {
    name: 'Ferrere',
    code: 'D554',
  },
  {
    name: 'Ferriere',
    code: 'D555',
  },
  {
    name: 'Ferruzzano',
    code: 'D557',
  },
  {
    name: 'Fiamignano',
    code: 'D560',
  },
  {
    name: 'Fiano',
    code: 'D562',
  },
  {
    name: 'Fiano Romano',
    code: 'D561',
  },
  {
    name: 'Fiastra',
    code: 'D564',
  },
  {
    name: 'Fiavè',
    code: 'D565',
  },
  {
    name: 'Ficarazzi',
    code: 'D567',
  },
  {
    name: 'Ficarolo',
    code: 'D568',
  },
  {
    name: 'Ficarra',
    code: 'D569',
  },
  {
    name: 'Ficulle',
    code: 'D570',
  },
  {
    name: 'Fidenza',
    code: 'B034',
  },
  {
    name: 'Fiè allo Sciliar',
    code: 'D571',
  },
  {
    name: 'Fierozzo',
    code: 'D573',
  },
  {
    name: 'Fiesco',
    code: 'D574',
  },
  {
    name: 'Fiesole',
    code: 'D575',
  },
  {
    name: 'Fiesse',
    code: 'D576',
  },
  {
    name: "Fiesso d'Artico",
    code: 'D578',
  },
  {
    name: 'Fiesso Umbertiano',
    code: 'D577',
  },
  {
    name: 'Figino Serenza',
    code: 'D579',
  },
  {
    name: 'Figline e Incisa Valdarno',
    code: 'M321',
  },
  {
    name: 'Figline Vegliaturo',
    code: 'D582',
  },
  {
    name: 'Filacciano',
    code: 'D586',
  },
  {
    name: 'Filadelfia',
    code: 'D587',
  },
  {
    name: 'Filago',
    code: 'D588',
  },
  {
    name: 'Filandari',
    code: 'D589',
  },
  {
    name: 'Filattiera',
    code: 'D590',
  },
  {
    name: 'Filettino',
    code: 'D591',
  },
  {
    name: 'Filetto',
    code: 'D592',
  },
  {
    name: 'Filiano',
    code: 'D593',
  },
  {
    name: 'Filighera',
    code: 'D594',
  },
  {
    name: 'Filignano',
    code: 'D595',
  },
  {
    name: 'Filogaso',
    code: 'D596',
  },
  {
    name: 'Filottrano',
    code: 'D597',
  },
  {
    name: 'Finale Emilia',
    code: 'D599',
  },
  {
    name: 'Finale Ligure',
    code: 'D600',
  },
  {
    name: 'Fino del Monte',
    code: 'D604',
  },
  {
    name: 'Fino Mornasco',
    code: 'D605',
  },
  {
    name: 'Fiorano al Serio',
    code: 'D606',
  },
  {
    name: 'Fiorano Canavese',
    code: 'D608',
  },
  {
    name: 'Fiorano Modenese',
    code: 'D607',
  },
  {
    name: "Fiorenzuola d'Arda",
    code: 'D611',
  },
  {
    name: 'Firenze',
    code: 'D612',
  },
  {
    name: 'Firenzuola',
    code: 'D613',
  },
  {
    name: 'Firmo',
    code: 'D614',
  },
  {
    name: 'Fiscaglia',
    code: 'M323',
  },
  {
    name: 'Fisciano',
    code: 'D615',
  },
  {
    name: 'Fiuggi',
    code: 'A310',
  },
  {
    name: 'Fiumalbo',
    code: 'D617',
  },
  {
    name: 'Fiumara',
    code: 'D619',
  },
  {
    name: 'Fiume Veneto',
    code: 'D621',
  },
  {
    name: 'Fiumedinisi',
    code: 'D622',
  },
  {
    name: 'Fiumefreddo Bruzio',
    code: 'D624',
  },
  {
    name: 'Fiumefreddo di Sicilia',
    code: 'D623',
  },
  {
    name: 'Fiumicello Villa Vicentina',
    code: 'M400',
  },
  {
    name: 'Fiumicino',
    code: 'M297',
  },
  {
    name: 'Fiuminata',
    code: 'D628',
  },
  {
    name: 'Fivizzano',
    code: 'D629',
  },
  {
    name: 'Flaibano',
    code: 'D630',
  },
  {
    name: 'Flero',
    code: 'D634',
  },
  {
    name: 'Floresta',
    code: 'D635',
  },
  {
    name: 'Floridia',
    code: 'D636',
  },
  {
    name: 'Florinas',
    code: 'D637',
  },
  {
    name: 'Flumeri',
    code: 'D638',
  },
  {
    name: 'Fluminimaggiore',
    code: 'D639',
  },
  {
    name: 'Flussio',
    code: 'D640',
  },
  {
    name: 'Fobello',
    code: 'D641',
  },
  {
    name: 'Foggia',
    code: 'D643',
  },
  {
    name: 'Foglianise',
    code: 'D644',
  },
  {
    name: 'Fogliano Redipuglia',
    code: 'D645',
  },
  {
    name: 'Foglizzo',
    code: 'D646',
  },
  {
    name: 'Foiano della Chiana',
    code: 'D649',
  },
  {
    name: 'Foiano di Val Fortore',
    code: 'D650',
  },
  {
    name: 'Folgaria',
    code: 'D651',
  },
  {
    name: 'Folignano',
    code: 'D652',
  },
  {
    name: 'Foligno',
    code: 'D653',
  },
  {
    name: 'Follina',
    code: 'D654',
  },
  {
    name: 'Follo',
    code: 'D655',
  },
  {
    name: 'Follonica',
    code: 'D656',
  },
  {
    name: 'Fombio',
    code: 'D660',
  },
  {
    name: 'Fondachelli-Fantina',
    code: 'D661',
  },
  {
    name: 'Fondi',
    code: 'D662',
  },
  {
    name: 'Fonni',
    code: 'D665',
  },
  {
    name: 'Fontainemore',
    code: 'D666',
  },
  {
    name: 'Fontana Liri',
    code: 'D667',
  },
  {
    name: 'Fontanafredda',
    code: 'D670',
  },
  {
    name: 'Fontanarosa',
    code: 'D671',
  },
  {
    name: 'Fontanelice',
    code: 'D668',
  },
  {
    name: 'Fontanella',
    code: 'D672',
  },
  {
    name: 'Fontanellato',
    code: 'D673',
  },
  {
    name: 'Fontanelle',
    code: 'D674',
  },
  {
    name: "Fontaneto d'Agogna",
    code: 'D675',
  },
  {
    name: 'Fontanetto Po',
    code: 'D676',
  },
  {
    name: 'Fontanigorda',
    code: 'D677',
  },
  {
    name: 'Fontanile',
    code: 'D678',
  },
  {
    name: 'Fontaniva',
    code: 'D679',
  },
  {
    name: 'Fonte',
    code: 'D680',
  },
  {
    name: 'Fonte Nuova',
    code: 'M309',
  },
  {
    name: 'Fontecchio',
    code: 'D681',
  },
  {
    name: 'Fontechiari',
    code: 'D682',
  },
  {
    name: 'Fontegreca',
    code: 'D683',
  },
  {
    name: 'Fonteno',
    code: 'D684',
  },
  {
    name: 'Fontevivo',
    code: 'D685',
  },
  {
    name: 'Fonzaso',
    code: 'D686',
  },
  {
    name: 'Foppolo',
    code: 'D688',
  },
  {
    name: 'Forano',
    code: 'D689',
  },
  {
    name: 'Force',
    code: 'D691',
  },
  {
    name: 'Forchia',
    code: 'D693',
  },
  {
    name: 'Forcola',
    code: 'D694',
  },
  {
    name: 'Fordongianus',
    code: 'D695',
  },
  {
    name: 'Forenza',
    code: 'D696',
  },
  {
    name: 'Foresto Sparso',
    code: 'D697',
  },
  {
    name: 'Forgaria nel Friuli',
    code: 'D700',
  },
  {
    name: 'Forino',
    code: 'D701',
  },
  {
    name: 'Forio',
    code: 'D702',
  },
  {
    name: 'Forlì',
    code: 'D704',
  },
  {
    name: 'Forlì del Sannio',
    code: 'D703',
  },
  {
    name: 'Forlimpopoli',
    code: 'D705',
  },
  {
    name: 'Formazza',
    code: 'D706',
  },
  {
    name: 'Formello',
    code: 'D707',
  },
  {
    name: 'Formia',
    code: 'D708',
  },
  {
    name: 'Formicola',
    code: 'D709',
  },
  {
    name: 'Formigara',
    code: 'D710',
  },
  {
    name: 'Formigine',
    code: 'D711',
  },
  {
    name: 'Formigliana',
    code: 'D712',
  },
  {
    name: 'Fornace',
    code: 'D714',
  },
  {
    name: 'Fornelli',
    code: 'D715',
  },
  {
    name: 'Forni Avoltri',
    code: 'D718',
  },
  {
    name: 'Forni di Sopra',
    code: 'D719',
  },
  {
    name: 'Forni di Sotto',
    code: 'D720',
  },
  {
    name: 'Forno Canavese',
    code: 'D725',
  },
  {
    name: 'Fornovo di Taro',
    code: 'D728',
  },
  {
    name: 'Fornovo San Giovanni',
    code: 'D727',
  },
  {
    name: 'Forte dei Marmi',
    code: 'D730',
  },
  {
    name: 'Fortezza',
    code: 'D731',
  },
  {
    name: 'Fortunago',
    code: 'D732',
  },
  {
    name: "Forza d'Agrò",
    code: 'D733',
  },
  {
    name: 'Fosciandora',
    code: 'D734',
  },
  {
    name: 'Fosdinovo',
    code: 'D735',
  },
  {
    name: 'Fossa',
    code: 'D736',
  },
  {
    name: 'Fossacesia',
    code: 'D738',
  },
  {
    name: 'Fossalta di Piave',
    code: 'D740',
  },
  {
    name: 'Fossalta di Portogruaro',
    code: 'D741',
  },
  {
    name: 'Fossalto',
    code: 'D737',
  },
  {
    name: 'Fossano',
    code: 'D742',
  },
  {
    name: 'Fossato di Vico',
    code: 'D745',
  },
  {
    name: 'Fossato Serralta',
    code: 'D744',
  },
  {
    name: 'Fossò',
    code: 'D748',
  },
  {
    name: 'Fossombrone',
    code: 'D749',
  },
  {
    name: 'Foza',
    code: 'D750',
  },
  {
    name: 'Frabosa Soprana',
    code: 'D751',
  },
  {
    name: 'Frabosa Sottana',
    code: 'D752',
  },
  {
    name: 'Fraconalto',
    code: 'D559',
  },
  {
    name: 'Fragagnano',
    code: 'D754',
  },
  {
    name: "Fragneto l'Abate",
    code: 'D755',
  },
  {
    name: 'Fragneto Monforte',
    code: 'D756',
  },
  {
    name: 'Fraine',
    code: 'D757',
  },
  {
    name: 'Framura',
    code: 'D758',
  },
  {
    name: 'Francavilla al Mare',
    code: 'D763',
  },
  {
    name: 'Francavilla Angitola',
    code: 'D762',
  },
  {
    name: 'Francavilla Bisio',
    code: 'D759',
  },
  {
    name: "Francavilla d'Ete",
    code: 'D760',
  },
  {
    name: 'Francavilla di Sicilia',
    code: 'D765',
  },
  {
    name: 'Francavilla Fontana',
    code: 'D761',
  },
  {
    name: 'Francavilla in Sinni',
    code: 'D766',
  },
  {
    name: 'Francavilla Marittima',
    code: 'D764',
  },
  {
    name: 'Francica',
    code: 'D767',
  },
  {
    name: 'Francofonte',
    code: 'D768',
  },
  {
    name: 'Francolise',
    code: 'D769',
  },
  {
    name: 'Frascaro',
    code: 'D770',
  },
  {
    name: 'Frascarolo',
    code: 'D771',
  },
  {
    name: 'Frascati',
    code: 'D773',
  },
  {
    name: 'Frascineto',
    code: 'D774',
  },
  {
    name: 'Frassilongo',
    code: 'D775',
  },
  {
    name: 'Frassinelle Polesine',
    code: 'D776',
  },
  {
    name: 'Frassinello Monferrato',
    code: 'D777',
  },
  {
    name: 'Frassineto Po',
    code: 'D780',
  },
  {
    name: 'Frassinetto',
    code: 'D781',
  },
  {
    name: 'Frassino',
    code: 'D782',
  },
  {
    name: 'Frassinoro',
    code: 'D783',
  },
  {
    name: 'Frasso Sabino',
    code: 'D785',
  },
  {
    name: 'Frasso Telesino',
    code: 'D784',
  },
  {
    name: 'Fratta Polesine',
    code: 'D788',
  },
  {
    name: 'Fratta Todina',
    code: 'D787',
  },
  {
    name: 'Frattamaggiore',
    code: 'D789',
  },
  {
    name: 'Frattaminore',
    code: 'D790',
  },
  {
    name: 'Fratte Rosa',
    code: 'D791',
  },
  {
    name: 'Frazzanò',
    code: 'D793',
  },
  {
    name: 'Fregona',
    code: 'D794',
  },
  {
    name: 'Fresagrandinaria',
    code: 'D796',
  },
  {
    name: 'Fresonara',
    code: 'D797',
  },
  {
    name: 'Frigento',
    code: 'D798',
  },
  {
    name: 'Frignano',
    code: 'D799',
  },
  {
    name: 'Frinco',
    code: 'D802',
  },
  {
    name: 'Frisa',
    code: 'D803',
  },
  {
    name: 'Frisanco',
    code: 'D804',
  },
  {
    name: 'Front',
    code: 'D805',
  },
  {
    name: 'Frontino',
    code: 'D807',
  },
  {
    name: 'Frontone',
    code: 'D808',
  },
  {
    name: 'Frosinone',
    code: 'D810',
  },
  {
    name: 'Frosolone',
    code: 'D811',
  },
  {
    name: 'Frossasco',
    code: 'D812',
  },
  {
    name: 'Frugarolo',
    code: 'D813',
  },
  {
    name: 'Fubine Monferrato',
    code: 'D814',
  },
  {
    name: 'Fucecchio',
    code: 'D815',
  },
  {
    name: 'Fuipiano Valle Imagna',
    code: 'D817',
  },
  {
    name: 'Fumane',
    code: 'D818',
  },
  {
    name: 'Fumone',
    code: 'D819',
  },
  {
    name: 'Funes',
    code: 'D821',
  },
  {
    name: 'Furci',
    code: 'D823',
  },
  {
    name: 'Furci Siculo',
    code: 'D824',
  },
  {
    name: 'Furnari',
    code: 'D825',
  },
  {
    name: 'Furore',
    code: 'D826',
  },
  {
    name: 'Furtei',
    code: 'D827',
  },
  {
    name: 'Fuscaldo',
    code: 'D828',
  },
  {
    name: 'Fusignano',
    code: 'D829',
  },
  {
    name: 'Fusine',
    code: 'D830',
  },
  {
    name: 'Futani',
    code: 'D832',
  },
  {
    name: 'Gabbioneta-Binanuova',
    code: 'D834',
  },
  {
    name: 'Gabiano',
    code: 'D835',
  },
  {
    name: 'Gabicce Mare',
    code: 'D836',
  },
  {
    name: 'Gaby',
    code: 'D839',
  },
  {
    name: 'Gadesco-Pieve Delmona',
    code: 'D841',
  },
  {
    name: 'Gadoni',
    code: 'D842',
  },
  {
    name: 'Gaeta',
    code: 'D843',
  },
  {
    name: 'Gaggi',
    code: 'D844',
  },
  {
    name: 'Gaggiano',
    code: 'D845',
  },
  {
    name: 'Gaggio Montano',
    code: 'D847',
  },
  {
    name: 'Gaglianico',
    code: 'D848',
  },
  {
    name: 'Gagliano Aterno',
    code: 'D850',
  },
  {
    name: 'Gagliano Castelferrato',
    code: 'D849',
  },
  {
    name: 'Gagliano del Capo',
    code: 'D851',
  },
  {
    name: 'Gagliato',
    code: 'D852',
  },
  {
    name: 'Gagliole',
    code: 'D853',
  },
  {
    name: 'Gaiarine',
    code: 'D854',
  },
  {
    name: 'Gaiba',
    code: 'D855',
  },
  {
    name: 'Gaiola',
    code: 'D856',
  },
  {
    name: 'Gaiole in Chianti',
    code: 'D858',
  },
  {
    name: 'Gairo',
    code: 'D859',
  },
  {
    name: 'Gais',
    code: 'D860',
  },
  {
    name: 'Galati Mamertino',
    code: 'D861',
  },
  {
    name: 'Galatina',
    code: 'D862',
  },
  {
    name: 'Galatone',
    code: 'D863',
  },
  {
    name: 'Galatro',
    code: 'D864',
  },
  {
    name: 'Galbiate',
    code: 'D865',
  },
  {
    name: 'Galeata',
    code: 'D867',
  },
  {
    name: 'Galgagnano',
    code: 'D868',
  },
  {
    name: 'Gallarate',
    code: 'D869',
  },
  {
    name: 'Gallese',
    code: 'D870',
  },
  {
    name: 'Galliate',
    code: 'D872',
  },
  {
    name: 'Galliate Lombardo',
    code: 'D871',
  },
  {
    name: 'Galliavola',
    code: 'D873',
  },
  {
    name: 'Gallicano',
    code: 'D874',
  },
  {
    name: 'Gallicano nel Lazio',
    code: 'D875',
  },
  {
    name: 'Gallicchio',
    code: 'D876',
  },
  {
    name: 'Galliera',
    code: 'D878',
  },
  {
    name: 'Galliera Veneta',
    code: 'D879',
  },
  {
    name: 'Gallinaro',
    code: 'D881',
  },
  {
    name: 'Gallio',
    code: 'D882',
  },
  {
    name: 'Gallipoli',
    code: 'D883',
  },
  {
    name: 'Gallo Matese',
    code: 'D884',
  },
  {
    name: 'Gallodoro',
    code: 'D885',
  },
  {
    name: 'Galluccio',
    code: 'D886',
  },
  {
    name: 'Galtellì',
    code: 'D888',
  },
  {
    name: 'Galzignano Terme',
    code: 'D889',
  },
  {
    name: 'Gamalero',
    code: 'D890',
  },
  {
    name: 'Gambara',
    code: 'D891',
  },
  {
    name: 'Gambarana',
    code: 'D892',
  },
  {
    name: 'Gambasca',
    code: 'D894',
  },
  {
    name: 'Gambassi Terme',
    code: 'D895',
  },
  {
    name: 'Gambatesa',
    code: 'D896',
  },
  {
    name: 'Gambellara',
    code: 'D897',
  },
  {
    name: 'Gamberale',
    code: 'D898',
  },
  {
    name: 'Gambettola',
    code: 'D899',
  },
  {
    name: 'Gambolò',
    code: 'D901',
  },
  {
    name: 'Gambugliano',
    code: 'D902',
  },
  {
    name: 'Gandellino',
    code: 'D903',
  },
  {
    name: 'Gandino',
    code: 'D905',
  },
  {
    name: 'Gandosso',
    code: 'D906',
  },
  {
    name: 'Gangi',
    code: 'D907',
  },
  {
    name: 'Garaguso',
    code: 'D909',
  },
  {
    name: 'Garbagna',
    code: 'D910',
  },
  {
    name: 'Garbagna Novarese',
    code: 'D911',
  },
  {
    name: 'Garbagnate Milanese',
    code: 'D912',
  },
  {
    name: 'Garbagnate Monastero',
    code: 'D913',
  },
  {
    name: 'Garda',
    code: 'D915',
  },
  {
    name: 'Gardone Riviera',
    code: 'D917',
  },
  {
    name: 'Gardone Val Trompia',
    code: 'D918',
  },
  {
    name: 'Garessio',
    code: 'D920',
  },
  {
    name: 'Gargallo',
    code: 'D921',
  },
  {
    name: 'Gargazzone',
    code: 'D923',
  },
  {
    name: 'Gargnano',
    code: 'D924',
  },
  {
    name: 'Garlasco',
    code: 'D925',
  },
  {
    name: 'Garlate',
    code: 'D926',
  },
  {
    name: 'Garlenda',
    code: 'D927',
  },
  {
    name: 'Garniga Terme',
    code: 'D928',
  },
  {
    name: 'Garzeno',
    code: 'D930',
  },
  {
    name: 'Garzigliana',
    code: 'D931',
  },
  {
    name: 'Gasperina',
    code: 'D932',
  },
  {
    name: 'Gassino Torinese',
    code: 'D933',
  },
  {
    name: 'Gattatico',
    code: 'D934',
  },
  {
    name: 'Gatteo',
    code: 'D935',
  },
  {
    name: 'Gattico-Veruno',
    code: 'M416',
  },
  {
    name: 'Gattinara',
    code: 'D938',
  },
  {
    name: 'Gavardo',
    code: 'D940',
  },
  {
    name: 'Gavello',
    code: 'D942',
  },
  {
    name: 'Gaverina Terme',
    code: 'D943',
  },
  {
    name: 'Gavi',
    code: 'D944',
  },
  {
    name: 'Gavignano',
    code: 'D945',
  },
  {
    name: 'Gavirate',
    code: 'D946',
  },
  {
    name: 'Gavoi',
    code: 'D947',
  },
  {
    name: 'Gavorrano',
    code: 'D948',
  },
  {
    name: 'Gazoldo degli Ippoliti',
    code: 'D949',
  },
  {
    name: 'Gazzada Schianno',
    code: 'D951',
  },
  {
    name: 'Gazzaniga',
    code: 'D952',
  },
  {
    name: 'Gazzo',
    code: 'D956',
  },
  {
    name: 'Gazzo Veronese',
    code: 'D957',
  },
  {
    name: 'Gazzola',
    code: 'D958',
  },
  {
    name: 'Gazzuolo',
    code: 'D959',
  },
  {
    name: 'Gela',
    code: 'D960',
  },
  {
    name: 'Gemmano',
    code: 'D961',
  },
  {
    name: 'Gemona del Friuli',
    code: 'D962',
  },
  {
    name: 'Gemonio',
    code: 'D963',
  },
  {
    name: 'Genazzano',
    code: 'D964',
  },
  {
    name: 'Genga',
    code: 'D965',
  },
  {
    name: 'Genivolta',
    code: 'D966',
  },
  {
    name: 'Genola',
    code: 'D967',
  },
  {
    name: 'Genoni',
    code: 'D968',
  },
  {
    name: 'Genova',
    code: 'D969',
  },
  {
    name: 'Genuri',
    code: 'D970',
  },
  {
    name: 'Genzano di Lucania',
    code: 'D971',
  },
  {
    name: 'Genzano di Roma',
    code: 'D972',
  },
  {
    name: 'Gera Lario',
    code: 'D974',
  },
  {
    name: 'Gerace',
    code: 'D975',
  },
  {
    name: 'Geraci Siculo',
    code: 'D977',
  },
  {
    name: 'Gerano',
    code: 'D978',
  },
  {
    name: 'Gerenzago',
    code: 'D980',
  },
  {
    name: 'Gerenzano',
    code: 'D981',
  },
  {
    name: 'Gergei',
    code: 'D982',
  },
  {
    name: 'Germagnano',
    code: 'D983',
  },
  {
    name: 'Germagno',
    code: 'D984',
  },
  {
    name: 'Germignaga',
    code: 'D987',
  },
  {
    name: 'Gerocarne',
    code: 'D988',
  },
  {
    name: 'Gerola Alta',
    code: 'D990',
  },
  {
    name: "Gerre de' Caprioli",
    code: 'D993',
  },
  {
    name: 'Gesico',
    code: 'D994',
  },
  {
    name: 'Gessate',
    code: 'D995',
  },
  {
    name: 'Gessopalena',
    code: 'D996',
  },
  {
    name: 'Gesturi',
    code: 'D997',
  },
  {
    name: 'Gesualdo',
    code: 'D998',
  },
  {
    name: 'Ghedi',
    code: 'D999',
  },
  {
    name: 'Ghemme',
    code: 'E001',
  },
  {
    name: 'Ghiffa',
    code: 'E003',
  },
  {
    name: 'Ghilarza',
    code: 'E004',
  },
  {
    name: 'Ghisalba',
    code: 'E006',
  },
  {
    name: 'Ghislarengo',
    code: 'E007',
  },
  {
    name: 'Giacciano con Baruchella',
    code: 'E008',
  },
  {
    name: 'Giaglione',
    code: 'E009',
  },
  {
    name: 'Gianico',
    code: 'E010',
  },
  {
    name: "Giano dell'Umbria",
    code: 'E012',
  },
  {
    name: 'Giano Vetusto',
    code: 'E011',
  },
  {
    name: 'Giardinello',
    code: 'E013',
  },
  {
    name: 'Giardini-Naxos',
    code: 'E014',
  },
  {
    name: 'Giarole',
    code: 'E015',
  },
  {
    name: 'Giarratana',
    code: 'E016',
  },
  {
    name: 'Giarre',
    code: 'E017',
  },
  {
    name: 'Giave',
    code: 'E019',
  },
  {
    name: 'Giaveno',
    code: 'E020',
  },
  {
    name: 'Giavera del Montello',
    code: 'E021',
  },
  {
    name: 'Giba',
    code: 'E022',
  },
  {
    name: 'Gibellina',
    code: 'E023',
  },
  {
    name: 'Gifflenga',
    code: 'E024',
  },
  {
    name: 'Giffone',
    code: 'E025',
  },
  {
    name: 'Giffoni Sei Casali',
    code: 'E026',
  },
  {
    name: 'Giffoni Valle Piana',
    code: 'E027',
  },
  {
    name: 'Gignese',
    code: 'E028',
  },
  {
    name: 'Gignod',
    code: 'E029',
  },
  {
    name: 'Gildone',
    code: 'E030',
  },
  {
    name: 'Gimigliano',
    code: 'E031',
  },
  {
    name: 'Ginestra',
    code: 'E033',
  },
  {
    name: 'Ginestra degli Schiavoni',
    code: 'E034',
  },
  {
    name: 'Ginosa',
    code: 'E036',
  },
  {
    name: 'Gioi',
    code: 'E037',
  },
  {
    name: 'Gioia dei Marsi',
    code: 'E040',
  },
  {
    name: 'Gioia del Colle',
    code: 'E038',
  },
  {
    name: 'Gioia Sannitica',
    code: 'E039',
  },
  {
    name: 'Gioia Tauro',
    code: 'E041',
  },
  {
    name: 'Gioiosa Ionica',
    code: 'E044',
  },
  {
    name: 'Gioiosa Marea',
    code: 'E043',
  },
  {
    name: 'Giove',
    code: 'E045',
  },
  {
    name: 'Giovinazzo',
    code: 'E047',
  },
  {
    name: 'Giovo',
    code: 'E048',
  },
  {
    name: 'Girasole',
    code: 'E049',
  },
  {
    name: 'Girifalco',
    code: 'E050',
  },
  {
    name: 'Gissi',
    code: 'E052',
  },
  {
    name: 'Giuggianello',
    code: 'E053',
  },
  {
    name: 'Giugliano in Campania',
    code: 'E054',
  },
  {
    name: 'Giuliana',
    code: 'E055',
  },
  {
    name: 'Giuliano di Roma',
    code: 'E057',
  },
  {
    name: 'Giuliano Teatino',
    code: 'E056',
  },
  {
    name: 'Giulianova',
    code: 'E058',
  },
  {
    name: 'Giungano',
    code: 'E060',
  },
  {
    name: 'Giurdignano',
    code: 'E061',
  },
  {
    name: 'Giussago',
    code: 'E062',
  },
  {
    name: 'Giussano',
    code: 'E063',
  },
  {
    name: 'Giustenice',
    code: 'E064',
  },
  {
    name: 'Giustino',
    code: 'E065',
  },
  {
    name: 'Giusvalla',
    code: 'E066',
  },
  {
    name: 'Givoletto',
    code: 'E067',
  },
  {
    name: 'Gizzeria',
    code: 'E068',
  },
  {
    name: 'Glorenza',
    code: 'E069',
  },
  {
    name: "Godega di Sant'Urbano",
    code: 'E071',
  },
  {
    name: 'Godiasco Salice Terme',
    code: 'E072',
  },
  {
    name: 'Godrano',
    code: 'E074',
  },
  {
    name: 'Goito',
    code: 'E078',
  },
  {
    name: 'Golasecca',
    code: 'E079',
  },
  {
    name: 'Golferenzo',
    code: 'E081',
  },
  {
    name: 'Golfo Aranci',
    code: 'M274',
  },
  {
    name: 'Gombito',
    code: 'E082',
  },
  {
    name: 'Gonars',
    code: 'E083',
  },
  {
    name: 'Goni',
    code: 'E084',
  },
  {
    name: 'Gonnesa',
    code: 'E086',
  },
  {
    name: 'Gonnoscodina',
    code: 'E087',
  },
  {
    name: 'Gonnosfanadiga',
    code: 'E085',
  },
  {
    name: 'Gonnosnò',
    code: 'D585',
  },
  {
    name: 'Gonnostramatza',
    code: 'E088',
  },
  {
    name: 'Gonzaga',
    code: 'E089',
  },
  {
    name: 'Gordona',
    code: 'E090',
  },
  {
    name: 'Gorga',
    code: 'E091',
  },
  {
    name: 'Gorgo al Monticano',
    code: 'E092',
  },
  {
    name: 'Gorgoglione',
    code: 'E093',
  },
  {
    name: 'Gorgonzola',
    code: 'E094',
  },
  {
    name: 'Goriano Sicoli',
    code: 'E096',
  },
  {
    name: 'Gorizia',
    code: 'E098',
  },
  {
    name: 'Gorla Maggiore',
    code: 'E101',
  },
  {
    name: 'Gorla Minore',
    code: 'E102',
  },
  {
    name: 'Gorlago',
    code: 'E100',
  },
  {
    name: 'Gorle',
    code: 'E103',
  },
  {
    name: 'Gornate Olona',
    code: 'E104',
  },
  {
    name: 'Gorno',
    code: 'E106',
  },
  {
    name: 'Goro',
    code: 'E107',
  },
  {
    name: 'Gorreto',
    code: 'E109',
  },
  {
    name: 'Gorzegno',
    code: 'E111',
  },
  {
    name: 'Gosaldo',
    code: 'E113',
  },
  {
    name: 'Gossolengo',
    code: 'E114',
  },
  {
    name: 'Gottasecca',
    code: 'E115',
  },
  {
    name: 'Gottolengo',
    code: 'E116',
  },
  {
    name: 'Govone',
    code: 'E118',
  },
  {
    name: 'Gozzano',
    code: 'E120',
  },
  {
    name: 'Gradara',
    code: 'E122',
  },
  {
    name: "Gradisca d'Isonzo",
    code: 'E124',
  },
  {
    name: 'Grado',
    code: 'E125',
  },
  {
    name: 'Gradoli',
    code: 'E126',
  },
  {
    name: 'Graffignana',
    code: 'E127',
  },
  {
    name: 'Graffignano',
    code: 'E128',
  },
  {
    name: 'Graglia',
    code: 'E130',
  },
  {
    name: 'Gragnano',
    code: 'E131',
  },
  {
    name: 'Gragnano Trebbiense',
    code: 'E132',
  },
  {
    name: 'Grammichele',
    code: 'E133',
  },
  {
    name: 'Grana',
    code: 'E134',
  },
  {
    name: "Granarolo dell'Emilia",
    code: 'E136',
  },
  {
    name: 'Grandate',
    code: 'E139',
  },
  {
    name: 'Grandola ed Uniti',
    code: 'E141',
  },
  {
    name: 'Graniti',
    code: 'E142',
  },
  {
    name: 'Granozzo con Monticello',
    code: 'E143',
  },
  {
    name: 'Grantola',
    code: 'E144',
  },
  {
    name: 'Grantorto',
    code: 'E145',
  },
  {
    name: 'Granze',
    code: 'E146',
  },
  {
    name: 'Grassano',
    code: 'E147',
  },
  {
    name: 'Grassobbio',
    code: 'E148',
  },
  {
    name: 'Gratteri',
    code: 'E149',
  },
  {
    name: 'Gravedona ed Uniti',
    code: 'M315',
  },
  {
    name: 'Gravellona Lomellina',
    code: 'E152',
  },
  {
    name: 'Gravellona Toce',
    code: 'E153',
  },
  {
    name: 'Gravere',
    code: 'E154',
  },
  {
    name: 'Gravina di Catania',
    code: 'E156',
  },
  {
    name: 'Gravina in Puglia',
    code: 'E155',
  },
  {
    name: 'Grazzanise',
    code: 'E158',
  },
  {
    name: 'Grazzano Badoglio',
    code: 'E159',
  },
  {
    name: 'Greccio',
    code: 'E160',
  },
  {
    name: 'Greci',
    code: 'E161',
  },
  {
    name: 'Greggio',
    code: 'E163',
  },
  {
    name: 'Gremiasco',
    code: 'E164',
  },
  {
    name: 'Gressan',
    code: 'E165',
  },
  {
    name: 'Gressoney-La-Trinité',
    code: 'E167',
  },
  {
    name: 'Gressoney-Saint-Jean',
    code: 'E168',
  },
  {
    name: 'Greve in Chianti',
    code: 'E169',
  },
  {
    name: 'Grezzago',
    code: 'E170',
  },
  {
    name: 'Grezzana',
    code: 'E171',
  },
  {
    name: 'Griante',
    code: 'E172',
  },
  {
    name: 'Gricignano di Aversa',
    code: 'E173',
  },
  {
    name: 'Grignasco',
    code: 'E177',
  },
  {
    name: 'Grigno',
    code: 'E178',
  },
  {
    name: 'Grimacco',
    code: 'E179',
  },
  {
    name: 'Grimaldi',
    code: 'E180',
  },
  {
    name: 'Grinzane Cavour',
    code: 'E182',
  },
  {
    name: 'Grisignano di Zocco',
    code: 'E184',
  },
  {
    name: 'Grisolia',
    code: 'E185',
  },
  {
    name: 'Grizzana Morandi',
    code: 'E187',
  },
  {
    name: 'Grognardo',
    code: 'E188',
  },
  {
    name: 'Gromo',
    code: 'E189',
  },
  {
    name: 'Grondona',
    code: 'E191',
  },
  {
    name: 'Grone',
    code: 'E192',
  },
  {
    name: 'Grontardo',
    code: 'E193',
  },
  {
    name: 'Gropello Cairoli',
    code: 'E195',
  },
  {
    name: 'Gropparello',
    code: 'E196',
  },
  {
    name: 'Groscavallo',
    code: 'E199',
  },
  {
    name: 'Grosio',
    code: 'E200',
  },
  {
    name: 'Grosotto',
    code: 'E201',
  },
  {
    name: 'Grosseto',
    code: 'E202',
  },
  {
    name: 'Grosso',
    code: 'E203',
  },
  {
    name: 'Grottaferrata',
    code: 'E204',
  },
  {
    name: 'Grottaglie',
    code: 'E205',
  },
  {
    name: 'Grottaminarda',
    code: 'E206',
  },
  {
    name: 'Grottammare',
    code: 'E207',
  },
  {
    name: 'Grottazzolina',
    code: 'E208',
  },
  {
    name: 'Grotte',
    code: 'E209',
  },
  {
    name: 'Grotte di Castro',
    code: 'E210',
  },
  {
    name: 'Grotteria',
    code: 'E212',
  },
  {
    name: 'Grottole',
    code: 'E213',
  },
  {
    name: 'Grottolella',
    code: 'E214',
  },
  {
    name: 'Gruaro',
    code: 'E215',
  },
  {
    name: 'Grugliasco',
    code: 'E216',
  },
  {
    name: 'Grumello Cremonese ed Uniti',
    code: 'E217',
  },
  {
    name: 'Grumello del Monte',
    code: 'E219',
  },
  {
    name: 'Grumento Nova',
    code: 'E221',
  },
  {
    name: 'Grumo Appula',
    code: 'E223',
  },
  {
    name: 'Grumo Nevano',
    code: 'E224',
  },
  {
    name: 'Grumolo delle Abbadesse',
    code: 'E226',
  },
  {
    name: 'Guagnano',
    code: 'E227',
  },
  {
    name: 'Gualdo',
    code: 'E228',
  },
  {
    name: 'Gualdo Cattaneo',
    code: 'E229',
  },
  {
    name: 'Gualdo Tadino',
    code: 'E230',
  },
  {
    name: 'Gualtieri',
    code: 'E232',
  },
  {
    name: 'Gualtieri Sicaminò',
    code: 'E233',
  },
  {
    name: 'Guamaggiore',
    code: 'E234',
  },
  {
    name: 'Guanzate',
    code: 'E235',
  },
  {
    name: 'Guarcino',
    code: 'E236',
  },
  {
    name: 'Guarda Veneta',
    code: 'E240',
  },
  {
    name: 'Guardabosone',
    code: 'E237',
  },
  {
    name: 'Guardamiglio',
    code: 'E238',
  },
  {
    name: 'Guardavalle',
    code: 'E239',
  },
  {
    name: 'Guardea',
    code: 'E241',
  },
  {
    name: 'Guardia Lombardi',
    code: 'E245',
  },
  {
    name: 'Guardia Perticara',
    code: 'E246',
  },
  {
    name: 'Guardia Piemontese',
    code: 'E242',
  },
  {
    name: 'Guardia Sanframondi',
    code: 'E249',
  },
  {
    name: 'Guardiagrele',
    code: 'E243',
  },
  {
    name: 'Guardialfiera',
    code: 'E244',
  },
  {
    name: 'Guardiaregia',
    code: 'E248',
  },
  {
    name: 'Guardistallo',
    code: 'E250',
  },
  {
    name: 'Guarene',
    code: 'E251',
  },
  {
    name: 'Guasila',
    code: 'E252',
  },
  {
    name: 'Guastalla',
    code: 'E253',
  },
  {
    name: 'Guazzora',
    code: 'E255',
  },
  {
    name: 'Gubbio',
    code: 'E256',
  },
  {
    name: 'Gudo Visconti',
    code: 'E258',
  },
  {
    name: 'Guglionesi',
    code: 'E259',
  },
  {
    name: 'Guidizzolo',
    code: 'E261',
  },
  {
    name: 'Guidonia Montecelio',
    code: 'E263',
  },
  {
    name: 'Guiglia',
    code: 'E264',
  },
  {
    name: 'Guilmi',
    code: 'E266',
  },
  {
    name: 'Gurro',
    code: 'E269',
  },
  {
    name: 'Guspini',
    code: 'E270',
  },
  {
    name: 'Gussago',
    code: 'E271',
  },
  {
    name: 'Gussola',
    code: 'E272',
  },
  {
    name: 'Hône',
    code: 'E273',
  },
  {
    name: 'Idro',
    code: 'E280',
  },
  {
    name: 'Iglesias',
    code: 'E281',
  },
  {
    name: 'Igliano',
    code: 'E282',
  },
  {
    name: 'Ilbono',
    code: 'E283',
  },
  {
    name: 'Illasi',
    code: 'E284',
  },
  {
    name: 'Illorai',
    code: 'E285',
  },
  {
    name: 'Imbersago',
    code: 'E287',
  },
  {
    name: 'Imer',
    code: 'E288',
  },
  {
    name: 'Imola',
    code: 'E289',
  },
  {
    name: 'Imperia',
    code: 'E290',
  },
  {
    name: 'Impruneta',
    code: 'E291',
  },
  {
    name: 'Inarzo',
    code: 'E292',
  },
  {
    name: 'Incisa Scapaccino',
    code: 'E295',
  },
  {
    name: 'Incudine',
    code: 'E297',
  },
  {
    name: 'Induno Olona',
    code: 'E299',
  },
  {
    name: 'Ingria',
    code: 'E301',
  },
  {
    name: 'Intragna',
    code: 'E304',
  },
  {
    name: 'Introbio',
    code: 'E305',
  },
  {
    name: 'Introd',
    code: 'E306',
  },
  {
    name: 'Introdacqua',
    code: 'E307',
  },
  {
    name: 'Inverigo',
    code: 'E309',
  },
  {
    name: 'Inverno e Monteleone',
    code: 'E310',
  },
  {
    name: 'Inverso Pinasca',
    code: 'E311',
  },
  {
    name: 'Inveruno',
    code: 'E313',
  },
  {
    name: 'Invorio',
    code: 'E314',
  },
  {
    name: 'Inzago',
    code: 'E317',
  },
  {
    name: 'Ionadi',
    code: 'E321',
  },
  {
    name: 'Irgoli',
    code: 'E323',
  },
  {
    name: 'Irma',
    code: 'E325',
  },
  {
    name: 'Irsina',
    code: 'E326',
  },
  {
    name: 'Isasca',
    code: 'E327',
  },
  {
    name: 'Isca sullo Ionio',
    code: 'E328',
  },
  {
    name: 'Ischia',
    code: 'E329',
  },
  {
    name: 'Ischia di Castro',
    code: 'E330',
  },
  {
    name: 'Ischitella',
    code: 'E332',
  },
  {
    name: 'Iseo',
    code: 'E333',
  },
  {
    name: 'Isera',
    code: 'E334',
  },
  {
    name: 'Isernia',
    code: 'E335',
  },
  {
    name: 'Isili',
    code: 'E336',
  },
  {
    name: 'Isnello',
    code: 'E337',
  },
  {
    name: "Isola d'Asti",
    code: 'E338',
  },
  {
    name: 'Isola del Cantone',
    code: 'E341',
  },
  {
    name: 'Isola del Giglio',
    code: 'E348',
  },
  {
    name: "Isola del Gran Sasso d'Italia",
    code: 'E343',
  },
  {
    name: 'Isola del Liri',
    code: 'E340',
  },
  {
    name: 'Isola del Piano',
    code: 'E351',
  },
  {
    name: 'Isola della Scala',
    code: 'E349',
  },
  {
    name: 'Isola delle Femmine',
    code: 'E350',
  },
  {
    name: 'Isola di Capo Rizzuto',
    code: 'E339',
  },
  {
    name: 'Isola di Fondra',
    code: 'E353',
  },
  {
    name: 'Isola Dovarese',
    code: 'E356',
  },
  {
    name: 'Isola Rizza',
    code: 'E358',
  },
  {
    name: "Isola Sant'Antonio",
    code: 'E360',
  },
  {
    name: 'Isola Vicentina',
    code: 'E354',
  },
  {
    name: 'Isolabella',
    code: 'E345',
  },
  {
    name: 'Isolabona',
    code: 'E346',
  },
  {
    name: 'Isole Tremiti',
    code: 'E363',
  },
  {
    name: 'Isorella',
    code: 'E364',
  },
  {
    name: 'Ispani',
    code: 'E365',
  },
  {
    name: 'Ispica',
    code: 'E366',
  },
  {
    name: 'Ispra',
    code: 'E367',
  },
  {
    name: 'Issiglio',
    code: 'E368',
  },
  {
    name: 'Issime',
    code: 'E369',
  },
  {
    name: 'Isso',
    code: 'E370',
  },
  {
    name: 'Issogne',
    code: 'E371',
  },
  {
    name: 'Istrana',
    code: 'E373',
  },
  {
    name: 'Itala',
    code: 'E374',
  },
  {
    name: 'Itri',
    code: 'E375',
  },
  {
    name: 'Ittireddu',
    code: 'E376',
  },
  {
    name: 'Ittiri',
    code: 'E377',
  },
  {
    name: 'Ivrea',
    code: 'E379',
  },
  {
    name: 'Izano',
    code: 'E380',
  },
  {
    name: 'Jacurso',
    code: 'E274',
  },
  {
    name: 'Jelsi',
    code: 'E381',
  },
  {
    name: 'Jenne',
    code: 'E382',
  },
  {
    name: 'Jerago con Orago',
    code: 'E386',
  },
  {
    name: 'Jerzu',
    code: 'E387',
  },
  {
    name: 'Jesi',
    code: 'E388',
  },
  {
    name: 'Jesolo',
    code: 'C388',
  },
  {
    name: 'Jolanda di Savoia',
    code: 'E320',
  },
  {
    name: 'Joppolo',
    code: 'E389',
  },
  {
    name: 'Joppolo Giancaxio',
    code: 'E390',
  },
  {
    name: 'Jovençan',
    code: 'E391',
  },
  {
    name: "L'Aquila",
    code: 'A345',
  },
  {
    name: 'La Cassa',
    code: 'E394',
  },
  {
    name: 'La Loggia',
    code: 'E423',
  },
  {
    name: 'La Maddalena',
    code: 'E425',
  },
  {
    name: 'La Magdeleine',
    code: 'A308',
  },
  {
    name: 'La Morra',
    code: 'E430',
  },
  {
    name: 'La Salle',
    code: 'E458',
  },
  {
    name: 'La Spezia',
    code: 'E463',
  },
  {
    name: 'La Thuile',
    code: 'E470',
  },
  {
    name: 'La Valle',
    code: 'E491',
  },
  {
    name: 'La Valle Agordina',
    code: 'E490',
  },
  {
    name: 'La Valletta Brianza',
    code: 'M348',
  },
  {
    name: 'Labico',
    code: 'E392',
  },
  {
    name: 'Labro',
    code: 'E393',
  },
  {
    name: 'Lacchiarella',
    code: 'E395',
  },
  {
    name: 'Lacco Ameno',
    code: 'E396',
  },
  {
    name: 'Lacedonia',
    code: 'E397',
  },
  {
    name: 'Laces',
    code: 'E398',
  },
  {
    name: 'Laconi',
    code: 'E400',
  },
  {
    name: 'Ladispoli',
    code: 'M212',
  },
  {
    name: 'Laerru',
    code: 'E401',
  },
  {
    name: 'Laganadi',
    code: 'E402',
  },
  {
    name: 'Laghi',
    code: 'E403',
  },
  {
    name: 'Laglio',
    code: 'E405',
  },
  {
    name: 'Lagnasco',
    code: 'E406',
  },
  {
    name: 'Lago',
    code: 'E407',
  },
  {
    name: 'Lagonegro',
    code: 'E409',
  },
  {
    name: 'Lagosanto',
    code: 'E410',
  },
  {
    name: 'Lagundo',
    code: 'E412',
  },
  {
    name: 'Laigueglia',
    code: 'E414',
  },
  {
    name: 'Lainate',
    code: 'E415',
  },
  {
    name: 'Laino',
    code: 'E416',
  },
  {
    name: 'Laino Borgo',
    code: 'E417',
  },
  {
    name: 'Laino Castello',
    code: 'E419',
  },
  {
    name: 'Laion',
    code: 'E420',
  },
  {
    name: 'Laives',
    code: 'E421',
  },
  {
    name: 'Lajatico',
    code: 'E413',
  },
  {
    name: 'Lallio',
    code: 'E422',
  },
  {
    name: 'Lama dei Peligni',
    code: 'E424',
  },
  {
    name: 'Lama Mocogno',
    code: 'E426',
  },
  {
    name: 'Lambrugo',
    code: 'E428',
  },
  {
    name: 'Lamezia Terme',
    code: 'M208',
  },
  {
    name: 'Lamon',
    code: 'E429',
  },
  {
    name: 'Lampedusa e Linosa',
    code: 'E431',
  },
  {
    name: 'Lamporecchio',
    code: 'E432',
  },
  {
    name: 'Lamporo',
    code: 'E433',
  },
  {
    name: 'Lana',
    code: 'E434',
  },
  {
    name: 'Lanciano',
    code: 'E435',
  },
  {
    name: 'Landiona',
    code: 'E436',
  },
  {
    name: 'Landriano',
    code: 'E437',
  },
  {
    name: 'Langhirano',
    code: 'E438',
  },
  {
    name: 'Langosco',
    code: 'E439',
  },
  {
    name: 'Lanusei',
    code: 'E441',
  },
  {
    name: 'Lanuvio',
    code: 'C767',
  },
  {
    name: 'Lanzada',
    code: 'E443',
  },
  {
    name: 'Lanzo Torinese',
    code: 'E445',
  },
  {
    name: 'Lapedona',
    code: 'E447',
  },
  {
    name: 'Lapio',
    code: 'E448',
  },
  {
    name: 'Lappano',
    code: 'E450',
  },
  {
    name: 'Larciano',
    code: 'E451',
  },
  {
    name: 'Lardirago',
    code: 'E454',
  },
  {
    name: 'Lariano',
    code: 'M207',
  },
  {
    name: 'Larino',
    code: 'E456',
  },
  {
    name: 'Las Plassas',
    code: 'E464',
  },
  {
    name: 'Lasa',
    code: 'E457',
  },
  {
    name: 'Lascari',
    code: 'E459',
  },
  {
    name: 'Lasnigo',
    code: 'E462',
  },
  {
    name: 'Lastebasse',
    code: 'E465',
  },
  {
    name: 'Lastra a Signa',
    code: 'E466',
  },
  {
    name: 'Latera',
    code: 'E467',
  },
  {
    name: 'Laterina Pergine Valdarno',
    code: 'M392',
  },
  {
    name: 'Laterza',
    code: 'E469',
  },
  {
    name: 'Latiano',
    code: 'E471',
  },
  {
    name: 'Latina',
    code: 'E472',
  },
  {
    name: 'Latisana',
    code: 'E473',
  },
  {
    name: 'Latronico',
    code: 'E474',
  },
  {
    name: 'Lattarico',
    code: 'E475',
  },
  {
    name: 'Lauco',
    code: 'E476',
  },
  {
    name: 'Laureana Cilento',
    code: 'E480',
  },
  {
    name: 'Laureana di Borrello',
    code: 'E479',
  },
  {
    name: 'Lauregno',
    code: 'E481',
  },
  {
    name: 'Laurenzana',
    code: 'E482',
  },
  {
    name: 'Lauria',
    code: 'E483',
  },
  {
    name: 'Lauriano',
    code: 'E484',
  },
  {
    name: 'Laurino',
    code: 'E485',
  },
  {
    name: 'Laurito',
    code: 'E486',
  },
  {
    name: 'Lauro',
    code: 'E487',
  },
  {
    name: 'Lavagna',
    code: 'E488',
  },
  {
    name: 'Lavagno',
    code: 'E489',
  },
  {
    name: 'Lavarone',
    code: 'E492',
  },
  {
    name: 'Lavello',
    code: 'E493',
  },
  {
    name: 'Lavena Ponte Tresa',
    code: 'E494',
  },
  {
    name: 'Laveno-Mombello',
    code: 'E496',
  },
  {
    name: 'Lavenone',
    code: 'E497',
  },
  {
    name: 'Laviano',
    code: 'E498',
  },
  {
    name: 'Lavis',
    code: 'E500',
  },
  {
    name: 'Lazise',
    code: 'E502',
  },
  {
    name: 'Lazzate',
    code: 'E504',
  },
  {
    name: 'Lecce',
    code: 'E506',
  },
  {
    name: 'Lecce nei Marsi',
    code: 'E505',
  },
  {
    name: 'Lecco',
    code: 'E507',
  },
  {
    name: 'Ledro',
    code: 'M313',
  },
  {
    name: 'Leffe',
    code: 'E509',
  },
  {
    name: 'Leggiuno',
    code: 'E510',
  },
  {
    name: 'Legnago',
    code: 'E512',
  },
  {
    name: 'Legnano',
    code: 'E514',
  },
  {
    name: 'Legnaro',
    code: 'E515',
  },
  {
    name: 'Lei',
    code: 'E517',
  },
  {
    name: 'Leini',
    code: 'E518',
  },
  {
    name: 'Leivi',
    code: 'E519',
  },
  {
    name: 'Lemie',
    code: 'E520',
  },
  {
    name: 'Lendinara',
    code: 'E522',
  },
  {
    name: 'Leni',
    code: 'E523',
  },
  {
    name: 'Lenna',
    code: 'E524',
  },
  {
    name: 'Leno',
    code: 'E526',
  },
  {
    name: 'Lenola',
    code: 'E527',
  },
  {
    name: 'Lenta',
    code: 'E528',
  },
  {
    name: 'Lentate sul Seveso',
    code: 'E530',
  },
  {
    name: 'Lentella',
    code: 'E531',
  },
  {
    name: 'Lentini',
    code: 'E532',
  },
  {
    name: 'Leonessa',
    code: 'E535',
  },
  {
    name: 'Leonforte',
    code: 'E536',
  },
  {
    name: 'Leporano',
    code: 'E537',
  },
  {
    name: 'Lequile',
    code: 'E538',
  },
  {
    name: 'Lequio Berria',
    code: 'E540',
  },
  {
    name: 'Lequio Tanaro',
    code: 'E539',
  },
  {
    name: 'Lercara Friddi',
    code: 'E541',
  },
  {
    name: 'Lerici',
    code: 'E542',
  },
  {
    name: 'Lerma',
    code: 'E543',
  },
  {
    name: 'Lesa',
    code: 'E544',
  },
  {
    name: 'Lesegno',
    code: 'E546',
  },
  {
    name: "Lesignano de' Bagni",
    code: 'E547',
  },
  {
    name: 'Lesina',
    code: 'E549',
  },
  {
    name: 'Lesmo',
    code: 'E550',
  },
  {
    name: 'Lessolo',
    code: 'E551',
  },
  {
    name: 'Lessona',
    code: 'M371',
  },
  {
    name: 'Lestizza',
    code: 'E553',
  },
  {
    name: 'Letino',
    code: 'E554',
  },
  {
    name: 'Letojanni',
    code: 'E555',
  },
  {
    name: 'Lettere',
    code: 'E557',
  },
  {
    name: 'Lettomanoppello',
    code: 'E558',
  },
  {
    name: 'Lettopalena',
    code: 'E559',
  },
  {
    name: 'Levanto',
    code: 'E560',
  },
  {
    name: 'Levate',
    code: 'E562',
  },
  {
    name: 'Leverano',
    code: 'E563',
  },
  {
    name: 'Levice',
    code: 'E564',
  },
  {
    name: 'Levico Terme',
    code: 'E565',
  },
  {
    name: 'Levone',
    code: 'E566',
  },
  {
    name: 'Lezzeno',
    code: 'E569',
  },
  {
    name: 'Liberi',
    code: 'E570',
  },
  {
    name: 'Librizzi',
    code: 'E571',
  },
  {
    name: 'Licata',
    code: 'E573',
  },
  {
    name: 'Licciana Nardi',
    code: 'E574',
  },
  {
    name: 'Licenza',
    code: 'E576',
  },
  {
    name: 'Licodia Eubea',
    code: 'E578',
  },
  {
    name: 'Lierna',
    code: 'E581',
  },
  {
    name: 'Lignana',
    code: 'E583',
  },
  {
    name: 'Lignano Sabbiadoro',
    code: 'E584',
  },
  {
    name: 'Lillianes',
    code: 'E587',
  },
  {
    name: 'Limana',
    code: 'E588',
  },
  {
    name: 'Limatola',
    code: 'E589',
  },
  {
    name: 'Limbadi',
    code: 'E590',
  },
  {
    name: 'Limbiate',
    code: 'E591',
  },
  {
    name: 'Limena',
    code: 'E592',
  },
  {
    name: 'Limido Comasco',
    code: 'E593',
  },
  {
    name: 'Limina',
    code: 'E594',
  },
  {
    name: 'Limone Piemonte',
    code: 'E597',
  },
  {
    name: 'Limone sul Garda',
    code: 'E596',
  },
  {
    name: 'Limosano',
    code: 'E599',
  },
  {
    name: 'Linarolo',
    code: 'E600',
  },
  {
    name: 'Linguaglossa',
    code: 'E602',
  },
  {
    name: 'Lioni',
    code: 'E605',
  },
  {
    name: 'Lipari',
    code: 'E606',
  },
  {
    name: 'Lipomo',
    code: 'E607',
  },
  {
    name: 'Lirio',
    code: 'E608',
  },
  {
    name: 'Liscate',
    code: 'E610',
  },
  {
    name: 'Liscia',
    code: 'E611',
  },
  {
    name: 'Lisciano Niccone',
    code: 'E613',
  },
  {
    name: 'Lisio',
    code: 'E615',
  },
  {
    name: 'Lissone',
    code: 'E617',
  },
  {
    name: 'Liveri',
    code: 'E620',
  },
  {
    name: 'Livigno',
    code: 'E621',
  },
  {
    name: 'Livinallongo del Col di Lana',
    code: 'E622',
  },
  {
    name: 'Livo',
    code: 'E623',
  },
  {
    name: 'Livo',
    code: 'E624',
  },
  {
    name: 'Livorno',
    code: 'E625',
  },
  {
    name: 'Livorno Ferraris',
    code: 'E626',
  },
  {
    name: 'Livraga',
    code: 'E627',
  },
  {
    name: 'Lizzanello',
    code: 'E629',
  },
  {
    name: 'Lizzano',
    code: 'E630',
  },
  {
    name: 'Lizzano in Belvedere',
    code: 'A771',
  },
  {
    name: 'Loano',
    code: 'E632',
  },
  {
    name: 'Loazzolo',
    code: 'E633',
  },
  {
    name: 'Locana',
    code: 'E635',
  },
  {
    name: 'Locate di Triulzi',
    code: 'E639',
  },
  {
    name: 'Locate Varesino',
    code: 'E638',
  },
  {
    name: 'Locatello',
    code: 'E640',
  },
  {
    name: 'Loceri',
    code: 'E644',
  },
  {
    name: 'Locorotondo',
    code: 'E645',
  },
  {
    name: 'Locri',
    code: 'D976',
  },
  {
    name: 'Loculi',
    code: 'E646',
  },
  {
    name: 'Lodè',
    code: 'E647',
  },
  {
    name: 'Lodi',
    code: 'E648',
  },
  {
    name: 'Lodi Vecchio',
    code: 'E651',
  },
  {
    name: 'Lodine',
    code: 'E649',
  },
  {
    name: 'Lodrino',
    code: 'E652',
  },
  {
    name: 'Lograto',
    code: 'E654',
  },
  {
    name: 'Loiano',
    code: 'E655',
  },
  {
    name: 'Loiri Porto San Paolo',
    code: 'M275',
  },
  {
    name: 'Lomagna',
    code: 'E656',
  },
  {
    name: 'Lomazzo',
    code: 'E659',
  },
  {
    name: 'Lombardore',
    code: 'E660',
  },
  {
    name: 'Lombriasco',
    code: 'E661',
  },
  {
    name: 'Lomello',
    code: 'E662',
  },
  {
    name: 'Lona-Lases',
    code: 'E664',
  },
  {
    name: 'Lonate Ceppino',
    code: 'E665',
  },
  {
    name: 'Lonate Pozzolo',
    code: 'E666',
  },
  {
    name: 'Lonato del Garda',
    code: 'M312',
  },
  {
    name: 'Londa',
    code: 'E668',
  },
  {
    name: 'Longano',
    code: 'E669',
  },
  {
    name: 'Longare',
    code: 'E671',
  },
  {
    name: 'Longarone',
    code: 'M342',
  },
  {
    name: 'Longhena',
    code: 'E673',
  },
  {
    name: 'Longi',
    code: 'E674',
  },
  {
    name: 'Longiano',
    code: 'E675',
  },
  {
    name: 'Longobardi',
    code: 'E677',
  },
  {
    name: 'Longobucco',
    code: 'E678',
  },
  {
    name: 'Longone al Segrino',
    code: 'E679',
  },
  {
    name: 'Longone Sabino',
    code: 'E681',
  },
  {
    name: 'Lonigo',
    code: 'E682',
  },
  {
    name: 'Loranzè',
    code: 'E683',
  },
  {
    name: 'Loreggia',
    code: 'E684',
  },
  {
    name: 'Loreglia',
    code: 'E685',
  },
  {
    name: 'Lorenzago di Cadore',
    code: 'E687',
  },
  {
    name: 'Loreo',
    code: 'E689',
  },
  {
    name: 'Loreto',
    code: 'E690',
  },
  {
    name: 'Loreto Aprutino',
    code: 'E691',
  },
  {
    name: 'Loria',
    code: 'E692',
  },
  {
    name: 'Loro Ciuffenna',
    code: 'E693',
  },
  {
    name: 'Loro Piceno',
    code: 'E694',
  },
  {
    name: 'Lorsica',
    code: 'E695',
  },
  {
    name: 'Losine',
    code: 'E698',
  },
  {
    name: 'Lotzorai',
    code: 'E700',
  },
  {
    name: 'Lovere',
    code: 'E704',
  },
  {
    name: 'Lovero',
    code: 'E705',
  },
  {
    name: 'Lozio',
    code: 'E706',
  },
  {
    name: 'Lozza',
    code: 'E707',
  },
  {
    name: 'Lozzo Atestino',
    code: 'E709',
  },
  {
    name: 'Lozzo di Cadore',
    code: 'E708',
  },
  {
    name: 'Lozzolo',
    code: 'E711',
  },
  {
    name: 'Lu e Cuccaro Monferrato',
    code: 'M420',
  },
  {
    name: 'Lubriano',
    code: 'E713',
  },
  {
    name: 'Lucca',
    code: 'E715',
  },
  {
    name: 'Lucca Sicula',
    code: 'E714',
  },
  {
    name: 'Lucera',
    code: 'E716',
  },
  {
    name: 'Lucignano',
    code: 'E718',
  },
  {
    name: 'Lucinasco',
    code: 'E719',
  },
  {
    name: 'Lucito',
    code: 'E722',
  },
  {
    name: 'Luco dei Marsi',
    code: 'E723',
  },
  {
    name: 'Lucoli',
    code: 'E724',
  },
  {
    name: "Lugagnano Val d'Arda",
    code: 'E726',
  },
  {
    name: 'Lugnano in Teverina',
    code: 'E729',
  },
  {
    name: 'Lugo',
    code: 'E730',
  },
  {
    name: 'Lugo di Vicenza',
    code: 'E731',
  },
  {
    name: 'Luino',
    code: 'E734',
  },
  {
    name: 'Luisago',
    code: 'E735',
  },
  {
    name: 'Lula',
    code: 'E736',
  },
  {
    name: 'Lumarzo',
    code: 'E737',
  },
  {
    name: 'Lumezzane',
    code: 'E738',
  },
  {
    name: 'Lunamatrona',
    code: 'E742',
  },
  {
    name: 'Lunano',
    code: 'E743',
  },
  {
    name: 'Lungavilla',
    code: 'B387',
  },
  {
    name: 'Lungro',
    code: 'E745',
  },
  {
    name: 'Luni',
    code: 'G143',
  },
  {
    name: 'Luogosano',
    code: 'E746',
  },
  {
    name: 'Luogosanto',
    code: 'E747',
  },
  {
    name: 'Lupara',
    code: 'E748',
  },
  {
    name: "Lurago d'Erba",
    code: 'E749',
  },
  {
    name: 'Lurago Marinone',
    code: 'E750',
  },
  {
    name: 'Lurano',
    code: 'E751',
  },
  {
    name: 'Luras',
    code: 'E752',
  },
  {
    name: 'Lurate Caccivio',
    code: 'E753',
  },
  {
    name: 'Lusciano',
    code: 'E754',
  },
  {
    name: 'Luserna',
    code: 'E757',
  },
  {
    name: 'Luserna San Giovanni',
    code: 'E758',
  },
  {
    name: 'Lusernetta',
    code: 'E759',
  },
  {
    name: 'Lusevera',
    code: 'E760',
  },
  {
    name: 'Lusia',
    code: 'E761',
  },
  {
    name: 'Lusiana Conco',
    code: 'M427',
  },
  {
    name: 'Lusigliè',
    code: 'E763',
  },
  {
    name: 'Luson',
    code: 'E764',
  },
  {
    name: 'Lustra',
    code: 'E767',
  },
  {
    name: 'Luvinate',
    code: 'E769',
  },
  {
    name: 'Luzzana',
    code: 'E770',
  },
  {
    name: 'Luzzara',
    code: 'E772',
  },
  {
    name: 'Luzzi',
    code: 'E773',
  },
  {
    name: 'Maccagno con Pino e Veddasca',
    code: 'M339',
  },
  {
    name: 'Maccastorna',
    code: 'E777',
  },
  {
    name: "Macchia d'Isernia",
    code: 'E778',
  },
  {
    name: 'Macchia Valfortore',
    code: 'E780',
  },
  {
    name: 'Macchiagodena',
    code: 'E779',
  },
  {
    name: 'Macello',
    code: 'E782',
  },
  {
    name: 'Macerata',
    code: 'E783',
  },
  {
    name: 'Macerata Campania',
    code: 'E784',
  },
  {
    name: 'Macerata Feltria',
    code: 'E785',
  },
  {
    name: 'Macherio',
    code: 'E786',
  },
  {
    name: 'Maclodio',
    code: 'E787',
  },
  {
    name: 'Macomer',
    code: 'E788',
  },
  {
    name: 'Macra',
    code: 'E789',
  },
  {
    name: 'Macugnaga',
    code: 'E790',
  },
  {
    name: 'Maddaloni',
    code: 'E791',
  },
  {
    name: 'Madesimo',
    code: 'E342',
  },
  {
    name: 'Madignano',
    code: 'E793',
  },
  {
    name: 'Madone',
    code: 'E794',
  },
  {
    name: 'Madonna del Sasso',
    code: 'E795',
  },
  {
    name: 'Madruzzo',
    code: 'M357',
  },
  {
    name: 'Maenza',
    code: 'E798',
  },
  {
    name: 'Mafalda',
    code: 'E799',
  },
  {
    name: 'Magasa',
    code: 'E800',
  },
  {
    name: 'Magenta',
    code: 'E801',
  },
  {
    name: 'Maggiora',
    code: 'E803',
  },
  {
    name: 'Magherno',
    code: 'E804',
  },
  {
    name: 'Magione',
    code: 'E805',
  },
  {
    name: 'Magisano',
    code: 'E806',
  },
  {
    name: 'Magliano Alfieri',
    code: 'E809',
  },
  {
    name: 'Magliano Alpi',
    code: 'E808',
  },
  {
    name: "Magliano de' Marsi",
    code: 'E811',
  },
  {
    name: 'Magliano di Tenna',
    code: 'E807',
  },
  {
    name: 'Magliano in Toscana',
    code: 'E810',
  },
  {
    name: 'Magliano Romano',
    code: 'E813',
  },
  {
    name: 'Magliano Sabina',
    code: 'E812',
  },
  {
    name: 'Magliano Vetere',
    code: 'E814',
  },
  {
    name: 'Maglie',
    code: 'E815',
  },
  {
    name: 'Magliolo',
    code: 'E816',
  },
  {
    name: 'Maglione',
    code: 'E817',
  },
  {
    name: 'Magnacavallo',
    code: 'E818',
  },
  {
    name: 'Magnago',
    code: 'E819',
  },
  {
    name: 'Magnano',
    code: 'E821',
  },
  {
    name: 'Magnano in Riviera',
    code: 'E820',
  },
  {
    name: 'Magomadas',
    code: 'E825',
  },
  {
    name: 'Magrè sulla strada del vino',
    code: 'E829',
  },
  {
    name: 'Magreglio',
    code: 'E830',
  },
  {
    name: 'Maida',
    code: 'E834',
  },
  {
    name: 'Maierà',
    code: 'E835',
  },
  {
    name: 'Maierato',
    code: 'E836',
  },
  {
    name: 'Maiolati Spontini',
    code: 'E837',
  },
  {
    name: 'Maiolo',
    code: 'E838',
  },
  {
    name: 'Maiori',
    code: 'E839',
  },
  {
    name: 'Mairago',
    code: 'E840',
  },
  {
    name: 'Mairano',
    code: 'E841',
  },
  {
    name: 'Maissana',
    code: 'E842',
  },
  {
    name: 'Majano',
    code: 'E833',
  },
  {
    name: 'Malagnino',
    code: 'E843',
  },
  {
    name: 'Malalbergo',
    code: 'E844',
  },
  {
    name: 'Malborghetto Valbruna',
    code: 'E847',
  },
  {
    name: 'Malcesine',
    code: 'E848',
  },
  {
    name: 'Malé',
    code: 'E850',
  },
  {
    name: 'Malegno',
    code: 'E851',
  },
  {
    name: 'Maleo',
    code: 'E852',
  },
  {
    name: 'Malesco',
    code: 'E853',
  },
  {
    name: 'Maletto',
    code: 'E854',
  },
  {
    name: 'Malfa',
    code: 'E855',
  },
  {
    name: 'Malgesso',
    code: 'E856',
  },
  {
    name: 'Malgrate',
    code: 'E858',
  },
  {
    name: 'Malito',
    code: 'E859',
  },
  {
    name: 'Mallare',
    code: 'E860',
  },
  {
    name: 'Malles Venosta',
    code: 'E862',
  },
  {
    name: 'Malnate',
    code: 'E863',
  },
  {
    name: 'Malo',
    code: 'E864',
  },
  {
    name: 'Malonno',
    code: 'E865',
  },
  {
    name: 'Maltignano',
    code: 'E868',
  },
  {
    name: 'Malvagna',
    code: 'E869',
  },
  {
    name: 'Malvicino',
    code: 'E870',
  },
  {
    name: 'Malvito',
    code: 'E872',
  },
  {
    name: 'Mammola',
    code: 'E873',
  },
  {
    name: 'Mamoiada',
    code: 'E874',
  },
  {
    name: 'Manciano',
    code: 'E875',
  },
  {
    name: 'Mandanici',
    code: 'E876',
  },
  {
    name: 'Mandas',
    code: 'E877',
  },
  {
    name: 'Mandatoriccio',
    code: 'E878',
  },
  {
    name: 'Mandela',
    code: 'B632',
  },
  {
    name: 'Mandello del Lario',
    code: 'E879',
  },
  {
    name: 'Mandello Vitta',
    code: 'E880',
  },
  {
    name: 'Manduria',
    code: 'E882',
  },
  {
    name: 'Manerba del Garda',
    code: 'E883',
  },
  {
    name: 'Manerbio',
    code: 'E884',
  },
  {
    name: 'Manfredonia',
    code: 'E885',
  },
  {
    name: 'Mango',
    code: 'E887',
  },
  {
    name: 'Mangone',
    code: 'E888',
  },
  {
    name: 'Maniace',
    code: 'M283',
  },
  {
    name: 'Maniago',
    code: 'E889',
  },
  {
    name: 'Manocalzati',
    code: 'E891',
  },
  {
    name: 'Manoppello',
    code: 'E892',
  },
  {
    name: 'Mansuè',
    code: 'E893',
  },
  {
    name: 'Manta',
    code: 'E894',
  },
  {
    name: 'Mantello',
    code: 'E896',
  },
  {
    name: 'Mantova',
    code: 'E897',
  },
  {
    name: 'Manzano',
    code: 'E899',
  },
  {
    name: 'Manziana',
    code: 'E900',
  },
  {
    name: 'Mapello',
    code: 'E901',
  },
  {
    name: 'Mappano',
    code: 'M316',
  },
  {
    name: 'Mara',
    code: 'E902',
  },
  {
    name: 'Maracalagonis',
    code: 'E903',
  },
  {
    name: 'Maranello',
    code: 'E904',
  },
  {
    name: 'Marano di Napoli',
    code: 'E906',
  },
  {
    name: 'Marano di Valpolicella',
    code: 'E911',
  },
  {
    name: 'Marano Equo',
    code: 'E908',
  },
  {
    name: 'Marano Lagunare',
    code: 'E910',
  },
  {
    name: 'Marano Marchesato',
    code: 'E914',
  },
  {
    name: 'Marano Principato',
    code: 'E915',
  },
  {
    name: 'Marano sul Panaro',
    code: 'E905',
  },
  {
    name: 'Marano Ticino',
    code: 'E907',
  },
  {
    name: 'Marano Vicentino',
    code: 'E912',
  },
  {
    name: 'Maranzana',
    code: 'E917',
  },
  {
    name: 'Maratea',
    code: 'E919',
  },
  {
    name: 'Marcallo con Casone',
    code: 'E921',
  },
  {
    name: 'Marcaria',
    code: 'E922',
  },
  {
    name: 'Marcedusa',
    code: 'E923',
  },
  {
    name: 'Marcellina',
    code: 'E924',
  },
  {
    name: 'Marcellinara',
    code: 'E925',
  },
  {
    name: 'Marcetelli',
    code: 'E927',
  },
  {
    name: 'Marcheno',
    code: 'E928',
  },
  {
    name: 'Marchirolo',
    code: 'E929',
  },
  {
    name: 'Marciana',
    code: 'E930',
  },
  {
    name: 'Marciana Marina',
    code: 'E931',
  },
  {
    name: 'Marcianise',
    code: 'E932',
  },
  {
    name: 'Marciano della Chiana',
    code: 'E933',
  },
  {
    name: 'Marcignago',
    code: 'E934',
  },
  {
    name: 'Marcon',
    code: 'E936',
  },
  {
    name: 'Marebbe',
    code: 'E938',
  },
  {
    name: 'Marene',
    code: 'E939',
  },
  {
    name: 'Mareno di Piave',
    code: 'E940',
  },
  {
    name: 'Marentino',
    code: 'E941',
  },
  {
    name: 'Maretto',
    code: 'E944',
  },
  {
    name: 'Margarita',
    code: 'E945',
  },
  {
    name: 'Margherita di Savoia',
    code: 'E946',
  },
  {
    name: 'Margno',
    code: 'E947',
  },
  {
    name: 'Mariana Mantovana',
    code: 'E949',
  },
  {
    name: 'Mariano Comense',
    code: 'E951',
  },
  {
    name: 'Mariano del Friuli',
    code: 'E952',
  },
  {
    name: 'Marianopoli',
    code: 'E953',
  },
  {
    name: 'Mariglianella',
    code: 'E954',
  },
  {
    name: 'Marigliano',
    code: 'E955',
  },
  {
    name: 'Marina di Gioiosa Ionica',
    code: 'E956',
  },
  {
    name: 'Marineo',
    code: 'E957',
  },
  {
    name: 'Marino',
    code: 'E958',
  },
  {
    name: 'Marlengo',
    code: 'E959',
  },
  {
    name: 'Marliana',
    code: 'E960',
  },
  {
    name: 'Marmentino',
    code: 'E961',
  },
  {
    name: 'Marmirolo',
    code: 'E962',
  },
  {
    name: 'Marmora',
    code: 'E963',
  },
  {
    name: 'Marnate',
    code: 'E965',
  },
  {
    name: 'Marone',
    code: 'E967',
  },
  {
    name: 'Maropati',
    code: 'E968',
  },
  {
    name: 'Marostica',
    code: 'E970',
  },
  {
    name: 'Marradi',
    code: 'E971',
  },
  {
    name: 'Marrubiu',
    code: 'E972',
  },
  {
    name: 'Marsaglia',
    code: 'E973',
  },
  {
    name: 'Marsala',
    code: 'E974',
  },
  {
    name: 'Marsciano',
    code: 'E975',
  },
  {
    name: 'Marsico Nuovo',
    code: 'E976',
  },
  {
    name: 'Marsicovetere',
    code: 'E977',
  },
  {
    name: 'Marta',
    code: 'E978',
  },
  {
    name: 'Martano',
    code: 'E979',
  },
  {
    name: 'Martellago',
    code: 'E980',
  },
  {
    name: 'Martello',
    code: 'E981',
  },
  {
    name: 'Martignacco',
    code: 'E982',
  },
  {
    name: 'Martignana di Po',
    code: 'E983',
  },
  {
    name: 'Martignano',
    code: 'E984',
  },
  {
    name: 'Martina Franca',
    code: 'E986',
  },
  {
    name: 'Martinengo',
    code: 'E987',
  },
  {
    name: 'Martiniana Po',
    code: 'E988',
  },
  {
    name: 'Martinsicuro',
    code: 'E989',
  },
  {
    name: 'Martirano',
    code: 'E990',
  },
  {
    name: 'Martirano Lombardo',
    code: 'E991',
  },
  {
    name: 'Martis',
    code: 'E992',
  },
  {
    name: 'Martone',
    code: 'E993',
  },
  {
    name: 'Marudo',
    code: 'E994',
  },
  {
    name: 'Maruggio',
    code: 'E995',
  },
  {
    name: 'Marzabotto',
    code: 'B689',
  },
  {
    name: 'Marzano',
    code: 'E999',
  },
  {
    name: 'Marzano Appio',
    code: 'E998',
  },
  {
    name: 'Marzano di Nola',
    code: 'E997',
  },
  {
    name: 'Marzi',
    code: 'F001',
  },
  {
    name: 'Marzio',
    code: 'F002',
  },
  {
    name: 'Masainas',
    code: 'M270',
  },
  {
    name: 'Masate',
    code: 'F003',
  },
  {
    name: 'Mascali',
    code: 'F004',
  },
  {
    name: 'Mascalucia',
    code: 'F005',
  },
  {
    name: 'Maschito',
    code: 'F006',
  },
  {
    name: 'Masciago Primo',
    code: 'F007',
  },
  {
    name: 'Maser',
    code: 'F009',
  },
  {
    name: 'Masera',
    code: 'F010',
  },
  {
    name: 'Maserà di Padova',
    code: 'F011',
  },
  {
    name: 'Maserada sul Piave',
    code: 'F012',
  },
  {
    name: 'Masi',
    code: 'F013',
  },
  {
    name: 'Masi Torello',
    code: 'F016',
  },
  {
    name: 'Masio',
    code: 'F015',
  },
  {
    name: 'Maslianico',
    code: 'F017',
  },
  {
    name: 'Masone',
    code: 'F020',
  },
  {
    name: 'Massa',
    code: 'F023',
  },
  {
    name: "Massa d'Albe",
    code: 'F022',
  },
  {
    name: 'Massa di Somma',
    code: 'M289',
  },
  {
    name: 'Massa e Cozzile',
    code: 'F025',
  },
  {
    name: 'Massa Fermana',
    code: 'F021',
  },
  {
    name: 'Massa Lombarda',
    code: 'F029',
  },
  {
    name: 'Massa Lubrense',
    code: 'F030',
  },
  {
    name: 'Massa Marittima',
    code: 'F032',
  },
  {
    name: 'Massa Martana',
    code: 'F024',
  },
  {
    name: 'Massafra',
    code: 'F027',
  },
  {
    name: 'Massalengo',
    code: 'F028',
  },
  {
    name: 'Massanzago',
    code: 'F033',
  },
  {
    name: 'Massarosa',
    code: 'F035',
  },
  {
    name: 'Massazza',
    code: 'F037',
  },
  {
    name: 'Massello',
    code: 'F041',
  },
  {
    name: 'Masserano',
    code: 'F042',
  },
  {
    name: 'Massignano',
    code: 'F044',
  },
  {
    name: 'Massimeno',
    code: 'F045',
  },
  {
    name: 'Massimino',
    code: 'F046',
  },
  {
    name: 'Massino Visconti',
    code: 'F047',
  },
  {
    name: 'Massiola',
    code: 'F048',
  },
  {
    name: 'Masullas',
    code: 'F050',
  },
  {
    name: 'Matelica',
    code: 'F051',
  },
  {
    name: 'Matera',
    code: 'F052',
  },
  {
    name: 'Mathi',
    code: 'F053',
  },
  {
    name: 'Matino',
    code: 'F054',
  },
  {
    name: 'Matrice',
    code: 'F055',
  },
  {
    name: 'Mattie',
    code: 'F058',
  },
  {
    name: 'Mattinata',
    code: 'F059',
  },
  {
    name: 'Mazara del Vallo',
    code: 'F061',
  },
  {
    name: 'Mazzano',
    code: 'F063',
  },
  {
    name: 'Mazzano Romano',
    code: 'F064',
  },
  {
    name: 'Mazzarino',
    code: 'F065',
  },
  {
    name: "Mazzarrà Sant'Andrea",
    code: 'F066',
  },
  {
    name: 'Mazzarrone',
    code: 'M271',
  },
  {
    name: 'Mazzè',
    code: 'F067',
  },
  {
    name: 'Mazzin',
    code: 'F068',
  },
  {
    name: 'Mazzo di Valtellina',
    code: 'F070',
  },
  {
    name: 'Meana di Susa',
    code: 'F074',
  },
  {
    name: 'Meana Sardo',
    code: 'F073',
  },
  {
    name: 'Meda',
    code: 'F078',
  },
  {
    name: 'Mede',
    code: 'F080',
  },
  {
    name: 'Medea',
    code: 'F081',
  },
  {
    name: 'Medesano',
    code: 'F082',
  },
  {
    name: 'Medicina',
    code: 'F083',
  },
  {
    name: 'Mediglia',
    code: 'F084',
  },
  {
    name: 'Medolago',
    code: 'F085',
  },
  {
    name: 'Medole',
    code: 'F086',
  },
  {
    name: 'Medolla',
    code: 'F087',
  },
  {
    name: 'Meduna di Livenza',
    code: 'F088',
  },
  {
    name: 'Meduno',
    code: 'F089',
  },
  {
    name: 'Megliadino San Vitale',
    code: 'F092',
  },
  {
    name: 'Meina',
    code: 'F093',
  },
  {
    name: 'Melara',
    code: 'F095',
  },
  {
    name: 'Melazzo',
    code: 'F096',
  },
  {
    name: 'Meldola',
    code: 'F097',
  },
  {
    name: 'Mele',
    code: 'F098',
  },
  {
    name: 'Melegnano',
    code: 'F100',
  },
  {
    name: 'Melendugno',
    code: 'F101',
  },
  {
    name: 'Meleti',
    code: 'F102',
  },
  {
    name: 'Melfi',
    code: 'F104',
  },
  {
    name: 'Melicuccà',
    code: 'F105',
  },
  {
    name: 'Melicucco',
    code: 'F106',
  },
  {
    name: 'Melilli',
    code: 'F107',
  },
  {
    name: 'Melissa',
    code: 'F108',
  },
  {
    name: 'Melissano',
    code: 'F109',
  },
  {
    name: 'Melito di Napoli',
    code: 'F111',
  },
  {
    name: 'Melito di Porto Salvo',
    code: 'F112',
  },
  {
    name: 'Melito Irpino',
    code: 'F110',
  },
  {
    name: 'Melizzano',
    code: 'F113',
  },
  {
    name: 'Melle',
    code: 'F114',
  },
  {
    name: 'Mello',
    code: 'F115',
  },
  {
    name: 'Melpignano',
    code: 'F117',
  },
  {
    name: 'Meltina',
    code: 'F118',
  },
  {
    name: 'Melzo',
    code: 'F119',
  },
  {
    name: 'Menaggio',
    code: 'F120',
  },
  {
    name: 'Menconico',
    code: 'F122',
  },
  {
    name: 'Mendatica',
    code: 'F123',
  },
  {
    name: 'Mendicino',
    code: 'F125',
  },
  {
    name: 'Menfi',
    code: 'F126',
  },
  {
    name: 'Mentana',
    code: 'F127',
  },
  {
    name: 'Meolo',
    code: 'F130',
  },
  {
    name: 'Merana',
    code: 'F131',
  },
  {
    name: 'Merano',
    code: 'F132',
  },
  {
    name: 'Merate',
    code: 'F133',
  },
  {
    name: 'Mercallo',
    code: 'F134',
  },
  {
    name: 'Mercatello sul Metauro',
    code: 'F135',
  },
  {
    name: 'Mercatino Conca',
    code: 'F136',
  },
  {
    name: 'Mercato San Severino',
    code: 'F138',
  },
  {
    name: 'Mercato Saraceno',
    code: 'F139',
  },
  {
    name: 'Mercenasco',
    code: 'F140',
  },
  {
    name: 'Mercogliano',
    code: 'F141',
  },
  {
    name: 'Mereto di Tomba',
    code: 'F144',
  },
  {
    name: 'Mergo',
    code: 'F145',
  },
  {
    name: 'Mergozzo',
    code: 'F146',
  },
  {
    name: 'Merì',
    code: 'F147',
  },
  {
    name: 'Merlara',
    code: 'F148',
  },
  {
    name: 'Merlino',
    code: 'F149',
  },
  {
    name: 'Merone',
    code: 'F151',
  },
  {
    name: 'Mesagne',
    code: 'F152',
  },
  {
    name: 'Mese',
    code: 'F153',
  },
  {
    name: 'Mesenzana',
    code: 'F154',
  },
  {
    name: 'Mesero',
    code: 'F155',
  },
  {
    name: 'Mesola',
    code: 'F156',
  },
  {
    name: 'Mesoraca',
    code: 'F157',
  },
  {
    name: 'Messina',
    code: 'F158',
  },
  {
    name: 'Mestrino',
    code: 'F161',
  },
  {
    name: 'Meta',
    code: 'F162',
  },
  {
    name: 'Mezzago',
    code: 'F165',
  },
  {
    name: 'Mezzana',
    code: 'F168',
  },
  {
    name: 'Mezzana Bigli',
    code: 'F170',
  },
  {
    name: 'Mezzana Mortigliengo',
    code: 'F167',
  },
  {
    name: 'Mezzana Rabattone',
    code: 'F171',
  },
  {
    name: 'Mezzane di Sotto',
    code: 'F172',
  },
  {
    name: 'Mezzanego',
    code: 'F173',
  },
  {
    name: 'Mezzanino',
    code: 'F175',
  },
  {
    name: 'Mezzano',
    code: 'F176',
  },
  {
    name: 'Mezzenile',
    code: 'F182',
  },
  {
    name: 'Mezzocorona',
    code: 'F183',
  },
  {
    name: 'Mezzojuso',
    code: 'F184',
  },
  {
    name: 'Mezzoldo',
    code: 'F186',
  },
  {
    name: 'Mezzolombardo',
    code: 'F187',
  },
  {
    name: 'Mezzomerico',
    code: 'F188',
  },
  {
    name: 'Miagliano',
    code: 'F189',
  },
  {
    name: 'Miane',
    code: 'F190',
  },
  {
    name: 'Miasino',
    code: 'F191',
  },
  {
    name: 'Miazzina',
    code: 'F192',
  },
  {
    name: 'Micigliano',
    code: 'F193',
  },
  {
    name: 'Miggiano',
    code: 'F194',
  },
  {
    name: 'Miglianico',
    code: 'F196',
  },
  {
    name: 'Miglierina',
    code: 'F200',
  },
  {
    name: 'Miglionico',
    code: 'F201',
  },
  {
    name: 'Mignanego',
    code: 'F202',
  },
  {
    name: 'Mignano Monte Lungo',
    code: 'F203',
  },
  {
    name: 'Milano',
    code: 'F205',
  },
  {
    name: 'Milazzo',
    code: 'F206',
  },
  {
    name: 'Milena',
    code: 'E618',
  },
  {
    name: 'Mileto',
    code: 'F207',
  },
  {
    name: 'Milis',
    code: 'F208',
  },
  {
    name: 'Militello in Val di Catania',
    code: 'F209',
  },
  {
    name: 'Militello Rosmarino',
    code: 'F210',
  },
  {
    name: 'Millesimo',
    code: 'F213',
  },
  {
    name: 'Milo',
    code: 'F214',
  },
  {
    name: 'Milzano',
    code: 'F216',
  },
  {
    name: 'Mineo',
    code: 'F217',
  },
  {
    name: 'Minerbe',
    code: 'F218',
  },
  {
    name: 'Minerbio',
    code: 'F219',
  },
  {
    name: 'Minervino di Lecce',
    code: 'F221',
  },
  {
    name: 'Minervino Murge',
    code: 'F220',
  },
  {
    name: 'Minori',
    code: 'F223',
  },
  {
    name: 'Minturno',
    code: 'F224',
  },
  {
    name: 'Minucciano',
    code: 'F225',
  },
  {
    name: 'Mioglia',
    code: 'F226',
  },
  {
    name: 'Mira',
    code: 'F229',
  },
  {
    name: 'Mirabella Eclano',
    code: 'F230',
  },
  {
    name: 'Mirabella Imbaccari',
    code: 'F231',
  },
  {
    name: 'Mirabello Monferrato',
    code: 'F232',
  },
  {
    name: 'Mirabello Sannitico',
    code: 'F233',
  },
  {
    name: 'Miradolo Terme',
    code: 'F238',
  },
  {
    name: 'Miranda',
    code: 'F239',
  },
  {
    name: 'Mirandola',
    code: 'F240',
  },
  {
    name: 'Mirano',
    code: 'F241',
  },
  {
    name: 'Mirto',
    code: 'F242',
  },
  {
    name: 'Misano Adriatico',
    code: 'F244',
  },
  {
    name: "Misano di Gera d'Adda",
    code: 'F243',
  },
  {
    name: 'Misilmeri',
    code: 'F246',
  },
  {
    name: 'Misinto',
    code: 'F247',
  },
  {
    name: 'Missaglia',
    code: 'F248',
  },
  {
    name: 'Missanello',
    code: 'F249',
  },
  {
    name: 'Misterbianco',
    code: 'F250',
  },
  {
    name: 'Mistretta',
    code: 'F251',
  },
  {
    name: 'Moasca',
    code: 'F254',
  },
  {
    name: 'Moconesi',
    code: 'F256',
  },
  {
    name: 'Modena',
    code: 'F257',
  },
  {
    name: 'Modica',
    code: 'F258',
  },
  {
    name: 'Modigliana',
    code: 'F259',
  },
  {
    name: 'Modolo',
    code: 'F261',
  },
  {
    name: 'Modugno',
    code: 'F262',
  },
  {
    name: 'Moena',
    code: 'F263',
  },
  {
    name: 'Moggio',
    code: 'F265',
  },
  {
    name: 'Moggio Udinese',
    code: 'F266',
  },
  {
    name: 'Moglia',
    code: 'F267',
  },
  {
    name: 'Mogliano',
    code: 'F268',
  },
  {
    name: 'Mogliano Veneto',
    code: 'F269',
  },
  {
    name: 'Mogorella',
    code: 'F270',
  },
  {
    name: 'Mogoro',
    code: 'F272',
  },
  {
    name: 'Moiano',
    code: 'F274',
  },
  {
    name: 'Moimacco',
    code: 'F275',
  },
  {
    name: 'Moio Alcantara',
    code: 'F277',
  },
  {
    name: "Moio de' Calvi",
    code: 'F276',
  },
  {
    name: 'Moio della Civitella',
    code: 'F278',
  },
  {
    name: 'Moiola',
    code: 'F279',
  },
  {
    name: 'Mola di Bari',
    code: 'F280',
  },
  {
    name: 'Molare',
    code: 'F281',
  },
  {
    name: 'Molazzana',
    code: 'F283',
  },
  {
    name: 'Molfetta',
    code: 'F284',
  },
  {
    name: 'Molina Aterno',
    code: 'M255',
  },
  {
    name: 'Molinara',
    code: 'F287',
  },
  {
    name: 'Molinella',
    code: 'F288',
  },
  {
    name: 'Molini di Triora',
    code: 'F290',
  },
  {
    name: 'Molino dei Torti',
    code: 'F293',
  },
  {
    name: 'Molise',
    code: 'F294',
  },
  {
    name: 'Moliterno',
    code: 'F295',
  },
  {
    name: 'Mollia',
    code: 'F297',
  },
  {
    name: 'Molochio',
    code: 'F301',
  },
  {
    name: 'Molteno',
    code: 'F304',
  },
  {
    name: 'Moltrasio',
    code: 'F305',
  },
  {
    name: 'Molveno',
    code: 'F307',
  },
  {
    name: 'Mombaldone',
    code: 'F308',
  },
  {
    name: 'Mombarcaro',
    code: 'F309',
  },
  {
    name: 'Mombaroccio',
    code: 'F310',
  },
  {
    name: 'Mombaruzzo',
    code: 'F311',
  },
  {
    name: 'Mombasiglio',
    code: 'F312',
  },
  {
    name: 'Mombello di Torino',
    code: 'F315',
  },
  {
    name: 'Mombello Monferrato',
    code: 'F313',
  },
  {
    name: 'Mombercelli',
    code: 'F316',
  },
  {
    name: 'Momo',
    code: 'F317',
  },
  {
    name: 'Mompantero',
    code: 'F318',
  },
  {
    name: 'Mompeo',
    code: 'F319',
  },
  {
    name: 'Momperone',
    code: 'F320',
  },
  {
    name: 'Monacilioni',
    code: 'F322',
  },
  {
    name: 'Monale',
    code: 'F323',
  },
  {
    name: 'Monasterace',
    code: 'F324',
  },
  {
    name: 'Monastero Bormida',
    code: 'F325',
  },
  {
    name: 'Monastero di Lanzo',
    code: 'F327',
  },
  {
    name: 'Monastero di Vasco',
    code: 'F326',
  },
  {
    name: 'Monasterolo Casotto',
    code: 'F329',
  },
  {
    name: 'Monasterolo del Castello',
    code: 'F328',
  },
  {
    name: 'Monasterolo di Savigliano',
    code: 'F330',
  },
  {
    name: 'Monastier di Treviso',
    code: 'F332',
  },
  {
    name: 'Monastir',
    code: 'F333',
  },
  {
    name: 'Moncalieri',
    code: 'F335',
  },
  {
    name: 'Moncalvo',
    code: 'F336',
  },
  {
    name: 'Moncenisio',
    code: 'D553',
  },
  {
    name: 'Moncestino',
    code: 'F337',
  },
  {
    name: 'Monchiero',
    code: 'F338',
  },
  {
    name: 'Monchio delle Corti',
    code: 'F340',
  },
  {
    name: 'Moncrivello',
    code: 'F342',
  },
  {
    name: 'Moncucco Torinese',
    code: 'F343',
  },
  {
    name: 'Mondaino',
    code: 'F346',
  },
  {
    name: 'Mondavio',
    code: 'F347',
  },
  {
    name: 'Mondolfo',
    code: 'F348',
  },
  {
    name: 'Mondovì',
    code: 'F351',
  },
  {
    name: 'Mondragone',
    code: 'F352',
  },
  {
    name: 'Moneglia',
    code: 'F354',
  },
  {
    name: 'Monesiglio',
    code: 'F355',
  },
  {
    name: 'Monfalcone',
    code: 'F356',
  },
  {
    name: "Monforte d'Alba",
    code: 'F358',
  },
  {
    name: 'Monforte San Giorgio',
    code: 'F359',
  },
  {
    name: 'Monfumo',
    code: 'F360',
  },
  {
    name: 'Mongardino',
    code: 'F361',
  },
  {
    name: 'Monghidoro',
    code: 'F363',
  },
  {
    name: 'Mongiana',
    code: 'F364',
  },
  {
    name: 'Mongiardino Ligure',
    code: 'F365',
  },
  {
    name: 'Mongiuffi Melia',
    code: 'F368',
  },
  {
    name: 'Mongrando',
    code: 'F369',
  },
  {
    name: 'Mongrassano',
    code: 'F370',
  },
  {
    name: 'Monguelfo-Tesido',
    code: 'F371',
  },
  {
    name: 'Monguzzo',
    code: 'F372',
  },
  {
    name: 'Moniga del Garda',
    code: 'F373',
  },
  {
    name: 'Monleale',
    code: 'F374',
  },
  {
    name: 'Monno',
    code: 'F375',
  },
  {
    name: 'Monopoli',
    code: 'F376',
  },
  {
    name: 'Monreale',
    code: 'F377',
  },
  {
    name: 'Monrupino',
    code: 'F378',
  },
  {
    name: 'Monsampietro Morico',
    code: 'F379',
  },
  {
    name: 'Monsampolo del Tronto',
    code: 'F380',
  },
  {
    name: 'Monsano',
    code: 'F381',
  },
  {
    name: 'Monselice',
    code: 'F382',
  },
  {
    name: 'Monserrato',
    code: 'F383',
  },
  {
    name: 'Monsummano Terme',
    code: 'F384',
  },
  {
    name: 'Montà',
    code: 'F385',
  },
  {
    name: 'Montabone',
    code: 'F386',
  },
  {
    name: 'Montacuto',
    code: 'F387',
  },
  {
    name: 'Montafia',
    code: 'F390',
  },
  {
    name: 'Montagano',
    code: 'F391',
  },
  {
    name: 'Montagna',
    code: 'F392',
  },
  {
    name: 'Montagna in Valtellina',
    code: 'F393',
  },
  {
    name: 'Montagnana',
    code: 'F394',
  },
  {
    name: 'Montagnareale',
    code: 'F395',
  },
  {
    name: 'Montaguto',
    code: 'F397',
  },
  {
    name: 'Montaione',
    code: 'F398',
  },
  {
    name: 'Montalbano Elicona',
    code: 'F400',
  },
  {
    name: 'Montalbano Jonico',
    code: 'F399',
  },
  {
    name: 'Montalcino',
    code: 'M378',
  },
  {
    name: 'Montaldeo',
    code: 'F403',
  },
  {
    name: 'Montaldo Bormida',
    code: 'F404',
  },
  {
    name: 'Montaldo di Mondovì',
    code: 'F405',
  },
  {
    name: 'Montaldo Roero',
    code: 'F408',
  },
  {
    name: 'Montaldo Scarampi',
    code: 'F409',
  },
  {
    name: 'Montaldo Torinese',
    code: 'F407',
  },
  {
    name: 'Montale',
    code: 'F410',
  },
  {
    name: 'Montalenghe',
    code: 'F411',
  },
  {
    name: 'Montallegro',
    code: 'F414',
  },
  {
    name: 'Montalto Carpasio',
    code: 'M387',
  },
  {
    name: 'Montalto delle Marche',
    code: 'F415',
  },
  {
    name: 'Montalto di Castro',
    code: 'F419',
  },
  {
    name: 'Montalto Dora',
    code: 'F420',
  },
  {
    name: 'Montalto Pavese',
    code: 'F417',
  },
  {
    name: 'Montalto Uffugo',
    code: 'F416',
  },
  {
    name: 'Montanaro',
    code: 'F422',
  },
  {
    name: 'Montanaso Lombardo',
    code: 'F423',
  },
  {
    name: 'Montanera',
    code: 'F424',
  },
  {
    name: 'Montano Antilia',
    code: 'F426',
  },
  {
    name: 'Montano Lucino',
    code: 'F427',
  },
  {
    name: 'Montappone',
    code: 'F428',
  },
  {
    name: 'Montaquila',
    code: 'F429',
  },
  {
    name: 'Montasola',
    code: 'F430',
  },
  {
    name: 'Montauro',
    code: 'F432',
  },
  {
    name: 'Montazzoli',
    code: 'F433',
  },
  {
    name: 'Monte Argentario',
    code: 'F437',
  },
  {
    name: 'Monte Castello di Vibio',
    code: 'F456',
  },
  {
    name: 'Monte Cavallo',
    code: 'F460',
  },
  {
    name: 'Monte Cerignone',
    code: 'F467',
  },
  {
    name: 'Monte Compatri',
    code: 'F477',
  },
  {
    name: 'Monte Cremasco',
    code: 'F434',
  },
  {
    name: 'Monte di Malo',
    code: 'F486',
  },
  {
    name: 'Monte di Procida',
    code: 'F488',
  },
  {
    name: 'Monte Giberto',
    code: 'F517',
  },
  {
    name: 'Monte Grimano Terme',
    code: 'F524',
  },
  {
    name: 'Monte Isola',
    code: 'F532',
  },
  {
    name: 'Monte Marenzo',
    code: 'F561',
  },
  {
    name: 'Monte Porzio',
    code: 'F589',
  },
  {
    name: 'Monte Porzio Catone',
    code: 'F590',
  },
  {
    name: 'Monte Rinaldo',
    code: 'F599',
  },
  {
    name: 'Monte Roberto',
    code: 'F600',
  },
  {
    name: 'Monte Romano',
    code: 'F603',
  },
  {
    name: 'Monte San Biagio',
    code: 'F616',
  },
  {
    name: 'Monte San Giacomo',
    code: 'F618',
  },
  {
    name: 'Monte San Giovanni Campano',
    code: 'F620',
  },
  {
    name: 'Monte San Giovanni in Sabina',
    code: 'F619',
  },
  {
    name: 'Monte San Giusto',
    code: 'F621',
  },
  {
    name: 'Monte San Martino',
    code: 'F622',
  },
  {
    name: 'Monte San Pietrangeli',
    code: 'F626',
  },
  {
    name: 'Monte San Pietro',
    code: 'F627',
  },
  {
    name: 'Monte San Savino',
    code: 'F628',
  },
  {
    name: 'Monte San Vito',
    code: 'F634',
  },
  {
    name: "Monte Sant'Angelo",
    code: 'F631',
  },
  {
    name: 'Monte Santa Maria Tiberina',
    code: 'F629',
  },
  {
    name: 'Monte Urano',
    code: 'F653',
  },
  {
    name: 'Monte Vidon Combatte',
    code: 'F664',
  },
  {
    name: 'Monte Vidon Corrado',
    code: 'F665',
  },
  {
    name: 'Montebello della Battaglia',
    code: 'F440',
  },
  {
    name: 'Montebello di Bertona',
    code: 'F441',
  },
  {
    name: 'Montebello Jonico',
    code: 'D746',
  },
  {
    name: 'Montebello sul Sangro',
    code: 'B268',
  },
  {
    name: 'Montebello Vicentino',
    code: 'F442',
  },
  {
    name: 'Montebelluna',
    code: 'F443',
  },
  {
    name: 'Montebruno',
    code: 'F445',
  },
  {
    name: 'Montebuono',
    code: 'F446',
  },
  {
    name: 'Montecalvo in Foglia',
    code: 'F450',
  },
  {
    name: 'Montecalvo Irpino',
    code: 'F448',
  },
  {
    name: 'Montecalvo Versiggia',
    code: 'F449',
  },
  {
    name: 'Montecarlo',
    code: 'F452',
  },
  {
    name: 'Montecarotto',
    code: 'F453',
  },
  {
    name: 'Montecassiano',
    code: 'F454',
  },
  {
    name: 'Montecastello',
    code: 'F455',
  },
  {
    name: 'Montecastrilli',
    code: 'F457',
  },
  {
    name: 'Montecatini Val di Cecina',
    code: 'F458',
  },
  {
    name: 'Montecatini-Terme',
    code: 'A561',
  },
  {
    name: 'Montecchia di Crosara',
    code: 'F461',
  },
  {
    name: 'Montecchio',
    code: 'F462',
  },
  {
    name: 'Montecchio Emilia',
    code: 'F463',
  },
  {
    name: 'Montecchio Maggiore',
    code: 'F464',
  },
  {
    name: 'Montecchio Precalcino',
    code: 'F465',
  },
  {
    name: "Montechiaro d'Acqui",
    code: 'F469',
  },
  {
    name: "Montechiaro d'Asti",
    code: 'F468',
  },
  {
    name: 'Montechiarugolo',
    code: 'F473',
  },
  {
    name: 'Monteciccardo',
    code: 'F474',
  },
  {
    name: 'Montecilfone',
    code: 'F475',
  },
  {
    name: 'Montecopiolo',
    code: 'F478',
  },
  {
    name: 'Montecorice',
    code: 'F479',
  },
  {
    name: 'Montecorvino Pugliano',
    code: 'F480',
  },
  {
    name: 'Montecorvino Rovella',
    code: 'F481',
  },
  {
    name: 'Montecosaro',
    code: 'F482',
  },
  {
    name: 'Montecrestese',
    code: 'F483',
  },
  {
    name: 'Montecreto',
    code: 'F484',
  },
  {
    name: 'Montedinove',
    code: 'F487',
  },
  {
    name: 'Montedoro',
    code: 'F489',
  },
  {
    name: 'Montefalcione',
    code: 'F491',
  },
  {
    name: 'Montefalco',
    code: 'F492',
  },
  {
    name: 'Montefalcone Appennino',
    code: 'F493',
  },
  {
    name: 'Montefalcone di Val Fortore',
    code: 'F494',
  },
  {
    name: 'Montefalcone nel Sannio',
    code: 'F495',
  },
  {
    name: 'Montefano',
    code: 'F496',
  },
  {
    name: 'Montefelcino',
    code: 'F497',
  },
  {
    name: 'Monteferrante',
    code: 'F498',
  },
  {
    name: 'Montefiascone',
    code: 'F499',
  },
  {
    name: 'Montefino',
    code: 'F500',
  },
  {
    name: 'Montefiore Conca',
    code: 'F502',
  },
  {
    name: "Montefiore dell'Aso",
    code: 'F501',
  },
  {
    name: 'Montefiorino',
    code: 'F503',
  },
  {
    name: 'Monteflavio',
    code: 'F504',
  },
  {
    name: 'Monteforte Cilento',
    code: 'F507',
  },
  {
    name: "Monteforte d'Alpone",
    code: 'F508',
  },
  {
    name: 'Monteforte Irpino',
    code: 'F506',
  },
  {
    name: 'Montefortino',
    code: 'F509',
  },
  {
    name: 'Montefranco',
    code: 'F510',
  },
  {
    name: 'Montefredane',
    code: 'F511',
  },
  {
    name: 'Montefusco',
    code: 'F512',
  },
  {
    name: 'Montegabbione',
    code: 'F513',
  },
  {
    name: 'Montegalda',
    code: 'F514',
  },
  {
    name: 'Montegaldella',
    code: 'F515',
  },
  {
    name: 'Montegallo',
    code: 'F516',
  },
  {
    name: 'Montegioco',
    code: 'F518',
  },
  {
    name: 'Montegiordano',
    code: 'F519',
  },
  {
    name: 'Montegiorgio',
    code: 'F520',
  },
  {
    name: 'Montegranaro',
    code: 'F522',
  },
  {
    name: 'Montegridolfo',
    code: 'F523',
  },
  {
    name: 'Montegrino Valtravaglia',
    code: 'F526',
  },
  {
    name: "Montegrosso d'Asti",
    code: 'F527',
  },
  {
    name: 'Montegrosso Pian Latte',
    code: 'F528',
  },
  {
    name: 'Montegrotto Terme',
    code: 'F529',
  },
  {
    name: 'Monteiasi',
    code: 'F531',
  },
  {
    name: 'Montelabbate',
    code: 'F533',
  },
  {
    name: 'Montelanico',
    code: 'F534',
  },
  {
    name: 'Montelapiano',
    code: 'F535',
  },
  {
    name: "Monteleone d'Orvieto",
    code: 'F543',
  },
  {
    name: 'Monteleone di Fermo',
    code: 'F536',
  },
  {
    name: 'Monteleone di Puglia',
    code: 'F538',
  },
  {
    name: 'Monteleone di Spoleto',
    code: 'F540',
  },
  {
    name: 'Monteleone Rocca Doria',
    code: 'F542',
  },
  {
    name: 'Monteleone Sabino',
    code: 'F541',
  },
  {
    name: 'Montelepre',
    code: 'F544',
  },
  {
    name: 'Montelibretti',
    code: 'F545',
  },
  {
    name: 'Montella',
    code: 'F546',
  },
  {
    name: 'Montello',
    code: 'F547',
  },
  {
    name: 'Montelongo',
    code: 'F548',
  },
  {
    name: 'Montelparo',
    code: 'F549',
  },
  {
    name: 'Montelupo Albese',
    code: 'F550',
  },
  {
    name: 'Montelupo Fiorentino',
    code: 'F551',
  },
  {
    name: 'Montelupone',
    code: 'F552',
  },
  {
    name: 'Montemaggiore Belsito',
    code: 'F553',
  },
  {
    name: 'Montemagno',
    code: 'F556',
  },
  {
    name: 'Montemale di Cuneo',
    code: 'F558',
  },
  {
    name: 'Montemarano',
    code: 'F559',
  },
  {
    name: 'Montemarciano',
    code: 'F560',
  },
  {
    name: 'Montemarzino',
    code: 'F562',
  },
  {
    name: 'Montemesola',
    code: 'F563',
  },
  {
    name: 'Montemezzo',
    code: 'F564',
  },
  {
    name: 'Montemignaio',
    code: 'F565',
  },
  {
    name: 'Montemiletto',
    code: 'F566',
  },
  {
    name: 'Montemilone',
    code: 'F568',
  },
  {
    name: 'Montemitro',
    code: 'F569',
  },
  {
    name: 'Montemonaco',
    code: 'F570',
  },
  {
    name: 'Montemurlo',
    code: 'F572',
  },
  {
    name: 'Montemurro',
    code: 'F573',
  },
  {
    name: 'Montenars',
    code: 'F574',
  },
  {
    name: 'Montenero di Bisaccia',
    code: 'F576',
  },
  {
    name: 'Montenero Sabino',
    code: 'F579',
  },
  {
    name: 'Montenero Val Cocchiara',
    code: 'F580',
  },
  {
    name: 'Montenerodomo',
    code: 'F578',
  },
  {
    name: 'Monteodorisio',
    code: 'F582',
  },
  {
    name: 'Montepaone',
    code: 'F586',
  },
  {
    name: 'Monteparano',
    code: 'F587',
  },
  {
    name: 'Monteprandone',
    code: 'F591',
  },
  {
    name: 'Montepulciano',
    code: 'F592',
  },
  {
    name: 'Monterchi',
    code: 'F594',
  },
  {
    name: 'Montereale',
    code: 'F595',
  },
  {
    name: 'Montereale Valcellina',
    code: 'F596',
  },
  {
    name: 'Monterenzio',
    code: 'F597',
  },
  {
    name: 'Monteriggioni',
    code: 'F598',
  },
  {
    name: 'Monteroduni',
    code: 'F601',
  },
  {
    name: "Monteroni d'Arbia",
    code: 'F605',
  },
  {
    name: 'Monteroni di Lecce',
    code: 'F604',
  },
  {
    name: 'Monterosi',
    code: 'F606',
  },
  {
    name: 'Monterosso al Mare',
    code: 'F609',
  },
  {
    name: 'Monterosso Almo',
    code: 'F610',
  },
  {
    name: 'Monterosso Calabro',
    code: 'F607',
  },
  {
    name: 'Monterosso Grana',
    code: 'F608',
  },
  {
    name: 'Monterotondo',
    code: 'F611',
  },
  {
    name: 'Monterotondo Marittimo',
    code: 'F612',
  },
  {
    name: 'Monterubbiano',
    code: 'F614',
  },
  {
    name: 'Montesano Salentino',
    code: 'F623',
  },
  {
    name: 'Montesano sulla Marcellana',
    code: 'F625',
  },
  {
    name: 'Montesarchio',
    code: 'F636',
  },
  {
    name: 'Montescaglioso',
    code: 'F637',
  },
  {
    name: 'Montescano',
    code: 'F638',
  },
  {
    name: 'Montescheno',
    code: 'F639',
  },
  {
    name: 'Montescudaio',
    code: 'F640',
  },
  {
    name: 'Montescudo-Monte Colombo',
    code: 'M368',
  },
  {
    name: 'Montese',
    code: 'F642',
  },
  {
    name: 'Montesegale',
    code: 'F644',
  },
  {
    name: 'Montesilvano',
    code: 'F646',
  },
  {
    name: 'Montespertoli',
    code: 'F648',
  },
  {
    name: 'Monteu da Po',
    code: 'F651',
  },
  {
    name: 'Monteu Roero',
    code: 'F654',
  },
  {
    name: 'Montevago',
    code: 'F655',
  },
  {
    name: 'Montevarchi',
    code: 'F656',
  },
  {
    name: 'Montevecchia',
    code: 'F657',
  },
  {
    name: 'Monteverde',
    code: 'F660',
  },
  {
    name: 'Monteverdi Marittimo',
    code: 'F661',
  },
  {
    name: 'Monteviale',
    code: 'F662',
  },
  {
    name: 'Montezemolo',
    code: 'F666',
  },
  {
    name: 'Monti',
    code: 'F667',
  },
  {
    name: 'Montiano',
    code: 'F668',
  },
  {
    name: 'Monticelli Brusati',
    code: 'F672',
  },
  {
    name: "Monticelli d'Ongina",
    code: 'F671',
  },
  {
    name: 'Monticelli Pavese',
    code: 'F670',
  },
  {
    name: 'Monticello Brianza',
    code: 'F674',
  },
  {
    name: 'Monticello Conte Otto',
    code: 'F675',
  },
  {
    name: "Monticello d'Alba",
    code: 'F669',
  },
  {
    name: 'Montichiari',
    code: 'F471',
  },
  {
    name: 'Monticiano',
    code: 'F676',
  },
  {
    name: 'Montieri',
    code: 'F677',
  },
  {
    name: 'Montiglio Monferrato',
    code: 'M302',
  },
  {
    name: 'Montignoso',
    code: 'F679',
  },
  {
    name: 'Montirone',
    code: 'F680',
  },
  {
    name: 'Montjovet',
    code: 'F367',
  },
  {
    name: 'Montodine',
    code: 'F681',
  },
  {
    name: 'Montoggio',
    code: 'F682',
  },
  {
    name: 'Montone',
    code: 'F685',
  },
  {
    name: 'Montopoli di Sabina',
    code: 'F687',
  },
  {
    name: "Montopoli in Val d'Arno",
    code: 'F686',
  },
  {
    name: 'Montorfano',
    code: 'F688',
  },
  {
    name: 'Montorio al Vomano',
    code: 'F690',
  },
  {
    name: 'Montorio nei Frentani',
    code: 'F689',
  },
  {
    name: 'Montorio Romano',
    code: 'F692',
  },
  {
    name: 'Montoro',
    code: 'M330',
  },
  {
    name: 'Montorso Vicentino',
    code: 'F696',
  },
  {
    name: 'Montottone',
    code: 'F697',
  },
  {
    name: 'Montresta',
    code: 'F698',
  },
  {
    name: 'Montù Beccaria',
    code: 'F701',
  },
  {
    name: 'Monvalle',
    code: 'F703',
  },
  {
    name: 'Monza',
    code: 'F704',
  },
  {
    name: 'Monzambano',
    code: 'F705',
  },
  {
    name: 'Monzuno',
    code: 'F706',
  },
  {
    name: 'Morano Calabro',
    code: 'F708',
  },
  {
    name: 'Morano sul Po',
    code: 'F707',
  },
  {
    name: 'Moransengo',
    code: 'F709',
  },
  {
    name: 'Moraro',
    code: 'F710',
  },
  {
    name: 'Morazzone',
    code: 'F711',
  },
  {
    name: 'Morbegno',
    code: 'F712',
  },
  {
    name: 'Morbello',
    code: 'F713',
  },
  {
    name: 'Morciano di Leuca',
    code: 'F716',
  },
  {
    name: 'Morciano di Romagna',
    code: 'F715',
  },
  {
    name: 'Morcone',
    code: 'F717',
  },
  {
    name: 'Mordano',
    code: 'F718',
  },
  {
    name: 'Morengo',
    code: 'F720',
  },
  {
    name: 'Mores',
    code: 'F721',
  },
  {
    name: 'Moresco',
    code: 'F722',
  },
  {
    name: 'Moretta',
    code: 'F723',
  },
  {
    name: 'Morfasso',
    code: 'F724',
  },
  {
    name: 'Morgano',
    code: 'F725',
  },
  {
    name: 'Morgex',
    code: 'F726',
  },
  {
    name: 'Morgongiori',
    code: 'F727',
  },
  {
    name: 'Mori',
    code: 'F728',
  },
  {
    name: 'Moriago della Battaglia',
    code: 'F729',
  },
  {
    name: 'Moricone',
    code: 'F730',
  },
  {
    name: 'Morigerati',
    code: 'F731',
  },
  {
    name: 'Morimondo',
    code: 'D033',
  },
  {
    name: 'Morino',
    code: 'F732',
  },
  {
    name: 'Moriondo Torinese',
    code: 'F733',
  },
  {
    name: 'Morlupo',
    code: 'F734',
  },
  {
    name: 'Mormanno',
    code: 'F735',
  },
  {
    name: 'Mornago',
    code: 'F736',
  },
  {
    name: 'Mornese',
    code: 'F737',
  },
  {
    name: 'Mornico al Serio',
    code: 'F738',
  },
  {
    name: 'Mornico Losana',
    code: 'F739',
  },
  {
    name: 'Morolo',
    code: 'F740',
  },
  {
    name: 'Morozzo',
    code: 'F743',
  },
  {
    name: 'Morra De Sanctis',
    code: 'F744',
  },
  {
    name: "Morro d'Alba",
    code: 'F745',
  },
  {
    name: "Morro d'Oro",
    code: 'F747',
  },
  {
    name: 'Morro Reatino',
    code: 'F746',
  },
  {
    name: 'Morrone del Sannio',
    code: 'F748',
  },
  {
    name: 'Morrovalle',
    code: 'F749',
  },
  {
    name: 'Morsano al Tagliamento',
    code: 'F750',
  },
  {
    name: 'Morsasco',
    code: 'F751',
  },
  {
    name: 'Mortara',
    code: 'F754',
  },
  {
    name: 'Mortegliano',
    code: 'F756',
  },
  {
    name: 'Morterone',
    code: 'F758',
  },
  {
    name: 'Moruzzo',
    code: 'F760',
  },
  {
    name: 'Moscazzano',
    code: 'F761',
  },
  {
    name: 'Moschiano',
    code: 'F762',
  },
  {
    name: "Mosciano Sant'Angelo",
    code: 'F764',
  },
  {
    name: 'Moscufo',
    code: 'F765',
  },
  {
    name: 'Moso in Passiria',
    code: 'F766',
  },
  {
    name: 'Mossa',
    code: 'F767',
  },
  {
    name: 'Motta Baluffi',
    code: 'F771',
  },
  {
    name: 'Motta Camastra',
    code: 'F772',
  },
  {
    name: "Motta d'Affermo",
    code: 'F773',
  },
  {
    name: "Motta de' Conti",
    code: 'F774',
  },
  {
    name: 'Motta di Livenza',
    code: 'F770',
  },
  {
    name: 'Motta Montecorvino',
    code: 'F777',
  },
  {
    name: 'Motta San Giovanni',
    code: 'F779',
  },
  {
    name: "Motta Sant'Anastasia",
    code: 'F781',
  },
  {
    name: 'Motta Santa Lucia',
    code: 'F780',
  },
  {
    name: 'Motta Visconti',
    code: 'F783',
  },
  {
    name: 'Mottafollone',
    code: 'F775',
  },
  {
    name: 'Mottalciata',
    code: 'F776',
  },
  {
    name: 'Motteggiana',
    code: 'B012',
  },
  {
    name: 'Mottola',
    code: 'F784',
  },
  {
    name: 'Mozzagrogna',
    code: 'F785',
  },
  {
    name: 'Mozzanica',
    code: 'F786',
  },
  {
    name: 'Mozzate',
    code: 'F788',
  },
  {
    name: 'Mozzecane',
    code: 'F789',
  },
  {
    name: 'Mozzo',
    code: 'F791',
  },
  {
    name: 'Muccia',
    code: 'F793',
  },
  {
    name: 'Muggia',
    code: 'F795',
  },
  {
    name: 'Muggiò',
    code: 'F797',
  },
  {
    name: 'Mugnano del Cardinale',
    code: 'F798',
  },
  {
    name: 'Mugnano di Napoli',
    code: 'F799',
  },
  {
    name: 'Mulazzano',
    code: 'F801',
  },
  {
    name: 'Mulazzo',
    code: 'F802',
  },
  {
    name: 'Mura',
    code: 'F806',
  },
  {
    name: 'Muravera',
    code: 'F808',
  },
  {
    name: 'Murazzano',
    code: 'F809',
  },
  {
    name: 'Murello',
    code: 'F811',
  },
  {
    name: 'Murialdo',
    code: 'F813',
  },
  {
    name: 'Murisengo',
    code: 'F814',
  },
  {
    name: 'Murlo',
    code: 'F815',
  },
  {
    name: 'Muro Leccese',
    code: 'F816',
  },
  {
    name: 'Muro Lucano',
    code: 'F817',
  },
  {
    name: 'Muros',
    code: 'F818',
  },
  {
    name: 'Muscoline',
    code: 'F820',
  },
  {
    name: 'Musei',
    code: 'F822',
  },
  {
    name: 'Musile di Piave',
    code: 'F826',
  },
  {
    name: 'Musso',
    code: 'F828',
  },
  {
    name: 'Mussolente',
    code: 'F829',
  },
  {
    name: 'Mussomeli',
    code: 'F830',
  },
  {
    name: 'Muzzana del Turgnano',
    code: 'F832',
  },
  {
    name: 'Muzzano',
    code: 'F833',
  },
  {
    name: 'Nago-Torbole',
    code: 'F835',
  },
  {
    name: 'Nalles',
    code: 'F836',
  },
  {
    name: 'Nanto',
    code: 'F838',
  },
  {
    name: 'Napoli',
    code: 'F839',
  },
  {
    name: 'Narbolia',
    code: 'F840',
  },
  {
    name: 'Narcao',
    code: 'F841',
  },
  {
    name: 'Nardò',
    code: 'F842',
  },
  {
    name: 'Nardodipace',
    code: 'F843',
  },
  {
    name: 'Narni',
    code: 'F844',
  },
  {
    name: 'Naro',
    code: 'F845',
  },
  {
    name: 'Narzole',
    code: 'F846',
  },
  {
    name: 'Nasino',
    code: 'F847',
  },
  {
    name: 'Naso',
    code: 'F848',
  },
  {
    name: 'Naturno',
    code: 'F849',
  },
  {
    name: 'Nave',
    code: 'F851',
  },
  {
    name: 'Navelli',
    code: 'F852',
  },
  {
    name: 'Naz-Sciaves',
    code: 'F856',
  },
  {
    name: 'Nazzano',
    code: 'F857',
  },
  {
    name: 'Ne',
    code: 'F858',
  },
  {
    name: 'Nebbiuno',
    code: 'F859',
  },
  {
    name: 'Negrar di Valpolicella',
    code: 'F861',
  },
  {
    name: 'Neirone',
    code: 'F862',
  },
  {
    name: 'Neive',
    code: 'F863',
  },
  {
    name: 'Nembro',
    code: 'F864',
  },
  {
    name: 'Nemi',
    code: 'F865',
  },
  {
    name: 'Nemoli',
    code: 'F866',
  },
  {
    name: 'Neoneli',
    code: 'F867',
  },
  {
    name: 'Nepi',
    code: 'F868',
  },
  {
    name: 'Nereto',
    code: 'F870',
  },
  {
    name: 'Nerola',
    code: 'F871',
  },
  {
    name: 'Nervesa della Battaglia',
    code: 'F872',
  },
  {
    name: 'Nerviano',
    code: 'F874',
  },
  {
    name: 'Nespolo',
    code: 'F876',
  },
  {
    name: 'Nesso',
    code: 'F877',
  },
  {
    name: 'Netro',
    code: 'F878',
  },
  {
    name: 'Nettuno',
    code: 'F880',
  },
  {
    name: 'Neviano',
    code: 'F881',
  },
  {
    name: 'Neviano degli Arduini',
    code: 'F882',
  },
  {
    name: 'Neviglie',
    code: 'F883',
  },
  {
    name: 'Niardo',
    code: 'F884',
  },
  {
    name: 'Nibbiola',
    code: 'F886',
  },
  {
    name: 'Nibionno',
    code: 'F887',
  },
  {
    name: 'Nichelino',
    code: 'F889',
  },
  {
    name: 'Nicolosi',
    code: 'F890',
  },
  {
    name: 'Nicorvo',
    code: 'F891',
  },
  {
    name: 'Nicosia',
    code: 'F892',
  },
  {
    name: 'Nicotera',
    code: 'F893',
  },
  {
    name: 'Niella Belbo',
    code: 'F894',
  },
  {
    name: 'Niella Tanaro',
    code: 'F895',
  },
  {
    name: 'Nimis',
    code: 'F898',
  },
  {
    name: 'Niscemi',
    code: 'F899',
  },
  {
    name: 'Nissoria',
    code: 'F900',
  },
  {
    name: 'Nizza di Sicilia',
    code: 'F901',
  },
  {
    name: 'Nizza Monferrato',
    code: 'F902',
  },
  {
    name: 'Noale',
    code: 'F904',
  },
  {
    name: 'Noasca',
    code: 'F906',
  },
  {
    name: 'Nocara',
    code: 'F907',
  },
  {
    name: 'Nocciano',
    code: 'F908',
  },
  {
    name: 'Nocera Inferiore',
    code: 'F912',
  },
  {
    name: 'Nocera Superiore',
    code: 'F913',
  },
  {
    name: 'Nocera Terinese',
    code: 'F910',
  },
  {
    name: 'Nocera Umbra',
    code: 'F911',
  },
  {
    name: 'Noceto',
    code: 'F914',
  },
  {
    name: 'Noci',
    code: 'F915',
  },
  {
    name: 'Nociglia',
    code: 'F916',
  },
  {
    name: 'Noepoli',
    code: 'F917',
  },
  {
    name: 'Nogara',
    code: 'F918',
  },
  {
    name: 'Nogaredo',
    code: 'F920',
  },
  {
    name: 'Nogarole Rocca',
    code: 'F921',
  },
  {
    name: 'Nogarole Vicentino',
    code: 'F922',
  },
  {
    name: 'Noicattaro',
    code: 'F923',
  },
  {
    name: 'Nola',
    code: 'F924',
  },
  {
    name: 'Nole',
    code: 'F925',
  },
  {
    name: 'Noli',
    code: 'F926',
  },
  {
    name: 'Nomaglio',
    code: 'F927',
  },
  {
    name: 'Nomi',
    code: 'F929',
  },
  {
    name: 'Nonantola',
    code: 'F930',
  },
  {
    name: 'None',
    code: 'F931',
  },
  {
    name: 'Nonio',
    code: 'F932',
  },
  {
    name: 'Noragugume',
    code: 'F933',
  },
  {
    name: 'Norbello',
    code: 'F934',
  },
  {
    name: 'Norcia',
    code: 'F935',
  },
  {
    name: 'Norma',
    code: 'F937',
  },
  {
    name: 'Nosate',
    code: 'F939',
  },
  {
    name: 'Notaresco',
    code: 'F942',
  },
  {
    name: 'Noto',
    code: 'F943',
  },
  {
    name: 'Nova Levante',
    code: 'F949',
  },
  {
    name: 'Nova Milanese',
    code: 'F944',
  },
  {
    name: 'Nova Ponente',
    code: 'F950',
  },
  {
    name: 'Nova Siri',
    code: 'A942',
  },
  {
    name: 'Novafeltria',
    code: 'F137',
  },
  {
    name: 'Novaledo',
    code: 'F947',
  },
  {
    name: 'Novalesa',
    code: 'F948',
  },
  {
    name: 'Novara',
    code: 'F952',
  },
  {
    name: 'Novara di Sicilia',
    code: 'F951',
  },
  {
    name: 'Novate Mezzola',
    code: 'F956',
  },
  {
    name: 'Novate Milanese',
    code: 'F955',
  },
  {
    name: 'Nove',
    code: 'F957',
  },
  {
    name: 'Novedrate',
    code: 'F958',
  },
  {
    name: 'Novella',
    code: 'M430',
  },
  {
    name: 'Novellara',
    code: 'F960',
  },
  {
    name: 'Novello',
    code: 'F961',
  },
  {
    name: 'Noventa di Piave',
    code: 'F963',
  },
  {
    name: 'Noventa Padovana',
    code: 'F962',
  },
  {
    name: 'Noventa Vicentina',
    code: 'F964',
  },
  {
    name: 'Novi di Modena',
    code: 'F966',
  },
  {
    name: 'Novi Ligure',
    code: 'F965',
  },
  {
    name: 'Novi Velia',
    code: 'F967',
  },
  {
    name: 'Noviglio',
    code: 'F968',
  },
  {
    name: 'Novoli',
    code: 'F970',
  },
  {
    name: 'Nucetto',
    code: 'F972',
  },
  {
    name: 'Nughedu San Nicolò',
    code: 'F975',
  },
  {
    name: 'Nughedu Santa Vittoria',
    code: 'F974',
  },
  {
    name: 'Nule',
    code: 'F976',
  },
  {
    name: 'Nulvi',
    code: 'F977',
  },
  {
    name: 'Numana',
    code: 'F978',
  },
  {
    name: 'Nuoro',
    code: 'F979',
  },
  {
    name: 'Nurachi',
    code: 'F980',
  },
  {
    name: 'Nuragus',
    code: 'F981',
  },
  {
    name: 'Nurallao',
    code: 'F982',
  },
  {
    name: 'Nuraminis',
    code: 'F983',
  },
  {
    name: 'Nureci',
    code: 'F985',
  },
  {
    name: 'Nurri',
    code: 'F986',
  },
  {
    name: 'Nus',
    code: 'F987',
  },
  {
    name: 'Nusco',
    code: 'F988',
  },
  {
    name: 'Nuvolento',
    code: 'F989',
  },
  {
    name: 'Nuvolera',
    code: 'F990',
  },
  {
    name: 'Nuxis',
    code: 'F991',
  },
  {
    name: 'Occhieppo Inferiore',
    code: 'F992',
  },
  {
    name: 'Occhieppo Superiore',
    code: 'F993',
  },
  {
    name: 'Occhiobello',
    code: 'F994',
  },
  {
    name: 'Occimiano',
    code: 'F995',
  },
  {
    name: 'Ocre',
    code: 'F996',
  },
  {
    name: 'Odalengo Grande',
    code: 'F997',
  },
  {
    name: 'Odalengo Piccolo',
    code: 'F998',
  },
  {
    name: 'Oderzo',
    code: 'F999',
  },
  {
    name: 'Odolo',
    code: 'G001',
  },
  {
    name: 'Ofena',
    code: 'G002',
  },
  {
    name: 'Offagna',
    code: 'G003',
  },
  {
    name: 'Offanengo',
    code: 'G004',
  },
  {
    name: 'Offida',
    code: 'G005',
  },
  {
    name: 'Offlaga',
    code: 'G006',
  },
  {
    name: 'Oggebbio',
    code: 'G007',
  },
  {
    name: 'Oggiona con Santo Stefano',
    code: 'G008',
  },
  {
    name: 'Oggiono',
    code: 'G009',
  },
  {
    name: 'Oglianico',
    code: 'G010',
  },
  {
    name: 'Ogliastro Cilento',
    code: 'G011',
  },
  {
    name: 'Olbia',
    code: 'G015',
  },
  {
    name: 'Olcenengo',
    code: 'G016',
  },
  {
    name: 'Oldenico',
    code: 'G018',
  },
  {
    name: 'Oleggio',
    code: 'G019',
  },
  {
    name: 'Oleggio Castello',
    code: 'G020',
  },
  {
    name: 'Olevano di Lomellina',
    code: 'G021',
  },
  {
    name: 'Olevano Romano',
    code: 'G022',
  },
  {
    name: 'Olevano sul Tusciano',
    code: 'G023',
  },
  {
    name: 'Olgiate Comasco',
    code: 'G025',
  },
  {
    name: 'Olgiate Molgora',
    code: 'G026',
  },
  {
    name: 'Olgiate Olona',
    code: 'G028',
  },
  {
    name: 'Olginate',
    code: 'G030',
  },
  {
    name: 'Oliena',
    code: 'G031',
  },
  {
    name: 'Oliva Gessi',
    code: 'G032',
  },
  {
    name: 'Olivadi',
    code: 'G034',
  },
  {
    name: 'Oliveri',
    code: 'G036',
  },
  {
    name: 'Oliveto Citra',
    code: 'G039',
  },
  {
    name: 'Oliveto Lario',
    code: 'G040',
  },
  {
    name: 'Oliveto Lucano',
    code: 'G037',
  },
  {
    name: 'Olivetta San Michele',
    code: 'G041',
  },
  {
    name: 'Olivola',
    code: 'G042',
  },
  {
    name: 'Ollastra',
    code: 'G043',
  },
  {
    name: 'Ollolai',
    code: 'G044',
  },
  {
    name: 'Ollomont',
    code: 'G045',
  },
  {
    name: 'Olmedo',
    code: 'G046',
  },
  {
    name: 'Olmeneta',
    code: 'G047',
  },
  {
    name: 'Olmo al Brembo',
    code: 'G049',
  },
  {
    name: 'Olmo Gentile',
    code: 'G048',
  },
  {
    name: 'Oltre il Colle',
    code: 'G050',
  },
  {
    name: 'Oltressenda Alta',
    code: 'G054',
  },
  {
    name: 'Oltrona di San Mamette',
    code: 'G056',
  },
  {
    name: 'Olzai',
    code: 'G058',
  },
  {
    name: 'Ome',
    code: 'G061',
  },
  {
    name: 'Omegna',
    code: 'G062',
  },
  {
    name: 'Omignano',
    code: 'G063',
  },
  {
    name: 'Onanì',
    code: 'G064',
  },
  {
    name: 'Onano',
    code: 'G065',
  },
  {
    name: 'Oncino',
    code: 'G066',
  },
  {
    name: 'Oneta',
    code: 'G068',
  },
  {
    name: 'Onifai',
    code: 'G070',
  },
  {
    name: 'Oniferi',
    code: 'G071',
  },
  {
    name: 'Ono San Pietro',
    code: 'G074',
  },
  {
    name: 'Onore',
    code: 'G075',
  },
  {
    name: 'Onzo',
    code: 'G076',
  },
  {
    name: 'Opera',
    code: 'G078',
  },
  {
    name: 'Opi',
    code: 'G079',
  },
  {
    name: 'Oppeano',
    code: 'G080',
  },
  {
    name: 'Oppido Lucano',
    code: 'G081',
  },
  {
    name: 'Oppido Mamertina',
    code: 'G082',
  },
  {
    name: 'Ora',
    code: 'G083',
  },
  {
    name: 'Orani',
    code: 'G084',
  },
  {
    name: 'Oratino',
    code: 'G086',
  },
  {
    name: 'Orbassano',
    code: 'G087',
  },
  {
    name: 'Orbetello',
    code: 'G088',
  },
  {
    name: 'Orciano Pisano',
    code: 'G090',
  },
  {
    name: 'Orco Feglino',
    code: 'D522',
  },
  {
    name: 'Ordona',
    code: 'M266',
  },
  {
    name: 'Orero',
    code: 'G093',
  },
  {
    name: 'Orgiano',
    code: 'G095',
  },
  {
    name: 'Orgosolo',
    code: 'G097',
  },
  {
    name: 'Oria',
    code: 'G098',
  },
  {
    name: 'Oricola',
    code: 'G102',
  },
  {
    name: 'Origgio',
    code: 'G103',
  },
  {
    name: 'Orino',
    code: 'G105',
  },
  {
    name: 'Orio al Serio',
    code: 'G108',
  },
  {
    name: 'Orio Canavese',
    code: 'G109',
  },
  {
    name: 'Orio Litta',
    code: 'G107',
  },
  {
    name: 'Oriolo',
    code: 'G110',
  },
  {
    name: 'Oriolo Romano',
    code: 'G111',
  },
  {
    name: 'Oristano',
    code: 'G113',
  },
  {
    name: 'Ormea',
    code: 'G114',
  },
  {
    name: 'Ormelle',
    code: 'G115',
  },
  {
    name: 'Ornago',
    code: 'G116',
  },
  {
    name: 'Ornavasso',
    code: 'G117',
  },
  {
    name: 'Ornica',
    code: 'G118',
  },
  {
    name: 'Orosei',
    code: 'G119',
  },
  {
    name: 'Orotelli',
    code: 'G120',
  },
  {
    name: 'Orria',
    code: 'G121',
  },
  {
    name: 'Orroli',
    code: 'G122',
  },
  {
    name: 'Orsago',
    code: 'G123',
  },
  {
    name: 'Orsara Bormida',
    code: 'G124',
  },
  {
    name: 'Orsara di Puglia',
    code: 'G125',
  },
  {
    name: 'Orsenigo',
    code: 'G126',
  },
  {
    name: 'Orsogna',
    code: 'G128',
  },
  {
    name: 'Orsomarso',
    code: 'G129',
  },
  {
    name: 'Orta di Atella',
    code: 'G130',
  },
  {
    name: 'Orta Nova',
    code: 'G131',
  },
  {
    name: 'Orta San Giulio',
    code: 'G134',
  },
  {
    name: 'Ortacesus',
    code: 'G133',
  },
  {
    name: 'Orte',
    code: 'G135',
  },
  {
    name: 'Ortelle',
    code: 'G136',
  },
  {
    name: 'Ortezzano',
    code: 'G137',
  },
  {
    name: 'Ortignano Raggiolo',
    code: 'G139',
  },
  {
    name: 'Ortisei',
    code: 'G140',
  },
  {
    name: 'Ortona',
    code: 'G141',
  },
  {
    name: 'Ortona dei Marsi',
    code: 'G142',
  },
  {
    name: 'Ortovero',
    code: 'G144',
  },
  {
    name: 'Ortucchio',
    code: 'G145',
  },
  {
    name: 'Ortueri',
    code: 'G146',
  },
  {
    name: 'Orune',
    code: 'G147',
  },
  {
    name: 'Orvieto',
    code: 'G148',
  },
  {
    name: 'Orvinio',
    code: 'B595',
  },
  {
    name: 'Orzinuovi',
    code: 'G149',
  },
  {
    name: 'Orzivecchi',
    code: 'G150',
  },
  {
    name: 'Osasco',
    code: 'G151',
  },
  {
    name: 'Osasio',
    code: 'G152',
  },
  {
    name: 'Oschiri',
    code: 'G153',
  },
  {
    name: 'Osidda',
    code: 'G154',
  },
  {
    name: 'Osiglia',
    code: 'G155',
  },
  {
    name: 'Osilo',
    code: 'G156',
  },
  {
    name: 'Osimo',
    code: 'G157',
  },
  {
    name: 'Osini',
    code: 'G158',
  },
  {
    name: 'Osio Sopra',
    code: 'G159',
  },
  {
    name: 'Osio Sotto',
    code: 'G160',
  },
  {
    name: 'Osnago',
    code: 'G161',
  },
  {
    name: 'Osoppo',
    code: 'G163',
  },
  {
    name: 'Ospedaletti',
    code: 'G164',
  },
  {
    name: 'Ospedaletto',
    code: 'G168',
  },
  {
    name: "Ospedaletto d'Alpinolo",
    code: 'G165',
  },
  {
    name: 'Ospedaletto Euganeo',
    code: 'G167',
  },
  {
    name: 'Ospedaletto Lodigiano',
    code: 'G166',
  },
  {
    name: 'Ospitale di Cadore',
    code: 'G169',
  },
  {
    name: 'Ospitaletto',
    code: 'G170',
  },
  {
    name: 'Ossago Lodigiano',
    code: 'G171',
  },
  {
    name: 'Ossana',
    code: 'G173',
  },
  {
    name: 'Ossi',
    code: 'G178',
  },
  {
    name: 'Ossimo',
    code: 'G179',
  },
  {
    name: 'Ossona',
    code: 'G181',
  },
  {
    name: 'Ostana',
    code: 'G183',
  },
  {
    name: 'Ostellato',
    code: 'G184',
  },
  {
    name: 'Ostiano',
    code: 'G185',
  },
  {
    name: 'Ostiglia',
    code: 'G186',
  },
  {
    name: 'Ostra',
    code: 'F401',
  },
  {
    name: 'Ostra Vetere',
    code: 'F581',
  },
  {
    name: 'Ostuni',
    code: 'G187',
  },
  {
    name: 'Otranto',
    code: 'G188',
  },
  {
    name: 'Otricoli',
    code: 'G189',
  },
  {
    name: 'Ottana',
    code: 'G191',
  },
  {
    name: 'Ottati',
    code: 'G192',
  },
  {
    name: 'Ottaviano',
    code: 'G190',
  },
  {
    name: 'Ottiglio',
    code: 'G193',
  },
  {
    name: 'Ottobiano',
    code: 'G194',
  },
  {
    name: 'Ottone',
    code: 'G195',
  },
  {
    name: 'Oulx',
    code: 'G196',
  },
  {
    name: 'Ovada',
    code: 'G197',
  },
  {
    name: 'Ovaro',
    code: 'G198',
  },
  {
    name: 'Oviglio',
    code: 'G199',
  },
  {
    name: 'Ovindoli',
    code: 'G200',
  },
  {
    name: 'Ovodda',
    code: 'G201',
  },
  {
    name: 'Oyace',
    code: 'G012',
  },
  {
    name: 'Ozegna',
    code: 'G202',
  },
  {
    name: 'Ozieri',
    code: 'G203',
  },
  {
    name: "Ozzano dell'Emilia",
    code: 'G205',
  },
  {
    name: 'Ozzano Monferrato',
    code: 'G204',
  },
  {
    name: 'Ozzero',
    code: 'G206',
  },
  {
    name: 'Pabillonis',
    code: 'G207',
  },
  {
    name: 'Pace del Mela',
    code: 'G209',
  },
  {
    name: 'Paceco',
    code: 'G208',
  },
  {
    name: 'Pacentro',
    code: 'G210',
  },
  {
    name: 'Pachino',
    code: 'G211',
  },
  {
    name: 'Paciano',
    code: 'G212',
  },
  {
    name: 'Padenghe sul Garda',
    code: 'G213',
  },
  {
    name: 'Paderna',
    code: 'G215',
  },
  {
    name: "Paderno d'Adda",
    code: 'G218',
  },
  {
    name: 'Paderno Dugnano',
    code: 'G220',
  },
  {
    name: 'Paderno Franciacorta',
    code: 'G217',
  },
  {
    name: 'Paderno Ponchielli',
    code: 'G222',
  },
  {
    name: 'Padova',
    code: 'G224',
  },
  {
    name: 'Padria',
    code: 'G225',
  },
  {
    name: 'Padru',
    code: 'M301',
  },
  {
    name: 'Padula',
    code: 'G226',
  },
  {
    name: 'Paduli',
    code: 'G227',
  },
  {
    name: 'Paesana',
    code: 'G228',
  },
  {
    name: 'Paese',
    code: 'G229',
  },
  {
    name: 'Pagani',
    code: 'G230',
  },
  {
    name: 'Paganico Sabino',
    code: 'G232',
  },
  {
    name: 'Pagazzano',
    code: 'G233',
  },
  {
    name: 'Pagliara',
    code: 'G234',
  },
  {
    name: 'Paglieta',
    code: 'G237',
  },
  {
    name: 'Pagnacco',
    code: 'G238',
  },
  {
    name: 'Pagno',
    code: 'G240',
  },
  {
    name: 'Pagnona',
    code: 'G241',
  },
  {
    name: 'Pago del Vallo di Lauro',
    code: 'G242',
  },
  {
    name: 'Pago Veiano',
    code: 'G243',
  },
  {
    name: 'Paisco Loveno',
    code: 'G247',
  },
  {
    name: 'Paitone',
    code: 'G248',
  },
  {
    name: 'Paladina',
    code: 'G249',
  },
  {
    name: 'Palagano',
    code: 'G250',
  },
  {
    name: 'Palagianello',
    code: 'G251',
  },
  {
    name: 'Palagiano',
    code: 'G252',
  },
  {
    name: 'Palagonia',
    code: 'G253',
  },
  {
    name: 'Palaia',
    code: 'G254',
  },
  {
    name: 'Palanzano',
    code: 'G255',
  },
  {
    name: 'Palata',
    code: 'G257',
  },
  {
    name: 'Palau',
    code: 'G258',
  },
  {
    name: 'Palazzago',
    code: 'G259',
  },
  {
    name: 'Palazzo Adriano',
    code: 'G263',
  },
  {
    name: 'Palazzo Canavese',
    code: 'G262',
  },
  {
    name: 'Palazzo Pignano',
    code: 'G260',
  },
  {
    name: 'Palazzo San Gervasio',
    code: 'G261',
  },
  {
    name: 'Palazzolo Acreide',
    code: 'G267',
  },
  {
    name: 'Palazzolo dello Stella',
    code: 'G268',
  },
  {
    name: "Palazzolo sull'Oglio",
    code: 'G264',
  },
  {
    name: 'Palazzolo Vercellese',
    code: 'G266',
  },
  {
    name: 'Palazzuolo sul Senio',
    code: 'G270',
  },
  {
    name: 'Palena',
    code: 'G271',
  },
  {
    name: 'Palermiti',
    code: 'G272',
  },
  {
    name: 'Palermo',
    code: 'G273',
  },
  {
    name: 'Palestrina',
    code: 'G274',
  },
  {
    name: 'Palestro',
    code: 'G275',
  },
  {
    name: 'Paliano',
    code: 'G276',
  },
  {
    name: 'Palizzi',
    code: 'G277',
  },
  {
    name: 'Pallagorio',
    code: 'G278',
  },
  {
    name: 'Pallanzeno',
    code: 'G280',
  },
  {
    name: 'Pallare',
    code: 'G281',
  },
  {
    name: 'Palma Campania',
    code: 'G283',
  },
  {
    name: 'Palma di Montechiaro',
    code: 'G282',
  },
  {
    name: 'Palmanova',
    code: 'G284',
  },
  {
    name: 'Palmariggi',
    code: 'G285',
  },
  {
    name: 'Palmas Arborea',
    code: 'G286',
  },
  {
    name: 'Palmi',
    code: 'G288',
  },
  {
    name: 'Palmiano',
    code: 'G289',
  },
  {
    name: 'Palmoli',
    code: 'G290',
  },
  {
    name: 'Palo del Colle',
    code: 'G291',
  },
  {
    name: 'Palombara Sabina',
    code: 'G293',
  },
  {
    name: 'Palombaro',
    code: 'G294',
  },
  {
    name: 'Palomonte',
    code: 'G292',
  },
  {
    name: 'Palosco',
    code: 'G295',
  },
  {
    name: 'Palù',
    code: 'G297',
  },
  {
    name: 'Palù del Fersina',
    code: 'G296',
  },
  {
    name: 'Paludi',
    code: 'G298',
  },
  {
    name: 'Paluzza',
    code: 'G300',
  },
  {
    name: 'Pamparato',
    code: 'G302',
  },
  {
    name: 'Pancalieri',
    code: 'G303',
  },
  {
    name: 'Pancarana',
    code: 'G304',
  },
  {
    name: 'Panchià',
    code: 'G305',
  },
  {
    name: 'Pandino',
    code: 'G306',
  },
  {
    name: 'Panettieri',
    code: 'G307',
  },
  {
    name: 'Panicale',
    code: 'G308',
  },
  {
    name: 'Pannarano',
    code: 'G311',
  },
  {
    name: 'Panni',
    code: 'G312',
  },
  {
    name: 'Pantelleria',
    code: 'G315',
  },
  {
    name: 'Pantigliate',
    code: 'G316',
  },
  {
    name: 'Paola',
    code: 'G317',
  },
  {
    name: 'Paolisi',
    code: 'G318',
  },
  {
    name: 'Papasidero',
    code: 'G320',
  },
  {
    name: 'Papozze',
    code: 'G323',
  },
  {
    name: 'Parabiago',
    code: 'G324',
  },
  {
    name: 'Parabita',
    code: 'G325',
  },
  {
    name: 'Paratico',
    code: 'G327',
  },
  {
    name: 'Parcines',
    code: 'G328',
  },
  {
    name: 'Parella',
    code: 'G330',
  },
  {
    name: 'Parenti',
    code: 'G331',
  },
  {
    name: 'Parete',
    code: 'G333',
  },
  {
    name: 'Pareto',
    code: 'G334',
  },
  {
    name: 'Parghelia',
    code: 'G335',
  },
  {
    name: 'Parlasco',
    code: 'G336',
  },
  {
    name: 'Parma',
    code: 'G337',
  },
  {
    name: 'Parodi Ligure',
    code: 'G338',
  },
  {
    name: 'Paroldo',
    code: 'G339',
  },
  {
    name: 'Parolise',
    code: 'G340',
  },
  {
    name: 'Parona',
    code: 'G342',
  },
  {
    name: 'Parrano',
    code: 'G344',
  },
  {
    name: 'Parre',
    code: 'G346',
  },
  {
    name: 'Partanna',
    code: 'G347',
  },
  {
    name: 'Partinico',
    code: 'G348',
  },
  {
    name: 'Paruzzaro',
    code: 'G349',
  },
  {
    name: 'Parzanica',
    code: 'G350',
  },
  {
    name: 'Pasian di Prato',
    code: 'G352',
  },
  {
    name: 'Pasiano di Pordenone',
    code: 'G353',
  },
  {
    name: 'Paspardo',
    code: 'G354',
  },
  {
    name: 'Passerano Marmorito',
    code: 'G358',
  },
  {
    name: 'Passignano sul Trasimeno',
    code: 'G359',
  },
  {
    name: 'Passirano',
    code: 'G361',
  },
  {
    name: 'Pastena',
    code: 'G362',
  },
  {
    name: 'Pastorano',
    code: 'G364',
  },
  {
    name: 'Pastrengo',
    code: 'G365',
  },
  {
    name: 'Pasturana',
    code: 'G367',
  },
  {
    name: 'Pasturo',
    code: 'G368',
  },
  {
    name: 'Paterno',
    code: 'M269',
  },
  {
    name: 'Paternò',
    code: 'G371',
  },
  {
    name: 'Paterno Calabro',
    code: 'G372',
  },
  {
    name: 'Paternopoli',
    code: 'G370',
  },
  {
    name: 'Patrica',
    code: 'G374',
  },
  {
    name: 'Pattada',
    code: 'G376',
  },
  {
    name: 'Patti',
    code: 'G377',
  },
  {
    name: 'Patù',
    code: 'G378',
  },
  {
    name: 'Pau',
    code: 'G379',
  },
  {
    name: 'Paularo',
    code: 'G381',
  },
  {
    name: 'Pauli Arbarei',
    code: 'G382',
  },
  {
    name: 'Paulilatino',
    code: 'G384',
  },
  {
    name: 'Paullo',
    code: 'G385',
  },
  {
    name: 'Paupisi',
    code: 'G386',
  },
  {
    name: 'Pavarolo',
    code: 'G387',
  },
  {
    name: 'Pavia',
    code: 'G388',
  },
  {
    name: 'Pavia di Udine',
    code: 'G389',
  },
  {
    name: 'Pavone Canavese',
    code: 'G392',
  },
  {
    name: 'Pavone del Mella',
    code: 'G391',
  },
  {
    name: 'Pavullo nel Frignano',
    code: 'G393',
  },
  {
    name: 'Pazzano',
    code: 'G394',
  },
  {
    name: 'Peccioli',
    code: 'G395',
  },
  {
    name: 'Pecetto di Valenza',
    code: 'G397',
  },
  {
    name: 'Pecetto Torinese',
    code: 'G398',
  },
  {
    name: 'Pedara',
    code: 'G402',
  },
  {
    name: 'Pedaso',
    code: 'G403',
  },
  {
    name: 'Pedavena',
    code: 'G404',
  },
  {
    name: 'Pedemonte',
    code: 'G406',
  },
  {
    name: 'Pederobba',
    code: 'G408',
  },
  {
    name: 'Pedesina',
    code: 'G410',
  },
  {
    name: 'Pedivigliano',
    code: 'G411',
  },
  {
    name: 'Pedrengo',
    code: 'G412',
  },
  {
    name: 'Peglio',
    code: 'G415',
  },
  {
    name: 'Peglio',
    code: 'G416',
  },
  {
    name: 'Pegognaga',
    code: 'G417',
  },
  {
    name: 'Peia',
    code: 'G418',
  },
  {
    name: 'Peio',
    code: 'G419',
  },
  {
    name: 'Pelago',
    code: 'G420',
  },
  {
    name: 'Pella',
    code: 'G421',
  },
  {
    name: 'Pellegrino Parmense',
    code: 'G424',
  },
  {
    name: 'Pellezzano',
    code: 'G426',
  },
  {
    name: 'Pellizzano',
    code: 'G428',
  },
  {
    name: 'Pelugo',
    code: 'G429',
  },
  {
    name: 'Penango',
    code: 'G430',
  },
  {
    name: 'Penna in Teverina',
    code: 'G432',
  },
  {
    name: 'Penna San Giovanni',
    code: 'G436',
  },
  {
    name: "Penna Sant'Andrea",
    code: 'G437',
  },
  {
    name: 'Pennabilli',
    code: 'G433',
  },
  {
    name: 'Pennadomo',
    code: 'G434',
  },
  {
    name: 'Pennapiedimonte',
    code: 'G435',
  },
  {
    name: 'Penne',
    code: 'G438',
  },
  {
    name: 'Pentone',
    code: 'G439',
  },
  {
    name: 'Perano',
    code: 'G441',
  },
  {
    name: 'Perarolo di Cadore',
    code: 'G442',
  },
  {
    name: 'Perca',
    code: 'G443',
  },
  {
    name: 'Percile',
    code: 'G444',
  },
  {
    name: 'Perdasdefogu',
    code: 'G445',
  },
  {
    name: 'Perdaxius',
    code: 'G446',
  },
  {
    name: 'Perdifumo',
    code: 'G447',
  },
  {
    name: 'Pereto',
    code: 'G449',
  },
  {
    name: 'Perfugas',
    code: 'G450',
  },
  {
    name: 'Pergine Valsugana',
    code: 'G452',
  },
  {
    name: 'Pergola',
    code: 'G453',
  },
  {
    name: 'Perinaldo',
    code: 'G454',
  },
  {
    name: 'Perito',
    code: 'G455',
  },
  {
    name: 'Perledo',
    code: 'G456',
  },
  {
    name: 'Perletto',
    code: 'G457',
  },
  {
    name: 'Perlo',
    code: 'G458',
  },
  {
    name: 'Perloz',
    code: 'G459',
  },
  {
    name: 'Pernumia',
    code: 'G461',
  },
  {
    name: 'Pero',
    code: 'C013',
  },
  {
    name: 'Perosa Argentina',
    code: 'G463',
  },
  {
    name: 'Perosa Canavese',
    code: 'G462',
  },
  {
    name: 'Perrero',
    code: 'G465',
  },
  {
    name: 'Persico Dosimo',
    code: 'G469',
  },
  {
    name: 'Pertengo',
    code: 'G471',
  },
  {
    name: 'Pertica Alta',
    code: 'G474',
  },
  {
    name: 'Pertica Bassa',
    code: 'G475',
  },
  {
    name: 'Pertosa',
    code: 'G476',
  },
  {
    name: 'Pertusio',
    code: 'G477',
  },
  {
    name: 'Perugia',
    code: 'G478',
  },
  {
    name: 'Pesaro',
    code: 'G479',
  },
  {
    name: 'Pescaglia',
    code: 'G480',
  },
  {
    name: 'Pescantina',
    code: 'G481',
  },
  {
    name: 'Pescara',
    code: 'G482',
  },
  {
    name: 'Pescarolo ed Uniti',
    code: 'G483',
  },
  {
    name: 'Pescasseroli',
    code: 'G484',
  },
  {
    name: 'Pescate',
    code: 'G485',
  },
  {
    name: 'Pesche',
    code: 'G486',
  },
  {
    name: 'Peschici',
    code: 'G487',
  },
  {
    name: 'Peschiera Borromeo',
    code: 'G488',
  },
  {
    name: 'Peschiera del Garda',
    code: 'G489',
  },
  {
    name: 'Pescia',
    code: 'G491',
  },
  {
    name: 'Pescina',
    code: 'G492',
  },
  {
    name: 'Pesco Sannita',
    code: 'G494',
  },
  {
    name: 'Pescocostanzo',
    code: 'G493',
  },
  {
    name: 'Pescolanciano',
    code: 'G495',
  },
  {
    name: 'Pescopagano',
    code: 'G496',
  },
  {
    name: 'Pescopennataro',
    code: 'G497',
  },
  {
    name: 'Pescorocchiano',
    code: 'G498',
  },
  {
    name: 'Pescosansonesco',
    code: 'G499',
  },
  {
    name: 'Pescosolido',
    code: 'G500',
  },
  {
    name: 'Pessano con Bornago',
    code: 'G502',
  },
  {
    name: 'Pessina Cremonese',
    code: 'G504',
  },
  {
    name: 'Pessinetto',
    code: 'G505',
  },
  {
    name: 'Petacciato',
    code: 'G506',
  },
  {
    name: 'Petilia Policastro',
    code: 'G508',
  },
  {
    name: 'Petina',
    code: 'G509',
  },
  {
    name: 'Petralia Soprana',
    code: 'G510',
  },
  {
    name: 'Petralia Sottana',
    code: 'G511',
  },
  {
    name: 'Petrella Salto',
    code: 'G513',
  },
  {
    name: 'Petrella Tifernina',
    code: 'G512',
  },
  {
    name: 'Petriano',
    code: 'G514',
  },
  {
    name: 'Petriolo',
    code: 'G515',
  },
  {
    name: 'Petritoli',
    code: 'G516',
  },
  {
    name: 'Petrizzi',
    code: 'G517',
  },
  {
    name: 'Petronà',
    code: 'G518',
  },
  {
    name: 'Petrosino',
    code: 'M281',
  },
  {
    name: 'Petruro Irpino',
    code: 'G519',
  },
  {
    name: 'Pettenasco',
    code: 'G520',
  },
  {
    name: 'Pettinengo',
    code: 'G521',
  },
  {
    name: 'Pettineo',
    code: 'G522',
  },
  {
    name: 'Pettoranello del Molise',
    code: 'G523',
  },
  {
    name: 'Pettorano sul Gizio',
    code: 'G524',
  },
  {
    name: 'Pettorazza Grimani',
    code: 'G525',
  },
  {
    name: 'Peveragno',
    code: 'G526',
  },
  {
    name: 'Pezzana',
    code: 'G528',
  },
  {
    name: 'Pezzaze',
    code: 'G529',
  },
  {
    name: 'Pezzolo Valle Uzzone',
    code: 'G532',
  },
  {
    name: 'Piacenza',
    code: 'G535',
  },
  {
    name: "Piacenza d'Adige",
    code: 'G534',
  },
  {
    name: 'Piadena Drizzona',
    code: 'M418',
  },
  {
    name: 'Piaggine',
    code: 'G538',
  },
  {
    name: 'Pian Camuno',
    code: 'G546',
  },
  {
    name: 'Piana Crixia',
    code: 'G542',
  },
  {
    name: 'Piana degli Albanesi',
    code: 'G543',
  },
  {
    name: 'Piana di Monte Verna',
    code: 'G541',
  },
  {
    name: 'Piancastagnaio',
    code: 'G547',
  },
  {
    name: 'Piancogno',
    code: 'G549',
  },
  {
    name: 'Piandimeleto',
    code: 'G551',
  },
  {
    name: 'Piane Crati',
    code: 'G553',
  },
  {
    name: 'Pianella',
    code: 'G555',
  },
  {
    name: 'Pianello del Lario',
    code: 'G556',
  },
  {
    name: 'Pianello Val Tidone',
    code: 'G557',
  },
  {
    name: 'Pianengo',
    code: 'G558',
  },
  {
    name: 'Pianezza',
    code: 'G559',
  },
  {
    name: 'Pianezze',
    code: 'G560',
  },
  {
    name: 'Pianfei',
    code: 'G561',
  },
  {
    name: 'Pianico',
    code: 'G564',
  },
  {
    name: 'Pianiga',
    code: 'G565',
  },
  {
    name: 'Piano di Sorrento',
    code: 'G568',
  },
  {
    name: 'Pianopoli',
    code: 'D546',
  },
  {
    name: 'Pianoro',
    code: 'G570',
  },
  {
    name: 'Piansano',
    code: 'G571',
  },
  {
    name: 'Piantedo',
    code: 'G572',
  },
  {
    name: 'Piario',
    code: 'G574',
  },
  {
    name: 'Piasco',
    code: 'G575',
  },
  {
    name: 'Piateda',
    code: 'G576',
  },
  {
    name: 'Piatto',
    code: 'G577',
  },
  {
    name: 'Piazza al Serchio',
    code: 'G582',
  },
  {
    name: 'Piazza Armerina',
    code: 'G580',
  },
  {
    name: 'Piazza Brembana',
    code: 'G579',
  },
  {
    name: 'Piazzatorre',
    code: 'G583',
  },
  {
    name: 'Piazzola sul Brenta',
    code: 'G587',
  },
  {
    name: 'Piazzolo',
    code: 'G588',
  },
  {
    name: 'Picciano',
    code: 'G589',
  },
  {
    name: 'Picerno',
    code: 'G590',
  },
  {
    name: 'Picinisco',
    code: 'G591',
  },
  {
    name: 'Pico',
    code: 'G592',
  },
  {
    name: 'Piea',
    code: 'G593',
  },
  {
    name: 'Piedicavallo',
    code: 'G594',
  },
  {
    name: 'Piedimonte Etneo',
    code: 'G597',
  },
  {
    name: 'Piedimonte Matese',
    code: 'G596',
  },
  {
    name: 'Piedimonte San Germano',
    code: 'G598',
  },
  {
    name: 'Piedimulera',
    code: 'G600',
  },
  {
    name: 'Piegaro',
    code: 'G601',
  },
  {
    name: 'Pienza',
    code: 'G602',
  },
  {
    name: 'Pieranica',
    code: 'G603',
  },
  {
    name: "Pietra de' Giorgi",
    code: 'G612',
  },
  {
    name: 'Pietra Ligure',
    code: 'G605',
  },
  {
    name: 'Pietra Marazzi',
    code: 'G619',
  },
  {
    name: 'Pietrabbondante',
    code: 'G606',
  },
  {
    name: 'Pietrabruna',
    code: 'G607',
  },
  {
    name: 'Pietracamela',
    code: 'G608',
  },
  {
    name: 'Pietracatella',
    code: 'G609',
  },
  {
    name: 'Pietracupa',
    code: 'G610',
  },
  {
    name: 'Pietradefusi',
    code: 'G611',
  },
  {
    name: 'Pietraferrazzana',
    code: 'G613',
  },
  {
    name: 'Pietrafitta',
    code: 'G615',
  },
  {
    name: 'Pietragalla',
    code: 'G616',
  },
  {
    name: 'Pietralunga',
    code: 'G618',
  },
  {
    name: 'Pietramelara',
    code: 'G620',
  },
  {
    name: 'Pietramontecorvino',
    code: 'G604',
  },
  {
    name: 'Pietranico',
    code: 'G621',
  },
  {
    name: 'Pietrapaola',
    code: 'G622',
  },
  {
    name: 'Pietrapertosa',
    code: 'G623',
  },
  {
    name: 'Pietraperzia',
    code: 'G624',
  },
  {
    name: 'Pietraporzio',
    code: 'G625',
  },
  {
    name: 'Pietraroja',
    code: 'G626',
  },
  {
    name: 'Pietrarubbia',
    code: 'G627',
  },
  {
    name: 'Pietrasanta',
    code: 'G628',
  },
  {
    name: 'Pietrastornina',
    code: 'G629',
  },
  {
    name: 'Pietravairano',
    code: 'G630',
  },
  {
    name: 'Pietrelcina',
    code: 'G631',
  },
  {
    name: 'Pieve a Nievole',
    code: 'G636',
  },
  {
    name: 'Pieve Albignola',
    code: 'G635',
  },
  {
    name: "Pieve d'Olmi",
    code: 'G647',
  },
  {
    name: 'Pieve del Cairo',
    code: 'G639',
  },
  {
    name: 'Pieve del Grappa',
    code: 'M422',
  },
  {
    name: 'Pieve di Bono-Prezzo',
    code: 'M365',
  },
  {
    name: 'Pieve di Cadore',
    code: 'G642',
  },
  {
    name: 'Pieve di Cento',
    code: 'G643',
  },
  {
    name: 'Pieve di Soligo',
    code: 'G645',
  },
  {
    name: 'Pieve di Teco',
    code: 'G632',
  },
  {
    name: 'Pieve Emanuele',
    code: 'G634',
  },
  {
    name: 'Pieve Fissiraga',
    code: 'G096',
  },
  {
    name: 'Pieve Fosciana',
    code: 'G648',
  },
  {
    name: 'Pieve Ligure',
    code: 'G646',
  },
  {
    name: 'Pieve Porto Morone',
    code: 'G650',
  },
  {
    name: 'Pieve San Giacomo',
    code: 'G651',
  },
  {
    name: 'Pieve Santo Stefano',
    code: 'G653',
  },
  {
    name: 'Pieve Tesino',
    code: 'G656',
  },
  {
    name: 'Pieve Torina',
    code: 'G657',
  },
  {
    name: 'Pieve Vergonte',
    code: 'G658',
  },
  {
    name: 'Pievepelago',
    code: 'G649',
  },
  {
    name: 'Piglio',
    code: 'G659',
  },
  {
    name: 'Pigna',
    code: 'G660',
  },
  {
    name: 'Pignataro Interamna',
    code: 'G662',
  },
  {
    name: 'Pignataro Maggiore',
    code: 'G661',
  },
  {
    name: 'Pignola',
    code: 'G663',
  },
  {
    name: 'Pignone',
    code: 'G664',
  },
  {
    name: 'Pigra',
    code: 'G665',
  },
  {
    name: 'Pila',
    code: 'G666',
  },
  {
    name: 'Pimentel',
    code: 'G669',
  },
  {
    name: 'Pimonte',
    code: 'G670',
  },
  {
    name: 'Pinarolo Po',
    code: 'G671',
  },
  {
    name: 'Pinasca',
    code: 'G672',
  },
  {
    name: 'Pincara',
    code: 'G673',
  },
  {
    name: 'Pinerolo',
    code: 'G674',
  },
  {
    name: 'Pineto',
    code: 'F831',
  },
  {
    name: "Pino d'Asti",
    code: 'G676',
  },
  {
    name: 'Pino Torinese',
    code: 'G678',
  },
  {
    name: 'Pinzano al Tagliamento',
    code: 'G680',
  },
  {
    name: 'Pinzolo',
    code: 'G681',
  },
  {
    name: 'Piobbico',
    code: 'G682',
  },
  {
    name: "Piobesi d'Alba",
    code: 'G683',
  },
  {
    name: 'Piobesi Torinese',
    code: 'G684',
  },
  {
    name: 'Piode',
    code: 'G685',
  },
  {
    name: 'Pioltello',
    code: 'G686',
  },
  {
    name: 'Piombino',
    code: 'G687',
  },
  {
    name: 'Piombino Dese',
    code: 'G688',
  },
  {
    name: 'Pioraco',
    code: 'G690',
  },
  {
    name: 'Piossasco',
    code: 'G691',
  },
  {
    name: 'Piovà Massaia',
    code: 'G692',
  },
  {
    name: 'Piove di Sacco',
    code: 'G693',
  },
  {
    name: 'Piovene Rocchette',
    code: 'G694',
  },
  {
    name: 'Piozzano',
    code: 'G696',
  },
  {
    name: 'Piozzo',
    code: 'G697',
  },
  {
    name: 'Piraino',
    code: 'G699',
  },
  {
    name: 'Pisa',
    code: 'G702',
  },
  {
    name: 'Pisano',
    code: 'G703',
  },
  {
    name: 'Piscina',
    code: 'G705',
  },
  {
    name: 'Piscinas',
    code: 'M291',
  },
  {
    name: 'Pisciotta',
    code: 'G707',
  },
  {
    name: 'Pisogne',
    code: 'G710',
  },
  {
    name: 'Pisoniano',
    code: 'G704',
  },
  {
    name: 'Pisticci',
    code: 'G712',
  },
  {
    name: 'Pistoia',
    code: 'G713',
  },
  {
    name: 'Pitigliano',
    code: 'G716',
  },
  {
    name: 'Piubega',
    code: 'G717',
  },
  {
    name: 'Piuro',
    code: 'G718',
  },
  {
    name: 'Piverone',
    code: 'G719',
  },
  {
    name: 'Pizzale',
    code: 'G720',
  },
  {
    name: 'Pizzighettone',
    code: 'G721',
  },
  {
    name: 'Pizzo',
    code: 'G722',
  },
  {
    name: 'Pizzoferrato',
    code: 'G724',
  },
  {
    name: 'Pizzoli',
    code: 'G726',
  },
  {
    name: 'Pizzone',
    code: 'G727',
  },
  {
    name: 'Pizzoni',
    code: 'G728',
  },
  {
    name: 'Placanica',
    code: 'G729',
  },
  {
    name: 'Plataci',
    code: 'G733',
  },
  {
    name: 'Platania',
    code: 'G734',
  },
  {
    name: 'Platì',
    code: 'G735',
  },
  {
    name: 'Plaus',
    code: 'G299',
  },
  {
    name: 'Plesio',
    code: 'G737',
  },
  {
    name: 'Ploaghe',
    code: 'G740',
  },
  {
    name: 'Plodio',
    code: 'G741',
  },
  {
    name: 'Pocapaglia',
    code: 'G742',
  },
  {
    name: 'Pocenia',
    code: 'G743',
  },
  {
    name: 'Podenzana',
    code: 'G746',
  },
  {
    name: 'Podenzano',
    code: 'G747',
  },
  {
    name: 'Pofi',
    code: 'G749',
  },
  {
    name: 'Poggiardo',
    code: 'G751',
  },
  {
    name: 'Poggibonsi',
    code: 'G752',
  },
  {
    name: 'Poggio a Caiano',
    code: 'G754',
  },
  {
    name: 'Poggio Bustone',
    code: 'G756',
  },
  {
    name: 'Poggio Catino',
    code: 'G757',
  },
  {
    name: 'Poggio Imperiale',
    code: 'G761',
  },
  {
    name: 'Poggio Mirteto',
    code: 'G763',
  },
  {
    name: 'Poggio Moiano',
    code: 'G764',
  },
  {
    name: 'Poggio Nativo',
    code: 'G765',
  },
  {
    name: 'Poggio Picenze',
    code: 'G766',
  },
  {
    name: 'Poggio Renatico',
    code: 'G768',
  },
  {
    name: 'Poggio Rusco',
    code: 'G753',
  },
  {
    name: 'Poggio San Lorenzo',
    code: 'G770',
  },
  {
    name: 'Poggio San Marcello',
    code: 'G771',
  },
  {
    name: 'Poggio San Vicino',
    code: 'D566',
  },
  {
    name: 'Poggio Sannita',
    code: 'B317',
  },
  {
    name: 'Poggio Torriana',
    code: 'M324',
  },
  {
    name: 'Poggiodomo',
    code: 'G758',
  },
  {
    name: 'Poggiofiorito',
    code: 'G760',
  },
  {
    name: 'Poggiomarino',
    code: 'G762',
  },
  {
    name: 'Poggioreale',
    code: 'G767',
  },
  {
    name: 'Poggiorsini',
    code: 'G769',
  },
  {
    name: 'Poggiridenti',
    code: 'G431',
  },
  {
    name: 'Pogliano Milanese',
    code: 'G772',
  },
  {
    name: 'Pognana Lario',
    code: 'G773',
  },
  {
    name: 'Pognano',
    code: 'G774',
  },
  {
    name: 'Pogno',
    code: 'G775',
  },
  {
    name: 'Poirino',
    code: 'G777',
  },
  {
    name: 'Pojana Maggiore',
    code: 'G776',
  },
  {
    name: 'Polaveno',
    code: 'G779',
  },
  {
    name: 'Polcenigo',
    code: 'G780',
  },
  {
    name: 'Polesella',
    code: 'G782',
  },
  {
    name: 'Polesine Zibello',
    code: 'M367',
  },
  {
    name: 'Poli',
    code: 'G784',
  },
  {
    name: 'Polia',
    code: 'G785',
  },
  {
    name: 'Policoro',
    code: 'G786',
  },
  {
    name: 'Polignano a Mare',
    code: 'G787',
  },
  {
    name: 'Polinago',
    code: 'G789',
  },
  {
    name: 'Polino',
    code: 'G790',
  },
  {
    name: 'Polistena',
    code: 'G791',
  },
  {
    name: 'Polizzi Generosa',
    code: 'G792',
  },
  {
    name: 'Polla',
    code: 'G793',
  },
  {
    name: 'Pollein',
    code: 'G794',
  },
  {
    name: 'Pollena Trocchia',
    code: 'G795',
  },
  {
    name: 'Pollenza',
    code: 'F567',
  },
  {
    name: 'Pollica',
    code: 'G796',
  },
  {
    name: 'Pollina',
    code: 'G797',
  },
  {
    name: 'Pollone',
    code: 'G798',
  },
  {
    name: 'Pollutri',
    code: 'G799',
  },
  {
    name: 'Polonghera',
    code: 'G800',
  },
  {
    name: 'Polpenazze del Garda',
    code: 'G801',
  },
  {
    name: 'Polverara',
    code: 'G802',
  },
  {
    name: 'Polverigi',
    code: 'G803',
  },
  {
    name: 'Pomarance',
    code: 'G804',
  },
  {
    name: 'Pomaretto',
    code: 'G805',
  },
  {
    name: 'Pomarico',
    code: 'G806',
  },
  {
    name: 'Pomaro Monferrato',
    code: 'G807',
  },
  {
    name: 'Pomarolo',
    code: 'G808',
  },
  {
    name: 'Pombia',
    code: 'G809',
  },
  {
    name: 'Pomezia',
    code: 'G811',
  },
  {
    name: "Pomigliano d'Arco",
    code: 'G812',
  },
  {
    name: 'Pompei',
    code: 'G813',
  },
  {
    name: 'Pompeiana',
    code: 'G814',
  },
  {
    name: 'Pompiano',
    code: 'G815',
  },
  {
    name: 'Pomponesco',
    code: 'G816',
  },
  {
    name: 'Pompu',
    code: 'G817',
  },
  {
    name: 'Poncarale',
    code: 'G818',
  },
  {
    name: 'Ponderano',
    code: 'G820',
  },
  {
    name: 'Ponna',
    code: 'G821',
  },
  {
    name: 'Ponsacco',
    code: 'G822',
  },
  {
    name: 'Ponso',
    code: 'G823',
  },
  {
    name: 'Pont-Canavese',
    code: 'G826',
  },
  {
    name: 'Pont-Saint-Martin',
    code: 'G854',
  },
  {
    name: 'Pontassieve',
    code: 'G825',
  },
  {
    name: 'Pontboset',
    code: 'G545',
  },
  {
    name: 'Ponte',
    code: 'G827',
  },
  {
    name: 'Ponte Buggianese',
    code: 'G833',
  },
  {
    name: "Ponte dell'Olio",
    code: 'G842',
  },
  {
    name: 'Ponte di Legno',
    code: 'G844',
  },
  {
    name: 'Ponte di Piave',
    code: 'G846',
  },
  {
    name: 'Ponte Gardena',
    code: 'G830',
  },
  {
    name: 'Ponte in Valtellina',
    code: 'G829',
  },
  {
    name: 'Ponte Lambro',
    code: 'G847',
  },
  {
    name: 'Ponte nelle Alpi',
    code: 'B662',
  },
  {
    name: 'Ponte Nizza',
    code: 'G851',
  },
  {
    name: 'Ponte Nossa',
    code: 'F941',
  },
  {
    name: 'Ponte San Nicolò',
    code: 'G855',
  },
  {
    name: 'Ponte San Pietro',
    code: 'G856',
  },
  {
    name: 'Pontebba',
    code: 'G831',
  },
  {
    name: 'Pontecagnano Faiano',
    code: 'G834',
  },
  {
    name: 'Pontecchio Polesine',
    code: 'G836',
  },
  {
    name: 'Pontechianale',
    code: 'G837',
  },
  {
    name: 'Pontecorvo',
    code: 'G838',
  },
  {
    name: 'Pontecurone',
    code: 'G839',
  },
  {
    name: 'Pontedassio',
    code: 'G840',
  },
  {
    name: 'Pontedera',
    code: 'G843',
  },
  {
    name: 'Pontelandolfo',
    code: 'G848',
  },
  {
    name: 'Pontelatone',
    code: 'G849',
  },
  {
    name: 'Pontelongo',
    code: 'G850',
  },
  {
    name: 'Pontenure',
    code: 'G852',
  },
  {
    name: 'Ponteranica',
    code: 'G853',
  },
  {
    name: 'Pontestura',
    code: 'G858',
  },
  {
    name: 'Pontevico',
    code: 'G859',
  },
  {
    name: 'Pontey',
    code: 'G860',
  },
  {
    name: 'Ponti',
    code: 'G861',
  },
  {
    name: 'Ponti sul Mincio',
    code: 'G862',
  },
  {
    name: 'Pontida',
    code: 'G864',
  },
  {
    name: 'Pontinia',
    code: 'G865',
  },
  {
    name: 'Pontinvrea',
    code: 'G866',
  },
  {
    name: 'Pontirolo Nuovo',
    code: 'G867',
  },
  {
    name: 'Pontoglio',
    code: 'G869',
  },
  {
    name: 'Pontremoli',
    code: 'G870',
  },
  {
    name: 'Ponza',
    code: 'G871',
  },
  {
    name: 'Ponzano di Fermo',
    code: 'G873',
  },
  {
    name: 'Ponzano Monferrato',
    code: 'G872',
  },
  {
    name: 'Ponzano Romano',
    code: 'G874',
  },
  {
    name: 'Ponzano Veneto',
    code: 'G875',
  },
  {
    name: 'Ponzone',
    code: 'G877',
  },
  {
    name: 'Popoli',
    code: 'G878',
  },
  {
    name: 'Poppi',
    code: 'G879',
  },
  {
    name: 'Porano',
    code: 'G881',
  },
  {
    name: 'Porcari',
    code: 'G882',
  },
  {
    name: 'Porcia',
    code: 'G886',
  },
  {
    name: 'Pordenone',
    code: 'G888',
  },
  {
    name: 'Porlezza',
    code: 'G889',
  },
  {
    name: 'Pornassio',
    code: 'G890',
  },
  {
    name: 'Porpetto',
    code: 'G891',
  },
  {
    name: 'Portacomaro',
    code: 'G894',
  },
  {
    name: 'Portalbera',
    code: 'G895',
  },
  {
    name: 'Porte',
    code: 'G900',
  },
  {
    name: 'Porte di Rendena',
    code: 'M358',
  },
  {
    name: 'Portici',
    code: 'G902',
  },
  {
    name: 'Portico di Caserta',
    code: 'G903',
  },
  {
    name: 'Portico e San Benedetto',
    code: 'G904',
  },
  {
    name: 'Portigliola',
    code: 'G905',
  },
  {
    name: 'Porto Azzurro',
    code: 'E680',
  },
  {
    name: 'Porto Ceresio',
    code: 'G906',
  },
  {
    name: 'Porto Cesareo',
    code: 'M263',
  },
  {
    name: 'Porto Empedocle',
    code: 'F299',
  },
  {
    name: 'Porto Mantovano',
    code: 'G917',
  },
  {
    name: 'Porto Recanati',
    code: 'G919',
  },
  {
    name: 'Porto San Giorgio',
    code: 'G920',
  },
  {
    name: "Porto Sant'Elpidio",
    code: 'G921',
  },
  {
    name: 'Porto Tolle',
    code: 'G923',
  },
  {
    name: 'Porto Torres',
    code: 'G924',
  },
  {
    name: 'Porto Valtravaglia',
    code: 'G907',
  },
  {
    name: 'Porto Viro',
    code: 'G926',
  },
  {
    name: 'Portobuffolè',
    code: 'G909',
  },
  {
    name: 'Portocannone',
    code: 'G910',
  },
  {
    name: 'Portoferraio',
    code: 'G912',
  },
  {
    name: 'Portofino',
    code: 'G913',
  },
  {
    name: 'Portogruaro',
    code: 'G914',
  },
  {
    name: 'Portomaggiore',
    code: 'G916',
  },
  {
    name: 'Portopalo di Capo Passero',
    code: 'M257',
  },
  {
    name: 'Portoscuso',
    code: 'G922',
  },
  {
    name: 'Portovenere',
    code: 'G925',
  },
  {
    name: 'Portula',
    code: 'G927',
  },
  {
    name: 'Posada',
    code: 'G929',
  },
  {
    name: 'Posina',
    code: 'G931',
  },
  {
    name: 'Positano',
    code: 'G932',
  },
  {
    name: 'Possagno',
    code: 'G933',
  },
  {
    name: 'Posta',
    code: 'G934',
  },
  {
    name: 'Posta Fibreno',
    code: 'G935',
  },
  {
    name: 'Postal',
    code: 'G936',
  },
  {
    name: 'Postalesio',
    code: 'G937',
  },
  {
    name: 'Postiglione',
    code: 'G939',
  },
  {
    name: 'Postua',
    code: 'G940',
  },
  {
    name: 'Potenza',
    code: 'G942',
  },
  {
    name: 'Potenza Picena',
    code: 'F632',
  },
  {
    name: 'Pove del Grappa',
    code: 'G943',
  },
  {
    name: 'Povegliano',
    code: 'G944',
  },
  {
    name: 'Povegliano Veronese',
    code: 'G945',
  },
  {
    name: 'Poviglio',
    code: 'G947',
  },
  {
    name: 'Povoletto',
    code: 'G949',
  },
  {
    name: 'Pozzaglia Sabina',
    code: 'G951',
  },
  {
    name: 'Pozzaglio ed Uniti',
    code: 'B914',
  },
  {
    name: 'Pozzallo',
    code: 'G953',
  },
  {
    name: 'Pozzilli',
    code: 'G954',
  },
  {
    name: "Pozzo d'Adda",
    code: 'G955',
  },
  {
    name: 'Pozzol Groppo',
    code: 'G960',
  },
  {
    name: 'Pozzolengo',
    code: 'G959',
  },
  {
    name: 'Pozzoleone',
    code: 'G957',
  },
  {
    name: 'Pozzolo Formigaro',
    code: 'G961',
  },
  {
    name: 'Pozzomaggiore',
    code: 'G962',
  },
  {
    name: 'Pozzonovo',
    code: 'G963',
  },
  {
    name: 'Pozzuoli',
    code: 'G964',
  },
  {
    name: 'Pozzuolo del Friuli',
    code: 'G966',
  },
  {
    name: 'Pozzuolo Martesana',
    code: 'G965',
  },
  {
    name: 'Pradalunga',
    code: 'G968',
  },
  {
    name: 'Pradamano',
    code: 'G969',
  },
  {
    name: 'Pradleves',
    code: 'G970',
  },
  {
    name: 'Pragelato',
    code: 'G973',
  },
  {
    name: 'Praia a Mare',
    code: 'G975',
  },
  {
    name: 'Praiano',
    code: 'G976',
  },
  {
    name: 'Pralboino',
    code: 'G977',
  },
  {
    name: 'Prali',
    code: 'G978',
  },
  {
    name: 'Pralormo',
    code: 'G979',
  },
  {
    name: 'Pralungo',
    code: 'G980',
  },
  {
    name: 'Pramaggiore',
    code: 'G981',
  },
  {
    name: 'Pramollo',
    code: 'G982',
  },
  {
    name: 'Prarolo',
    code: 'G985',
  },
  {
    name: 'Prarostino',
    code: 'G986',
  },
  {
    name: 'Prasco',
    code: 'G987',
  },
  {
    name: 'Prascorsano',
    code: 'G988',
  },
  {
    name: 'Prata Camportaccio',
    code: 'G993',
  },
  {
    name: "Prata d'Ansidonia",
    code: 'G992',
  },
  {
    name: 'Prata di Pordenone',
    code: 'G994',
  },
  {
    name: 'Prata di Principato Ultra',
    code: 'G990',
  },
  {
    name: 'Prata Sannita',
    code: 'G991',
  },
  {
    name: 'Pratella',
    code: 'G995',
  },
  {
    name: 'Pratiglione',
    code: 'G997',
  },
  {
    name: 'Prato',
    code: 'G999',
  },
  {
    name: 'Prato allo Stelvio',
    code: 'H004',
  },
  {
    name: 'Prato Carnico',
    code: 'H002',
  },
  {
    name: 'Prato Sesia',
    code: 'H001',
  },
  {
    name: 'Pratola Peligna',
    code: 'H007',
  },
  {
    name: 'Pratola Serra',
    code: 'H006',
  },
  {
    name: 'Pratovecchio Stia',
    code: 'M329',
  },
  {
    name: 'Pravisdomini',
    code: 'H010',
  },
  {
    name: 'Pray',
    code: 'G974',
  },
  {
    name: 'Prazzo',
    code: 'H011',
  },
  {
    name: 'Pré-Saint-Didier',
    code: 'H042',
  },
  {
    name: 'Precenicco',
    code: 'H014',
  },
  {
    name: 'Preci',
    code: 'H015',
  },
  {
    name: 'Predaia',
    code: 'M344',
  },
  {
    name: 'Predappio',
    code: 'H017',
  },
  {
    name: 'Predazzo',
    code: 'H018',
  },
  {
    name: 'Predoi',
    code: 'H019',
  },
  {
    name: 'Predore',
    code: 'H020',
  },
  {
    name: 'Predosa',
    code: 'H021',
  },
  {
    name: 'Preganziol',
    code: 'H022',
  },
  {
    name: 'Pregnana Milanese',
    code: 'H026',
  },
  {
    name: 'Prelà',
    code: 'H027',
  },
  {
    name: 'Premana',
    code: 'H028',
  },
  {
    name: 'Premariacco',
    code: 'H029',
  },
  {
    name: 'Premeno',
    code: 'H030',
  },
  {
    name: 'Premia',
    code: 'H033',
  },
  {
    name: 'Premilcuore',
    code: 'H034',
  },
  {
    name: 'Premolo',
    code: 'H036',
  },
  {
    name: 'Premosello-Chiovenda',
    code: 'H037',
  },
  {
    name: 'Preone',
    code: 'H038',
  },
  {
    name: 'Prepotto',
    code: 'H040',
  },
  {
    name: 'Preseglie',
    code: 'H043',
  },
  {
    name: 'Presenzano',
    code: 'H045',
  },
  {
    name: 'Presezzo',
    code: 'H046',
  },
  {
    name: 'Presicce-Acquarica',
    code: 'M428',
  },
  {
    name: 'Pressana',
    code: 'H048',
  },
  {
    name: 'Pretoro',
    code: 'H052',
  },
  {
    name: 'Prevalle',
    code: 'H055',
  },
  {
    name: 'Prezza',
    code: 'H056',
  },
  {
    name: 'Priero',
    code: 'H059',
  },
  {
    name: 'Prignano Cilento',
    code: 'H062',
  },
  {
    name: 'Prignano sulla Secchia',
    code: 'H061',
  },
  {
    name: 'Primaluna',
    code: 'H063',
  },
  {
    name: 'Primiero San Martino di Castrozza',
    code: 'M359',
  },
  {
    name: 'Priocca',
    code: 'H068',
  },
  {
    name: 'Priola',
    code: 'H069',
  },
  {
    name: 'Priolo Gargallo',
    code: 'M279',
  },
  {
    name: 'Priverno',
    code: 'G698',
  },
  {
    name: 'Prizzi',
    code: 'H070',
  },
  {
    name: 'Proceno',
    code: 'H071',
  },
  {
    name: 'Procida',
    code: 'H072',
  },
  {
    name: 'Propata',
    code: 'H073',
  },
  {
    name: 'Proserpio',
    code: 'H074',
  },
  {
    name: 'Prossedi',
    code: 'H076',
  },
  {
    name: "Provaglio d'Iseo",
    code: 'H078',
  },
  {
    name: 'Provaglio Val Sabbia',
    code: 'H077',
  },
  {
    name: 'Proves',
    code: 'H081',
  },
  {
    name: 'Provvidenti',
    code: 'H083',
  },
  {
    name: 'Prunetto',
    code: 'H085',
  },
  {
    name: 'Puegnago del Garda',
    code: 'H086',
  },
  {
    name: 'Puglianello',
    code: 'H087',
  },
  {
    name: 'Pula',
    code: 'H088',
  },
  {
    name: 'Pulfero',
    code: 'H089',
  },
  {
    name: 'Pulsano',
    code: 'H090',
  },
  {
    name: 'Pumenengo',
    code: 'H091',
  },
  {
    name: 'Pusiano',
    code: 'H094',
  },
  {
    name: 'Putifigari',
    code: 'H095',
  },
  {
    name: 'Putignano',
    code: 'H096',
  },
  {
    name: 'Quadrelle',
    code: 'H097',
  },
  {
    name: 'Quadri',
    code: 'H098',
  },
  {
    name: 'Quagliuzzo',
    code: 'H100',
  },
  {
    name: 'Qualiano',
    code: 'H101',
  },
  {
    name: 'Quaranti',
    code: 'H102',
  },
  {
    name: 'Quaregna Cerreto',
    code: 'M414',
  },
  {
    name: 'Quargnento',
    code: 'H104',
  },
  {
    name: 'Quarna Sopra',
    code: 'H106',
  },
  {
    name: 'Quarna Sotto',
    code: 'H107',
  },
  {
    name: 'Quarona',
    code: 'H108',
  },
  {
    name: 'Quarrata',
    code: 'H109',
  },
  {
    name: 'Quart',
    code: 'H110',
  },
  {
    name: 'Quarto',
    code: 'H114',
  },
  {
    name: "Quarto d'Altino",
    code: 'H117',
  },
  {
    name: "Quartu Sant'Elena",
    code: 'H118',
  },
  {
    name: 'Quartucciu',
    code: 'H119',
  },
  {
    name: 'Quassolo',
    code: 'H120',
  },
  {
    name: 'Quattordio',
    code: 'H121',
  },
  {
    name: 'Quattro Castella',
    code: 'H122',
  },
  {
    name: 'Quero Vas',
    code: 'M332',
  },
  {
    name: 'Quiliano',
    code: 'H126',
  },
  {
    name: 'Quincinetto',
    code: 'H127',
  },
  {
    name: 'Quindici',
    code: 'H128',
  },
  {
    name: 'Quingentole',
    code: 'H129',
  },
  {
    name: 'Quintano',
    code: 'H130',
  },
  {
    name: 'Quinto di Treviso',
    code: 'H131',
  },
  {
    name: 'Quinto Vercellese',
    code: 'H132',
  },
  {
    name: 'Quinto Vicentino',
    code: 'H134',
  },
  {
    name: "Quinzano d'Oglio",
    code: 'H140',
  },
  {
    name: 'Quistello',
    code: 'H143',
  },
  {
    name: 'Rabbi',
    code: 'H146',
  },
  {
    name: 'Racale',
    code: 'H147',
  },
  {
    name: 'Racalmuto',
    code: 'H148',
  },
  {
    name: 'Racconigi',
    code: 'H150',
  },
  {
    name: 'Raccuja',
    code: 'H151',
  },
  {
    name: 'Racines',
    code: 'H152',
  },
  {
    name: 'Radda in Chianti',
    code: 'H153',
  },
  {
    name: 'Raddusa',
    code: 'H154',
  },
  {
    name: 'Radicofani',
    code: 'H156',
  },
  {
    name: 'Radicondoli',
    code: 'H157',
  },
  {
    name: 'Raffadali',
    code: 'H159',
  },
  {
    name: 'Ragalna',
    code: 'M287',
  },
  {
    name: 'Ragogna',
    code: 'H161',
  },
  {
    name: 'Ragusa',
    code: 'H163',
  },
  {
    name: 'Raiano',
    code: 'H166',
  },
  {
    name: 'Ramacca',
    code: 'H168',
  },
  {
    name: 'Rancio Valcuvia',
    code: 'H173',
  },
  {
    name: 'Ranco',
    code: 'H174',
  },
  {
    name: 'Randazzo',
    code: 'H175',
  },
  {
    name: 'Ranica',
    code: 'H176',
  },
  {
    name: 'Ranzanico',
    code: 'H177',
  },
  {
    name: 'Ranzo',
    code: 'H180',
  },
  {
    name: 'Rapagnano',
    code: 'H182',
  },
  {
    name: 'Rapallo',
    code: 'H183',
  },
  {
    name: 'Rapino',
    code: 'H184',
  },
  {
    name: 'Rapolano Terme',
    code: 'H185',
  },
  {
    name: 'Rapolla',
    code: 'H186',
  },
  {
    name: 'Rapone',
    code: 'H187',
  },
  {
    name: 'Rassa',
    code: 'H188',
  },
  {
    name: 'Rasun-Anterselva',
    code: 'H189',
  },
  {
    name: 'Rasura',
    code: 'H192',
  },
  {
    name: 'Ravanusa',
    code: 'H194',
  },
  {
    name: 'Ravarino',
    code: 'H195',
  },
  {
    name: 'Ravascletto',
    code: 'H196',
  },
  {
    name: 'Ravello',
    code: 'H198',
  },
  {
    name: 'Ravenna',
    code: 'H199',
  },
  {
    name: 'Raveo',
    code: 'H200',
  },
  {
    name: 'Raviscanina',
    code: 'H202',
  },
  {
    name: 'Re',
    code: 'H203',
  },
  {
    name: 'Rea',
    code: 'H204',
  },
  {
    name: 'Realmonte',
    code: 'H205',
  },
  {
    name: 'Reana del Rojale',
    code: 'H206',
  },
  {
    name: 'Reano',
    code: 'H207',
  },
  {
    name: 'Recale',
    code: 'H210',
  },
  {
    name: 'Recanati',
    code: 'H211',
  },
  {
    name: 'Recco',
    code: 'H212',
  },
  {
    name: 'Recetto',
    code: 'H213',
  },
  {
    name: 'Recoaro Terme',
    code: 'H214',
  },
  {
    name: 'Redavalle',
    code: 'H216',
  },
  {
    name: 'Redondesco',
    code: 'H218',
  },
  {
    name: 'Refrancore',
    code: 'H219',
  },
  {
    name: 'Refrontolo',
    code: 'H220',
  },
  {
    name: 'Regalbuto',
    code: 'H221',
  },
  {
    name: 'Reggello',
    code: 'H222',
  },
  {
    name: 'Reggio di Calabria',
    code: 'H224',
  },
  {
    name: "Reggio nell'Emilia",
    code: 'H223',
  },
  {
    name: 'Reggiolo',
    code: 'H225',
  },
  {
    name: 'Reino',
    code: 'H227',
  },
  {
    name: 'Reitano',
    code: 'H228',
  },
  {
    name: 'Remanzacco',
    code: 'H229',
  },
  {
    name: 'Remedello',
    code: 'H230',
  },
  {
    name: 'Renate',
    code: 'H233',
  },
  {
    name: 'Rende',
    code: 'H235',
  },
  {
    name: 'Renon',
    code: 'H236',
  },
  {
    name: 'Resana',
    code: 'H238',
  },
  {
    name: 'Rescaldina',
    code: 'H240',
  },
  {
    name: 'Resia',
    code: 'H242',
  },
  {
    name: 'Resiutta',
    code: 'H244',
  },
  {
    name: 'Resuttano',
    code: 'H245',
  },
  {
    name: 'Retorbido',
    code: 'H246',
  },
  {
    name: 'Revello',
    code: 'H247',
  },
  {
    name: "Revigliasco d'Asti",
    code: 'H250',
  },
  {
    name: 'Revine Lago',
    code: 'H253',
  },
  {
    name: 'Rezzago',
    code: 'H255',
  },
  {
    name: 'Rezzato',
    code: 'H256',
  },
  {
    name: 'Rezzo',
    code: 'H257',
  },
  {
    name: 'Rezzoaglio',
    code: 'H258',
  },
  {
    name: 'Rhêmes-Notre-Dame',
    code: 'H262',
  },
  {
    name: 'Rhêmes-Saint-Georges',
    code: 'H263',
  },
  {
    name: 'Rho',
    code: 'H264',
  },
  {
    name: 'Riace',
    code: 'H265',
  },
  {
    name: 'Rialto',
    code: 'H266',
  },
  {
    name: 'Riano',
    code: 'H267',
  },
  {
    name: 'Riardo',
    code: 'H268',
  },
  {
    name: 'Ribera',
    code: 'H269',
  },
  {
    name: 'Ribordone',
    code: 'H270',
  },
  {
    name: 'Ricadi',
    code: 'H271',
  },
  {
    name: 'Ricaldone',
    code: 'H272',
  },
  {
    name: 'Riccia',
    code: 'H273',
  },
  {
    name: 'Riccione',
    code: 'H274',
  },
  {
    name: 'Riccò del Golfo di Spezia',
    code: 'H275',
  },
  {
    name: 'Ricengo',
    code: 'H276',
  },
  {
    name: 'Ricigliano',
    code: 'H277',
  },
  {
    name: 'Riese Pio X',
    code: 'H280',
  },
  {
    name: 'Riesi',
    code: 'H281',
  },
  {
    name: 'Rieti',
    code: 'H282',
  },
  {
    name: 'Rifiano',
    code: 'H284',
  },
  {
    name: 'Rifreddo',
    code: 'H285',
  },
  {
    name: 'Rignano Flaminio',
    code: 'H288',
  },
  {
    name: 'Rignano Garganico',
    code: 'H287',
  },
  {
    name: "Rignano sull'Arno",
    code: 'H286',
  },
  {
    name: 'Rigolato',
    code: 'H289',
  },
  {
    name: 'Rimella',
    code: 'H293',
  },
  {
    name: 'Rimini',
    code: 'H294',
  },
  {
    name: 'Rio',
    code: 'M391',
  },
  {
    name: 'Rio di Pusteria',
    code: 'H299',
  },
  {
    name: 'Rio Saliceto',
    code: 'H298',
  },
  {
    name: 'Riofreddo',
    code: 'H300',
  },
  {
    name: 'Riola Sardo',
    code: 'H301',
  },
  {
    name: 'Riolo Terme',
    code: 'H302',
  },
  {
    name: 'Riolunato',
    code: 'H303',
  },
  {
    name: 'Riomaggiore',
    code: 'H304',
  },
  {
    name: 'Rionero in Vulture',
    code: 'H307',
  },
  {
    name: 'Rionero Sannitico',
    code: 'H308',
  },
  {
    name: 'Ripa Teatina',
    code: 'H320',
  },
  {
    name: 'Ripabottoni',
    code: 'H311',
  },
  {
    name: 'Ripacandida',
    code: 'H312',
  },
  {
    name: 'Ripalimosani',
    code: 'H313',
  },
  {
    name: 'Ripalta Arpina',
    code: 'H314',
  },
  {
    name: 'Ripalta Cremasca',
    code: 'H315',
  },
  {
    name: 'Ripalta Guerina',
    code: 'H316',
  },
  {
    name: 'Riparbella',
    code: 'H319',
  },
  {
    name: 'Ripatransone',
    code: 'H321',
  },
  {
    name: 'Ripe San Ginesio',
    code: 'H323',
  },
  {
    name: 'Ripi',
    code: 'H324',
  },
  {
    name: 'Riposto',
    code: 'H325',
  },
  {
    name: 'Rittana',
    code: 'H326',
  },
  {
    name: 'Riva del Garda',
    code: 'H330',
  },
  {
    name: 'Riva del Po',
    code: 'M410',
  },
  {
    name: 'Riva di Solto',
    code: 'H331',
  },
  {
    name: 'Riva Ligure',
    code: 'H328',
  },
  {
    name: 'Riva presso Chieri',
    code: 'H337',
  },
  {
    name: 'Rivalba',
    code: 'H333',
  },
  {
    name: 'Rivalta Bormida',
    code: 'H334',
  },
  {
    name: 'Rivalta di Torino',
    code: 'H335',
  },
  {
    name: 'Rivamonte Agordino',
    code: 'H327',
  },
  {
    name: 'Rivanazzano Terme',
    code: 'H336',
  },
  {
    name: 'Rivara',
    code: 'H338',
  },
  {
    name: 'Rivarolo Canavese',
    code: 'H340',
  },
  {
    name: 'Rivarolo del Re ed Uniti',
    code: 'H341',
  },
  {
    name: 'Rivarolo Mantovano',
    code: 'H342',
  },
  {
    name: 'Rivarone',
    code: 'H343',
  },
  {
    name: 'Rivarossa',
    code: 'H344',
  },
  {
    name: 'Rive',
    code: 'H346',
  },
  {
    name: "Rive d'Arcano",
    code: 'H347',
  },
  {
    name: 'Rivello',
    code: 'H348',
  },
  {
    name: 'Rivergaro',
    code: 'H350',
  },
  {
    name: 'Rivignano Teor',
    code: 'M317',
  },
  {
    name: 'Rivisondoli',
    code: 'H353',
  },
  {
    name: 'Rivodutri',
    code: 'H354',
  },
  {
    name: 'Rivoli',
    code: 'H355',
  },
  {
    name: 'Rivoli Veronese',
    code: 'H356',
  },
  {
    name: "Rivolta d'Adda",
    code: 'H357',
  },
  {
    name: 'Rizziconi',
    code: 'H359',
  },
  {
    name: 'Roana',
    code: 'H361',
  },
  {
    name: 'Roaschia',
    code: 'H362',
  },
  {
    name: 'Roascio',
    code: 'H363',
  },
  {
    name: 'Roasio',
    code: 'H365',
  },
  {
    name: 'Roatto',
    code: 'H366',
  },
  {
    name: 'Robassomero',
    code: 'H367',
  },
  {
    name: 'Robbiate',
    code: 'G223',
  },
  {
    name: 'Robbio',
    code: 'H369',
  },
  {
    name: 'Robecchetto con Induno',
    code: 'H371',
  },
  {
    name: "Robecco d'Oglio",
    code: 'H372',
  },
  {
    name: 'Robecco Pavese',
    code: 'H375',
  },
  {
    name: 'Robecco sul Naviglio',
    code: 'H373',
  },
  {
    name: 'Robella',
    code: 'H376',
  },
  {
    name: 'Robilante',
    code: 'H377',
  },
  {
    name: 'Roburent',
    code: 'H378',
  },
  {
    name: 'Rocca Canavese',
    code: 'H386',
  },
  {
    name: 'Rocca Canterano',
    code: 'H387',
  },
  {
    name: 'Rocca Cigliè',
    code: 'H391',
  },
  {
    name: "Rocca d'Arazzo",
    code: 'H392',
  },
  {
    name: "Rocca d'Arce",
    code: 'H393',
  },
  {
    name: "Rocca d'Evandro",
    code: 'H398',
  },
  {
    name: "Rocca de' Baldi",
    code: 'H395',
  },
  {
    name: "Rocca de' Giorgi",
    code: 'H396',
  },
  {
    name: 'Rocca di Botte',
    code: 'H399',
  },
  {
    name: 'Rocca di Cambio',
    code: 'H400',
  },
  {
    name: 'Rocca di Cave',
    code: 'H401',
  },
  {
    name: 'Rocca di Mezzo',
    code: 'H402',
  },
  {
    name: 'Rocca di Neto',
    code: 'H403',
  },
  {
    name: 'Rocca di Papa',
    code: 'H404',
  },
  {
    name: 'Rocca Grimalda',
    code: 'H414',
  },
  {
    name: 'Rocca Imperiale',
    code: 'H416',
  },
  {
    name: 'Rocca Massima',
    code: 'H421',
  },
  {
    name: 'Rocca Pia',
    code: 'H429',
  },
  {
    name: 'Rocca Pietore',
    code: 'H379',
  },
  {
    name: 'Rocca Priora',
    code: 'H432',
  },
  {
    name: 'Rocca San Casciano',
    code: 'H437',
  },
  {
    name: 'Rocca San Felice',
    code: 'H438',
  },
  {
    name: 'Rocca San Giovanni',
    code: 'H439',
  },
  {
    name: 'Rocca Santa Maria',
    code: 'H440',
  },
  {
    name: 'Rocca Santo Stefano',
    code: 'H441',
  },
  {
    name: 'Rocca Sinibalda',
    code: 'H446',
  },
  {
    name: 'Rocca Susella',
    code: 'H450',
  },
  {
    name: 'Roccabascerana',
    code: 'H382',
  },
  {
    name: 'Roccabernarda',
    code: 'H383',
  },
  {
    name: 'Roccabianca',
    code: 'H384',
  },
  {
    name: 'Roccabruna',
    code: 'H385',
  },
  {
    name: 'Roccacasale',
    code: 'H389',
  },
  {
    name: 'Roccadaspide',
    code: 'H394',
  },
  {
    name: 'Roccafiorita',
    code: 'H405',
  },
  {
    name: 'Roccafluvione',
    code: 'H390',
  },
  {
    name: 'Roccaforte del Greco',
    code: 'H408',
  },
  {
    name: 'Roccaforte Ligure',
    code: 'H406',
  },
  {
    name: 'Roccaforte Mondovì',
    code: 'H407',
  },
  {
    name: 'Roccaforzata',
    code: 'H409',
  },
  {
    name: 'Roccafranca',
    code: 'H410',
  },
  {
    name: 'Roccagiovine',
    code: 'H411',
  },
  {
    name: 'Roccagloriosa',
    code: 'H412',
  },
  {
    name: 'Roccagorga',
    code: 'H413',
  },
  {
    name: 'Roccalbegna',
    code: 'H417',
  },
  {
    name: 'Roccalumera',
    code: 'H418',
  },
  {
    name: 'Roccamandolfi',
    code: 'H420',
  },
  {
    name: 'Roccamena',
    code: 'H422',
  },
  {
    name: 'Roccamonfina',
    code: 'H423',
  },
  {
    name: 'Roccamontepiano',
    code: 'H424',
  },
  {
    name: 'Roccamorice',
    code: 'H425',
  },
  {
    name: 'Roccanova',
    code: 'H426',
  },
  {
    name: 'Roccantica',
    code: 'H427',
  },
  {
    name: 'Roccapalumba',
    code: 'H428',
  },
  {
    name: 'Roccapiemonte',
    code: 'H431',
  },
  {
    name: 'Roccarainola',
    code: 'H433',
  },
  {
    name: 'Roccaraso',
    code: 'H434',
  },
  {
    name: 'Roccaromana',
    code: 'H436',
  },
  {
    name: 'Roccascalegna',
    code: 'H442',
  },
  {
    name: 'Roccasecca',
    code: 'H443',
  },
  {
    name: 'Roccasecca dei Volsci',
    code: 'H444',
  },
  {
    name: 'Roccasicura',
    code: 'H445',
  },
  {
    name: 'Roccasparvera',
    code: 'H447',
  },
  {
    name: 'Roccaspinalveti',
    code: 'H448',
  },
  {
    name: 'Roccastrada',
    code: 'H449',
  },
  {
    name: 'Roccavaldina',
    code: 'H380',
  },
  {
    name: 'Roccaverano',
    code: 'H451',
  },
  {
    name: 'Roccavignale',
    code: 'H452',
  },
  {
    name: 'Roccavione',
    code: 'H453',
  },
  {
    name: 'Roccavivara',
    code: 'H454',
  },
  {
    name: 'Roccella Ionica',
    code: 'H456',
  },
  {
    name: 'Roccella Valdemone',
    code: 'H455',
  },
  {
    name: 'Rocchetta a Volturno',
    code: 'H458',
  },
  {
    name: 'Rocchetta Belbo',
    code: 'H462',
  },
  {
    name: 'Rocchetta di Vara',
    code: 'H461',
  },
  {
    name: 'Rocchetta e Croce',
    code: 'H459',
  },
  {
    name: 'Rocchetta Ligure',
    code: 'H465',
  },
  {
    name: 'Rocchetta Nervina',
    code: 'H460',
  },
  {
    name: 'Rocchetta Palafea',
    code: 'H466',
  },
  {
    name: "Rocchetta Sant'Antonio",
    code: 'H467',
  },
  {
    name: 'Rocchetta Tanaro',
    code: 'H468',
  },
  {
    name: 'Rodano',
    code: 'H470',
  },
  {
    name: 'Roddi',
    code: 'H472',
  },
  {
    name: 'Roddino',
    code: 'H473',
  },
  {
    name: 'Rodello',
    code: 'H474',
  },
  {
    name: 'Rodengo',
    code: 'H475',
  },
  {
    name: 'Rodengo Saiano',
    code: 'H477',
  },
  {
    name: 'Rodero',
    code: 'H478',
  },
  {
    name: 'Rodi Garganico',
    code: 'H480',
  },
  {
    name: 'Rodì Milici',
    code: 'H479',
  },
  {
    name: 'Rodigo',
    code: 'H481',
  },
  {
    name: 'Roè Volciano',
    code: 'H484',
  },
  {
    name: 'Rofrano',
    code: 'H485',
  },
  {
    name: 'Rogeno',
    code: 'H486',
  },
  {
    name: 'Roggiano Gravina',
    code: 'H488',
  },
  {
    name: 'Roghudi',
    code: 'H489',
  },
  {
    name: 'Rogliano',
    code: 'H490',
  },
  {
    name: 'Rognano',
    code: 'H491',
  },
  {
    name: 'Rogno',
    code: 'H492',
  },
  {
    name: 'Rogolo',
    code: 'H493',
  },
  {
    name: 'Roiate',
    code: 'H494',
  },
  {
    name: 'Roio del Sangro',
    code: 'H495',
  },
  {
    name: 'Roisan',
    code: 'H497',
  },
  {
    name: 'Roletto',
    code: 'H498',
  },
  {
    name: 'Rolo',
    code: 'H500',
  },
  {
    name: 'Roma',
    code: 'H501',
  },
  {
    name: 'Romagnano al Monte',
    code: 'H503',
  },
  {
    name: 'Romagnano Sesia',
    code: 'H502',
  },
  {
    name: 'Romagnese',
    code: 'H505',
  },
  {
    name: 'Romana',
    code: 'H507',
  },
  {
    name: 'Romanengo',
    code: 'H508',
  },
  {
    name: 'Romano Canavese',
    code: 'H511',
  },
  {
    name: "Romano d'Ezzelino",
    code: 'H512',
  },
  {
    name: 'Romano di Lombardia',
    code: 'H509',
  },
  {
    name: "Romans d'Isonzo",
    code: 'H514',
  },
  {
    name: 'Rombiolo',
    code: 'H516',
  },
  {
    name: 'Romeno',
    code: 'H517',
  },
  {
    name: 'Romentino',
    code: 'H518',
  },
  {
    name: 'Rometta',
    code: 'H519',
  },
  {
    name: 'Ronago',
    code: 'H521',
  },
  {
    name: 'Roncà',
    code: 'H522',
  },
  {
    name: 'Roncade',
    code: 'H523',
  },
  {
    name: 'Roncadelle',
    code: 'H525',
  },
  {
    name: 'Roncaro',
    code: 'H527',
  },
  {
    name: 'Roncegno Terme',
    code: 'H528',
  },
  {
    name: 'Roncello',
    code: 'H529',
  },
  {
    name: 'Ronchi dei Legionari',
    code: 'H531',
  },
  {
    name: 'Ronchi Valsugana',
    code: 'H532',
  },
  {
    name: 'Ronchis',
    code: 'H533',
  },
  {
    name: 'Ronciglione',
    code: 'H534',
  },
  {
    name: "Ronco all'Adige",
    code: 'H540',
  },
  {
    name: 'Ronco Biellese',
    code: 'H538',
  },
  {
    name: 'Ronco Briantino',
    code: 'H537',
  },
  {
    name: 'Ronco Canavese',
    code: 'H539',
  },
  {
    name: 'Ronco Scrivia',
    code: 'H536',
  },
  {
    name: 'Roncobello',
    code: 'H535',
  },
  {
    name: 'Roncoferraro',
    code: 'H541',
  },
  {
    name: 'Roncofreddo',
    code: 'H542',
  },
  {
    name: 'Roncola',
    code: 'H544',
  },
  {
    name: 'Rondanina',
    code: 'H546',
  },
  {
    name: 'Rondissone',
    code: 'H547',
  },
  {
    name: 'Ronsecco',
    code: 'H549',
  },
  {
    name: 'Ronzo-Chienis',
    code: 'M303',
  },
  {
    name: 'Ronzone',
    code: 'H552',
  },
  {
    name: 'Roppolo',
    code: 'H553',
  },
  {
    name: 'Rorà',
    code: 'H554',
  },
  {
    name: 'Rosà',
    code: 'H556',
  },
  {
    name: 'Rosarno',
    code: 'H558',
  },
  {
    name: 'Rosasco',
    code: 'H559',
  },
  {
    name: 'Rosate',
    code: 'H560',
  },
  {
    name: 'Rosazza',
    code: 'H561',
  },
  {
    name: 'Rosciano',
    code: 'H562',
  },
  {
    name: 'Roscigno',
    code: 'H564',
  },
  {
    name: 'Rose',
    code: 'H565',
  },
  {
    name: 'Rosello',
    code: 'H566',
  },
  {
    name: 'Roseto Capo Spulico',
    code: 'H572',
  },
  {
    name: 'Roseto degli Abruzzi',
    code: 'F585',
  },
  {
    name: 'Roseto Valfortore',
    code: 'H568',
  },
  {
    name: 'Rosignano Marittimo',
    code: 'H570',
  },
  {
    name: 'Rosignano Monferrato',
    code: 'H569',
  },
  {
    name: 'Rosolina',
    code: 'H573',
  },
  {
    name: 'Rosolini',
    code: 'H574',
  },
  {
    name: 'Rosora',
    code: 'H575',
  },
  {
    name: 'Rossa',
    code: 'H577',
  },
  {
    name: 'Rossana',
    code: 'H578',
  },
  {
    name: 'Rossano Veneto',
    code: 'H580',
  },
  {
    name: 'Rossiglione',
    code: 'H581',
  },
  {
    name: 'Rosta',
    code: 'H583',
  },
  {
    name: "Rota d'Imagna",
    code: 'H584',
  },
  {
    name: 'Rota Greca',
    code: 'H585',
  },
  {
    name: 'Rotella',
    code: 'H588',
  },
  {
    name: 'Rotello',
    code: 'H589',
  },
  {
    name: 'Rotonda',
    code: 'H590',
  },
  {
    name: 'Rotondella',
    code: 'H591',
  },
  {
    name: 'Rotondi',
    code: 'H592',
  },
  {
    name: 'Rottofreno',
    code: 'H593',
  },
  {
    name: 'Rotzo',
    code: 'H594',
  },
  {
    name: 'Roure',
    code: 'H555',
  },
  {
    name: 'Rovasenda',
    code: 'H364',
  },
  {
    name: 'Rovato',
    code: 'H598',
  },
  {
    name: 'Rovegno',
    code: 'H599',
  },
  {
    name: 'Rovellasca',
    code: 'H601',
  },
  {
    name: 'Rovello Porro',
    code: 'H602',
  },
  {
    name: 'Roverbella',
    code: 'H604',
  },
  {
    name: 'Roverchiara',
    code: 'H606',
  },
  {
    name: 'Roverè della Luna',
    code: 'H607',
  },
  {
    name: 'Roverè Veronese',
    code: 'H608',
  },
  {
    name: 'Roveredo di Guà',
    code: 'H610',
  },
  {
    name: 'Roveredo in Piano',
    code: 'H609',
  },
  {
    name: 'Rovereto',
    code: 'H612',
  },
  {
    name: 'Rovescala',
    code: 'H614',
  },
  {
    name: 'Rovetta',
    code: 'H615',
  },
  {
    name: 'Roviano',
    code: 'H618',
  },
  {
    name: 'Rovigo',
    code: 'H620',
  },
  {
    name: 'Rovito',
    code: 'H621',
  },
  {
    name: 'Rovolon',
    code: 'H622',
  },
  {
    name: 'Rozzano',
    code: 'H623',
  },
  {
    name: 'Rubano',
    code: 'H625',
  },
  {
    name: 'Rubiana',
    code: 'H627',
  },
  {
    name: 'Rubiera',
    code: 'H628',
  },
  {
    name: 'Ruda',
    code: 'H629',
  },
  {
    name: 'Rudiano',
    code: 'H630',
  },
  {
    name: 'Rueglio',
    code: 'H631',
  },
  {
    name: 'Ruffano',
    code: 'H632',
  },
  {
    name: 'Ruffia',
    code: 'H633',
  },
  {
    name: 'Ruffrè-Mendola',
    code: 'H634',
  },
  {
    name: 'Rufina',
    code: 'H635',
  },
  {
    name: 'Ruinas',
    code: 'F271',
  },
  {
    name: 'Rumo',
    code: 'H639',
  },
  {
    name: 'Ruoti',
    code: 'H641',
  },
  {
    name: 'Russi',
    code: 'H642',
  },
  {
    name: 'Rutigliano',
    code: 'H643',
  },
  {
    name: 'Rutino',
    code: 'H644',
  },
  {
    name: 'Ruviano',
    code: 'H165',
  },
  {
    name: 'Ruvo del Monte',
    code: 'H646',
  },
  {
    name: 'Ruvo di Puglia',
    code: 'H645',
  },
  {
    name: 'Sabaudia',
    code: 'H647',
  },
  {
    name: 'Sabbio Chiese',
    code: 'H650',
  },
  {
    name: 'Sabbioneta',
    code: 'H652',
  },
  {
    name: 'Sacco',
    code: 'H654',
  },
  {
    name: 'Saccolongo',
    code: 'H655',
  },
  {
    name: 'Sacile',
    code: 'H657',
  },
  {
    name: 'Sacrofano',
    code: 'H658',
  },
  {
    name: 'Sadali',
    code: 'H659',
  },
  {
    name: 'Sagama',
    code: 'H661',
  },
  {
    name: 'Sagliano Micca',
    code: 'H662',
  },
  {
    name: 'Sagrado',
    code: 'H665',
  },
  {
    name: 'Sagron Mis',
    code: 'H666',
  },
  {
    name: 'Saint-Christophe',
    code: 'H669',
  },
  {
    name: 'Saint-Denis',
    code: 'H670',
  },
  {
    name: 'Saint-Marcel',
    code: 'H671',
  },
  {
    name: 'Saint-Nicolas',
    code: 'H672',
  },
  {
    name: 'Saint-Oyen',
    code: 'H673',
  },
  {
    name: 'Saint-Pierre',
    code: 'H674',
  },
  {
    name: 'Saint-Rhémy-en-Bosses',
    code: 'H675',
  },
  {
    name: 'Saint-Vincent',
    code: 'H676',
  },
  {
    name: 'Sala Baganza',
    code: 'H682',
  },
  {
    name: 'Sala Biellese',
    code: 'H681',
  },
  {
    name: 'Sala Bolognese',
    code: 'H678',
  },
  {
    name: 'Sala Comacina',
    code: 'H679',
  },
  {
    name: 'Sala Consilina',
    code: 'H683',
  },
  {
    name: 'Sala Monferrato',
    code: 'H677',
  },
  {
    name: 'Salandra',
    code: 'H687',
  },
  {
    name: 'Salaparuta',
    code: 'H688',
  },
  {
    name: 'Salara',
    code: 'H689',
  },
  {
    name: 'Salasco',
    code: 'H690',
  },
  {
    name: 'Salassa',
    code: 'H691',
  },
  {
    name: 'Salbertrand',
    code: 'H684',
  },
  {
    name: 'Salcedo',
    code: 'F810',
  },
  {
    name: 'Salcito',
    code: 'H693',
  },
  {
    name: 'Sale',
    code: 'H694',
  },
  {
    name: 'Sale delle Langhe',
    code: 'H695',
  },
  {
    name: 'Sale Marasino',
    code: 'H699',
  },
  {
    name: 'Sale San Giovanni',
    code: 'H704',
  },
  {
    name: 'Salemi',
    code: 'H700',
  },
  {
    name: 'Salento',
    code: 'H686',
  },
  {
    name: 'Salerano Canavese',
    code: 'H702',
  },
  {
    name: 'Salerano sul Lambro',
    code: 'H701',
  },
  {
    name: 'Salerno',
    code: 'H703',
  },
  {
    name: 'Salgareda',
    code: 'H706',
  },
  {
    name: 'Sali Vercellese',
    code: 'H707',
  },
  {
    name: 'Salice Salentino',
    code: 'H708',
  },
  {
    name: 'Saliceto',
    code: 'H710',
  },
  {
    name: 'Salisano',
    code: 'H713',
  },
  {
    name: 'Salizzole',
    code: 'H714',
  },
  {
    name: 'Salle',
    code: 'H715',
  },
  {
    name: 'Salmour',
    code: 'H716',
  },
  {
    name: 'Salò',
    code: 'H717',
  },
  {
    name: 'Salorno',
    code: 'H719',
  },
  {
    name: 'Salsomaggiore Terme',
    code: 'H720',
  },
  {
    name: 'Saltrio',
    code: 'H723',
  },
  {
    name: 'Saludecio',
    code: 'H724',
  },
  {
    name: 'Saluggia',
    code: 'H725',
  },
  {
    name: 'Salussola',
    code: 'H726',
  },
  {
    name: 'Saluzzo',
    code: 'H727',
  },
  {
    name: 'Salve',
    code: 'H729',
  },
  {
    name: 'Salvirola',
    code: 'H731',
  },
  {
    name: 'Salvitelle',
    code: 'H732',
  },
  {
    name: 'Salza di Pinerolo',
    code: 'H734',
  },
  {
    name: 'Salza Irpina',
    code: 'H733',
  },
  {
    name: 'Salzano',
    code: 'H735',
  },
  {
    name: 'Samarate',
    code: 'H736',
  },
  {
    name: 'Samassi',
    code: 'H738',
  },
  {
    name: 'Samatzai',
    code: 'H739',
  },
  {
    name: 'Sambuca di Sicilia',
    code: 'H743',
  },
  {
    name: 'Sambuca Pistoiese',
    code: 'H744',
  },
  {
    name: 'Sambuci',
    code: 'H745',
  },
  {
    name: 'Sambuco',
    code: 'H746',
  },
  {
    name: 'Sammichele di Bari',
    code: 'H749',
  },
  {
    name: 'Samo',
    code: 'H013',
  },
  {
    name: 'Samolaco',
    code: 'H752',
  },
  {
    name: 'Samone',
    code: 'H753',
  },
  {
    name: 'Samone',
    code: 'H754',
  },
  {
    name: 'Sampeyre',
    code: 'H755',
  },
  {
    name: 'Samugheo',
    code: 'H756',
  },
  {
    name: 'San Bartolomeo al Mare',
    code: 'H763',
  },
  {
    name: 'San Bartolomeo in Galdo',
    code: 'H764',
  },
  {
    name: 'San Bartolomeo Val Cavargna',
    code: 'H760',
  },
  {
    name: 'San Basile',
    code: 'H765',
  },
  {
    name: 'San Basilio',
    code: 'H766',
  },
  {
    name: 'San Bassano',
    code: 'H767',
  },
  {
    name: 'San Bellino',
    code: 'H768',
  },
  {
    name: 'San Benedetto Belbo',
    code: 'H770',
  },
  {
    name: 'San Benedetto dei Marsi',
    code: 'H772',
  },
  {
    name: 'San Benedetto del Tronto',
    code: 'H769',
  },
  {
    name: 'San Benedetto in Perillis',
    code: 'H773',
  },
  {
    name: 'San Benedetto Po',
    code: 'H771',
  },
  {
    name: 'San Benedetto Ullano',
    code: 'H774',
  },
  {
    name: 'San Benedetto Val di Sambro',
    code: 'G566',
  },
  {
    name: 'San Benigno Canavese',
    code: 'H775',
  },
  {
    name: 'San Bernardino Verbano',
    code: 'H777',
  },
  {
    name: 'San Biagio della Cima',
    code: 'H780',
  },
  {
    name: 'San Biagio di Callalta',
    code: 'H781',
  },
  {
    name: 'San Biagio Platani',
    code: 'H778',
  },
  {
    name: 'San Biagio Saracinisco',
    code: 'H779',
  },
  {
    name: 'San Biase',
    code: 'H782',
  },
  {
    name: 'San Bonifacio',
    code: 'H783',
  },
  {
    name: 'San Buono',
    code: 'H784',
  },
  {
    name: 'San Calogero',
    code: 'H785',
  },
  {
    name: 'San Candido',
    code: 'H786',
  },
  {
    name: "San Canzian d'Isonzo",
    code: 'H787',
  },
  {
    name: 'San Carlo Canavese',
    code: 'H789',
  },
  {
    name: 'San Casciano dei Bagni',
    code: 'H790',
  },
  {
    name: 'San Casciano in Val di Pesa',
    code: 'H791',
  },
  {
    name: 'San Cassiano',
    code: 'M264',
  },
  {
    name: 'San Cataldo',
    code: 'H792',
  },
  {
    name: 'San Cesareo',
    code: 'M295',
  },
  {
    name: 'San Cesario di Lecce',
    code: 'H793',
  },
  {
    name: 'San Cesario sul Panaro',
    code: 'H794',
  },
  {
    name: 'San Chirico Nuovo',
    code: 'H795',
  },
  {
    name: 'San Chirico Raparo',
    code: 'H796',
  },
  {
    name: 'San Cipirello',
    code: 'H797',
  },
  {
    name: "San Cipriano d'Aversa",
    code: 'H798',
  },
  {
    name: 'San Cipriano Picentino',
    code: 'H800',
  },
  {
    name: 'San Cipriano Po',
    code: 'H799',
  },
  {
    name: 'San Clemente',
    code: 'H801',
  },
  {
    name: 'San Colombano al Lambro',
    code: 'H803',
  },
  {
    name: 'San Colombano Belmonte',
    code: 'H804',
  },
  {
    name: 'San Colombano Certenoli',
    code: 'H802',
  },
  {
    name: 'San Cono',
    code: 'H805',
  },
  {
    name: 'San Cosmo Albanese',
    code: 'H806',
  },
  {
    name: 'San Costantino Albanese',
    code: 'H808',
  },
  {
    name: 'San Costantino Calabro',
    code: 'H807',
  },
  {
    name: 'San Costanzo',
    code: 'H809',
  },
  {
    name: 'San Cristoforo',
    code: 'H810',
  },
  {
    name: 'San Damiano al Colle',
    code: 'H814',
  },
  {
    name: "San Damiano d'Asti",
    code: 'H811',
  },
  {
    name: 'San Damiano Macra',
    code: 'H812',
  },
  {
    name: 'San Daniele del Friuli',
    code: 'H816',
  },
  {
    name: 'San Daniele Po',
    code: 'H815',
  },
  {
    name: 'San Demetrio Corone',
    code: 'H818',
  },
  {
    name: "San Demetrio ne' Vestini",
    code: 'H819',
  },
  {
    name: 'San Didero',
    code: 'H820',
  },
  {
    name: 'San Donà di Piave',
    code: 'H823',
  },
  {
    name: 'San Donaci',
    code: 'H822',
  },
  {
    name: 'San Donato di Lecce',
    code: 'H826',
  },
  {
    name: 'San Donato di Ninea',
    code: 'H825',
  },
  {
    name: 'San Donato Milanese',
    code: 'H827',
  },
  {
    name: 'San Donato Val di Comino',
    code: 'H824',
  },
  {
    name: 'San Dorligo della Valle',
    code: 'D324',
  },
  {
    name: 'San Fele',
    code: 'H831',
  },
  {
    name: 'San Felice a Cancello',
    code: 'H834',
  },
  {
    name: 'San Felice Circeo',
    code: 'H836',
  },
  {
    name: 'San Felice del Benaco',
    code: 'H838',
  },
  {
    name: 'San Felice del Molise',
    code: 'H833',
  },
  {
    name: 'San Felice sul Panaro',
    code: 'H835',
  },
  {
    name: 'San Ferdinando',
    code: 'M277',
  },
  {
    name: 'San Ferdinando di Puglia',
    code: 'H839',
  },
  {
    name: 'San Fermo della Battaglia',
    code: 'H840',
  },
  {
    name: 'San Fili',
    code: 'H841',
  },
  {
    name: 'San Filippo del Mela',
    code: 'H842',
  },
  {
    name: 'San Fior',
    code: 'H843',
  },
  {
    name: 'San Fiorano',
    code: 'H844',
  },
  {
    name: 'San Floriano del Collio',
    code: 'H845',
  },
  {
    name: 'San Floro',
    code: 'H846',
  },
  {
    name: 'San Francesco al Campo',
    code: 'H847',
  },
  {
    name: 'San Fratello',
    code: 'H850',
  },
  {
    name: 'San Gavino Monreale',
    code: 'H856',
  },
  {
    name: 'San Gemini',
    code: 'H857',
  },
  {
    name: 'San Genesio Atesino',
    code: 'H858',
  },
  {
    name: 'San Genesio ed Uniti',
    code: 'H859',
  },
  {
    name: 'San Gennaro Vesuviano',
    code: 'H860',
  },
  {
    name: 'San Germano Chisone',
    code: 'H862',
  },
  {
    name: 'San Germano Vercellese',
    code: 'H861',
  },
  {
    name: 'San Gervasio Bresciano',
    code: 'H865',
  },
  {
    name: 'San Giacomo degli Schiavoni',
    code: 'H867',
  },
  {
    name: 'San Giacomo delle Segnate',
    code: 'H870',
  },
  {
    name: 'San Giacomo Filippo',
    code: 'H868',
  },
  {
    name: 'San Giacomo Vercellese',
    code: 'B952',
  },
  {
    name: 'San Gillio',
    code: 'H873',
  },
  {
    name: 'San Gimignano',
    code: 'H875',
  },
  {
    name: 'San Ginesio',
    code: 'H876',
  },
  {
    name: 'San Giorgio a Cremano',
    code: 'H892',
  },
  {
    name: 'San Giorgio a Liri',
    code: 'H880',
  },
  {
    name: 'San Giorgio Albanese',
    code: 'H881',
  },
  {
    name: 'San Giorgio Bigarello',
    code: 'H883',
  },
  {
    name: 'San Giorgio Canavese',
    code: 'H890',
  },
  {
    name: 'San Giorgio del Sannio',
    code: 'H894',
  },
  {
    name: 'San Giorgio della Richinvelda',
    code: 'H891',
  },
  {
    name: 'San Giorgio delle Pertiche',
    code: 'H893',
  },
  {
    name: 'San Giorgio di Lomellina',
    code: 'H885',
  },
  {
    name: 'San Giorgio di Nogaro',
    code: 'H895',
  },
  {
    name: 'San Giorgio di Piano',
    code: 'H896',
  },
  {
    name: 'San Giorgio in Bosco',
    code: 'H897',
  },
  {
    name: 'San Giorgio Ionico',
    code: 'H882',
  },
  {
    name: 'San Giorgio La Molara',
    code: 'H898',
  },
  {
    name: 'San Giorgio Lucano',
    code: 'H888',
  },
  {
    name: 'San Giorgio Monferrato',
    code: 'H878',
  },
  {
    name: 'San Giorgio Morgeto',
    code: 'H889',
  },
  {
    name: 'San Giorgio Piacentino',
    code: 'H887',
  },
  {
    name: 'San Giorgio Scarampi',
    code: 'H899',
  },
  {
    name: 'San Giorgio su Legnano',
    code: 'H884',
  },
  {
    name: 'San Giorio di Susa',
    code: 'H900',
  },
  {
    name: 'San Giovanni a Piro',
    code: 'H907',
  },
  {
    name: 'San Giovanni al Natisone',
    code: 'H906',
  },
  {
    name: 'San Giovanni Bianco',
    code: 'H910',
  },
  {
    name: 'San Giovanni del Dosso',
    code: 'H912',
  },
  {
    name: 'San Giovanni di Fassa',
    code: 'M390',
  },
  {
    name: 'San Giovanni di Gerace',
    code: 'H903',
  },
  {
    name: 'San Giovanni Gemini',
    code: 'H914',
  },
  {
    name: 'San Giovanni Ilarione',
    code: 'H916',
  },
  {
    name: 'San Giovanni in Croce',
    code: 'H918',
  },
  {
    name: 'San Giovanni in Fiore',
    code: 'H919',
  },
  {
    name: 'San Giovanni in Galdo',
    code: 'H920',
  },
  {
    name: 'San Giovanni in Marignano',
    code: 'H921',
  },
  {
    name: 'San Giovanni in Persiceto',
    code: 'G467',
  },
  {
    name: 'San Giovanni Incarico',
    code: 'H917',
  },
  {
    name: 'San Giovanni la Punta',
    code: 'H922',
  },
  {
    name: 'San Giovanni Lipioni',
    code: 'H923',
  },
  {
    name: 'San Giovanni Lupatoto',
    code: 'H924',
  },
  {
    name: 'San Giovanni Rotondo',
    code: 'H926',
  },
  {
    name: 'San Giovanni Suergiu',
    code: 'G287',
  },
  {
    name: 'San Giovanni Teatino',
    code: 'D690',
  },
  {
    name: 'San Giovanni Valdarno',
    code: 'H901',
  },
  {
    name: 'San Giuliano del Sannio',
    code: 'H928',
  },
  {
    name: 'San Giuliano di Puglia',
    code: 'H929',
  },
  {
    name: 'San Giuliano Milanese',
    code: 'H930',
  },
  {
    name: 'San Giuliano Terme',
    code: 'A562',
  },
  {
    name: 'San Giuseppe Jato',
    code: 'H933',
  },
  {
    name: 'San Giuseppe Vesuviano',
    code: 'H931',
  },
  {
    name: 'San Giustino',
    code: 'H935',
  },
  {
    name: 'San Giusto Canavese',
    code: 'H936',
  },
  {
    name: 'San Godenzo',
    code: 'H937',
  },
  {
    name: "San Gregorio d'Ippona",
    code: 'H941',
  },
  {
    name: 'San Gregorio da Sassola',
    code: 'H942',
  },
  {
    name: 'San Gregorio di Catania',
    code: 'H940',
  },
  {
    name: 'San Gregorio Magno',
    code: 'H943',
  },
  {
    name: 'San Gregorio Matese',
    code: 'H939',
  },
  {
    name: 'San Gregorio nelle Alpi',
    code: 'H938',
  },
  {
    name: 'San Lazzaro di Savena',
    code: 'H945',
  },
  {
    name: 'San Leo',
    code: 'H949',
  },
  {
    name: 'San Leonardo',
    code: 'H951',
  },
  {
    name: 'San Leonardo in Passiria',
    code: 'H952',
  },
  {
    name: 'San Leucio del Sannio',
    code: 'H953',
  },
  {
    name: 'San Lorenzello',
    code: 'H955',
  },
  {
    name: 'San Lorenzo',
    code: 'H959',
  },
  {
    name: 'San Lorenzo al Mare',
    code: 'H957',
  },
  {
    name: 'San Lorenzo Bellizzi',
    code: 'H961',
  },
  {
    name: 'San Lorenzo del Vallo',
    code: 'H962',
  },
  {
    name: 'San Lorenzo di Sebato',
    code: 'H956',
  },
  {
    name: 'San Lorenzo Dorsino',
    code: 'M345',
  },
  {
    name: 'San Lorenzo in Campo',
    code: 'H958',
  },
  {
    name: 'San Lorenzo Isontino',
    code: 'H964',
  },
  {
    name: 'San Lorenzo Maggiore',
    code: 'H967',
  },
  {
    name: 'San Lorenzo Nuovo',
    code: 'H969',
  },
  {
    name: 'San Luca',
    code: 'H970',
  },
  {
    name: 'San Lucido',
    code: 'H971',
  },
  {
    name: 'San Lupo',
    code: 'H973',
  },
  {
    name: "San Mango d'Aquino",
    code: 'H976',
  },
  {
    name: 'San Mango Piemonte',
    code: 'H977',
  },
  {
    name: 'San Mango sul Calore',
    code: 'H975',
  },
  {
    name: 'San Marcellino',
    code: 'H978',
  },
  {
    name: 'San Marcello',
    code: 'H979',
  },
  {
    name: 'San Marcello Piteglio',
    code: 'M377',
  },
  {
    name: 'San Marco Argentano',
    code: 'H981',
  },
  {
    name: "San Marco d'Alunzio",
    code: 'H982',
  },
  {
    name: 'San Marco dei Cavoti',
    code: 'H984',
  },
  {
    name: 'San Marco Evangelista',
    code: 'F043',
  },
  {
    name: 'San Marco in Lamis',
    code: 'H985',
  },
  {
    name: 'San Marco la Catola',
    code: 'H986',
  },
  {
    name: 'San Martino al Tagliamento',
    code: 'H999',
  },
  {
    name: 'San Martino Alfieri',
    code: 'H987',
  },
  {
    name: 'San Martino Buon Albergo',
    code: 'I003',
  },
  {
    name: 'San Martino Canavese',
    code: 'H997',
  },
  {
    name: "San Martino d'Agri",
    code: 'H994',
  },
  {
    name: "San Martino dall'Argine",
    code: 'I005',
  },
  {
    name: 'San Martino del Lago',
    code: 'I007',
  },
  {
    name: 'San Martino di Finita',
    code: 'H992',
  },
  {
    name: 'San Martino di Lupari',
    code: 'I008',
  },
  {
    name: 'San Martino di Venezze',
    code: 'H996',
  },
  {
    name: 'San Martino in Badia',
    code: 'H988',
  },
  {
    name: 'San Martino in Passiria',
    code: 'H989',
  },
  {
    name: 'San Martino in Pensilis',
    code: 'H990',
  },
  {
    name: 'San Martino in Rio',
    code: 'I011',
  },
  {
    name: 'San Martino in Strada',
    code: 'I012',
  },
  {
    name: 'San Martino Sannita',
    code: 'I002',
  },
  {
    name: 'San Martino Siccomario',
    code: 'I014',
  },
  {
    name: 'San Martino sulla Marrucina',
    code: 'H991',
  },
  {
    name: 'San Martino Valle Caudina',
    code: 'I016',
  },
  {
    name: 'San Marzano di San Giuseppe',
    code: 'I018',
  },
  {
    name: 'San Marzano Oliveto',
    code: 'I017',
  },
  {
    name: 'San Marzano sul Sarno',
    code: 'I019',
  },
  {
    name: 'San Massimo',
    code: 'I023',
  },
  {
    name: 'San Maurizio Canavese',
    code: 'I024',
  },
  {
    name: "San Maurizio d'Opaglio",
    code: 'I025',
  },
  {
    name: 'San Mauro Castelverde',
    code: 'I028',
  },
  {
    name: 'San Mauro Cilento',
    code: 'I031',
  },
  {
    name: 'San Mauro di Saline',
    code: 'H712',
  },
  {
    name: 'San Mauro Forte',
    code: 'I029',
  },
  {
    name: 'San Mauro la Bruca',
    code: 'I032',
  },
  {
    name: 'San Mauro Marchesato',
    code: 'I026',
  },
  {
    name: 'San Mauro Pascoli',
    code: 'I027',
  },
  {
    name: 'San Mauro Torinese',
    code: 'I030',
  },
  {
    name: 'San Michele al Tagliamento',
    code: 'I040',
  },
  {
    name: "San Michele all'Adige",
    code: 'I042',
  },
  {
    name: 'San Michele di Ganzaria',
    code: 'I035',
  },
  {
    name: 'San Michele di Serino',
    code: 'I034',
  },
  {
    name: 'San Michele Mondovì',
    code: 'I037',
  },
  {
    name: 'San Michele Salentino',
    code: 'I045',
  },
  {
    name: 'San Miniato',
    code: 'I046',
  },
  {
    name: 'San Nazzaro',
    code: 'I049',
  },
  {
    name: 'San Nazzaro Sesia',
    code: 'I052',
  },
  {
    name: 'San Nazzaro Val Cavargna',
    code: 'I051',
  },
  {
    name: 'San Nicandro Garganico',
    code: 'I054',
  },
  {
    name: 'San Nicola Arcella',
    code: 'I060',
  },
  {
    name: 'San Nicola Baronia',
    code: 'I061',
  },
  {
    name: 'San Nicola da Crissa',
    code: 'I058',
  },
  {
    name: "San Nicola dell'Alto",
    code: 'I057',
  },
  {
    name: 'San Nicola la Strada',
    code: 'I056',
  },
  {
    name: 'San Nicola Manfredi',
    code: 'I062',
  },
  {
    name: "San Nicolò d'Arcidano",
    code: 'A368',
  },
  {
    name: 'San Nicolò di Comelico',
    code: 'I063',
  },
  {
    name: 'San Nicolò Gerrei',
    code: 'G383',
  },
  {
    name: 'San Pancrazio',
    code: 'I065',
  },
  {
    name: 'San Pancrazio Salentino',
    code: 'I066',
  },
  {
    name: 'San Paolo',
    code: 'G407',
  },
  {
    name: 'San Paolo Albanese',
    code: 'B906',
  },
  {
    name: 'San Paolo Bel Sito',
    code: 'I073',
  },
  {
    name: "San Paolo d'Argon",
    code: 'B310',
  },
  {
    name: 'San Paolo di Civitate',
    code: 'I072',
  },
  {
    name: 'San Paolo di Jesi',
    code: 'I071',
  },
  {
    name: 'San Paolo Solbrito',
    code: 'I076',
  },
  {
    name: 'San Pellegrino Terme',
    code: 'I079',
  },
  {
    name: "San Pier d'Isonzo",
    code: 'I082',
  },
  {
    name: 'San Pier Niceto',
    code: 'I084',
  },
  {
    name: 'San Piero Patti',
    code: 'I086',
  },
  {
    name: 'San Pietro a Maida',
    code: 'I093',
  },
  {
    name: 'San Pietro al Natisone',
    code: 'I092',
  },
  {
    name: 'San Pietro al Tanagro',
    code: 'I089',
  },
  {
    name: 'San Pietro Apostolo',
    code: 'I095',
  },
  {
    name: 'San Pietro Avellana',
    code: 'I096',
  },
  {
    name: 'San Pietro Clarenza',
    code: 'I098',
  },
  {
    name: 'San Pietro di Cadore',
    code: 'I088',
  },
  {
    name: 'San Pietro di Caridà',
    code: 'I102',
  },
  {
    name: 'San Pietro di Feletto',
    code: 'I103',
  },
  {
    name: 'San Pietro di Morubio',
    code: 'I105',
  },
  {
    name: 'San Pietro in Amantea',
    code: 'I108',
  },
  {
    name: 'San Pietro in Cariano',
    code: 'I109',
  },
  {
    name: 'San Pietro in Casale',
    code: 'I110',
  },
  {
    name: 'San Pietro in Cerro',
    code: 'G788',
  },
  {
    name: 'San Pietro in Gu',
    code: 'I107',
  },
  {
    name: 'San Pietro in Guarano',
    code: 'I114',
  },
  {
    name: 'San Pietro in Lama',
    code: 'I115',
  },
  {
    name: 'San Pietro Infine',
    code: 'I113',
  },
  {
    name: 'San Pietro Mosezzo',
    code: 'I116',
  },
  {
    name: 'San Pietro Mussolino',
    code: 'I117',
  },
  {
    name: 'San Pietro Val Lemina',
    code: 'I090',
  },
  {
    name: 'San Pietro Vernotico',
    code: 'I119',
  },
  {
    name: 'San Pietro Viminario',
    code: 'I120',
  },
  {
    name: 'San Pio delle Camere',
    code: 'I121',
  },
  {
    name: "San Polo d'Enza",
    code: 'I123',
  },
  {
    name: 'San Polo dei Cavalieri',
    code: 'I125',
  },
  {
    name: 'San Polo di Piave',
    code: 'I124',
  },
  {
    name: 'San Polo Matese',
    code: 'I122',
  },
  {
    name: 'San Ponso',
    code: 'I126',
  },
  {
    name: 'San Possidonio',
    code: 'I128',
  },
  {
    name: 'San Potito Sannitico',
    code: 'I130',
  },
  {
    name: 'San Potito Ultra',
    code: 'I129',
  },
  {
    name: 'San Prisco',
    code: 'I131',
  },
  {
    name: 'San Procopio',
    code: 'I132',
  },
  {
    name: 'San Prospero',
    code: 'I133',
  },
  {
    name: "San Quirico d'Orcia",
    code: 'I135',
  },
  {
    name: 'San Quirino',
    code: 'I136',
  },
  {
    name: 'San Raffaele Cimena',
    code: 'I137',
  },
  {
    name: 'San Roberto',
    code: 'I139',
  },
  {
    name: 'San Rocco al Porto',
    code: 'I140',
  },
  {
    name: 'San Romano in Garfagnana',
    code: 'I142',
  },
  {
    name: 'San Rufo',
    code: 'I143',
  },
  {
    name: 'San Salvatore di Fitalia',
    code: 'I147',
  },
  {
    name: 'San Salvatore Monferrato',
    code: 'I144',
  },
  {
    name: 'San Salvatore Telesino',
    code: 'I145',
  },
  {
    name: 'San Salvo',
    code: 'I148',
  },
  {
    name: 'San Sebastiano al Vesuvio',
    code: 'I151',
  },
  {
    name: 'San Sebastiano Curone',
    code: 'I150',
  },
  {
    name: 'San Sebastiano da Po',
    code: 'I152',
  },
  {
    name: 'San Secondo di Pinerolo',
    code: 'I154',
  },
  {
    name: 'San Secondo Parmense',
    code: 'I153',
  },
  {
    name: 'San Severino Lucano',
    code: 'I157',
  },
  {
    name: 'San Severino Marche',
    code: 'I156',
  },
  {
    name: 'San Severo',
    code: 'I158',
  },
  {
    name: 'San Siro',
    code: 'I162',
  },
  {
    name: 'San Sossio Baronia',
    code: 'I163',
  },
  {
    name: 'San Sostene',
    code: 'I164',
  },
  {
    name: 'San Sosti',
    code: 'I165',
  },
  {
    name: 'San Sperate',
    code: 'I166',
  },
  {
    name: 'San Stino di Livenza',
    code: 'I373',
  },
  {
    name: 'San Tammaro',
    code: 'I261',
  },
  {
    name: 'San Teodoro',
    code: 'I328',
  },
  {
    name: 'San Teodoro',
    code: 'I329',
  },
  {
    name: 'San Tomaso Agordino',
    code: 'I347',
  },
  {
    name: 'San Valentino in Abruzzo Citeriore',
    code: 'I376',
  },
  {
    name: 'San Valentino Torio',
    code: 'I377',
  },
  {
    name: 'San Venanzo',
    code: 'I381',
  },
  {
    name: 'San Vendemiano',
    code: 'I382',
  },
  {
    name: 'San Vero Milis',
    code: 'I384',
  },
  {
    name: 'San Vincenzo',
    code: 'I390',
  },
  {
    name: 'San Vincenzo La Costa',
    code: 'I388',
  },
  {
    name: 'San Vincenzo Valle Roveto',
    code: 'I389',
  },
  {
    name: 'San Vitaliano',
    code: 'I391',
  },
  {
    name: 'San Vito',
    code: 'I402',
  },
  {
    name: 'San Vito al Tagliamento',
    code: 'I403',
  },
  {
    name: 'San Vito al Torre',
    code: 'I404',
  },
  {
    name: 'San Vito Chietino',
    code: 'I394',
  },
  {
    name: 'San Vito dei Normanni',
    code: 'I396',
  },
  {
    name: 'San Vito di Cadore',
    code: 'I392',
  },
  {
    name: 'San Vito di Fagagna',
    code: 'I405',
  },
  {
    name: 'San Vito di Leguzzano',
    code: 'I401',
  },
  {
    name: 'San Vito Lo Capo',
    code: 'I407',
  },
  {
    name: 'San Vito Romano',
    code: 'I400',
  },
  {
    name: 'San Vito sullo Ionio',
    code: 'I393',
  },
  {
    name: 'San Vittore del Lazio',
    code: 'I408',
  },
  {
    name: 'San Vittore Olona',
    code: 'I409',
  },
  {
    name: 'San Zeno di Montagna',
    code: 'I414',
  },
  {
    name: 'San Zeno Naviglio',
    code: 'I412',
  },
  {
    name: 'San Zenone al Lambro',
    code: 'I415',
  },
  {
    name: 'San Zenone al Po',
    code: 'I416',
  },
  {
    name: 'San Zenone degli Ezzelini',
    code: 'I417',
  },
  {
    name: 'Sanarica',
    code: 'H757',
  },
  {
    name: 'Sandigliano',
    code: 'H821',
  },
  {
    name: 'Sandrigo',
    code: 'H829',
  },
  {
    name: 'Sanfrè',
    code: 'H851',
  },
  {
    name: 'Sanfront',
    code: 'H852',
  },
  {
    name: 'Sangano',
    code: 'H855',
  },
  {
    name: 'Sangiano',
    code: 'H872',
  },
  {
    name: 'Sangineto',
    code: 'H877',
  },
  {
    name: 'Sanguinetto',
    code: 'H944',
  },
  {
    name: 'Sanluri',
    code: 'H974',
  },
  {
    name: "Sannazzaro de' Burgondi",
    code: 'I048',
  },
  {
    name: 'Sannicandro di Bari',
    code: 'I053',
  },
  {
    name: 'Sannicola',
    code: 'I059',
  },
  {
    name: 'Sanremo',
    code: 'I138',
  },
  {
    name: 'Sansepolcro',
    code: 'I155',
  },
  {
    name: "Sant'Agapito",
    code: 'I189',
  },
  {
    name: "Sant'Agata Bolognese",
    code: 'I191',
  },
  {
    name: "Sant'Agata de' Goti",
    code: 'I197',
  },
  {
    name: "Sant'Agata del Bianco",
    code: 'I198',
  },
  {
    name: "Sant'Agata di Esaro",
    code: 'I192',
  },
  {
    name: "Sant'Agata di Militello",
    code: 'I199',
  },
  {
    name: "Sant'Agata di Puglia",
    code: 'I193',
  },
  {
    name: "Sant'Agata Feltria",
    code: 'I201',
  },
  {
    name: "Sant'Agata Fossili",
    code: 'I190',
  },
  {
    name: "Sant'Agata li Battiati",
    code: 'I202',
  },
  {
    name: "Sant'Agata sul Santerno",
    code: 'I196',
  },
  {
    name: "Sant'Agnello",
    code: 'I208',
  },
  {
    name: "Sant'Albano Stura",
    code: 'I210',
  },
  {
    name: "Sant'Alessio con Vialone",
    code: 'I213',
  },
  {
    name: "Sant'Alessio in Aspromonte",
    code: 'I214',
  },
  {
    name: "Sant'Alessio Siculo",
    code: 'I215',
  },
  {
    name: "Sant'Alfio",
    code: 'I216',
  },
  {
    name: "Sant'Ambrogio di Torino",
    code: 'I258',
  },
  {
    name: "Sant'Ambrogio di Valpolicella",
    code: 'I259',
  },
  {
    name: "Sant'Ambrogio sul Garigliano",
    code: 'I256',
  },
  {
    name: "Sant'Anastasia",
    code: 'I262',
  },
  {
    name: "Sant'Anatolia di Narco",
    code: 'I263',
  },
  {
    name: "Sant'Andrea Apostolo dello Ionio",
    code: 'I266',
  },
  {
    name: "Sant'Andrea del Garigliano",
    code: 'I265',
  },
  {
    name: "Sant'Andrea di Conza",
    code: 'I264',
  },
  {
    name: "Sant'Andrea Frius",
    code: 'I271',
  },
  {
    name: "Sant'Angelo a Cupolo",
    code: 'I277',
  },
  {
    name: "Sant'Angelo a Fasanella",
    code: 'I278',
  },
  {
    name: "Sant'Angelo a Scala",
    code: 'I280',
  },
  {
    name: "Sant'Angelo all'Esca",
    code: 'I279',
  },
  {
    name: "Sant'Angelo d'Alife",
    code: 'I273',
  },
  {
    name: "Sant'Angelo dei Lombardi",
    code: 'I281',
  },
  {
    name: "Sant'Angelo del Pesco",
    code: 'I282',
  },
  {
    name: "Sant'Angelo di Brolo",
    code: 'I283',
  },
  {
    name: "Sant'Angelo di Piove di Sacco",
    code: 'I275',
  },
  {
    name: "Sant'Angelo in Pontano",
    code: 'I286',
  },
  {
    name: "Sant'Angelo in Vado",
    code: 'I287',
  },
  {
    name: "Sant'Angelo Le Fratte",
    code: 'I288',
  },
  {
    name: "Sant'Angelo Limosano",
    code: 'I289',
  },
  {
    name: "Sant'Angelo Lodigiano",
    code: 'I274',
  },
  {
    name: "Sant'Angelo Lomellina",
    code: 'I276',
  },
  {
    name: "Sant'Angelo Muxaro",
    code: 'I290',
  },
  {
    name: "Sant'Angelo Romano",
    code: 'I284',
  },
  {
    name: "Sant'Anna Arresi",
    code: 'M209',
  },
  {
    name: "Sant'Anna d'Alfaedo",
    code: 'I292',
  },
  {
    name: "Sant'Antimo",
    code: 'I293',
  },
  {
    name: "Sant'Antioco",
    code: 'I294',
  },
  {
    name: "Sant'Antonino di Susa",
    code: 'I296',
  },
  {
    name: "Sant'Antonio Abate",
    code: 'I300',
  },
  {
    name: "Sant'Antonio di Gallura",
    code: 'M276',
  },
  {
    name: "Sant'Apollinare",
    code: 'I302',
  },
  {
    name: "Sant'Arcangelo",
    code: 'I305',
  },
  {
    name: "Sant'Arcangelo Trimonte",
    code: 'F557',
  },
  {
    name: "Sant'Arpino",
    code: 'I306',
  },
  {
    name: "Sant'Arsenio",
    code: 'I307',
  },
  {
    name: "Sant'Egidio alla Vibrata",
    code: 'I318',
  },
  {
    name: "Sant'Egidio del Monte Albino",
    code: 'I317',
  },
  {
    name: "Sant'Elena",
    code: 'I319',
  },
  {
    name: "Sant'Elena Sannita",
    code: 'B466',
  },
  {
    name: "Sant'Elia a Pianisi",
    code: 'I320',
  },
  {
    name: "Sant'Elia Fiumerapido",
    code: 'I321',
  },
  {
    name: "Sant'Elpidio a Mare",
    code: 'I324',
  },
  {
    name: "Sant'Eufemia a Maiella",
    code: 'I332',
  },
  {
    name: "Sant'Eufemia d'Aspromonte",
    code: 'I333',
  },
  {
    name: "Sant'Eusanio del Sangro",
    code: 'I335',
  },
  {
    name: "Sant'Eusanio Forconese",
    code: 'I336',
  },
  {
    name: "Sant'Ilario d'Enza",
    code: 'I342',
  },
  {
    name: "Sant'Ilario dello Ionio",
    code: 'I341',
  },
  {
    name: "Sant'Ippolito",
    code: 'I344',
  },
  {
    name: "Sant'Olcese",
    code: 'I346',
  },
  {
    name: "Sant'Omero",
    code: 'I348',
  },
  {
    name: "Sant'Omobono Terme",
    code: 'M333',
  },
  {
    name: "Sant'Onofrio",
    code: 'I350',
  },
  {
    name: "Sant'Oreste",
    code: 'I352',
  },
  {
    name: "Sant'Orsola Terme",
    code: 'I354',
  },
  {
    name: "Sant'Urbano",
    code: 'I375',
  },
  {
    name: 'Santa Brigida',
    code: 'I168',
  },
  {
    name: 'Santa Caterina Albanese',
    code: 'I171',
  },
  {
    name: 'Santa Caterina dello Ionio',
    code: 'I170',
  },
  {
    name: 'Santa Caterina Villarmosa',
    code: 'I169',
  },
  {
    name: 'Santa Cesarea Terme',
    code: 'I172',
  },
  {
    name: "Santa Cristina d'Aspromonte",
    code: 'I176',
  },
  {
    name: 'Santa Cristina e Bissone',
    code: 'I175',
  },
  {
    name: 'Santa Cristina Gela',
    code: 'I174',
  },
  {
    name: 'Santa Cristina Valgardena',
    code: 'I173',
  },
  {
    name: 'Santa Croce Camerina',
    code: 'I178',
  },
  {
    name: 'Santa Croce del Sannio',
    code: 'I179',
  },
  {
    name: 'Santa Croce di Magliano',
    code: 'I181',
  },
  {
    name: "Santa Croce sull'Arno",
    code: 'I177',
  },
  {
    name: 'Santa Domenica Talao',
    code: 'I183',
  },
  {
    name: 'Santa Domenica Vittoria',
    code: 'I184',
  },
  {
    name: 'Santa Elisabetta',
    code: 'I185',
  },
  {
    name: 'Santa Fiora',
    code: 'I187',
  },
  {
    name: 'Santa Flavia',
    code: 'I188',
  },
  {
    name: 'Santa Giuletta',
    code: 'I203',
  },
  {
    name: 'Santa Giusta',
    code: 'I205',
  },
  {
    name: 'Santa Giustina',
    code: 'I206',
  },
  {
    name: 'Santa Giustina in Colle',
    code: 'I207',
  },
  {
    name: 'Santa Luce',
    code: 'I217',
  },
  {
    name: 'Santa Lucia del Mela',
    code: 'I220',
  },
  {
    name: 'Santa Lucia di Piave',
    code: 'I221',
  },
  {
    name: 'Santa Lucia di Serino',
    code: 'I219',
  },
  {
    name: 'Santa Margherita di Belice',
    code: 'I224',
  },
  {
    name: 'Santa Margherita di Staffora',
    code: 'I230',
  },
  {
    name: 'Santa Margherita Ligure',
    code: 'I225',
  },
  {
    name: 'Santa Maria a Monte',
    code: 'I232',
  },
  {
    name: 'Santa Maria a Vico',
    code: 'I233',
  },
  {
    name: 'Santa Maria Capua Vetere',
    code: 'I234',
  },
  {
    name: 'Santa Maria Coghinas',
    code: 'M284',
  },
  {
    name: 'Santa Maria del Cedro',
    code: 'C717',
  },
  {
    name: 'Santa Maria del Molise',
    code: 'I238',
  },
  {
    name: 'Santa Maria della Versa',
    code: 'I237',
  },
  {
    name: 'Santa Maria di Licodia',
    code: 'I240',
  },
  {
    name: 'Santa Maria di Sala',
    code: 'I242',
  },
  {
    name: 'Santa Maria Hoè',
    code: 'I243',
  },
  {
    name: 'Santa Maria Imbaro',
    code: 'I244',
  },
  {
    name: 'Santa Maria la Carità',
    code: 'M273',
  },
  {
    name: 'Santa Maria la Fossa',
    code: 'I247',
  },
  {
    name: 'Santa Maria la Longa',
    code: 'I248',
  },
  {
    name: 'Santa Maria Maggiore',
    code: 'I249',
  },
  {
    name: 'Santa Maria Nuova',
    code: 'I251',
  },
  {
    name: 'Santa Marina',
    code: 'I253',
  },
  {
    name: 'Santa Marina Salina',
    code: 'I254',
  },
  {
    name: 'Santa Marinella',
    code: 'I255',
  },
  {
    name: 'Santa Ninfa',
    code: 'I291',
  },
  {
    name: 'Santa Paolina',
    code: 'I301',
  },
  {
    name: 'Santa Severina',
    code: 'I308',
  },
  {
    name: 'Santa Sofia',
    code: 'I310',
  },
  {
    name: "Santa Sofia d'Epiro",
    code: 'I309',
  },
  {
    name: 'Santa Teresa di Riva',
    code: 'I311',
  },
  {
    name: 'Santa Teresa Gallura',
    code: 'I312',
  },
  {
    name: 'Santa Venerina',
    code: 'I314',
  },
  {
    name: "Santa Vittoria d'Alba",
    code: 'I316',
  },
  {
    name: 'Santa Vittoria in Matenano',
    code: 'I315',
  },
  {
    name: 'Santadi',
    code: 'I182',
  },
  {
    name: 'Santarcangelo di Romagna',
    code: 'I304',
  },
  {
    name: 'Sante Marie',
    code: 'I326',
  },
  {
    name: 'Santena',
    code: 'I327',
  },
  {
    name: 'Santeramo in Colle',
    code: 'I330',
  },
  {
    name: 'Santhià',
    code: 'I337',
  },
  {
    name: 'Santi Cosma e Damiano',
    code: 'I339',
  },
  {
    name: 'Santo Stefano al Mare',
    code: 'I365',
  },
  {
    name: 'Santo Stefano Belbo',
    code: 'I367',
  },
  {
    name: "Santo Stefano d'Aveto",
    code: 'I368',
  },
  {
    name: 'Santo Stefano del Sole',
    code: 'I357',
  },
  {
    name: 'Santo Stefano di Cadore',
    code: 'C919',
  },
  {
    name: 'Santo Stefano di Camastra',
    code: 'I370',
  },
  {
    name: 'Santo Stefano di Magra',
    code: 'I363',
  },
  {
    name: 'Santo Stefano di Rogliano',
    code: 'I359',
  },
  {
    name: 'Santo Stefano di Sessanio',
    code: 'I360',
  },
  {
    name: 'Santo Stefano in Aspromonte',
    code: 'I371',
  },
  {
    name: 'Santo Stefano Lodigiano',
    code: 'I362',
  },
  {
    name: 'Santo Stefano Quisquina',
    code: 'I356',
  },
  {
    name: 'Santo Stefano Roero',
    code: 'I372',
  },
  {
    name: 'Santo Stefano Ticino',
    code: 'I361',
  },
  {
    name: 'Santomenna',
    code: 'I260',
  },
  {
    name: 'Santopadre',
    code: 'I351',
  },
  {
    name: 'Santorso',
    code: 'I353',
  },
  {
    name: 'Santu Lussurgiu',
    code: 'I374',
  },
  {
    name: 'Sanza',
    code: 'I410',
  },
  {
    name: 'Sanzeno',
    code: 'I411',
  },
  {
    name: 'Saonara',
    code: 'I418',
  },
  {
    name: 'Saponara',
    code: 'I420',
  },
  {
    name: 'Sappada',
    code: 'I421',
  },
  {
    name: 'Sapri',
    code: 'I422',
  },
  {
    name: 'Saracena',
    code: 'I423',
  },
  {
    name: 'Saracinesco',
    code: 'I424',
  },
  {
    name: 'Sarcedo',
    code: 'I425',
  },
  {
    name: 'Sarconi',
    code: 'I426',
  },
  {
    name: 'Sardara',
    code: 'I428',
  },
  {
    name: 'Sardigliano',
    code: 'I429',
  },
  {
    name: 'Sarego',
    code: 'I430',
  },
  {
    name: 'Sarentino',
    code: 'I431',
  },
  {
    name: 'Sarezzano',
    code: 'I432',
  },
  {
    name: 'Sarezzo',
    code: 'I433',
  },
  {
    name: 'Sarmato',
    code: 'I434',
  },
  {
    name: 'Sarmede',
    code: 'I435',
  },
  {
    name: 'Sarnano',
    code: 'I436',
  },
  {
    name: 'Sarnico',
    code: 'I437',
  },
  {
    name: 'Sarno',
    code: 'I438',
  },
  {
    name: 'Sarnonico',
    code: 'I439',
  },
  {
    name: 'Saronno',
    code: 'I441',
  },
  {
    name: 'Sarre',
    code: 'I442',
  },
  {
    name: 'Sarroch',
    code: 'I443',
  },
  {
    name: 'Sarsina',
    code: 'I444',
  },
  {
    name: 'Sarteano',
    code: 'I445',
  },
  {
    name: 'Sartirana Lomellina',
    code: 'I447',
  },
  {
    name: 'Sarule',
    code: 'I448',
  },
  {
    name: 'Sarzana',
    code: 'I449',
  },
  {
    name: 'Sassano',
    code: 'I451',
  },
  {
    name: 'Sassari',
    code: 'I452',
  },
  {
    name: 'Sassello',
    code: 'I453',
  },
  {
    name: 'Sassetta',
    code: 'I454',
  },
  {
    name: 'Sassinoro',
    code: 'I455',
  },
  {
    name: 'Sasso di Castalda',
    code: 'I457',
  },
  {
    name: 'Sasso Marconi',
    code: 'G972',
  },
  {
    name: 'Sassocorvaro Auditore',
    code: 'M413',
  },
  {
    name: 'Sassofeltrio',
    code: 'I460',
  },
  {
    name: 'Sassoferrato',
    code: 'I461',
  },
  {
    name: 'Sassuolo',
    code: 'I462',
  },
  {
    name: 'Satriano',
    code: 'I463',
  },
  {
    name: 'Satriano di Lucania',
    code: 'G614',
  },
  {
    name: 'Sauris',
    code: 'I464',
  },
  {
    name: "Sauze d'Oulx",
    code: 'I466',
  },
  {
    name: 'Sauze di Cesana',
    code: 'I465',
  },
  {
    name: 'Sava',
    code: 'I467',
  },
  {
    name: 'Savelli',
    code: 'I468',
  },
  {
    name: 'Saviano',
    code: 'I469',
  },
  {
    name: 'Savigliano',
    code: 'I470',
  },
  {
    name: 'Savignano Irpino',
    code: 'I471',
  },
  {
    name: 'Savignano sul Panaro',
    code: 'I473',
  },
  {
    name: 'Savignano sul Rubicone',
    code: 'I472',
  },
  {
    name: 'Savignone',
    code: 'I475',
  },
  {
    name: "Saviore dell'Adamello",
    code: 'I476',
  },
  {
    name: 'Savoca',
    code: 'I477',
  },
  {
    name: 'Savogna',
    code: 'I478',
  },
  {
    name: "Savogna d'Isonzo",
    code: 'I479',
  },
  {
    name: 'Savoia di Lucania',
    code: 'H730',
  },
  {
    name: 'Savona',
    code: 'I480',
  },
  {
    name: 'Scafa',
    code: 'I482',
  },
  {
    name: 'Scafati',
    code: 'I483',
  },
  {
    name: 'Scagnello',
    code: 'I484',
  },
  {
    name: 'Scala',
    code: 'I486',
  },
  {
    name: 'Scala Coeli',
    code: 'I485',
  },
  {
    name: 'Scaldasole',
    code: 'I487',
  },
  {
    name: 'Scalea',
    code: 'I489',
  },
  {
    name: 'Scalenghe',
    code: 'I490',
  },
  {
    name: 'Scaletta Zanclea',
    code: 'I492',
  },
  {
    name: 'Scampitella',
    code: 'I493',
  },
  {
    name: 'Scandale',
    code: 'I494',
  },
  {
    name: 'Scandiano',
    code: 'I496',
  },
  {
    name: 'Scandicci',
    code: 'B962',
  },
  {
    name: 'Scandolara Ravara',
    code: 'I497',
  },
  {
    name: "Scandolara Ripa d'Oglio",
    code: 'I498',
  },
  {
    name: 'Scandriglia',
    code: 'I499',
  },
  {
    name: 'Scanno',
    code: 'I501',
  },
  {
    name: 'Scano di Montiferro',
    code: 'I503',
  },
  {
    name: 'Scansano',
    code: 'I504',
  },
  {
    name: 'Scanzano Jonico',
    code: 'M256',
  },
  {
    name: 'Scanzorosciate',
    code: 'I506',
  },
  {
    name: 'Scapoli',
    code: 'I507',
  },
  {
    name: 'Scarlino',
    code: 'I510',
  },
  {
    name: 'Scarmagno',
    code: 'I511',
  },
  {
    name: 'Scarnafigi',
    code: 'I512',
  },
  {
    name: 'Scarperia e San Piero',
    code: 'M326',
  },
  {
    name: 'Scena',
    code: 'I519',
  },
  {
    name: 'Scerni',
    code: 'I520',
  },
  {
    name: 'Scheggia e Pascelupo',
    code: 'I522',
  },
  {
    name: 'Scheggino',
    code: 'I523',
  },
  {
    name: 'Schiavi di Abruzzo',
    code: 'I526',
  },
  {
    name: 'Schiavon',
    code: 'I527',
  },
  {
    name: 'Schignano',
    code: 'I529',
  },
  {
    name: 'Schilpario',
    code: 'I530',
  },
  {
    name: 'Schio',
    code: 'I531',
  },
  {
    name: 'Schivenoglia',
    code: 'I532',
  },
  {
    name: 'Sciacca',
    code: 'I533',
  },
  {
    name: 'Sciara',
    code: 'I534',
  },
  {
    name: 'Scicli',
    code: 'I535',
  },
  {
    name: 'Scido',
    code: 'I536',
  },
  {
    name: 'Scigliano',
    code: 'D290',
  },
  {
    name: 'Scilla',
    code: 'I537',
  },
  {
    name: 'Scillato',
    code: 'I538',
  },
  {
    name: 'Sciolze',
    code: 'I539',
  },
  {
    name: 'Scisciano',
    code: 'I540',
  },
  {
    name: 'Sclafani Bagni',
    code: 'I541',
  },
  {
    name: 'Scontrone',
    code: 'I543',
  },
  {
    name: 'Scopa',
    code: 'I544',
  },
  {
    name: 'Scopello',
    code: 'I545',
  },
  {
    name: 'Scoppito',
    code: 'I546',
  },
  {
    name: 'Scordia',
    code: 'I548',
  },
  {
    name: 'Scorrano',
    code: 'I549',
  },
  {
    name: 'Scorzè',
    code: 'I551',
  },
  {
    name: 'Scurcola Marsicana',
    code: 'I553',
  },
  {
    name: 'Scurelle',
    code: 'I554',
  },
  {
    name: 'Scurzolengo',
    code: 'I555',
  },
  {
    name: 'Seborga',
    code: 'I556',
  },
  {
    name: 'Secinaro',
    code: 'I558',
  },
  {
    name: 'Seclì',
    code: 'I559',
  },
  {
    name: 'Secugnago',
    code: 'I561',
  },
  {
    name: 'Sedegliano',
    code: 'I562',
  },
  {
    name: 'Sedico',
    code: 'I563',
  },
  {
    name: 'Sedilo',
    code: 'I564',
  },
  {
    name: 'Sedini',
    code: 'I565',
  },
  {
    name: 'Sedriano',
    code: 'I566',
  },
  {
    name: 'Sedrina',
    code: 'I567',
  },
  {
    name: 'Sefro',
    code: 'I569',
  },
  {
    name: 'Segariu',
    code: 'I570',
  },
  {
    name: 'Seggiano',
    code: 'I571',
  },
  {
    name: 'Segni',
    code: 'I573',
  },
  {
    name: 'Segonzano',
    code: 'I576',
  },
  {
    name: 'Segrate',
    code: 'I577',
  },
  {
    name: 'Segusino',
    code: 'I578',
  },
  {
    name: 'Selargius',
    code: 'I580',
  },
  {
    name: 'Selci',
    code: 'I581',
  },
  {
    name: 'Selegas',
    code: 'I582',
  },
  {
    name: 'Sella Giudicarie',
    code: 'M360',
  },
  {
    name: 'Sellano',
    code: 'I585',
  },
  {
    name: 'Sellero',
    code: 'I588',
  },
  {
    name: 'Sellia',
    code: 'I589',
  },
  {
    name: 'Sellia Marina',
    code: 'I590',
  },
  {
    name: 'Selva dei Molini',
    code: 'I593',
  },
  {
    name: 'Selva di Cadore',
    code: 'I592',
  },
  {
    name: 'Selva di Progno',
    code: 'I594',
  },
  {
    name: 'Selva di Val Gardena',
    code: 'I591',
  },
  {
    name: 'Selvazzano Dentro',
    code: 'I595',
  },
  {
    name: 'Selvino',
    code: 'I597',
  },
  {
    name: 'Semestene',
    code: 'I598',
  },
  {
    name: 'Semiana',
    code: 'I599',
  },
  {
    name: 'Seminara',
    code: 'I600',
  },
  {
    name: 'Semproniano',
    code: 'I601',
  },
  {
    name: 'Senago',
    code: 'I602',
  },
  {
    name: 'Senale-San Felice',
    code: 'I603',
  },
  {
    name: 'Senales',
    code: 'I604',
  },
  {
    name: 'Seneghe',
    code: 'I605',
  },
  {
    name: 'Senerchia',
    code: 'I606',
  },
  {
    name: 'Seniga',
    code: 'I607',
  },
  {
    name: 'Senigallia',
    code: 'I608',
  },
  {
    name: 'Senis',
    code: 'I609',
  },
  {
    name: 'Senise',
    code: 'I610',
  },
  {
    name: 'Senna Comasco',
    code: 'I611',
  },
  {
    name: 'Senna Lodigiana',
    code: 'I612',
  },
  {
    name: 'Sennariolo',
    code: 'I613',
  },
  {
    name: 'Sennori',
    code: 'I614',
  },
  {
    name: 'Senorbì',
    code: 'I615',
  },
  {
    name: 'Sepino',
    code: 'I618',
  },
  {
    name: 'Sequals',
    code: 'I621',
  },
  {
    name: 'Seravezza',
    code: 'I622',
  },
  {
    name: 'Serdiana',
    code: 'I624',
  },
  {
    name: 'Seregno',
    code: 'I625',
  },
  {
    name: 'Seren del Grappa',
    code: 'I626',
  },
  {
    name: 'Sergnano',
    code: 'I627',
  },
  {
    name: 'Seriate',
    code: 'I628',
  },
  {
    name: 'Serina',
    code: 'I629',
  },
  {
    name: 'Serino',
    code: 'I630',
  },
  {
    name: 'Serle',
    code: 'I631',
  },
  {
    name: 'Sermide e Felonica',
    code: 'I632',
  },
  {
    name: 'Sermoneta',
    code: 'I634',
  },
  {
    name: 'Sernaglia della Battaglia',
    code: 'I635',
  },
  {
    name: 'Sernio',
    code: 'I636',
  },
  {
    name: 'Serole',
    code: 'I637',
  },
  {
    name: "Serra d'Aiello",
    code: 'I642',
  },
  {
    name: "Serra de' Conti",
    code: 'I643',
  },
  {
    name: 'Serra Riccò',
    code: 'I640',
  },
  {
    name: 'Serra San Bruno',
    code: 'I639',
  },
  {
    name: 'Serra San Quirico',
    code: 'I653',
  },
  {
    name: "Serra Sant'Abbondio",
    code: 'I654',
  },
  {
    name: 'Serracapriola',
    code: 'I641',
  },
  {
    name: 'Serradifalco',
    code: 'I644',
  },
  {
    name: "Serralunga d'Alba",
    code: 'I646',
  },
  {
    name: 'Serralunga di Crea',
    code: 'I645',
  },
  {
    name: 'Serramanna',
    code: 'I647',
  },
  {
    name: 'Serramazzoni',
    code: 'F357',
  },
  {
    name: 'Serramezzana',
    code: 'I648',
  },
  {
    name: 'Serramonacesca',
    code: 'I649',
  },
  {
    name: 'Serrapetrona',
    code: 'I651',
  },
  {
    name: 'Serrara Fontana',
    code: 'I652',
  },
  {
    name: 'Serrastretta',
    code: 'I655',
  },
  {
    name: 'Serrata',
    code: 'I656',
  },
  {
    name: 'Serravalle a Po',
    code: 'I662',
  },
  {
    name: 'Serravalle di Chienti',
    code: 'I661',
  },
  {
    name: 'Serravalle Langhe',
    code: 'I659',
  },
  {
    name: 'Serravalle Pistoiese',
    code: 'I660',
  },
  {
    name: 'Serravalle Scrivia',
    code: 'I657',
  },
  {
    name: 'Serravalle Sesia',
    code: 'I663',
  },
  {
    name: 'Serre',
    code: 'I666',
  },
  {
    name: 'Serrenti',
    code: 'I667',
  },
  {
    name: 'Serri',
    code: 'I668',
  },
  {
    name: 'Serrone',
    code: 'I669',
  },
  {
    name: 'Sersale',
    code: 'I671',
  },
  {
    name: 'Servigliano',
    code: 'C070',
  },
  {
    name: 'Sessa Aurunca',
    code: 'I676',
  },
  {
    name: 'Sessa Cilento',
    code: 'I677',
  },
  {
    name: 'Sessame',
    code: 'I678',
  },
  {
    name: 'Sessano del Molise',
    code: 'I679',
  },
  {
    name: 'Sesta Godano',
    code: 'E070',
  },
  {
    name: 'Sestino',
    code: 'I681',
  },
  {
    name: 'Sesto',
    code: 'I687',
  },
  {
    name: 'Sesto al Reghena',
    code: 'I686',
  },
  {
    name: 'Sesto Calende',
    code: 'I688',
  },
  {
    name: 'Sesto Campano',
    code: 'I682',
  },
  {
    name: 'Sesto ed Uniti',
    code: 'I683',
  },
  {
    name: 'Sesto Fiorentino',
    code: 'I684',
  },
  {
    name: 'Sesto San Giovanni',
    code: 'I690',
  },
  {
    name: 'Sestola',
    code: 'I689',
  },
  {
    name: 'Sestri Levante',
    code: 'I693',
  },
  {
    name: 'Sestriere',
    code: 'I692',
  },
  {
    name: 'Sestu',
    code: 'I695',
  },
  {
    name: 'Settala',
    code: 'I696',
  },
  {
    name: 'Settefrati',
    code: 'I697',
  },
  {
    name: 'Settime',
    code: 'I698',
  },
  {
    name: 'Settimo Milanese',
    code: 'I700',
  },
  {
    name: 'Settimo Rottaro',
    code: 'I701',
  },
  {
    name: 'Settimo San Pietro',
    code: 'I699',
  },
  {
    name: 'Settimo Torinese',
    code: 'I703',
  },
  {
    name: 'Settimo Vittone',
    code: 'I702',
  },
  {
    name: 'Settingiano',
    code: 'I704',
  },
  {
    name: 'Setzu',
    code: 'I705',
  },
  {
    name: 'Seui',
    code: 'I706',
  },
  {
    name: 'Seulo',
    code: 'I707',
  },
  {
    name: 'Seveso',
    code: 'I709',
  },
  {
    name: 'Sezzadio',
    code: 'I711',
  },
  {
    name: 'Sezze',
    code: 'I712',
  },
  {
    name: 'Sfruz',
    code: 'I714',
  },
  {
    name: 'Sgonico',
    code: 'I715',
  },
  {
    name: 'Sgurgola',
    code: 'I716',
  },
  {
    name: 'Siamaggiore',
    code: 'I717',
  },
  {
    name: 'Siamanna',
    code: 'I718',
  },
  {
    name: 'Siano',
    code: 'I720',
  },
  {
    name: 'Siapiccia',
    code: 'I721',
  },
  {
    name: 'Sicignano degli Alburni',
    code: 'M253',
  },
  {
    name: 'Siculiana',
    code: 'I723',
  },
  {
    name: 'Siddi',
    code: 'I724',
  },
  {
    name: 'Siderno',
    code: 'I725',
  },
  {
    name: 'Siena',
    code: 'I726',
  },
  {
    name: 'Sigillo',
    code: 'I727',
  },
  {
    name: 'Signa',
    code: 'I728',
  },
  {
    name: 'Silandro',
    code: 'I729',
  },
  {
    name: 'Silanus',
    code: 'I730',
  },
  {
    name: 'Silea',
    code: 'F116',
  },
  {
    name: 'Siligo',
    code: 'I732',
  },
  {
    name: 'Siliqua',
    code: 'I734',
  },
  {
    name: 'Silius',
    code: 'I735',
  },
  {
    name: 'Sillano Giuncugnano',
    code: 'M347',
  },
  {
    name: 'Sillavengo',
    code: 'I736',
  },
  {
    name: "Silvano d'Orba",
    code: 'I738',
  },
  {
    name: 'Silvano Pietra',
    code: 'I739',
  },
  {
    name: 'Silvi',
    code: 'I741',
  },
  {
    name: 'Simala',
    code: 'I742',
  },
  {
    name: 'Simaxis',
    code: 'I743',
  },
  {
    name: 'Simbario',
    code: 'I744',
  },
  {
    name: 'Simeri Crichi',
    code: 'I745',
  },
  {
    name: 'Sinagra',
    code: 'I747',
  },
  {
    name: 'Sinalunga',
    code: 'A468',
  },
  {
    name: 'Sindia',
    code: 'I748',
  },
  {
    name: 'Sini',
    code: 'I749',
  },
  {
    name: 'Sinio',
    code: 'I750',
  },
  {
    name: 'Siniscola',
    code: 'I751',
  },
  {
    name: 'Sinnai',
    code: 'I752',
  },
  {
    name: 'Sinopoli',
    code: 'I753',
  },
  {
    name: 'Siracusa',
    code: 'I754',
  },
  {
    name: 'Sirignano',
    code: 'I756',
  },
  {
    name: 'Siris',
    code: 'I757',
  },
  {
    name: 'Sirmione',
    code: 'I633',
  },
  {
    name: 'Sirolo',
    code: 'I758',
  },
  {
    name: 'Sirone',
    code: 'I759',
  },
  {
    name: 'Sirtori',
    code: 'I761',
  },
  {
    name: 'Sissa Trecasali',
    code: 'M325',
  },
  {
    name: 'Siurgus Donigala',
    code: 'I765',
  },
  {
    name: 'Siziano',
    code: 'E265',
  },
  {
    name: 'Sizzano',
    code: 'I767',
  },
  {
    name: 'Sluderno',
    code: 'I771',
  },
  {
    name: 'Smerillo',
    code: 'I774',
  },
  {
    name: 'Soave',
    code: 'I775',
  },
  {
    name: 'Socchieve',
    code: 'I777',
  },
  {
    name: 'Soddì',
    code: 'I778',
  },
  {
    name: 'Sogliano al Rubicone',
    code: 'I779',
  },
  {
    name: 'Sogliano Cavour',
    code: 'I780',
  },
  {
    name: 'Soglio',
    code: 'I781',
  },
  {
    name: 'Soiano del Lago',
    code: 'I782',
  },
  {
    name: 'Solagna',
    code: 'I783',
  },
  {
    name: 'Solarino',
    code: 'I785',
  },
  {
    name: 'Solaro',
    code: 'I786',
  },
  {
    name: 'Solarolo',
    code: 'I787',
  },
  {
    name: 'Solarolo Rainerio',
    code: 'I790',
  },
  {
    name: 'Solarussa',
    code: 'I791',
  },
  {
    name: 'Solbiate Arno',
    code: 'I793',
  },
  {
    name: 'Solbiate con Cagno',
    code: 'M412',
  },
  {
    name: 'Solbiate Olona',
    code: 'I794',
  },
  {
    name: 'Soldano',
    code: 'I796',
  },
  {
    name: 'Soleminis',
    code: 'I797',
  },
  {
    name: 'Solero',
    code: 'I798',
  },
  {
    name: 'Solesino',
    code: 'I799',
  },
  {
    name: 'Soleto',
    code: 'I800',
  },
  {
    name: 'Solferino',
    code: 'I801',
  },
  {
    name: 'Soliera',
    code: 'I802',
  },
  {
    name: 'Solignano',
    code: 'I803',
  },
  {
    name: 'Solofra',
    code: 'I805',
  },
  {
    name: 'Solonghello',
    code: 'I808',
  },
  {
    name: 'Solopaca',
    code: 'I809',
  },
  {
    name: 'Solto Collina',
    code: 'I812',
  },
  {
    name: 'Solza',
    code: 'I813',
  },
  {
    name: 'Somaglia',
    code: 'I815',
  },
  {
    name: 'Somano',
    code: 'I817',
  },
  {
    name: 'Somma Lombardo',
    code: 'I819',
  },
  {
    name: 'Somma Vesuviana',
    code: 'I820',
  },
  {
    name: 'Sommacampagna',
    code: 'I821',
  },
  {
    name: 'Sommariva del Bosco',
    code: 'I822',
  },
  {
    name: 'Sommariva Perno',
    code: 'I823',
  },
  {
    name: 'Sommatino',
    code: 'I824',
  },
  {
    name: 'Sommo',
    code: 'I825',
  },
  {
    name: 'Sona',
    code: 'I826',
  },
  {
    name: 'Soncino',
    code: 'I827',
  },
  {
    name: 'Sondalo',
    code: 'I828',
  },
  {
    name: 'Sondrio',
    code: 'I829',
  },
  {
    name: 'Songavazzo',
    code: 'I830',
  },
  {
    name: 'Sonico',
    code: 'I831',
  },
  {
    name: 'Sonnino',
    code: 'I832',
  },
  {
    name: 'Sora',
    code: 'I838',
  },
  {
    name: 'Soraga di Fassa',
    code: 'I839',
  },
  {
    name: 'Soragna',
    code: 'I840',
  },
  {
    name: 'Sorano',
    code: 'I841',
  },
  {
    name: 'Sorbo San Basile',
    code: 'I844',
  },
  {
    name: 'Sorbo Serpico',
    code: 'I843',
  },
  {
    name: 'Sorbolo Mezzani',
    code: 'M411',
  },
  {
    name: 'Sordevolo',
    code: 'I847',
  },
  {
    name: 'Sordio',
    code: 'I848',
  },
  {
    name: 'Soresina',
    code: 'I849',
  },
  {
    name: 'Sorgà',
    code: 'I850',
  },
  {
    name: 'Sorgono',
    code: 'I851',
  },
  {
    name: 'Sori',
    code: 'I852',
  },
  {
    name: 'Sorianello',
    code: 'I853',
  },
  {
    name: 'Soriano Calabro',
    code: 'I854',
  },
  {
    name: 'Soriano nel Cimino',
    code: 'I855',
  },
  {
    name: 'Sorico',
    code: 'I856',
  },
  {
    name: 'Soriso',
    code: 'I857',
  },
  {
    name: 'Sorisole',
    code: 'I858',
  },
  {
    name: 'Sormano',
    code: 'I860',
  },
  {
    name: 'Sorradile',
    code: 'I861',
  },
  {
    name: 'Sorrento',
    code: 'I862',
  },
  {
    name: 'Sorso',
    code: 'I863',
  },
  {
    name: 'Sortino',
    code: 'I864',
  },
  {
    name: 'Sospiro',
    code: 'I865',
  },
  {
    name: 'Sospirolo',
    code: 'I866',
  },
  {
    name: 'Sossano',
    code: 'I867',
  },
  {
    name: 'Sostegno',
    code: 'I868',
  },
  {
    name: 'Sotto il Monte Giovanni XXIII',
    code: 'I869',
  },
  {
    name: 'Sover',
    code: 'I871',
  },
  {
    name: 'Soverato',
    code: 'I872',
  },
  {
    name: 'Sovere',
    code: 'I873',
  },
  {
    name: 'Soveria Mannelli',
    code: 'I874',
  },
  {
    name: 'Soveria Simeri',
    code: 'I875',
  },
  {
    name: 'Soverzene',
    code: 'I876',
  },
  {
    name: 'Sovicille',
    code: 'I877',
  },
  {
    name: 'Sovico',
    code: 'I878',
  },
  {
    name: 'Sovizzo',
    code: 'I879',
  },
  {
    name: 'Sovramonte',
    code: 'I673',
  },
  {
    name: 'Sozzago',
    code: 'I880',
  },
  {
    name: 'Spadafora',
    code: 'I881',
  },
  {
    name: 'Spadola',
    code: 'I884',
  },
  {
    name: 'Sparanise',
    code: 'I885',
  },
  {
    name: 'Sparone',
    code: 'I886',
  },
  {
    name: 'Specchia',
    code: 'I887',
  },
  {
    name: 'Spello',
    code: 'I888',
  },
  {
    name: 'Sperlinga',
    code: 'I891',
  },
  {
    name: 'Sperlonga',
    code: 'I892',
  },
  {
    name: 'Sperone',
    code: 'I893',
  },
  {
    name: 'Spessa',
    code: 'I894',
  },
  {
    name: 'Spezzano Albanese',
    code: 'I895',
  },
  {
    name: 'Spezzano della Sila',
    code: 'I896',
  },
  {
    name: 'Spiazzo',
    code: 'I899',
  },
  {
    name: 'Spigno Monferrato',
    code: 'I901',
  },
  {
    name: 'Spigno Saturnia',
    code: 'I902',
  },
  {
    name: 'Spilamberto',
    code: 'I903',
  },
  {
    name: 'Spilimbergo',
    code: 'I904',
  },
  {
    name: 'Spilinga',
    code: 'I905',
  },
  {
    name: 'Spinadesco',
    code: 'I906',
  },
  {
    name: 'Spinazzola',
    code: 'I907',
  },
  {
    name: 'Spinea',
    code: 'I908',
  },
  {
    name: 'Spineda',
    code: 'I909',
  },
  {
    name: 'Spinete',
    code: 'I910',
  },
  {
    name: 'Spineto Scrivia',
    code: 'I911',
  },
  {
    name: 'Spinetoli',
    code: 'I912',
  },
  {
    name: "Spino d'Adda",
    code: 'I914',
  },
  {
    name: 'Spinone al Lago',
    code: 'I916',
  },
  {
    name: 'Spinoso',
    code: 'I917',
  },
  {
    name: 'Spirano',
    code: 'I919',
  },
  {
    name: 'Spoleto',
    code: 'I921',
  },
  {
    name: 'Spoltore',
    code: 'I922',
  },
  {
    name: 'Spongano',
    code: 'I923',
  },
  {
    name: 'Spormaggiore',
    code: 'I924',
  },
  {
    name: 'Sporminore',
    code: 'I925',
  },
  {
    name: 'Spotorno',
    code: 'I926',
  },
  {
    name: 'Spresiano',
    code: 'I927',
  },
  {
    name: 'Spriana',
    code: 'I928',
  },
  {
    name: 'Squillace',
    code: 'I929',
  },
  {
    name: 'Squinzano',
    code: 'I930',
  },
  {
    name: 'Staffolo',
    code: 'I932',
  },
  {
    name: 'Stagno Lombardo',
    code: 'I935',
  },
  {
    name: 'Staiti',
    code: 'I936',
  },
  {
    name: 'Stalettì',
    code: 'I937',
  },
  {
    name: 'Stanghella',
    code: 'I938',
  },
  {
    name: 'Staranzano',
    code: 'I939',
  },
  {
    name: 'Statte',
    code: 'M298',
  },
  {
    name: 'Stazzano',
    code: 'I941',
  },
  {
    name: 'Stazzema',
    code: 'I942',
  },
  {
    name: 'Stazzona',
    code: 'I943',
  },
  {
    name: 'Stefanaconi',
    code: 'I945',
  },
  {
    name: 'Stella',
    code: 'I946',
  },
  {
    name: 'Stella Cilento',
    code: 'G887',
  },
  {
    name: 'Stellanello',
    code: 'I947',
  },
  {
    name: 'Stelvio',
    code: 'I948',
  },
  {
    name: 'Stenico',
    code: 'I949',
  },
  {
    name: 'Sternatia',
    code: 'I950',
  },
  {
    name: 'Stezzano',
    code: 'I951',
  },
  {
    name: 'Stienta',
    code: 'I953',
  },
  {
    name: 'Stigliano',
    code: 'I954',
  },
  {
    name: 'Stignano',
    code: 'I955',
  },
  {
    name: 'Stilo',
    code: 'I956',
  },
  {
    name: 'Stimigliano',
    code: 'I959',
  },
  {
    name: 'Stintino',
    code: 'M290',
  },
  {
    name: 'Stio',
    code: 'I960',
  },
  {
    name: 'Stornara',
    code: 'I962',
  },
  {
    name: 'Stornarella',
    code: 'I963',
  },
  {
    name: 'Storo',
    code: 'I964',
  },
  {
    name: 'Stra',
    code: 'I965',
  },
  {
    name: 'Stradella',
    code: 'I968',
  },
  {
    name: 'Strambinello',
    code: 'I969',
  },
  {
    name: 'Strambino',
    code: 'I970',
  },
  {
    name: 'Strangolagalli',
    code: 'I973',
  },
  {
    name: 'Stregna',
    code: 'I974',
  },
  {
    name: 'Strembo',
    code: 'I975',
  },
  {
    name: 'Stresa',
    code: 'I976',
  },
  {
    name: 'Strevi',
    code: 'I977',
  },
  {
    name: 'Striano',
    code: 'I978',
  },
  {
    name: 'Strona',
    code: 'I980',
  },
  {
    name: 'Stroncone',
    code: 'I981',
  },
  {
    name: 'Strongoli',
    code: 'I982',
  },
  {
    name: 'Stroppiana',
    code: 'I984',
  },
  {
    name: 'Stroppo',
    code: 'I985',
  },
  {
    name: 'Strozza',
    code: 'I986',
  },
  {
    name: 'Sturno',
    code: 'I990',
  },
  {
    name: 'Suardi',
    code: 'B014',
  },
  {
    name: 'Subbiano',
    code: 'I991',
  },
  {
    name: 'Subiaco',
    code: 'I992',
  },
  {
    name: 'Succivo',
    code: 'I993',
  },
  {
    name: 'Sueglio',
    code: 'I994',
  },
  {
    name: 'Suelli',
    code: 'I995',
  },
  {
    name: 'Suello',
    code: 'I996',
  },
  {
    name: 'Suisio',
    code: 'I997',
  },
  {
    name: 'Sulbiate',
    code: 'I998',
  },
  {
    name: 'Sulmona',
    code: 'I804',
  },
  {
    name: 'Sulzano',
    code: 'L002',
  },
  {
    name: 'Sumirago',
    code: 'L003',
  },
  {
    name: 'Summonte',
    code: 'L004',
  },
  {
    name: 'Suni',
    code: 'L006',
  },
  {
    name: 'Suno',
    code: 'L007',
  },
  {
    name: 'Supersano',
    code: 'L008',
  },
  {
    name: 'Supino',
    code: 'L009',
  },
  {
    name: 'Surano',
    code: 'L010',
  },
  {
    name: 'Surbo',
    code: 'L011',
  },
  {
    name: 'Susa',
    code: 'L013',
  },
  {
    name: 'Susegana',
    code: 'L014',
  },
  {
    name: 'Sustinente',
    code: 'L015',
  },
  {
    name: 'Sutera',
    code: 'L016',
  },
  {
    name: 'Sutri',
    code: 'L017',
  },
  {
    name: 'Sutrio',
    code: 'L018',
  },
  {
    name: 'Suvereto',
    code: 'L019',
  },
  {
    name: 'Suzzara',
    code: 'L020',
  },
  {
    name: 'Taceno',
    code: 'L022',
  },
  {
    name: 'Tadasuni',
    code: 'L023',
  },
  {
    name: 'Taggia',
    code: 'L024',
  },
  {
    name: 'Tagliacozzo',
    code: 'L025',
  },
  {
    name: 'Taglio di Po',
    code: 'L026',
  },
  {
    name: 'Tagliolo Monferrato',
    code: 'L027',
  },
  {
    name: 'Taibon Agordino',
    code: 'L030',
  },
  {
    name: 'Taino',
    code: 'L032',
  },
  {
    name: 'Taipana',
    code: 'G736',
  },
  {
    name: 'Talamello',
    code: 'L034',
  },
  {
    name: 'Talamona',
    code: 'L035',
  },
  {
    name: 'Talana',
    code: 'L036',
  },
  {
    name: 'Taleggio',
    code: 'L037',
  },
  {
    name: 'Talla',
    code: 'L038',
  },
  {
    name: 'Talmassons',
    code: 'L039',
  },
  {
    name: 'Tambre',
    code: 'L040',
  },
  {
    name: 'Taormina',
    code: 'L042',
  },
  {
    name: 'Tarano',
    code: 'L046',
  },
  {
    name: 'Taranta Peligna',
    code: 'L047',
  },
  {
    name: 'Tarantasca',
    code: 'L048',
  },
  {
    name: 'Taranto',
    code: 'L049',
  },
  {
    name: 'Tarcento',
    code: 'L050',
  },
  {
    name: 'Tarquinia',
    code: 'D024',
  },
  {
    name: 'Tarsia',
    code: 'L055',
  },
  {
    name: 'Tartano',
    code: 'L056',
  },
  {
    name: 'Tarvisio',
    code: 'L057',
  },
  {
    name: 'Tarzo',
    code: 'L058',
  },
  {
    name: 'Tassarolo',
    code: 'L059',
  },
  {
    name: 'Taurano',
    code: 'L061',
  },
  {
    name: 'Taurasi',
    code: 'L062',
  },
  {
    name: 'Taurianova',
    code: 'L063',
  },
  {
    name: 'Taurisano',
    code: 'L064',
  },
  {
    name: 'Tavagnacco',
    code: 'L065',
  },
  {
    name: 'Tavagnasco',
    code: 'L066',
  },
  {
    name: 'Tavazzano con Villavesco',
    code: 'F260',
  },
  {
    name: 'Tavenna',
    code: 'L069',
  },
  {
    name: 'Taverna',
    code: 'L070',
  },
  {
    name: 'Tavernerio',
    code: 'L071',
  },
  {
    name: 'Tavernola Bergamasca',
    code: 'L073',
  },
  {
    name: 'Tavernole sul Mella',
    code: 'C698',
  },
  {
    name: 'Taviano',
    code: 'L074',
  },
  {
    name: 'Tavigliano',
    code: 'L075',
  },
  {
    name: 'Tavoleto',
    code: 'L078',
  },
  {
    name: 'Tavullia',
    code: 'L081',
  },
  {
    name: 'Teana',
    code: 'L082',
  },
  {
    name: 'Teano',
    code: 'L083',
  },
  {
    name: 'Teggiano',
    code: 'D292',
  },
  {
    name: 'Teglio',
    code: 'L084',
  },
  {
    name: 'Teglio Veneto',
    code: 'L085',
  },
  {
    name: 'Telese Terme',
    code: 'L086',
  },
  {
    name: 'Telgate',
    code: 'L087',
  },
  {
    name: 'Telti',
    code: 'L088',
  },
  {
    name: 'Telve',
    code: 'L089',
  },
  {
    name: 'Telve di Sopra',
    code: 'L090',
  },
  {
    name: 'Tempio Pausania',
    code: 'L093',
  },
  {
    name: 'Temù',
    code: 'L094',
  },
  {
    name: 'Tenna',
    code: 'L096',
  },
  {
    name: 'Tenno',
    code: 'L097',
  },
  {
    name: 'Teolo',
    code: 'L100',
  },
  {
    name: 'Teora',
    code: 'L102',
  },
  {
    name: 'Teramo',
    code: 'L103',
  },
  {
    name: 'Terdobbiate',
    code: 'L104',
  },
  {
    name: 'Terelle',
    code: 'L105',
  },
  {
    name: 'Terento',
    code: 'L106',
  },
  {
    name: 'Terenzo',
    code: 'E548',
  },
  {
    name: 'Tergu',
    code: 'M282',
  },
  {
    name: 'Terlano',
    code: 'L108',
  },
  {
    name: 'Terlizzi',
    code: 'L109',
  },
  {
    name: 'Terme Vigliatore',
    code: 'M210',
  },
  {
    name: 'Termeno sulla strada del vino',
    code: 'L111',
  },
  {
    name: 'Termini Imerese',
    code: 'L112',
  },
  {
    name: 'Termoli',
    code: 'L113',
  },
  {
    name: 'Ternate',
    code: 'L115',
  },
  {
    name: 'Ternengo',
    code: 'L116',
  },
  {
    name: 'Terni',
    code: 'L117',
  },
  {
    name: "Terno d'Isola",
    code: 'L118',
  },
  {
    name: 'Terracina',
    code: 'L120',
  },
  {
    name: 'Terragnolo',
    code: 'L121',
  },
  {
    name: 'Terralba',
    code: 'L122',
  },
  {
    name: 'Terranova da Sibari',
    code: 'L124',
  },
  {
    name: 'Terranova dei Passerini',
    code: 'L125',
  },
  {
    name: 'Terranova di Pollino',
    code: 'L126',
  },
  {
    name: 'Terranova Sappo Minulio',
    code: 'L127',
  },
  {
    name: 'Terranuova Bracciolini',
    code: 'L123',
  },
  {
    name: 'Terrasini',
    code: 'L131',
  },
  {
    name: 'Terrassa Padovana',
    code: 'L132',
  },
  {
    name: 'Terravecchia',
    code: 'L134',
  },
  {
    name: 'Terrazzo',
    code: 'L136',
  },
  {
    name: "Terre d'Adige",
    code: 'M407',
  },
  {
    name: 'Terre del Reno',
    code: 'M381',
  },
  {
    name: 'Terre Roveresche',
    code: 'M379',
  },
  {
    name: 'Terricciola',
    code: 'L138',
  },
  {
    name: 'Terruggia',
    code: 'L139',
  },
  {
    name: 'Tertenia',
    code: 'L140',
  },
  {
    name: 'Terzigno',
    code: 'L142',
  },
  {
    name: 'Terzo',
    code: 'L143',
  },
  {
    name: "Terzo d'Aquileia",
    code: 'L144',
  },
  {
    name: 'Terzolas',
    code: 'L145',
  },
  {
    name: 'Terzorio',
    code: 'L146',
  },
  {
    name: 'Tesero',
    code: 'L147',
  },
  {
    name: 'Tesimo',
    code: 'L149',
  },
  {
    name: 'Tessennano',
    code: 'L150',
  },
  {
    name: 'Testico',
    code: 'L152',
  },
  {
    name: 'Teti',
    code: 'L153',
  },
  {
    name: 'Teulada',
    code: 'L154',
  },
  {
    name: 'Teverola',
    code: 'L155',
  },
  {
    name: 'Tezze sul Brenta',
    code: 'L156',
  },
  {
    name: 'Thiene',
    code: 'L157',
  },
  {
    name: 'Thiesi',
    code: 'L158',
  },
  {
    name: 'Tiana',
    code: 'L160',
  },
  {
    name: 'Ticengo',
    code: 'L164',
  },
  {
    name: 'Ticineto',
    code: 'L165',
  },
  {
    name: 'Tiggiano',
    code: 'L166',
  },
  {
    name: 'Tiglieto',
    code: 'L167',
  },
  {
    name: 'Tigliole',
    code: 'L168',
  },
  {
    name: 'Tignale',
    code: 'L169',
  },
  {
    name: 'Tinnura',
    code: 'L172',
  },
  {
    name: 'Tione degli Abruzzi',
    code: 'L173',
  },
  {
    name: 'Tione di Trento',
    code: 'L174',
  },
  {
    name: 'Tirano',
    code: 'L175',
  },
  {
    name: 'Tires',
    code: 'L176',
  },
  {
    name: 'Tiriolo',
    code: 'L177',
  },
  {
    name: 'Tirolo',
    code: 'L178',
  },
  {
    name: 'Tissi',
    code: 'L180',
  },
  {
    name: 'Tito',
    code: 'L181',
  },
  {
    name: 'Tivoli',
    code: 'L182',
  },
  {
    name: 'Tizzano Val Parma',
    code: 'L183',
  },
  {
    name: 'Toano',
    code: 'L184',
  },
  {
    name: 'Tocco Caudio',
    code: 'L185',
  },
  {
    name: 'Tocco da Casauria',
    code: 'L186',
  },
  {
    name: 'Toceno',
    code: 'L187',
  },
  {
    name: 'Todi',
    code: 'L188',
  },
  {
    name: 'Toffia',
    code: 'L189',
  },
  {
    name: 'Toirano',
    code: 'L190',
  },
  {
    name: 'Tolentino',
    code: 'L191',
  },
  {
    name: 'Tolfa',
    code: 'L192',
  },
  {
    name: 'Tollegno',
    code: 'L193',
  },
  {
    name: 'Tollo',
    code: 'L194',
  },
  {
    name: 'Tolmezzo',
    code: 'L195',
  },
  {
    name: 'Tolve',
    code: 'L197',
  },
  {
    name: 'Tombolo',
    code: 'L199',
  },
  {
    name: 'Ton',
    code: 'L200',
  },
  {
    name: 'Tonara',
    code: 'L202',
  },
  {
    name: 'Tonco',
    code: 'L203',
  },
  {
    name: 'Tonengo',
    code: 'L204',
  },
  {
    name: 'Tonezza del Cimone',
    code: 'D717',
  },
  {
    name: 'Tora e Piccilli',
    code: 'L205',
  },
  {
    name: 'Torano Castello',
    code: 'L206',
  },
  {
    name: 'Torano Nuovo',
    code: 'L207',
  },
  {
    name: 'Torbole Casaglia',
    code: 'L210',
  },
  {
    name: 'Torcegno',
    code: 'L211',
  },
  {
    name: 'Torchiara',
    code: 'L212',
  },
  {
    name: 'Torchiarolo',
    code: 'L213',
  },
  {
    name: 'Torella dei Lombardi',
    code: 'L214',
  },
  {
    name: 'Torella del Sannio',
    code: 'L215',
  },
  {
    name: 'Torgiano',
    code: 'L216',
  },
  {
    name: 'Torgnon',
    code: 'L217',
  },
  {
    name: 'Torino',
    code: 'L219',
  },
  {
    name: 'Torino di Sangro',
    code: 'L218',
  },
  {
    name: 'Toritto',
    code: 'L220',
  },
  {
    name: 'Torlino Vimercati',
    code: 'L221',
  },
  {
    name: 'Tornaco',
    code: 'L223',
  },
  {
    name: 'Tornareccio',
    code: 'L224',
  },
  {
    name: 'Tornata',
    code: 'L225',
  },
  {
    name: 'Tornimparte',
    code: 'L227',
  },
  {
    name: 'Torno',
    code: 'L228',
  },
  {
    name: 'Tornolo',
    code: 'L229',
  },
  {
    name: 'Toro',
    code: 'L230',
  },
  {
    name: 'Torpè',
    code: 'L231',
  },
  {
    name: 'Torraca',
    code: 'L233',
  },
  {
    name: 'Torralba',
    code: 'L235',
  },
  {
    name: 'Torrazza Coste',
    code: 'L237',
  },
  {
    name: 'Torrazza Piemonte',
    code: 'L238',
  },
  {
    name: 'Torrazzo',
    code: 'L239',
  },
  {
    name: 'Torre Annunziata',
    code: 'L245',
  },
  {
    name: 'Torre Beretti e Castellaro',
    code: 'L250',
  },
  {
    name: 'Torre Boldone',
    code: 'L251',
  },
  {
    name: 'Torre Bormida',
    code: 'L252',
  },
  {
    name: 'Torre Cajetani',
    code: 'L243',
  },
  {
    name: 'Torre Canavese',
    code: 'L247',
  },
  {
    name: "Torre d'Arese",
    code: 'L256',
  },
  {
    name: "Torre d'Isola",
    code: 'L269',
  },
  {
    name: "Torre de' Busi",
    code: 'L257',
  },
  {
    name: "Torre de' Negri",
    code: 'L262',
  },
  {
    name: "Torre de' Passeri",
    code: 'L263',
  },
  {
    name: "Torre de' Picenardi",
    code: 'L258',
  },
  {
    name: "Torre de' Roveri",
    code: 'L265',
  },
  {
    name: 'Torre del Greco',
    code: 'L259',
  },
  {
    name: 'Torre di Mosto',
    code: 'L267',
  },
  {
    name: 'Torre di Ruggiero',
    code: 'L240',
  },
  {
    name: 'Torre di Santa Maria',
    code: 'L244',
  },
  {
    name: 'Torre Le Nocelle',
    code: 'L272',
  },
  {
    name: 'Torre Mondovì',
    code: 'L241',
  },
  {
    name: 'Torre Orsaia',
    code: 'L274',
  },
  {
    name: 'Torre Pallavicina',
    code: 'L276',
  },
  {
    name: 'Torre Pellice',
    code: 'L277',
  },
  {
    name: 'Torre San Giorgio',
    code: 'L278',
  },
  {
    name: 'Torre San Patrizio',
    code: 'L279',
  },
  {
    name: 'Torre Santa Susanna',
    code: 'L280',
  },
  {
    name: 'Torreano',
    code: 'L246',
  },
  {
    name: 'Torrebelvicino',
    code: 'L248',
  },
  {
    name: 'Torrebruna',
    code: 'L253',
  },
  {
    name: 'Torrecuso',
    code: 'L254',
  },
  {
    name: 'Torreglia',
    code: 'L270',
  },
  {
    name: 'Torregrotta',
    code: 'L271',
  },
  {
    name: 'Torremaggiore',
    code: 'L273',
  },
  {
    name: 'Torrenova',
    code: 'M286',
  },
  {
    name: 'Torresina',
    code: 'L281',
  },
  {
    name: 'Torretta',
    code: 'L282',
  },
  {
    name: 'Torrevecchia Pia',
    code: 'L285',
  },
  {
    name: 'Torrevecchia Teatina',
    code: 'L284',
  },
  {
    name: 'Torri del Benaco',
    code: 'L287',
  },
  {
    name: 'Torri di Quartesolo',
    code: 'L297',
  },
  {
    name: 'Torri in Sabina',
    code: 'L286',
  },
  {
    name: 'Torrice',
    code: 'L290',
  },
  {
    name: 'Torricella',
    code: 'L294',
  },
  {
    name: 'Torricella del Pizzo',
    code: 'L296',
  },
  {
    name: 'Torricella in Sabina',
    code: 'L293',
  },
  {
    name: 'Torricella Peligna',
    code: 'L291',
  },
  {
    name: 'Torricella Sicura',
    code: 'L295',
  },
  {
    name: 'Torricella Verzate',
    code: 'L292',
  },
  {
    name: 'Torriglia',
    code: 'L298',
  },
  {
    name: 'Torrile',
    code: 'L299',
  },
  {
    name: 'Torrioni',
    code: 'L301',
  },
  {
    name: 'Torrita di Siena',
    code: 'L303',
  },
  {
    name: 'Torrita Tiberina',
    code: 'L302',
  },
  {
    name: 'Tortolì',
    code: 'A355',
  },
  {
    name: 'Tortona',
    code: 'L304',
  },
  {
    name: 'Tortora',
    code: 'L305',
  },
  {
    name: 'Tortorella',
    code: 'L306',
  },
  {
    name: 'Tortoreto',
    code: 'L307',
  },
  {
    name: 'Tortorici',
    code: 'L308',
  },
  {
    name: 'Torviscosa',
    code: 'L309',
  },
  {
    name: 'Toscolano-Maderno',
    code: 'L312',
  },
  {
    name: 'Tossicia',
    code: 'L314',
  },
  {
    name: "Tovo di Sant'Agata",
    code: 'L316',
  },
  {
    name: 'Tovo San Giacomo',
    code: 'L315',
  },
  {
    name: 'Trabia',
    code: 'L317',
  },
  {
    name: 'Tradate',
    code: 'L319',
  },
  {
    name: 'Tramatza',
    code: 'L321',
  },
  {
    name: 'Trambileno',
    code: 'L322',
  },
  {
    name: 'Tramonti',
    code: 'L323',
  },
  {
    name: 'Tramonti di Sopra',
    code: 'L324',
  },
  {
    name: 'Tramonti di Sotto',
    code: 'L325',
  },
  {
    name: 'Tramutola',
    code: 'L326',
  },
  {
    name: 'Trana',
    code: 'L327',
  },
  {
    name: 'Trani',
    code: 'L328',
  },
  {
    name: 'Traona',
    code: 'L330',
  },
  {
    name: 'Trapani',
    code: 'L331',
  },
  {
    name: 'Trappeto',
    code: 'L332',
  },
  {
    name: 'Trarego Viggiona',
    code: 'L333',
  },
  {
    name: 'Trasacco',
    code: 'L334',
  },
  {
    name: 'Trasaghis',
    code: 'L335',
  },
  {
    name: 'Trasquera',
    code: 'L336',
  },
  {
    name: 'Tratalias',
    code: 'L337',
  },
  {
    name: 'Travacò Siccomario',
    code: 'I236',
  },
  {
    name: 'Travagliato',
    code: 'L339',
  },
  {
    name: 'Travedona-Monate',
    code: 'L342',
  },
  {
    name: 'Traversella',
    code: 'L345',
  },
  {
    name: 'Traversetolo',
    code: 'L346',
  },
  {
    name: 'Traves',
    code: 'L340',
  },
  {
    name: 'Travesio',
    code: 'L347',
  },
  {
    name: 'Travo',
    code: 'L348',
  },
  {
    name: 'Tre Ville',
    code: 'M361',
  },
  {
    name: 'Trebaseleghe',
    code: 'L349',
  },
  {
    name: 'Trebisacce',
    code: 'L353',
  },
  {
    name: 'Trecase',
    code: 'M280',
  },
  {
    name: 'Trecastagni',
    code: 'L355',
  },
  {
    name: 'Trecastelli',
    code: 'M318',
  },
  {
    name: 'Trecate',
    code: 'L356',
  },
  {
    name: 'Trecchina',
    code: 'L357',
  },
  {
    name: 'Trecenta',
    code: 'L359',
  },
  {
    name: 'Tredozio',
    code: 'L361',
  },
  {
    name: 'Treglio',
    code: 'L363',
  },
  {
    name: 'Tregnago',
    code: 'L364',
  },
  {
    name: 'Treia',
    code: 'L366',
  },
  {
    name: 'Treiso',
    code: 'L367',
  },
  {
    name: 'Tremestieri Etneo',
    code: 'L369',
  },
  {
    name: 'Tremezzina',
    code: 'M341',
  },
  {
    name: 'Tremosine sul Garda',
    code: 'L372',
  },
  {
    name: 'Trentinara',
    code: 'L377',
  },
  {
    name: 'Trento',
    code: 'L378',
  },
  {
    name: 'Trentola Ducenta',
    code: 'L379',
  },
  {
    name: 'Trenzano',
    code: 'L380',
  },
  {
    name: 'Treppo Grande',
    code: 'L382',
  },
  {
    name: 'Treppo Ligosullo',
    code: 'M399',
  },
  {
    name: 'Trepuzzi',
    code: 'L383',
  },
  {
    name: 'Trequanda',
    code: 'L384',
  },
  {
    name: 'Tresana',
    code: 'L386',
  },
  {
    name: 'Trescore Balneario',
    code: 'L388',
  },
  {
    name: 'Trescore Cremasco',
    code: 'L389',
  },
  {
    name: 'Tresignana',
    code: 'M409',
  },
  {
    name: 'Tresivio',
    code: 'L392',
  },
  {
    name: 'Tresnuraghes',
    code: 'L393',
  },
  {
    name: 'Trevenzuolo',
    code: 'L396',
  },
  {
    name: 'Trevi',
    code: 'L397',
  },
  {
    name: 'Trevi nel Lazio',
    code: 'L398',
  },
  {
    name: 'Trevico',
    code: 'L399',
  },
  {
    name: 'Treviglio',
    code: 'L400',
  },
  {
    name: 'Trevignano',
    code: 'L402',
  },
  {
    name: 'Trevignano Romano',
    code: 'L401',
  },
  {
    name: 'Treville',
    code: 'L403',
  },
  {
    name: 'Treviolo',
    code: 'L404',
  },
  {
    name: 'Treviso',
    code: 'L407',
  },
  {
    name: 'Treviso Bresciano',
    code: 'L406',
  },
  {
    name: 'Trezzano Rosa',
    code: 'L408',
  },
  {
    name: 'Trezzano sul Naviglio',
    code: 'L409',
  },
  {
    name: "Trezzo sull'Adda",
    code: 'L411',
  },
  {
    name: 'Trezzo Tinella',
    code: 'L410',
  },
  {
    name: 'Trezzone',
    code: 'L413',
  },
  {
    name: 'Tribano',
    code: 'L414',
  },
  {
    name: 'Tribiano',
    code: 'L415',
  },
  {
    name: 'Tribogna',
    code: 'L416',
  },
  {
    name: 'Tricarico',
    code: 'L418',
  },
  {
    name: 'Tricase',
    code: 'L419',
  },
  {
    name: 'Tricerro',
    code: 'L420',
  },
  {
    name: 'Tricesimo',
    code: 'L421',
  },
  {
    name: 'Triei',
    code: 'L423',
  },
  {
    name: 'Trieste',
    code: 'L424',
  },
  {
    name: 'Triggiano',
    code: 'L425',
  },
  {
    name: 'Trigolo',
    code: 'L426',
  },
  {
    name: 'Trinità',
    code: 'L427',
  },
  {
    name: "Trinità d'Agultu e Vignola",
    code: 'L428',
  },
  {
    name: 'Trinitapoli',
    code: 'B915',
  },
  {
    name: 'Trino',
    code: 'L429',
  },
  {
    name: 'Triora',
    code: 'L430',
  },
  {
    name: 'Tripi',
    code: 'L431',
  },
  {
    name: 'Trisobbio',
    code: 'L432',
  },
  {
    name: 'Trissino',
    code: 'L433',
  },
  {
    name: 'Triuggio',
    code: 'L434',
  },
  {
    name: 'Trivento',
    code: 'L435',
  },
  {
    name: 'Trivigliano',
    code: 'L437',
  },
  {
    name: 'Trivignano Udinese',
    code: 'L438',
  },
  {
    name: 'Trivigno',
    code: 'L439',
  },
  {
    name: 'Trivolzio',
    code: 'L440',
  },
  {
    name: 'Trodena nel parco naturale',
    code: 'L444',
  },
  {
    name: 'Trofarello',
    code: 'L445',
  },
  {
    name: 'Troia',
    code: 'L447',
  },
  {
    name: 'Troina',
    code: 'L448',
  },
  {
    name: 'Tromello',
    code: 'L449',
  },
  {
    name: 'Trontano',
    code: 'L450',
  },
  {
    name: 'Tronzano Lago Maggiore',
    code: 'A705',
  },
  {
    name: 'Tronzano Vercellese',
    code: 'L451',
  },
  {
    name: 'Tropea',
    code: 'L452',
  },
  {
    name: 'Trovo',
    code: 'L453',
  },
  {
    name: 'Truccazzano',
    code: 'L454',
  },
  {
    name: 'Tubre',
    code: 'L455',
  },
  {
    name: 'Tufara',
    code: 'L458',
  },
  {
    name: 'Tufillo',
    code: 'L459',
  },
  {
    name: 'Tufino',
    code: 'L460',
  },
  {
    name: 'Tufo',
    code: 'L461',
  },
  {
    name: 'Tuglie',
    code: 'L462',
  },
  {
    name: 'Tuili',
    code: 'L463',
  },
  {
    name: 'Tula',
    code: 'L464',
  },
  {
    name: 'Tuoro sul Trasimeno',
    code: 'L466',
  },
  {
    name: 'Turania',
    code: 'G507',
  },
  {
    name: 'Turano Lodigiano',
    code: 'L469',
  },
  {
    name: 'Turate',
    code: 'L470',
  },
  {
    name: 'Turbigo',
    code: 'L471',
  },
  {
    name: 'Turi',
    code: 'L472',
  },
  {
    name: 'Turri',
    code: 'L473',
  },
  {
    name: 'Turriaco',
    code: 'L474',
  },
  {
    name: 'Turrivalignani',
    code: 'L475',
  },
  {
    name: 'Tursi',
    code: 'L477',
  },
  {
    name: 'Tusa',
    code: 'L478',
  },
  {
    name: 'Tuscania',
    code: 'L310',
  },
  {
    name: 'Ubiale Clanezzo',
    code: 'C789',
  },
  {
    name: 'Uboldo',
    code: 'L480',
  },
  {
    name: 'Ucria',
    code: 'L482',
  },
  {
    name: 'Udine',
    code: 'L483',
  },
  {
    name: 'Ugento',
    code: 'L484',
  },
  {
    name: 'Uggiano la Chiesa',
    code: 'L485',
  },
  {
    name: 'Uggiate-Trevano',
    code: 'L487',
  },
  {
    name: 'Ulà Tirso',
    code: 'L488',
  },
  {
    name: 'Ulassai',
    code: 'L489',
  },
  {
    name: 'Ultimo',
    code: 'L490',
  },
  {
    name: 'Umbertide',
    code: 'D786',
  },
  {
    name: 'Umbriatico',
    code: 'L492',
  },
  {
    name: "Urago d'Oglio",
    code: 'L494',
  },
  {
    name: 'Uras',
    code: 'L496',
  },
  {
    name: 'Urbana',
    code: 'L497',
  },
  {
    name: 'Urbania',
    code: 'L498',
  },
  {
    name: 'Urbe',
    code: 'L499',
  },
  {
    name: 'Urbino',
    code: 'L500',
  },
  {
    name: 'Urbisaglia',
    code: 'L501',
  },
  {
    name: 'Urgnano',
    code: 'L502',
  },
  {
    name: 'Uri',
    code: 'L503',
  },
  {
    name: 'Ururi',
    code: 'L505',
  },
  {
    name: 'Urzulei',
    code: 'L506',
  },
  {
    name: 'Uscio',
    code: 'L507',
  },
  {
    name: 'Usellus',
    code: 'L508',
  },
  {
    name: 'Usini',
    code: 'L509',
  },
  {
    name: 'Usmate Velate',
    code: 'L511',
  },
  {
    name: 'Ussana',
    code: 'L512',
  },
  {
    name: 'Ussaramanna',
    code: 'L513',
  },
  {
    name: 'Ussassai',
    code: 'L514',
  },
  {
    name: 'Usseaux',
    code: 'L515',
  },
  {
    name: 'Usseglio',
    code: 'L516',
  },
  {
    name: 'Ussita',
    code: 'L517',
  },
  {
    name: 'Ustica',
    code: 'L519',
  },
  {
    name: 'Uta',
    code: 'L521',
  },
  {
    name: 'Uzzano',
    code: 'L522',
  },
  {
    name: 'Vaccarizzo Albanese',
    code: 'L524',
  },
  {
    name: 'Vacone',
    code: 'L525',
  },
  {
    name: 'Vacri',
    code: 'L526',
  },
  {
    name: 'Vadena',
    code: 'L527',
  },
  {
    name: 'Vado Ligure',
    code: 'L528',
  },
  {
    name: 'Vagli Sotto',
    code: 'L533',
  },
  {
    name: 'Vaglia',
    code: 'L529',
  },
  {
    name: 'Vaglio Basilicata',
    code: 'L532',
  },
  {
    name: 'Vaglio Serra',
    code: 'L531',
  },
  {
    name: 'Vaiano',
    code: 'L537',
  },
  {
    name: 'Vaiano Cremasco',
    code: 'L535',
  },
  {
    name: 'Vaie',
    code: 'L538',
  },
  {
    name: 'Vailate',
    code: 'L539',
  },
  {
    name: 'Vairano Patenora',
    code: 'L540',
  },
  {
    name: 'Vajont',
    code: 'M265',
  },
  {
    name: 'Val Brembilla',
    code: 'M334',
  },
  {
    name: 'Val della Torre',
    code: 'L555',
  },
  {
    name: 'Val di Chy',
    code: 'M405',
  },
  {
    name: 'Val di Nizza',
    code: 'L562',
  },
  {
    name: 'Val di Vizze',
    code: 'L564',
  },
  {
    name: 'Val di Zoldo',
    code: 'M374',
  },
  {
    name: 'Val Liona',
    code: 'M384',
  },
  {
    name: 'Val Masino',
    code: 'L638',
  },
  {
    name: 'Val Rezzo',
    code: 'H259',
  },
  {
    name: 'Valbondione',
    code: 'L544',
  },
  {
    name: 'Valbrembo',
    code: 'L545',
  },
  {
    name: 'Valbrenta',
    code: 'M423',
  },
  {
    name: 'Valbrevenna',
    code: 'L546',
  },
  {
    name: 'Valbrona',
    code: 'L547',
  },
  {
    name: 'Valchiusa',
    code: 'M415',
  },
  {
    name: 'Valdagno',
    code: 'L551',
  },
  {
    name: 'Valdaone',
    code: 'M343',
  },
  {
    name: 'Valdaora',
    code: 'L552',
  },
  {
    name: 'Valdastico',
    code: 'L554',
  },
  {
    name: 'Valdengo',
    code: 'L556',
  },
  {
    name: 'Valderice',
    code: 'G319',
  },
  {
    name: 'Valdidentro',
    code: 'L557',
  },
  {
    name: 'Valdieri',
    code: 'L558',
  },
  {
    name: 'Valdilana',
    code: 'M417',
  },
  {
    name: 'Valdina',
    code: 'L561',
  },
  {
    name: 'Valdisotto',
    code: 'L563',
  },
  {
    name: 'Valdobbiadene',
    code: 'L565',
  },
  {
    name: 'Valduggia',
    code: 'L566',
  },
  {
    name: 'Valeggio',
    code: 'L568',
  },
  {
    name: 'Valeggio sul Mincio',
    code: 'L567',
  },
  {
    name: 'Valentano',
    code: 'L569',
  },
  {
    name: 'Valenza',
    code: 'L570',
  },
  {
    name: 'Valenzano',
    code: 'L571',
  },
  {
    name: 'Valera Fratta',
    code: 'L572',
  },
  {
    name: 'Valfabbrica',
    code: 'L573',
  },
  {
    name: 'Valfenera',
    code: 'L574',
  },
  {
    name: 'Valfloriana',
    code: 'L575',
  },
  {
    name: 'Valfornace',
    code: 'M382',
  },
  {
    name: 'Valfurva',
    code: 'L576',
  },
  {
    name: 'Valganna',
    code: 'L577',
  },
  {
    name: 'Valgioie',
    code: 'L578',
  },
  {
    name: 'Valgoglio',
    code: 'L579',
  },
  {
    name: 'Valgrana',
    code: 'L580',
  },
  {
    name: 'Valgreghentino',
    code: 'L581',
  },
  {
    name: 'Valgrisenche',
    code: 'L582',
  },
  {
    name: 'Valguarnera Caropepe',
    code: 'L583',
  },
  {
    name: 'Vallada Agordina',
    code: 'L584',
  },
  {
    name: 'Vallanzengo',
    code: 'L586',
  },
  {
    name: 'Vallarsa',
    code: 'L588',
  },
  {
    name: 'Vallata',
    code: 'L589',
  },
  {
    name: 'Valle Agricola',
    code: 'L594',
  },
  {
    name: 'Valle Aurina',
    code: 'L595',
  },
  {
    name: 'Valle Cannobina',
    code: 'M404',
  },
  {
    name: 'Valle Castellana',
    code: 'L597',
  },
  {
    name: "Valle dell'Angelo",
    code: 'G540',
  },
  {
    name: 'Valle di Cadore',
    code: 'L590',
  },
  {
    name: 'Valle di Casies',
    code: 'L601',
  },
  {
    name: 'Valle di Maddaloni',
    code: 'L591',
  },
  {
    name: 'Valle Lomellina',
    code: 'L593',
  },
  {
    name: 'Valle Salimbene',
    code: 'L617',
  },
  {
    name: 'Valle San Nicolao',
    code: 'L620',
  },
  {
    name: 'Vallebona',
    code: 'L596',
  },
  {
    name: 'Vallecorsa',
    code: 'L598',
  },
  {
    name: 'Vallecrosia',
    code: 'L599',
  },
  {
    name: 'Valledolmo',
    code: 'L603',
  },
  {
    name: 'Valledoria',
    code: 'L604',
  },
  {
    name: 'Vallefiorita',
    code: 'I322',
  },
  {
    name: 'Vallefoglia',
    code: 'M331',
  },
  {
    name: 'Vallelaghi',
    code: 'M362',
  },
  {
    name: 'Vallelonga',
    code: 'L607',
  },
  {
    name: 'Vallelunga Pratameno',
    code: 'L609',
  },
  {
    name: 'Vallemaio',
    code: 'L605',
  },
  {
    name: 'Vallepietra',
    code: 'L611',
  },
  {
    name: 'Vallerano',
    code: 'L612',
  },
  {
    name: 'Vallermosa',
    code: 'L613',
  },
  {
    name: 'Vallerotonda',
    code: 'L614',
  },
  {
    name: 'Vallesaccarda',
    code: 'L616',
  },
  {
    name: 'Valleve',
    code: 'L623',
  },
  {
    name: 'Valli del Pasubio',
    code: 'L624',
  },
  {
    name: 'Vallinfreda',
    code: 'L625',
  },
  {
    name: 'Vallio Terme',
    code: 'L626',
  },
  {
    name: 'Vallo della Lucania',
    code: 'L628',
  },
  {
    name: 'Vallo di Nera',
    code: 'L627',
  },
  {
    name: 'Vallo Torinese',
    code: 'L629',
  },
  {
    name: 'Valloriate',
    code: 'L631',
  },
  {
    name: 'Valmacca',
    code: 'L633',
  },
  {
    name: 'Valmadrera',
    code: 'L634',
  },
  {
    name: 'Valmontone',
    code: 'L639',
  },
  {
    name: 'Valmorea',
    code: 'L640',
  },
  {
    name: 'Valmozzola',
    code: 'L641',
  },
  {
    name: 'Valnegra',
    code: 'L642',
  },
  {
    name: 'Valpelline',
    code: 'L643',
  },
  {
    name: 'Valperga',
    code: 'L644',
  },
  {
    name: 'Valprato Soana',
    code: 'B510',
  },
  {
    name: 'Valsamoggia',
    code: 'M320',
  },
  {
    name: 'Valsavarenche',
    code: 'L647',
  },
  {
    name: 'Valsinni',
    code: 'D513',
  },
  {
    name: 'Valsolda',
    code: 'C936',
  },
  {
    name: 'Valstrona',
    code: 'L651',
  },
  {
    name: 'Valtopina',
    code: 'L653',
  },
  {
    name: 'Valtorta',
    code: 'L655',
  },
  {
    name: 'Valtournenche',
    code: 'L654',
  },
  {
    name: 'Valva',
    code: 'L656',
  },
  {
    name: 'Valvarrone',
    code: 'M395',
  },
  {
    name: 'Valvasone Arzene',
    code: 'M346',
  },
  {
    name: 'Valverde',
    code: 'L658',
  },
  {
    name: 'Valvestino',
    code: 'L468',
  },
  {
    name: 'Vandoies',
    code: 'L660',
  },
  {
    name: 'Vanzaghello',
    code: 'L664',
  },
  {
    name: 'Vanzago',
    code: 'L665',
  },
  {
    name: 'Vanzone con San Carlo',
    code: 'L666',
  },
  {
    name: "Vaprio d'Adda",
    code: 'L667',
  },
  {
    name: "Vaprio d'Agogna",
    code: 'L668',
  },
  {
    name: 'Varallo',
    code: 'L669',
  },
  {
    name: 'Varallo Pombia',
    code: 'L670',
  },
  {
    name: 'Varano Borghi',
    code: 'L671',
  },
  {
    name: "Varano de' Melegari",
    code: 'L672',
  },
  {
    name: 'Varapodio',
    code: 'L673',
  },
  {
    name: 'Varazze',
    code: 'L675',
  },
  {
    name: 'Varco Sabino',
    code: 'L676',
  },
  {
    name: 'Varedo',
    code: 'L677',
  },
  {
    name: 'Varenna',
    code: 'L680',
  },
  {
    name: 'Varese',
    code: 'L682',
  },
  {
    name: 'Varese Ligure',
    code: 'L681',
  },
  {
    name: 'Varisella',
    code: 'L685',
  },
  {
    name: 'Varmo',
    code: 'L686',
  },
  {
    name: 'Varna',
    code: 'L687',
  },
  {
    name: 'Varsi',
    code: 'L689',
  },
  {
    name: 'Varzi',
    code: 'L690',
  },
  {
    name: 'Varzo',
    code: 'L691',
  },
  {
    name: 'Vasanello',
    code: 'A701',
  },
  {
    name: 'Vasia',
    code: 'L693',
  },
  {
    name: 'Vasto',
    code: 'E372',
  },
  {
    name: 'Vastogirardi',
    code: 'L696',
  },
  {
    name: 'Vauda Canavese',
    code: 'L698',
  },
  {
    name: 'Vazzano',
    code: 'L699',
  },
  {
    name: 'Vazzola',
    code: 'L700',
  },
  {
    name: 'Vecchiano',
    code: 'L702',
  },
  {
    name: 'Vedano al Lambro',
    code: 'L704',
  },
  {
    name: 'Vedano Olona',
    code: 'L703',
  },
  {
    name: 'Vedelago',
    code: 'L706',
  },
  {
    name: 'Vedeseta',
    code: 'L707',
  },
  {
    name: 'Veduggio con Colzano',
    code: 'L709',
  },
  {
    name: 'Veggiano',
    code: 'L710',
  },
  {
    name: 'Veglie',
    code: 'L711',
  },
  {
    name: 'Veglio',
    code: 'L712',
  },
  {
    name: 'Vejano',
    code: 'L713',
  },
  {
    name: 'Veleso',
    code: 'L715',
  },
  {
    name: 'Velezzo Lomellina',
    code: 'L716',
  },
  {
    name: 'Velletri',
    code: 'L719',
  },
  {
    name: 'Vellezzo Bellini',
    code: 'L720',
  },
  {
    name: "Velo d'Astico",
    code: 'L723',
  },
  {
    name: 'Velo Veronese',
    code: 'L722',
  },
  {
    name: 'Velturno',
    code: 'L724',
  },
  {
    name: 'Venafro',
    code: 'L725',
  },
  {
    name: 'Venaria Reale',
    code: 'L727',
  },
  {
    name: 'Venarotta',
    code: 'L728',
  },
  {
    name: 'Venasca',
    code: 'L729',
  },
  {
    name: 'Venaus',
    code: 'L726',
  },
  {
    name: 'Vendone',
    code: 'L730',
  },
  {
    name: 'Venegono Inferiore',
    code: 'L733',
  },
  {
    name: 'Venegono Superiore',
    code: 'L734',
  },
  {
    name: 'Venetico',
    code: 'L735',
  },
  {
    name: 'Venezia',
    code: 'L736',
  },
  {
    name: 'Veniano',
    code: 'L737',
  },
  {
    name: 'Venosa',
    code: 'L738',
  },
  {
    name: 'Ventasso',
    code: 'M364',
  },
  {
    name: 'Venticano',
    code: 'L739',
  },
  {
    name: 'Ventimiglia',
    code: 'L741',
  },
  {
    name: 'Ventimiglia di Sicilia',
    code: 'L740',
  },
  {
    name: 'Ventotene',
    code: 'L742',
  },
  {
    name: 'Venzone',
    code: 'L743',
  },
  {
    name: 'Verano',
    code: 'L745',
  },
  {
    name: 'Verano Brianza',
    code: 'L744',
  },
  {
    name: 'Verbania',
    code: 'L746',
  },
  {
    name: 'Verbicaro',
    code: 'L747',
  },
  {
    name: 'Vercana',
    code: 'L748',
  },
  {
    name: 'Verceia',
    code: 'L749',
  },
  {
    name: 'Vercelli',
    code: 'L750',
  },
  {
    name: 'Vercurago',
    code: 'L751',
  },
  {
    name: 'Verdellino',
    code: 'L752',
  },
  {
    name: 'Verdello',
    code: 'L753',
  },
  {
    name: 'Verderio',
    code: 'M337',
  },
  {
    name: 'Verduno',
    code: 'L758',
  },
  {
    name: 'Vergato',
    code: 'L762',
  },
  {
    name: 'Verghereto',
    code: 'L764',
  },
  {
    name: 'Vergiate',
    code: 'L765',
  },
  {
    name: 'Vermezzo con Zelo',
    code: 'M424',
  },
  {
    name: 'Vermiglio',
    code: 'L769',
  },
  {
    name: 'Vernante',
    code: 'L771',
  },
  {
    name: 'Vernasca',
    code: 'L772',
  },
  {
    name: 'Vernate',
    code: 'L773',
  },
  {
    name: 'Vernazza',
    code: 'L774',
  },
  {
    name: 'Vernio',
    code: 'L775',
  },
  {
    name: 'Vernole',
    code: 'L776',
  },
  {
    name: 'Verolanuova',
    code: 'L777',
  },
  {
    name: 'Verolavecchia',
    code: 'L778',
  },
  {
    name: 'Verolengo',
    code: 'L779',
  },
  {
    name: 'Veroli',
    code: 'L780',
  },
  {
    name: 'Verona',
    code: 'L781',
  },
  {
    name: 'Veronella',
    code: 'D193',
  },
  {
    name: 'Verrayes',
    code: 'L783',
  },
  {
    name: 'Verrès',
    code: 'C282',
  },
  {
    name: 'Verretto',
    code: 'L784',
  },
  {
    name: 'Verrone',
    code: 'L785',
  },
  {
    name: 'Verrua Po',
    code: 'L788',
  },
  {
    name: 'Verrua Savoia',
    code: 'L787',
  },
  {
    name: 'Vertemate con Minoprio',
    code: 'L792',
  },
  {
    name: 'Vertova',
    code: 'L795',
  },
  {
    name: 'Verucchio',
    code: 'L797',
  },
  {
    name: 'Vervio',
    code: 'L799',
  },
  {
    name: 'Verzegnis',
    code: 'L801',
  },
  {
    name: 'Verzino',
    code: 'L802',
  },
  {
    name: 'Verzuolo',
    code: 'L804',
  },
  {
    name: 'Vescovana',
    code: 'L805',
  },
  {
    name: 'Vescovato',
    code: 'L806',
  },
  {
    name: 'Vesime',
    code: 'L807',
  },
  {
    name: 'Vespolate',
    code: 'L808',
  },
  {
    name: 'Vessalico',
    code: 'L809',
  },
  {
    name: 'Vestenanova',
    code: 'L810',
  },
  {
    name: 'Vestignè',
    code: 'L811',
  },
  {
    name: 'Vestone',
    code: 'L812',
  },
  {
    name: 'Vetralla',
    code: 'L814',
  },
  {
    name: 'Vetto',
    code: 'L815',
  },
  {
    name: "Vezza d'Alba",
    code: 'L817',
  },
  {
    name: "Vezza d'Oglio",
    code: 'L816',
  },
  {
    name: 'Vezzano Ligure',
    code: 'L819',
  },
  {
    name: 'Vezzano sul Crostolo',
    code: 'L820',
  },
  {
    name: 'Vezzi Portio',
    code: 'L823',
  },
  {
    name: 'Viadana',
    code: 'L826',
  },
  {
    name: 'Viadanica',
    code: 'L827',
  },
  {
    name: 'Viagrande',
    code: 'L828',
  },
  {
    name: 'Viale',
    code: 'L829',
  },
  {
    name: 'Vialfrè',
    code: 'L830',
  },
  {
    name: 'Viano',
    code: 'L831',
  },
  {
    name: 'Viareggio',
    code: 'L833',
  },
  {
    name: 'Viarigi',
    code: 'L834',
  },
  {
    name: 'Vibo Valentia',
    code: 'F537',
  },
  {
    name: 'Vibonati',
    code: 'L835',
  },
  {
    name: 'Vicalvi',
    code: 'L836',
  },
  {
    name: 'Vicari',
    code: 'L837',
  },
  {
    name: 'Vicchio',
    code: 'L838',
  },
  {
    name: 'Vicenza',
    code: 'L840',
  },
  {
    name: 'Vico del Gargano',
    code: 'L842',
  },
  {
    name: 'Vico Equense',
    code: 'L845',
  },
  {
    name: 'Vico nel Lazio',
    code: 'L843',
  },
  {
    name: 'Vicoforte',
    code: 'L841',
  },
  {
    name: 'Vicoli',
    code: 'L846',
  },
  {
    name: 'Vicolungo',
    code: 'L847',
  },
  {
    name: 'Vicopisano',
    code: 'L850',
  },
  {
    name: 'Vicovaro',
    code: 'L851',
  },
  {
    name: 'Viddalba',
    code: 'M259',
  },
  {
    name: 'Vidigulfo',
    code: 'L854',
  },
  {
    name: 'Vidor',
    code: 'L856',
  },
  {
    name: 'Vidracco',
    code: 'L857',
  },
  {
    name: 'Vieste',
    code: 'L858',
  },
  {
    name: 'Vietri di Potenza',
    code: 'L859',
  },
  {
    name: 'Vietri sul Mare',
    code: 'L860',
  },
  {
    name: 'Viganò',
    code: 'L866',
  },
  {
    name: 'Vigano San Martino',
    code: 'L865',
  },
  {
    name: 'Vigarano Mainarda',
    code: 'L868',
  },
  {
    name: 'Vigasio',
    code: 'L869',
  },
  {
    name: 'Vigevano',
    code: 'L872',
  },
  {
    name: 'Viggianello',
    code: 'L873',
  },
  {
    name: 'Viggiano',
    code: 'L874',
  },
  {
    name: 'Viggiù',
    code: 'L876',
  },
  {
    name: "Vighizzolo d'Este",
    code: 'L878',
  },
  {
    name: 'Vigliano Biellese',
    code: 'L880',
  },
  {
    name: "Vigliano d'Asti",
    code: 'L879',
  },
  {
    name: 'Vignale Monferrato',
    code: 'L881',
  },
  {
    name: 'Vignanello',
    code: 'L882',
  },
  {
    name: 'Vignate',
    code: 'L883',
  },
  {
    name: 'Vignola',
    code: 'L885',
  },
  {
    name: 'Vignola-Falesina',
    code: 'L886',
  },
  {
    name: 'Vignole Borbera',
    code: 'L887',
  },
  {
    name: 'Vignolo',
    code: 'L888',
  },
  {
    name: 'Vignone',
    code: 'L889',
  },
  {
    name: 'Vigo di Cadore',
    code: 'L890',
  },
  {
    name: 'Vigodarzere',
    code: 'L892',
  },
  {
    name: 'Vigolo',
    code: 'L894',
  },
  {
    name: 'Vigolzone',
    code: 'L897',
  },
  {
    name: 'Vigone',
    code: 'L898',
  },
  {
    name: 'Vigonovo',
    code: 'L899',
  },
  {
    name: 'Vigonza',
    code: 'L900',
  },
  {
    name: 'Viguzzolo',
    code: 'L904',
  },
  {
    name: 'Villa Bartolomea',
    code: 'L912',
  },
  {
    name: 'Villa Basilica',
    code: 'L913',
  },
  {
    name: 'Villa Biscossi',
    code: 'L917',
  },
  {
    name: 'Villa Carcina',
    code: 'L919',
  },
  {
    name: 'Villa Castelli',
    code: 'L920',
  },
  {
    name: 'Villa Celiera',
    code: 'L922',
  },
  {
    name: 'Villa Collemandina',
    code: 'L926',
  },
  {
    name: 'Villa Cortese',
    code: 'L928',
  },
  {
    name: "Villa d'Adda",
    code: 'L929',
  },
  {
    name: "Villa d'Almè",
    code: 'A215',
  },
  {
    name: "Villa d'Ogna",
    code: 'L938',
  },
  {
    name: 'Villa del Bosco',
    code: 'L933',
  },
  {
    name: 'Villa del Conte',
    code: 'L934',
  },
  {
    name: 'Villa di Briano',
    code: 'D801',
  },
  {
    name: 'Villa di Chiavenna',
    code: 'L907',
  },
  {
    name: 'Villa di Serio',
    code: 'L936',
  },
  {
    name: 'Villa di Tirano',
    code: 'L908',
  },
  {
    name: 'Villa Estense',
    code: 'L937',
  },
  {
    name: 'Villa Faraldi',
    code: 'L943',
  },
  {
    name: 'Villa Guardia',
    code: 'L956',
  },
  {
    name: 'Villa Lagarina',
    code: 'L957',
  },
  {
    name: 'Villa Latina',
    code: 'A081',
  },
  {
    name: 'Villa Literno',
    code: 'L844',
  },
  {
    name: 'Villa Minozzo',
    code: 'L969',
  },
  {
    name: 'Villa San Giovanni',
    code: 'M018',
  },
  {
    name: 'Villa San Giovanni in Tuscia',
    code: 'H913',
  },
  {
    name: 'Villa San Pietro',
    code: 'I118',
  },
  {
    name: 'Villa San Secondo',
    code: 'M019',
  },
  {
    name: "Villa Sant'Angelo",
    code: 'M023',
  },
  {
    name: "Villa Sant'Antonio",
    code: 'I298',
  },
  {
    name: 'Villa Santa Lucia',
    code: 'L905',
  },
  {
    name: 'Villa Santa Lucia degli Abruzzi',
    code: 'M021',
  },
  {
    name: 'Villa Santa Maria',
    code: 'M022',
  },
  {
    name: 'Villa Santina',
    code: 'L909',
  },
  {
    name: 'Villa Santo Stefano',
    code: 'I364',
  },
  {
    name: 'Villa Verde',
    code: 'A609',
  },
  {
    name: 'Villabassa',
    code: 'L915',
  },
  {
    name: 'Villabate',
    code: 'L916',
  },
  {
    name: 'Villachiara',
    code: 'L923',
  },
  {
    name: 'Villacidro',
    code: 'L924',
  },
  {
    name: 'Villadeati',
    code: 'L931',
  },
  {
    name: 'Villadose',
    code: 'L939',
  },
  {
    name: 'Villadossola',
    code: 'L906',
  },
  {
    name: 'Villafalletto',
    code: 'L942',
  },
  {
    name: "Villafranca d'Asti",
    code: 'L945',
  },
  {
    name: 'Villafranca di Verona',
    code: 'L949',
  },
  {
    name: 'Villafranca in Lunigiana',
    code: 'L946',
  },
  {
    name: 'Villafranca Padovana',
    code: 'L947',
  },
  {
    name: 'Villafranca Piemonte',
    code: 'L948',
  },
  {
    name: 'Villafranca Sicula',
    code: 'L944',
  },
  {
    name: 'Villafranca Tirrena',
    code: 'L950',
  },
  {
    name: 'Villafrati',
    code: 'L951',
  },
  {
    name: 'Villaga',
    code: 'L952',
  },
  {
    name: 'Villagrande Strisaili',
    code: 'L953',
  },
  {
    name: 'Villalago',
    code: 'L958',
  },
  {
    name: 'Villalba',
    code: 'L959',
  },
  {
    name: 'Villalfonsina',
    code: 'L961',
  },
  {
    name: 'Villalvernia',
    code: 'L963',
  },
  {
    name: 'Villamagna',
    code: 'L964',
  },
  {
    name: 'Villamaina',
    code: 'L965',
  },
  {
    name: 'Villamar',
    code: 'L966',
  },
  {
    name: 'Villamarzana',
    code: 'L967',
  },
  {
    name: 'Villamassargia',
    code: 'L968',
  },
  {
    name: 'Villamiroglio',
    code: 'L970',
  },
  {
    name: 'Villandro',
    code: 'L971',
  },
  {
    name: 'Villanova Biellese',
    code: 'L978',
  },
  {
    name: 'Villanova Canavese',
    code: 'L982',
  },
  {
    name: "Villanova d'Albenga",
    code: 'L975',
  },
  {
    name: "Villanova d'Ardenghi",
    code: 'L983',
  },
  {
    name: "Villanova d'Asti",
    code: 'L984',
  },
  {
    name: 'Villanova del Battista',
    code: 'L973',
  },
  {
    name: 'Villanova del Ghebbo',
    code: 'L985',
  },
  {
    name: 'Villanova del Sillaro',
    code: 'L977',
  },
  {
    name: 'Villanova di Camposampiero',
    code: 'L979',
  },
  {
    name: 'Villanova Marchesana',
    code: 'L988',
  },
  {
    name: 'Villanova Mondovì',
    code: 'L974',
  },
  {
    name: 'Villanova Monferrato',
    code: 'L972',
  },
  {
    name: 'Villanova Monteleone',
    code: 'L989',
  },
  {
    name: 'Villanova Solaro',
    code: 'L990',
  },
  {
    name: "Villanova sull'Arda",
    code: 'L980',
  },
  {
    name: 'Villanova Truschedu',
    code: 'L991',
  },
  {
    name: 'Villanova Tulo',
    code: 'L992',
  },
  {
    name: 'Villanovaforru',
    code: 'L986',
  },
  {
    name: 'Villanovafranca',
    code: 'L987',
  },
  {
    name: 'Villanterio',
    code: 'L994',
  },
  {
    name: 'Villanuova sul Clisi',
    code: 'L995',
  },
  {
    name: 'Villaperuccio',
    code: 'M278',
  },
  {
    name: 'Villapiana',
    code: 'B903',
  },
  {
    name: 'Villaputzu',
    code: 'L998',
  },
  {
    name: 'Villar Dora',
    code: 'L999',
  },
  {
    name: 'Villar Focchiardo',
    code: 'M007',
  },
  {
    name: 'Villar Pellice',
    code: 'M013',
  },
  {
    name: 'Villar Perosa',
    code: 'M014',
  },
  {
    name: 'Villar San Costanzo',
    code: 'M015',
  },
  {
    name: 'Villarbasse',
    code: 'M002',
  },
  {
    name: 'Villarboit',
    code: 'M003',
  },
  {
    name: 'Villareggia',
    code: 'M004',
  },
  {
    name: 'Villaricca',
    code: 'G309',
  },
  {
    name: 'Villaromagnano',
    code: 'M009',
  },
  {
    name: 'Villarosa',
    code: 'M011',
  },
  {
    name: 'Villasalto',
    code: 'M016',
  },
  {
    name: 'Villasanta',
    code: 'M017',
  },
  {
    name: 'Villasimius',
    code: 'B738',
  },
  {
    name: 'Villasor',
    code: 'M025',
  },
  {
    name: 'Villaspeciosa',
    code: 'M026',
  },
  {
    name: 'Villastellone',
    code: 'M027',
  },
  {
    name: 'Villata',
    code: 'M028',
  },
  {
    name: 'Villaurbana',
    code: 'M030',
  },
  {
    name: 'Villavallelonga',
    code: 'M031',
  },
  {
    name: 'Villaverla',
    code: 'M032',
  },
  {
    name: "Ville d'Anaunia",
    code: 'M363',
  },
  {
    name: 'Ville di Fiemme',
    code: 'M431',
  },
  {
    name: 'Villeneuve',
    code: 'L981',
  },
  {
    name: 'Villesse',
    code: 'M043',
  },
  {
    name: 'Villetta Barrea',
    code: 'M041',
  },
  {
    name: 'Villette',
    code: 'M042',
  },
  {
    name: 'Villimpenta',
    code: 'M044',
  },
  {
    name: 'Villongo',
    code: 'M045',
  },
  {
    name: 'Villorba',
    code: 'M048',
  },
  {
    name: 'Vilminore di Scalve',
    code: 'M050',
  },
  {
    name: 'Vimercate',
    code: 'M052',
  },
  {
    name: 'Vimodrone',
    code: 'M053',
  },
  {
    name: 'Vinadio',
    code: 'M055',
  },
  {
    name: 'Vinchiaturo',
    code: 'M057',
  },
  {
    name: 'Vinchio',
    code: 'M058',
  },
  {
    name: 'Vinci',
    code: 'M059',
  },
  {
    name: 'Vinovo',
    code: 'M060',
  },
  {
    name: 'Vinzaglio',
    code: 'M062',
  },
  {
    name: 'Viola',
    code: 'M063',
  },
  {
    name: 'Vione',
    code: 'M065',
  },
  {
    name: 'Vipiteno',
    code: 'M067',
  },
  {
    name: 'Virle Piemonte',
    code: 'M069',
  },
  {
    name: 'Visano',
    code: 'M070',
  },
  {
    name: 'Vische',
    code: 'M071',
  },
  {
    name: 'Visciano',
    code: 'M072',
  },
  {
    name: 'Visco',
    code: 'M073',
  },
  {
    name: 'Visone',
    code: 'M077',
  },
  {
    name: 'Visso',
    code: 'M078',
  },
  {
    name: 'Vistarino',
    code: 'M079',
  },
  {
    name: 'Vistrorio',
    code: 'M080',
  },
  {
    name: 'Vita',
    code: 'M081',
  },
  {
    name: 'Viterbo',
    code: 'M082',
  },
  {
    name: 'Viticuso',
    code: 'M083',
  },
  {
    name: "Vito d'Asio",
    code: 'M085',
  },
  {
    name: 'Vitorchiano',
    code: 'M086',
  },
  {
    name: 'Vittoria',
    code: 'M088',
  },
  {
    name: 'Vittorio Veneto',
    code: 'M089',
  },
  {
    name: 'Vittorito',
    code: 'M090',
  },
  {
    name: 'Vittuone',
    code: 'M091',
  },
  {
    name: 'Vitulano',
    code: 'M093',
  },
  {
    name: 'Vitulazio',
    code: 'M092',
  },
  {
    name: 'Viù',
    code: 'M094',
  },
  {
    name: 'Vivaro',
    code: 'M096',
  },
  {
    name: 'Vivaro Romano',
    code: 'M095',
  },
  {
    name: 'Viverone',
    code: 'M098',
  },
  {
    name: 'Vizzini',
    code: 'M100',
  },
  {
    name: 'Vizzola Ticino',
    code: 'M101',
  },
  {
    name: 'Vizzolo Predabissi',
    code: 'M102',
  },
  {
    name: "Vo'",
    code: 'M103',
  },
  {
    name: 'Vobarno',
    code: 'M104',
  },
  {
    name: 'Vobbia',
    code: 'M105',
  },
  {
    name: 'Vocca',
    code: 'M106',
  },
  {
    name: 'Vodo Cadore',
    code: 'M108',
  },
  {
    name: 'Voghera',
    code: 'M109',
  },
  {
    name: 'Voghiera',
    code: 'M110',
  },
  {
    name: 'Vogogna',
    code: 'M111',
  },
  {
    name: 'Volano',
    code: 'M113',
  },
  {
    name: 'Volla',
    code: 'M115',
  },
  {
    name: 'Volongo',
    code: 'M116',
  },
  {
    name: 'Volpago del Montello',
    code: 'M118',
  },
  {
    name: 'Volpara',
    code: 'M119',
  },
  {
    name: 'Volpedo',
    code: 'M120',
  },
  {
    name: 'Volpeglino',
    code: 'M121',
  },
  {
    name: 'Volpiano',
    code: 'M122',
  },
  {
    name: 'Volta Mantovana',
    code: 'M125',
  },
  {
    name: 'Voltaggio',
    code: 'M123',
  },
  {
    name: 'Voltago Agordino',
    code: 'M124',
  },
  {
    name: 'Volterra',
    code: 'M126',
  },
  {
    name: 'Voltido',
    code: 'M127',
  },
  {
    name: 'Volturara Appula',
    code: 'M131',
  },
  {
    name: 'Volturara Irpina',
    code: 'M130',
  },
  {
    name: 'Volturino',
    code: 'M132',
  },
  {
    name: 'Volvera',
    code: 'M133',
  },
  {
    name: 'Vottignasco',
    code: 'M136',
  },
  {
    name: 'Zaccanopoli',
    code: 'M138',
  },
  {
    name: 'Zafferana Etnea',
    code: 'M139',
  },
  {
    name: 'Zagarise',
    code: 'M140',
  },
  {
    name: 'Zagarolo',
    code: 'M141',
  },
  {
    name: 'Zambrone',
    code: 'M143',
  },
  {
    name: 'Zandobbio',
    code: 'M144',
  },
  {
    name: 'Zanè',
    code: 'M145',
  },
  {
    name: 'Zanica',
    code: 'M147',
  },
  {
    name: 'Zapponeta',
    code: 'M267',
  },
  {
    name: 'Zavattarello',
    code: 'M150',
  },
  {
    name: 'Zeccone',
    code: 'M152',
  },
  {
    name: 'Zeddiani',
    code: 'M153',
  },
  {
    name: 'Zelbio',
    code: 'M156',
  },
  {
    name: 'Zelo Buon Persico',
    code: 'M158',
  },
  {
    name: 'Zeme',
    code: 'M161',
  },
  {
    name: 'Zenevredo',
    code: 'M162',
  },
  {
    name: 'Zenson di Piave',
    code: 'M163',
  },
  {
    name: 'Zerba',
    code: 'M165',
  },
  {
    name: 'Zerbo',
    code: 'M166',
  },
  {
    name: 'Zerbolò',
    code: 'M167',
  },
  {
    name: 'Zerfaliu',
    code: 'M168',
  },
  {
    name: 'Zeri',
    code: 'M169',
  },
  {
    name: 'Zermeghedo',
    code: 'M170',
  },
  {
    name: 'Zero Branco',
    code: 'M171',
  },
  {
    name: 'Zevio',
    code: 'M172',
  },
  {
    name: 'Ziano di Fiemme',
    code: 'M173',
  },
  {
    name: 'Ziano Piacentino',
    code: 'L848',
  },
  {
    name: 'Zibido San Giacomo',
    code: 'M176',
  },
  {
    name: 'Zignago',
    code: 'M177',
  },
  {
    name: 'Zimella',
    code: 'M178',
  },
  {
    name: 'Zimone',
    code: 'M179',
  },
  {
    name: 'Zinasco',
    code: 'M180',
  },
  {
    name: 'Zoagli',
    code: 'M182',
  },
  {
    name: 'Zocca',
    code: 'M183',
  },
  {
    name: 'Zogno',
    code: 'M184',
  },
  {
    name: 'Zola Predosa',
    code: 'M185',
  },
  {
    name: 'Zollino',
    code: 'M187',
  },
  {
    name: 'Zone',
    code: 'M188',
  },
  {
    name: 'Zoppè di Cadore',
    code: 'M189',
  },
  {
    name: 'Zoppola',
    code: 'M190',
  },
  {
    name: 'Zovencedo',
    code: 'M194',
  },
  {
    name: 'Zubiena',
    code: 'M196',
  },
  {
    name: 'Zuccarello',
    code: 'M197',
  },
  {
    name: 'Zugliano',
    code: 'M199',
  },
  {
    name: 'Zuglio',
    code: 'M200',
  },
  {
    name: 'Zumaglia',
    code: 'M201',
  },
  {
    name: 'Zumpano',
    code: 'M202',
  },
  {
    name: 'Zungoli',
    code: 'M203',
  },
  {
    name: 'Zungri',
    code: 'M204',
  },
];

export default municipalitiesData;
