const municipalities = [
  { name: 'Agrigento', code: 'A089' },
  { name: 'Alessandria', code: 'A182' },
  { name: 'Ancona', code: 'A271' },
  { name: 'Aosta', code: 'A326' },
  { name: 'Arezzo', code: 'A390' },
  { name: 'Ascoli Piceno', code: 'A462' },
  { name: 'Asti', code: 'A479' },
  { name: 'Avellino', code: 'A494' },
  { name: 'Bari', code: 'A662' },
  { name: 'Barletta-Andria-Trani', code: 'M379' },
  { name: 'Belluno', code: 'A757' },
  { name: 'Benevento', code: 'A783' },
  { name: 'Bergamo', code: 'A794' },
  { name: 'Biella', code: 'A859' },
  { name: 'Bologna', code: 'A944' },
  { name: 'Bolzano', code: 'B041' },
  { name: 'Brescia', code: 'B157' },
  { name: 'Brindisi', code: 'B180' },
  { name: 'Cagliari', code: 'B354' },
  { name: 'Caltanissetta', code: 'B429' },
  { name: 'Campobasso', code: 'B519' },
  { name: 'Carbonia-Iglesias', code: 'B719' },
  { name: 'Caserta', code: 'B963' },
  { name: 'Catania', code: 'C351' },
  { name: 'Catanzaro', code: 'C352' },
  { name: 'Chieti', code: 'C632' },
  { name: 'Como', code: 'C933' },
  { name: 'Cosenza', code: 'D086' },
  { name: 'Cremona', code: 'D150' },
  { name: 'Crotone', code: 'D122' },
  { name: 'Cuneo', code: 'D205' },
  { name: 'Enna', code: 'D403' },
  { name: 'Fermo', code: 'D505' },
  { name: 'Ferrara', code: 'D545' },
  { name: 'Firenze', code: 'D612' },
  { name: 'Foggia', code: 'D643' },
  { name: 'Forlì-Cesena', code: 'D707' },
  { name: 'Frosinone', code: 'D810' },
  { name: 'Genova', code: 'E001' },
  { name: 'Gorizia', code: 'E098' },
  { name: 'Grosseto', code: 'E202' },
  { name: 'Imperia', code: 'E290' },
  { name: 'Isernia', code: 'E329' },
  { name: "L'Aquila", code: 'E459' },
  { name: 'La Spezia', code: 'E463' },
  { name: 'Latina', code: 'E472' },
  { name: 'Lecce', code: 'E506' },
  { name: 'Lecco', code: 'E507' },
  { name: 'Livorno', code: 'E625' },
  { name: 'Lodi', code: 'E643' },
  { name: 'Lucca', code: 'E714' },
  { name: 'Macerata', code: 'E783' },
  { name: 'Mantova', code: 'E897' },
  { name: 'Massa-Carrara', code: 'E936' },
  { name: 'Matera', code: 'F052' },
  { name: 'Messina', code: 'F158' },
  { name: 'Milano', code: 'F205' },
  { name: 'Modena', code: 'F257' },
  { name: 'Monza e Brianza', code: 'F268' },
  { name: 'Napoli', code: 'F839' },
  { name: 'Novara', code: 'F952' },
  { name: 'Nuoro', code: 'F979' },
  { name: 'Olbia-Tempio', code: 'G015' },
  { name: 'Oristano', code: 'G113' },
  { name: 'Padova', code: 'G224' },
  { name: 'Palermo', code: 'G273' },
  { name: 'Parma', code: 'G337' },
  { name: 'Pavia', code: 'G388' },
  { name: 'Perugia', code: 'G478' },
  { name: 'Pesaro e Urbino', code: 'G482' },
  { name: 'Pescara', code: 'G482' },
  { name: 'Piacenza', code: 'G621' },
  { name: 'Pisa', code: 'G702' },
  { name: 'Pistoia', code: 'G713' },
  { name: 'Pordenone', code: 'G888' },
  { name: 'Potenza', code: 'H035' },
  { name: 'Prato', code: 'H074' },
  { name: 'Ragusa', code: 'H163' },
  { name: 'Ravenna', code: 'H199' },
  { name: 'Reggio Calabria', code: 'H224' },
  { name: 'Reggio Emilia', code: 'H226' },
  { name: 'Rieti', code: 'H282' },
  { name: 'Rimini', code: 'H294' },
  { name: 'Roma', code: 'H501' },
  { name: 'Rovigo', code: 'H620' },
  { name: 'Salerno', code: 'H769' },
  { name: 'Sassari', code: 'I452' },
  { name: 'Savona', code: 'I480' },
  { name: 'Siena', code: 'I726' },
  { name: 'Siracusa', code: 'I754' },
  { name: 'Sondrio', code: 'I829' },
  { name: 'Taranto', code: 'L049' },
  { name: 'Teramo', code: 'L103' },
  { name: 'Terni', code: 'L117' },
  { name: 'Torino', code: 'L219' },
  { name: 'Trapani', code: 'L331' },
  { name: 'Trento', code: 'L378' },
  { name: 'Treviso', code: 'L407' },
  { name: 'Trieste', code: 'L424' },
  { name: 'Udine', code: 'L558' },
  { name: 'Varese', code: 'L682' },
  { name: 'Venezia', code: 'L736' },
  { name: 'Verbano-Cusio-Ossola', code: 'M148' },
  { name: 'Vercelli', code: 'M158' },
  { name: 'Verona', code: 'M213' },
  { name: 'Vibo Valentia', code: 'M262' },
  { name: 'Vicenza', code: 'M267' },
  { name: 'Viterbo', code: 'M327' },
];

export default municipalities;
